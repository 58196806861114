import React from 'react';
import PropTypes from 'prop-types';

const MedicalHistory = ({ color }) => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9422 4.18281L10.8172 1.05781C10.7591 0.999791 10.6902 0.953782 10.6143 0.922415C10.5384 0.891047 10.4571 0.874936 10.375 0.875H4.125C3.79348 0.875 3.47554 1.0067 3.24112 1.24112C3.0067 1.47554 2.875 1.79348 2.875 2.125V3.375H1.625C1.29348 3.375 0.975537 3.5067 0.741116 3.74112C0.506696 3.97554 0.375 4.29348 0.375 4.625V15.875C0.375 16.2065 0.506696 16.5245 0.741116 16.7589C0.975537 16.9933 1.29348 17.125 1.625 17.125H10.375C10.7065 17.125 11.0245 16.9933 11.2589 16.7589C11.4933 16.5245 11.625 16.2065 11.625 15.875V14.625H12.875C13.2065 14.625 13.5245 14.4933 13.7589 14.2589C13.9933 14.0245 14.125 13.7065 14.125 13.375V4.625C14.1251 4.5429 14.109 4.46159 14.0776 4.38572C14.0462 4.30985 14.0002 4.2409 13.9422 4.18281ZM10.375 15.875H1.625V4.625H7.61641L10.375 7.38359V13.9875C10.375 13.9922 10.375 13.9961 10.375 14C10.375 14.0039 10.375 14.0078 10.375 14.0125V15.875ZM12.875 13.375H11.625V7.125C11.6251 7.0429 11.609 6.96159 11.5776 6.88572C11.5462 6.80985 11.5002 6.7409 11.4422 6.68281L8.31719 3.55781C8.2591 3.49979 8.19015 3.45378 8.11428 3.42241C8.03841 3.39105 7.9571 3.37494 7.875 3.375H4.125V2.125H10.1164L12.875 4.88359V13.375ZM8.5 10.875C8.5 11.0408 8.43415 11.1997 8.31694 11.3169C8.19973 11.4342 8.04076 11.5 7.875 11.5H4.125C3.95924 11.5 3.80027 11.4342 3.68306 11.3169C3.56585 11.1997 3.5 11.0408 3.5 10.875C3.5 10.7092 3.56585 10.5503 3.68306 10.4331C3.80027 10.3158 3.95924 10.25 4.125 10.25H7.875C8.04076 10.25 8.19973 10.3158 8.31694 10.4331C8.43415 10.5503 8.5 10.7092 8.5 10.875ZM8.5 13.375C8.5 13.5408 8.43415 13.6997 8.31694 13.8169C8.19973 13.9342 8.04076 14 7.875 14H4.125C3.95924 14 3.80027 13.9342 3.68306 13.8169C3.56585 13.6997 3.5 13.5408 3.5 13.375C3.5 13.2092 3.56585 13.0503 3.68306 12.9331C3.80027 12.8158 3.95924 12.75 4.125 12.75H7.875C8.04076 12.75 8.19973 12.8158 8.31694 12.9331C8.43415 13.0503 8.5 13.2092 8.5 13.375Z"
        fill={color}
      />
    </svg>
  );
};

export default MedicalHistory;

MedicalHistory.propTypes = {
  color: PropTypes.string,
};
