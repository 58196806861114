import Button from 'components/Buttons/Button';
import Dropdown from 'components/Dropdown/Index';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { hereditaryDiseases } from 'mocks/medicalRecords';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FamilyHistorySolid } from 'assets/icons/medicalRecords/family-history-solid.svg';
import { useRecordState } from 'store/modules/myRecords';

const AddFamilyHistory = ({ type, callback, selectedRecord }) => {
  //local State
  const [hereditaryDisease, setHereditaryDisease] = useState();
  const [formData, setFormData] = useState({
    hereditaryDisease: '',
    relationship: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const { loading, patientId } = useRecordState();
  const { update_medical_record, edit_medical_record } = useMedicalRecord();
  const [familyHistories] = useState([]);
  //Effect
  useEffect(() => {
    if (selectedRecord && type === 'Edit') {
      setFormData({
        relationship: selectedRecord?.patientRelationship,
        hereditaryDisease: selectedRecord?.hereditaryDisease,
      });
      setHereditaryDisease({
        id: selectedRecord?.hereditaryDisease,
        name: selectedRecord?.hereditaryDisease,
        label: selectedRecord?.hereditaryDisease,
      });
    }
  }, [selectedRecord, type]);
  
  const pushIntoArray = () => {
    familyHistories.push(formData);
    setTimeout(() => {
      if (formData.hereditaryDisease || formData.relationship) {
        setHereditaryDisease({});
        setFormData({
          hereditaryDisease: '',
          relationship: '',
        });
      }
    }, 500);
  };
  const updateMedicalRecord = () => {
    type === 'Edit'
      ? edit_medical_record('familyHistory', selectedRecord._id, formData, callback)
      : update_medical_record('familyHistory', patientId , formData, pushIntoArray);
  };
  const disabledBtn = () => {
    return !hereditaryDisease || !formData.relationship;
  };

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={type === 'Edit' ? updateMedicalRecord : callback}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <Dropdown
              label="Known Hereditary Disease"
              variant="lg:col-span-6 col-span-12"
              name="hereditaryDisease"
              id="hereditaryDisease"
              value={hereditaryDisease}
              optionContainerVariant="w-full md:min-h-[100px] min-h-[150px]"
              options={hereditaryDiseases}
              onChange={(data) => {
                setHereditaryDisease(data);
                setFormData({ ...formData, hereditaryDisease: data.value });
              }}
            />
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Relationship with the patient"
                type="text"
                id="relationship"
                name="relationship"
                readOnly={loading}
                value={formData?.relationship}
                onChange={handleChange}
                showError={false}
              />
            </div>
            {type === 'Add' && (
              <p
                data-testid="test-add"
                className={`text-brand_secondary font-campton_m text-14  ${
                  disabledBtn() ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={disabledBtn() ? null : updateMedicalRecord}
                disabled={disabledBtn()}
              >
                Add
              </p>
            )}
            {familyHistories.length > 0 && (
              <div className="col-span-12 border-t border-dashed border-neutral_stroke pt-6">
                {familyHistories?.map((item) => (
                  <div key={item} className="flex space-x-4 mb-6 last-of-type:mb-0">
                    <FamilyHistorySolid />
                    <div>
                    <p className="capitalize font-campton_m text-neutral_black text-14">{item?.hereditaryDisease}</p>
                    <p 
                    className='text-12 text-neutral_body font-campton_r mt-0.5'
                    >
                    Relationship: <span className='text-neutral_black'>{item?.relationship}</span></p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <Button name={type === 'Edit' ? 'Save' : 'Close'} theme="blue" styles="w-28 h-10" />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddFamilyHistory;
AddFamilyHistory.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};
