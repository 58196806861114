import React from "react";
import Status from "components/Status";
import PropTypes from "prop-types"
import { formatDate } from "utils/formatDate";
import { formatCurrency } from "utils/formatCurrency";

export default function Billing({ selectedPayment }) {
    return (
        <>
            <section className="border border-neutral_stroke_2 bg-[#F7FBFD] rounded-[16px] font-campton_r mb-[24px]">
                <div className="p-[16px] border-b border-b-neutral_stroke_2">
                    <div className="flex justify-between">
                        <p className="text-12 text-neutral_body">Bill Amount</p>
                        <Status
                            status={selectedPayment?.paymentStatus}
                            className="text-12 text-neutral_white border-0 bg-neutral_black"
                        />
                    </div>
                    <p className="text-14 text-neutral_black">{formatCurrency(selectedPayment?.amountExpected)}</p>
                </div>
                <div className="grid grid-cols-2">
                    <div className="p-[16px] border-r border-r-neutral_stroke_2">
                        <p className="text-12 text-neutral_body">Amount paid</p>
                        <p className="text-14 text-neutral_black">{formatCurrency(selectedPayment?.amountPaid)}</p>
                    </div>
                    <div className="p-[16px]">
                        <p className="text-12 text-neutral_body">Outstanding amount</p>
                        <p className="text-14 text-neutral_black">{formatCurrency(selectedPayment?.amountExpected - selectedPayment?.amountPaid)}</p>
                    </div>
                </div>
            </section>
            <section className="flex justify-between p-[16px] border border-neutral_stroke_2 rounded-[16px] font-campton_r mb-[24px]">
                <div>
                    <p className="text-12 text-neutral_body">Billed On</p>
                    <p className="text-14 text-neutral_black">{formatDate(selectedPayment?.createdAt)}</p>
                </div>
                <div>
                    <p className="text-12 text-neutral_body"> Due Date</p>
                    <p className="text-14 text-neutral_black">{selectedPayment?.dueDate ? formatDate(selectedPayment?.dueDate) : 'N/A'}</p>
                </div>
            </section>
        </>
    )
}

Billing.propTypes = {
    selectedPayment: PropTypes.object
};
