import { useAppSelector } from 'hooks/useReduxHook';
import { createSlice } from '@reduxjs/toolkit';
import {
  bookAppointment,
  getAppointments,
  getAvailableTimeSlot,
  getCurrentPolicy,
  getServices,
} from './actions';

const initialState = {
  appointments: {},
  availableTime: [],
  transactionDetails: {},
  liveAppointment: {},
  services: [],
  currentPolicy: {}
};

export const appointment = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setLiveAppointment: (state, action) => {
      state.liveAppointment = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.data;
        state.loading = false;
      })
      .addCase(bookAppointment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getAvailableTimeSlot.fulfilled, (state, action) => {
        state.availableTime = action.payload.data
        state.loading = false;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.services = action.payload.data;
        state.loading = false;
      })
      .addCase(getCurrentPolicy.fulfilled, (state, action) => {
        state.currentPolicy = action.payload.data;
        state.loading = false;
      });
  },
});
// Selectors
const selectAppointment = (state) => state.appointment;
export const { setLiveAppointment } = appointment.actions;
//App Redux State
export const useAppointmentState = () => useAppSelector(selectAppointment);

export default appointment.reducer;
