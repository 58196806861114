import dayjs from "dayjs"

export const convertTo24HourFormat = (time12Hour) => {
    if (!time12Hour) return ""
    const timeParts = time12Hour?.split(':')
    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1].split(' ')[0]);
    const meridiem = timeParts[1].split(' ')[1];

    if (meridiem === 'PM' && hours < 12) {
        hours += 12;
    } else if (meridiem === 'AM' && hours === 12) {
        hours = 0;
    }

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedTime;
}

export const isTimeInRange = (appointmentDate, startTime, endTime) => {
    if (!appointmentDate || !startTime || !endTime) return null
    const startT = convertTo24HourFormat(startTime).split(":")
    const endT = convertTo24HourFormat(endTime).split(":")
    const dateToCompare = dayjs(appointmentDate).subtract(1, 'hour');
    const currentDate = dayjs();

    if (!dateToCompare.isSame(currentDate, 'day')) return false

    const start = dayjs(dateToCompare).set('hour', startT[0]).set('minute', startT[1]);
    const end = dayjs(dateToCompare).set('hour', endT[0]).set('minute', endT[1]);

    return !!(currentDate.isAfter(start) && currentDate.isBefore(end));
}

export const isTimeInThePast = (appointmentDate, startTime, endTime) => {
    if (!appointmentDate || !startTime || !endTime) return null
    const startT = convertTo24HourFormat(startTime).split(":")
    const endT = convertTo24HourFormat(endTime).split(":")
    const dateToCompare = dayjs(appointmentDate);
    const currentDate = dayjs();
    
    if (!dateToCompare.isSame(currentDate, 'day')) return false

    const start = dayjs(dateToCompare).set('hour', startT[0]).set('minute', startT[1]);
    const end = dayjs(dateToCompare).set('hour', endT[0]).set('minute', endT[1]);

    return !((currentDate.isAfter(start) && end.isAfter(currentDate)) || end.isAfter(currentDate));
}