/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import walking from 'assets/svg/home/walking.svg';
import { SlideUp } from 'components/Animations';

const Walking = () => {
  return (
    <SlideUp className="h-full overflow-y-scroll">
      <p className="font-campton_m text-[32px] text-neutral_black leading-[38.4px]">
      Getting even a little bit of exercise can have a big impact on your health.
      </p>
      <p className="text-14 text-neutral_body font-campton_m mt-3">
      walking kust 30 minutes a day can reduce your risk of health disease, stoke, daibetes and cancer.
      </p>
      <p className="flex space-x-4 text-neutral_black text-12 my-6 font-campton_r">
        <span className="border border-neutral_stroke_2 px-2 py-1 rounded-full"> 5 min read</span>
        <span className="border border-neutral_stroke_2 py-1 px-2 rounded-full"> 21 May, 2023</span>
      </p>
      <div className="">
        <img src={walking} alt="healthy tips" className="w-full rounded-3xl object-cover lg:h-[400px] h-72" />
      </div>
      <div className="md:mb-0 mt-6 lg:h-[calc(100%-100px)] h-full">
        <p className="text-14 font-campton_r text-neutral_body mt-6">
        A study published in the British Journal of Sports Medicine found that people who walked just 30 minutes a day, five days a week, had a significantly lower risk of developing chronic diseases, including cardiovascular disease, type 2 diabetes, and cancer. The study showed that even low-intensity exercise can have a big impact on health.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
        Walking also has some other benefits, including improved mental health, better sleep, and a lower risk of developing osteoporosis. So if you're looking to improve your overall health, walking 30 minutes a day is a great place to start!
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
        So lace up your sneakers and get moving!"
        </p>
      </div>
    </SlideUp>
  );
};

export default Walking;
