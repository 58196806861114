import DefaultDatepicker from 'components/Datepicker/Index';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import React, { useEffect, useMemo, useState } from 'react';
import { medicalDiagnosisOptions } from 'mocks/medicalRecords';
import PropTypes from 'prop-types';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import dayjs from 'dayjs';
import Button from 'components/Buttons/Button';
import { useRecordState } from 'store/modules/myRecords';
import { removeSpacesFromWord } from 'utils/removeSpacesFromWord';

const AddMedicalHistory = ({ type, callback, selectedRecord }) => {
  const [formData, setFormData] = useState({
    name: '',
    medications: '',
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { update_medical_record, edit_medical_record, loading } = useMedicalRecord();
  //Redux Store
  const { patientId } = useRecordState();
  //Effect
  useEffect(() => {
    if (selectedRecord && type === 'Edit') {
      setStartDate(selectedRecord?.startDate ? new Date(removeSpacesFromWord(selectedRecord.startDate)) : "");
      setEndDate(selectedRecord?.endDate ? new Date(removeSpacesFromWord(selectedRecord.endDate)) : "");
      setFormData({
        medications: selectedRecord?.medications,
        name: selectedRecord?.name,
      });
    }
  }, [selectedRecord, type]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateMedicalRecord = () => {
    const payload = {
      ...formData,
      medications: [formData.medications],
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
    };
    type === 'Edit'
      ? edit_medical_record('medicalDiagnosis', selectedRecord._id, payload, callback)
      : update_medical_record('medicalDiagnosis', patientId, payload, callback);
  };

  const suggestions = useMemo(() => {
    if(formData.name) {
    return medicalDiagnosisOptions.filter((item) =>
      item.includes(formData.name?.toLowerCase())
    )}  
  }, [formData.name])
  
  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={updateMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <div className="lg:col-span-6 col-span-12">
              <div>
                <Input
                  label="Medical Diagnosis"
                  type="text"
                  id="medicalDiagnosis"
                  name="name"
                  // readOnly={loading}
                  value={formData?.name}
                  onChange={handleChange}
                />
              </div>
              <ul className="flex flex-wrap">
                {suggestions?.map(suggestion =>
                    <li
                        key={suggestion}
                        className="text-12 text-neutral_black font-campton_r
                    leading-[18px] bg-[#F2F3F3] py-[4px] px-[8px] mr-[8px] w-fit mb-[8px] cursor-pointer"
                        onClick={() => setFormData({...formData, name: suggestion})}
                    >
                        {suggestion}
                    </li>
                )}
              </ul>
            </div>
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Medications"
                type="text"
                id="medications"
                name="medications"
                // readOnly={loading}
                value={formData?.medications}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <DefaultDatepicker
              label="Start Date"
              containerVariant="lg:col-span-6 col-span-12"
              startDate={startDate}
              maxDate={new Date()}
              onSelect={(date) => setStartDate(date)}
              name="startDate"
              value={startDate}
            />
            <DefaultDatepicker
              label="End Date"
              containerVariant="lg:col-span-6 col-span-12"
              startDate={endDate}
              onSelect={(date) => setEndDate(date)}
              name="endDate"
              value={endDate}
            />
          </div>
          <div className="flex justify-end">
            <Button
              name="Save"
              theme="blue"
              className="w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white"
              loading={loading}
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddMedicalHistory;
AddMedicalHistory.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};
