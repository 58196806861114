import React from "react"
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import { motion } from "framer-motion";
import { ReactComponent as Reply } from 'assets/icons/reply.svg';
import { ReactComponent as PDF } from 'assets/icons/pdf.svg';
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import { formatTwelveHour } from "utils/formatTime";
import { calculateTimeDifference } from "utils/formatDate";

export default function Message({ message, index }) {
    const { loggedUser } = useSelector(state => state.auth)

    return (
        <motion.div
            initial={{ opacity: 0, x: "10px" }}
            animate={{ opacity: 1, x: 0, transition: { duration: .5, delay: index === 0 ? 0 : (.1 * index) } }}
            exit={{ opacity: 0 }}
            data-testid={message?.id}
            className="flex mb-[48px]"
        >
            <Avatar
                url={message?.sender?.profile_picture}
                width="34px"
                height="34px"
                //alt={admin?.firstName?.split('')[0]}
                initials={`${message?.sender?.firstName?.split('')[0] || ''}${message?.sender?.lastName?.split('')[0] || ''}`}
                className="w-[34px] h-[34px] mr-[16px] shrink-0"
            />
            <div className="w-full">
                <div className="mb-[8px]">
                    <div className="flex justify-between items-center">
                        <p className="text-14 text-neutral_black font-campton_m mb-[4px]">
                            {message?.sender?.id === loggedUser?._id ? 'You' : 'Dr. unknown'}
                            <span className="text-12 text-neutral_body"> {new Date(message?.updatedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} ({calculateTimeDifference(message?.updatedAt)})</span>
                            <span className="text-12 text-neutral_body"> {formatTwelveHour(message?.createdAt)}</span>
                        </p>
                        <Reply className="cursor-pointer hide_tap" />
                    </div>
                    <p className="text-12 text-neutral_body font-campton_r ellipsis_2_lines">
                        <span className="text-[#A9B2B2]">To:</span> {message?.ccProviders?.map((item) => `${item?.qualification || ''}. ${item?.firstName} ${item?.lastName} (${item?.email}), `)}
                    </p>
                </div>
                <div className="border border-neutral_stroke_2 p-[16px] rounded-[8px]">
                    {message?.content?.file ?
                        <div className="mb-[8px]">
                            {message?.content?.fileType !== 'pdf' ?
                                <img
                                    src={message?.content?.file}
                                    width="100%"
                                    height="100%"
                                    alt={message?.content?.file}
                                /> :
                                <a href={message?.content?.file} download>
                                    <div className="flex items-center">
                                        <PDF className="mr-[8px] shrink-0" />
                                        <p className="text-14 font-campton_r text-neutral_black">{message?.content?.file}</p>
                                    </div>
                                </a>
                            }
                        </div> : ''
                    }
                    <div className="text-14 font-campton_r text-neutral_black">{parse(message?.content?.message)}</div>
                </div>
            </div>
        </motion.div>
    )
}

Message.propTypes = {
    message: PropTypes.object,
    index: PropTypes.number
}