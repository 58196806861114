import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"

export default function ScrollContainer({ children }) {
    const ref = useRef();
    const [containerHeight, setContainerHeight] = useState("auto");

    useEffect(() => {
        if (ref.current) {
            const contentHeight = ref.current.scrollHeight;
            const windowHeight = window.innerHeight;
            const newHeight = contentHeight > windowHeight ? windowHeight - 16 : "auto"; // 16px is the approximate scrollbar width

            setContainerHeight(newHeight)
        }
    }, [])

    return (
        <div
            ref={ref}
            style={{ height: containerHeight }}
            className="overflow-auto pb-[80px] md:pb-[130px]"
        >
            {children}
        </div>
    );
}

ScrollContainer.propTypes = {
    children: PropTypes.any
}