import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/useReduxHook';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { setUserInfo, useAuthState } from 'store/modules/auth';
import Button from 'components/Buttons/Button';
import { useRecordState } from 'store/modules/myRecords';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';

const Form = ({ type, callback, selectedRecord }) => {
  //local State
  const [phone, setPhone] = useState(type === 'Edit' ? selectedRecord?.countryCode+selectedRecord?.phoneNumber : '');
  const [formData, setFormData] = useState({
    name: '',
    emailAddress: '',
    address: '',
  });
    //Hooks
    const dispatch = useAppDispatch();
    const { update_medical_record, edit_medical_record } = useMedicalRecord();
    const {loading} = useRecordState()
    //Redux
    const { userInfo } = useAuthState();
//Effect
useEffect(() => {
  if (selectedRecord && type === 'Edit') {
    setFormData({
      name: selectedRecord?.name,
      emailAddress: selectedRecord?.emailAddress,
      phoneNumber: selectedRecord?.phoneNumber,
      address: selectedRecord?.address
    });
    dispatch(
      setUserInfo({
        phoneNumber: selectedRecord?.phoneNumber,
        countryCode: selectedRecord?.countryCode,
      }),
    );
  }
}, [selectedRecord, type]);
//Redux Store
const {patientId} = useRecordState()
  //Handler function
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const updateMedicalRecord = () => {
    const payload = { ...formData, ...userInfo };
    type === 'Edit'
      ? edit_medical_record('familyHospital', selectedRecord._id,  payload , callback)
      : update_medical_record('familyHospital', patientId, payload, callback);
  };

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={updateMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Hospital Name"
                type="text"
                id="name"
                name="name"
                readOnly={loading}
                value={formData?.name}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Email"
                type="text"
                id="emailAddress"
                name="emailAddress"
                  readOnly={loading}
                value={formData?.emailAddress}
                onChange={handleChange}
              />
            </div>

            <Phone value={phone} onChange={setPhone} variant="lg:col-span-6 col-span-12" />
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Address"
                type="text"
                id="address"
                name="address"
                readOnly={loading}
                value={formData?.address}
                onChange={handleChange}
                showError={false}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button name="Save" theme="blue" className="w-28 h-10" disabled={checkEmptyProperties(formData) || !userInfo?.phoneNumber} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default Form;
Form.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};