import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/useReduxHook';
import {
    getProviders,
} from './actions';

const initialState = {
    specialists: [],
};

export const providers = createSlice({
    name: 'providers',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getProviders.fulfilled, (state, action) => {
                state.specialists = action?.payload?.data || []
            })
    },
});
const selectProviders = (state) => state.providers;

//App Redux State
export const useProviderState = () => useAppSelector(selectProviders);

export default providers.reducer;
