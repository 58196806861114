import React from 'react';
import PropTypes from 'prop-types';

const TipBox = ({ img, time, title, onClick, description, className, id }) => {

  return (
    <div 
      className={`shrink-0 cursor-pointer shadow-md transition ease-in-out duration-500 ${className}`}
      onClick={onClick} 
      data-testid={`test-${id}`}
    >
      <div className="overflow-hidden">
        <img src={img} alt="healthy tips" className="w-full h-full rounded-tr-[8px] rounded-tl-[8px] object-cover" />
      </div>
      <div className="p-4 font-campton_r border-neutral_stroke_2">
        <p className="text-10 text-brand_secondary">{time} min read</p>
        <p className="text-14 text-neutral_black font-campton_m">{title}</p>
        <p className='text-12 text-neutral_body mt-1 truncate-2'>{description}</p>
      </div>
    </div>
  );
};


export default TipBox;

TipBox.propTypes = {
  img: PropTypes.string,
  time: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string
};
