import Button from 'components/Buttons/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import successGif from 'assets/svg/check.gif';
import { useAuthState } from 'store/modules/auth';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';

const LoginSuccess = () => {
  //Hooks
  const navigate = useNavigate();
  const { get_patient_profile } = useMedicalRecord()

//Redux Store
  const {loggedUser} = useAuthState();
  //Handler function
  const handleLogin = () => {
    get_patient_profile()
    navigate('/dashboard')
  }
  return (
    <div className=''>
      <div className="text-center mt-2">
        <div className="flex items-center justify-center">
          <img src={successGif} alt="success" className='w-[50%] [h-50%]'/>
        </div>
        <p className="font-campton_sb text-20 md:text-28 text-neutral_black">Congratulations, {loggedUser.firstName}!</p>
        <p className="mt-4 font-campton_r text-14 md:text-16 text-neutral_body">
          Your account was successfully created. Enjoy the privileges GabbiHealth offers
        </p>
      </div>
      <Button name="My Dashboard" theme="blue" className="w-full mt-10" onClick={handleLogin} />
    </div>
  );
};

export default LoginSuccess;
