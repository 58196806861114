import { useState } from 'react'
import useMakeRequest from 'hooks/useMakeRequest'
import useTryCatch from 'hooks/useTryCatch'
import { getOutstandingBills } from 'store/modules/billsAndPayments/actions'

export default function useBillsAndPayment() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_outstandingBills = () => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getOutstandingBills(), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    };

    const get_billingHistory = ({ params }) => {
        const url = `/patient-profile/bills`;
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, params, alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    };

    const get_paymentHistory = (id) => {
        const url = `payments/${id}/payment-history`;
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    };

    const get_institutionData = (id) => {
        const url = `service/clinicals?institutionId=${id}&type=institution`;
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    };

    const get_institution_policy = (institutionId) => {
        const url = `policy/current/${institutionId}`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    };

    const verify_outstanding_bill_payment = ({ billId, reference }) => {
        const url = `payments/${billId}/outstanding-payment/${reference}`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get' })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    };

    const verify_bill_part_payment = ({ billId, reference, status }) => {
        const url = `payments/${billId}/bill-payment/${reference}/${status}`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get' })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    };

    return {
        get_outstandingBills,
        get_billingHistory,
        get_paymentHistory,
        get_institutionData,
        verify_outstanding_bill_payment,
        get_institution_policy,
        verify_bill_part_payment,
        loading,
    }
}
