import React, { useContext } from 'react';
import { ReactComponent as Calendar } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import dayjs from 'dayjs';
import { ReactComponent as BloodTypeSolid } from 'assets/icons/medicalRecords/blood-type-solid.svg';
import { ReactComponent as Saturation } from 'assets/icons/vitals/saturation.svg';
import { ReactComponent as Temperature } from 'assets/icons/vitals/temperature.svg';
import { ReactComponent as HeartRate } from 'assets/icons/vitals/heartbeat.svg';
import { ReactComponent as Height } from 'assets/icons/vitals/height.svg';
import { ReactComponent as ResRate } from 'assets/icons/vitals/respiration.svg';
import { ReactComponent as Weight } from 'assets/icons/medicalRecords/lifestyle.svg';
import { ReactComponent as Bmi } from 'assets/icons/vitals/bmi.svg';
import PropTypes from 'prop-types';
import { VisitContext } from 'context';
import TreatmentPlan from './VisitSummaryPdf/TreatmentPlan';
import LabRequest from './VisitSummaryPdf/LabRequest';
import ChiefComplaint from './VisitSummaryPdf/ChiefComplaint';
import PhysicalExamination from './VisitSummaryPdf/PhysicalExamination';

const VisitSummaryPdf = ({ labTests, templateRef, visitDetails, visitTreatmentPlan,  }) => {
  const { visit, progressNote } = useContext(VisitContext);
  const vitals = progressNote?.vitals ?? visitDetails?.vitals

  const vitalSign = [
    {
      icon: <BloodTypeSolid className="w-6 h-6" />,
      title: 'Blood Pressure',
      value: vitals?.sysBloodPressure
        ? `${vitals?.sysBloodPressure}/${vitals?.diaBloodPressure}`
        : 'N/A',
      label: 'mmHg',
    },
    {
      icon: <HeartRate className="w-6 h-6" />,
      title: 'Heart Rate',
      value: vitals?.heartRate ?? 'N/A',
      label: 'bpm',
    },
    {
      icon: <Temperature className="w-6 h-6" />,
      title: 'Temperature',
      value: vitals?.temperature ?? 'N/A',
      variant: 'text-[#881616]',
      label: '°C',
    },
    {
      icon: <ResRate className="w-6 h-6" />,
      title: 'Respiratory Rate',
      value: vitals?.respiratoryRate ?? 'N/A',
      label: 'bpm',
      variant: 'text-[#106032]',
    },
    {
      icon: <Saturation className="w-6 h-6" />,
      title: 'Oxygen Saturation',
      value: vitals?.oxygenSaturation ?? 'N/A',
      variant: 'text-[#935F06]',
      label: '%',
    },
    {
      icon: <Bmi className="w-6 h-6" />,
      title: 'BMI',
      value: `${Number(vitals?.bmi).toFixed(2)}`,
      variant: 'text-[#935F06]',
    },
    {
      icon: <Height className="w-6 h-6" />,
      title: 'Height',
      value: vitals?.height ?? 'N/A',
      variant: 'text-[#935F06]',
      label: 'meters',
    },
    {
      icon: <Weight className="w-6 h-6" />,
      title: 'Weight',
      value: vitals?.weight ?? 'N/A',
      variant: 'text-[#881616]',
      label: 'kg',
    },
  ];

  const instructions = progressNote?.visitSummary?.instructions ?? visitDetails?.visitSummary?.instructions
  const primaryDiagnosis = progressNote?.primaryDiagnosis?.primaryDiagnosis ?? visitDetails?.primaryDiagnosis
  const secondaryDiagnosis = progressNote?.secondaryDiagnosis?.secondaryDiagnosis ?? visitDetails?.secondaryDiagnosis
  const physicalExamination = progressNote?.physicalExamination ?? visitDetails?.physicalExamination
  const chiefComplaint = progressNote?.chiefComplaint ?? visit?.chiefComplaint
  const labRequests = progressNote?.labRequests ?? labTests?.labTest


  return (
    <div ref={templateRef}>
      <div className=" border-b-neutral_stroke_2 border-b-[0.5px] pb-8">
        <div className="p-6">
          <p className="font-campton_m text-base text-neutral_black">After Visit Summary</p>
          <div className="flex ml-[2px]">
            <Calendar className="mr-[8px] w-3" />
            <p className="text-10 font-campton_r mb-[8.5px] text-neutral_body">
              Visit Duration:{' '}
              <span className="ml-1">
                {dayjs(visit?.createdAt).format('DD, MMM YYYY')} to {dayjs(visit?.updatedAt).format('DD, MMM YYYY')}
              </span>
            </p>
          </div>
          <p className="text-[10px] font-campton_r text-neutral_black">
            {visitDetails?.patient?.firstName} {visitDetails?.patient?.lastName}
          </p>
        </div>
      </div>
      <div className="py-2 px-8 w-full">
        <div className="w-full">
          <div className="bg-[#FAFAFA] w-full">
            <div className="flex w-full border-b-[0.5px] border-neutral_stroke_2 py-2.5 px-4">
              <Document className="mr-3" />
              <p className="font-campton_r text-brand_secondary text-12 ">Follow-up Instructions</p>
            </div>
            {
              <div className="px-4 py-3">
                {instructions ? (
                  instructions.map((item) => (
                    <p key={item?._id} className="font-campton_r text-[9px] text-neutral_black mt-2 first-of-type:mt-0">
                      {item.note ?? 'N/A'}
                    </p>
                  ))
                ) : (
                  <p className="font-campton_r text-neutral_black text-[9px]">No follow-up instruction added</p>
                )}
              </div>
            }
          </div>
          <div className="grid grid-cols-12 mt-2 gap-2">
            <div className="col-span-7">
              <div className="bg-[#FAFAFA] py-2.5 px-4">
                <div className="flex w-full">
                  <Document className="mr-3" />
                  <p className="font-campton_r text-brand_secondary text-12 ">You were diagnosed with</p>
                </div>
                <div className="mt-4 px-3">
                  <ul className="text-10 font-campton_r text-neutral_black grid grid-cols-2 list-disc">
                    <li>{primaryDiagnosis ?? 'N/A'}</li>
                    <li>{secondaryDiagnosis ?? 'N/A'}</li>
                  </ul>
                </div>
              </div>
              <div className="bg-[#FAFAFA] py-2.5 px-4 mt-2">
                <div className="flex w-full">
                  <Document className="mr-3" />
                  <p className="font-campton_r text-brand_secondary text-12 ">Vitals as of This Visit</p>
                </div>
                <div className="grid grid-cols-12 mt-4">
                  {vitalSign.map((item) => (
                    <div
                      key={item.title}
                      className="col-span-6 py-3 border-b-[0.5px] border-b-neutral_stroke_2 flex space-x-2"
                    >
                      {item.icon}
                      <div className="font-campton_r text-[8px]">
                        <p className="text-neutral_body">{item.title}</p>
                        <p className="text-neutral_black font-campton_m">
                          {item.value}
                          {item.label}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-5 bg-[#FAFAFA] py-2.5 px-4">
              <div className="flex w-full">
                <Document className="mr-3" />
                <p className="font-campton_r text-brand_secondary text-12 ">You were attended to by</p>
              </div>
              <div className="mt-4">
                {visit?.provider?.lastName ? (
                  <div>
                    <p className="text-10 text-neutral_black font-campton_r capitalize">
                      {visit?.provider?.firstName} {visit?.provider?.lastName}{' '}
                      <span className="ml-1 text-neutral_body">| {visit?.provider.GPINumber ?? 'N/A'}</span>
                    </p>
                    <p className="text-10 text-neutral_black font-campton_r mt-0.5">
                      {visit?.provider?.specialty ?? 'N/A'}
                    </p>
                    <p className="text-10 text-neutral_black font-campton_r">
                      {visit?.provider?.phoneNumber
                        ? `${visit?.provider?.countryCode}${visit?.provider?.phoneNumber}`
                        : 'N/A'}
                      <span className="ml-1 text-neutral_body">| {visit?.provider.emailAddress}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-10 text-neutral_black font-campton_m mb-0.5 uppercase">
                      {visit?.institution?.businessName}
                    </p>
                    <p className="text-12 text-neutral_body font-campton_r">
                      {visit?.institution?.businessEmailAddress}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <PhysicalExamination physicalExamination={physicalExamination} />
          <ChiefComplaint chiefComplaint={chiefComplaint}/>
          <LabRequest labRequests={labRequests}/>
         <TreatmentPlan progressNote={progressNote} visitTreatmentPlan={visitTreatmentPlan}/>
        </div>
      </div>
    </div>
  );
};

export default VisitSummaryPdf;
VisitSummaryPdf.propTypes = {
  templateRef: PropTypes.any,
  labTests: PropTypes.object,
  visitDetails: PropTypes.object,
  visitTreatmentPlan: PropTypes.object,
};
