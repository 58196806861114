import React, { useState } from 'react';
import Dropdown from 'components/Dropdown/Index';
import { SlideUp } from 'components/Animations';
import TextArea from 'components/Inputs/TextArea';
import { cancellation_reasons } from 'mocks/appointment';
import PropTypes from 'prop-types';

const CancelAppointment = ({formData, setFormData}) => {
  const [reason, setReason] = useState()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div className="mt-2 pb-40">
      <p className="text-14 font-campton_r text-neutral_body">
        Select reason, read appointment policy and authorize rescheduling
      </p>
      <div className="border-t pt-6 border-neutral_stroke mt-6 border-dashed">
        <Dropdown
          id="reason"
          label="Reason"
          name="reason"
          options={cancellation_reasons}
          value={reason}
          onChange={(data) => {
            setReason(data)
            setFormData({ ...formData, reason: data.value });
          }}
        />
      </div>
      {formData.reason === 'others' && (
        <SlideUp>
          <TextArea
            placeholder="Type here..."
            type="text"
            id="reason"
            name="other_reason"
            variant="mt-4"
            onChange={handleChange}
          />
        </SlideUp>
      )}
      <div className="border-t border-dashed border-neutral_stroke pt-4 mt-6 text-14 font-campton_r">
        <p className="text-neutral_black font-campton_m">Reddington’s Appointment Cancellation Policy</p>
        <p className='text-neutral_body mt-2'>
          This appointment policy outlines the guidelines and procedures for managing appointments at Reddington
          Hospital. Our goal is to ensure efficient, timely, and high-quality healthcare services for all patients while
          maintaining a smooth workflow for our medical staff. Please read this policy carefully to understand the
          appointment booking process and your responsibilities as a patient.
        </p>
        <p className='text-neutral_black my-4'> Appointment Guidelines:</p>
        <ul className="list-decimal text-neutral_body" >
          <li>
            Arrival Time: Patients should arrive at the hospital at least 15 minutes before their scheduled appointment
            to complete any necessary paperwork and check-in procedures.
          </li>
          <li>
            Late Arrivals: If a patient arrives more than 15 minutes late for their appointment, the hospital reserves
            the right to reschedule the appointment to minimize disruptions to the schedule.
          </li>
          <li>
            Cancellation and Rescheduling: Patients are encouraged to provide at least 24 hours notice for appointment
            cancellations or rescheduling. Failure to do so may result in a missed appointment fee.
          </li>
          <li>
            No-Show Policy: If a patient fails to show up for a scheduled appointment without prior notice, a no-show
            fee may be applied, and future appointment requests may be affected.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CancelAppointment;
CancelAppointment.propTypes = {
    formData: PropTypes.object,
    setFormData: PropTypes.func,
  };
