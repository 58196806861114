import Button from 'components/Buttons/Button';
import React, { useEffect, useState, useMemo } from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import { SlideUp } from 'components/Animations';
import { ReactComponent as MedicationIllustration } from 'assets/svg/medications-empty.svg';
import useTable from 'hooks/useTable';
import Table from 'components/Table';
import EmptyState from 'components/EmptyState';
import { useRecordState } from 'store/modules/myRecords';
import useDebounce from 'hooks/useDebounce';
import useMyRecords from 'hooks/fetch/useMyRecords';
import { useAuthState } from 'store/modules/auth';
import dayjs from 'dayjs';
import { Search } from 'components/Inputs';
import { medication_list_header } from 'mocks/myRecords';
import DefaultModal from 'components/Modals/DefaultModal';
import ViewMedications from './ViewMedications';
import { groupDataByDate } from 'utils/groupDataByDate';
import { ReactComponent as Tablet } from 'assets/svg/home/paracetamol.svg';
import { ReactComponent as TabletSm } from 'assets/svg/tablet.svg';
import Spinner from 'components/Spinner';
import { ReactComponent as Calendar } from 'assets/svg/appointment/calendar.svg';
import NewMedication from './NewMedication';

const Medications = ({ callback }) => {
  const { selectLimit, changePage, params, handleSearch } = useTable();
  const [showModal, setShowModal] = useState('');
  const { loggedUser } = useAuthState();
  const [selectedItem, setSelectedItem] = useState({});
  const { get_medications, loading } = useMyRecords();
  const { medications } = useRecordState();
  const debounceValue = useDebounce(params.search);

  useEffect(() => {
    get_medications(loggedUser._id, {
      ...params,
    });
  }, [debounceValue, params.limit, params.page]);

  const handleSingleMedications = (item) => {
    setSelectedItem(item);
    setShowModal('view');
  };
  const ongoingMedications = useMemo(() => {
    return medications?.medications?.filter((option) => option.status === 'ongoing').splice(0, 1);
  }, [medications?.medications]);

  const filteredMedications = useMemo(() => groupDataByDate(medications?.medications, 'startDate'), [medications]);

  const handleCallback = () => {
    get_medications(loggedUser._id, {
      ...params,
      limit: 10,
    });
    setShowModal('')
  }

  return (
    <DefaultModal
      closeModal={callback}
      styles="w-full md:w-[457px]"
      position="modal-right"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
      title="Medications"
    >
      <SlideUp>
        <>
          <p className="text-12 font-campton_r text-neutral_body">Ongoing medication(s)</p>
          <div className="bg-light_blue p-4 mt-4 rounded-xl">
            {ongoingMedications?.length ? (
              <>
                {ongoingMedications?.map((item) => (
                  <div className="" key={item.medicationName}>
                    <div className="text-14 text-neutral_black flex justify-between">
                      <p className="font-campton_m">
                        {item.medicationName} ({item.dosage})
                      </p>
                      <p className="font-campton_r py-1 px-2 bg-[#10354114] rounded-full">
                        {' '}
                        {dayjs(item?.endDate).format('D') - dayjs(new Date()).format('D')} days left
                      </p>
                    </div>
                    <p className="flex">
                      <Calendar />
                      <span className="font-campton_r text-12 text-neutral_body ml-0.5">
                        {dayjs(item.startDate).format('MMM DD, YYYY')} - {dayjs(item.endDate).format('MMM DD, YYYY')}
                      </span>
                    </p>
                    <p className="text-12 text-neutral_body font-campton_r mt-2 flex">
                      <TabletSm className="mr-0.5" />
                      {item.frequency} <span className="text-[#DFE2E2] mx-1">|</span> {item?.routeOfAdministration}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <p className="text-14 text-neutral_black font-campton_r text-center">No Ongoing Medication Available</p>
            )}
          </div>
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <p className="text-14 font-campton_m text-neutral_black">Medication History</p>
              <Button
                name="New medication"
                theme="blue"
                className="w-[148px] h-8"
                onClick={() => setShowModal('new')}
              />
            </div>
            <Search id="search" name="search" placeholder="Search by medication name" onChange={handleSearch} />
          </div>

          <Table
            headers={medication_list_header}
            totalCount={medications?.total}
            hasHeader={false}
            perPage={medications?.limit}
            currentPage={medications?.page}
            hasTable={false}
            selectLimit={selectLimit}
            data={filteredMedications}
            changePage={changePage}
            loading={loading}
            emptyState={
              <EmptyState
                illustration={<MedicationIllustration />}
                title="No Medication Available."
                description="You currently have no medication.
                              All your medication will be displayed here."
              />
            }
          >
            {loading ? 
              <Spinner className="h-[50vh]" />
             : 
              <>
                {filteredMedications.length > 0 ? (
                  <div className="overflow-scroll h-[50vh]">
                      {filteredMedications?.map((item) => (
                        <div key={item?._id}>
                          <p className="font-campton_r text-12 text-neutral_body ">
                            {dayjs(item.date).format('MMM DD, YYYY')}
                          </p>
                          {item.items.map((data) => (
                            <div
                              key={data.medicationName}
                              onClick={() => handleSingleMedications(data)}
                              data-testid={data?._id}
                              className="flex space-x-2 pb-4 border-b border-b-neutral_stroke_2 w-full cursor-pointer my-4"
                            >
                              <Tablet />
                              <div className="col-span-10 w-full">
                                <p className="text-12 font-campton_m text-neutral_black">
                                  <span className='capitalize'>{data?.medicationName}</span> ({data.dosage})
                                </p>
                                <div className="bg-primary_tint_50 mt-1 rounded py-2 px-[6px] w-full">
                                  <p className="font-campton_r text-12 text-neutral_black">
                                    {data.createdBy.patientId ? 'Added By' : 'Prescribed By'}
                                  </p>
                                  <p className="text-12 font-campton_r text-neutral_black">
                                    {data?.createdBy?.firstName} {data?.createdBy?.lastName}
                                  </p>
                                  <p className="font-campton_r text-12 text-neutral_body">
                                    {data?.createdBy?.emailAddress ?? 'N/A'} |{' '}
                                    {data.createdBy.patientId ? loggedUser?.gNumber : data?.createdBy?.GPINumber}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                  ) : (
                    ''
                  )}
                </>
            }
          </Table>
        </>
      </SlideUp>
      {showModal && (
        <DefaultModal
          closeModal={() => setShowModal('')}
          styles="w-[457px] max-w-[457px]"
          icon={<ArrowBack fill="#748181" className="w-2" />}
          position={showModal !== 'delete' ? 'modal-right' : ''}
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={showModal === 'view' ? `${selectedItem?.medicationName} (${selectedItem?.dosage})` : `${showModal} Medication`}
        >
          {showModal === 'view' ? <ViewMedications data={selectedItem} setShowModal={setShowModal} callback={handleCallback}/>
          : <NewMedication type={showModal} callback={handleCallback} selectedItem={selectedItem}/>}
        </DefaultModal>
      )}
    </DefaultModal>
  );
};

export default Medications;
Medications.propTypes = {
  callback: PropTypes.any,
};
