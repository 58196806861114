import React from 'react';
import PropTypes from 'prop-types';
import { useAppointmentState } from 'store/modules/appointments';
import { formatCurrency } from 'utils/formatCurrency';

const AppointmentPolicy = ({ data, amount }) => {
  const { currentPolicy } = useAppointmentState();
  const cancellationFee = (currentPolicy?.currentAppointmentPolicy?.cancellationFeePercentage / 100) * amount;

  return (
    <div className='modal-right-body'>
      <div className="font-campton_r text-14 text-neutral_black">
        <p className="">
          At <span className="capitalize">{data.providerInfo?.institionName}</span>, we are committed to providing you
          with the best possible healthcare experience. To ensure a smooth and efficient appointment process for both our
          patients and healthcare providers, we have established the following appointment policy:
        </p>
        <div className="pl-3 mt-8">
          <ul className="list-decimal">
            <div>
              <li className="">Scheduling Appointments</li>
              <p className="pl-2 text-neutral_body mt-2">
                Appointment Types: We offer a variety of appointment types, including in-person consultations and
                telehealth appointments. You can schedule appointments based on your needs and preferences.
              </p>
            </div>
            <div className="mt-4">
              <li className="">Appointment Confirmation</li>
              <p className="pl-2 text-neutral_body mt-2">
                Confirmation Notifications: You will receive appointment confirmation notifications via email. Please
                confirm your appointment to ensure it is reserved for you.
              </p>
            </div>
            <div className="mt-4">
              <li className="">Cancellation and Rescheduling:</li>
              <div className="text-neutral_body pl-2 mt-2">
                <p className="">
                  Cancellation Notice: If you need to cancel this appointment, a cancellation fee of{' '}
                  {formatCurrency(cancellationFee)} will be charged.
                </p>
                <p className="mt-2">
                  Rescheduling Notice: please notify us at least{' '}
                  {currentPolicy?.currentAppointmentPolicy?.reschedulingTimeframeDays} days before the appointment date.
                  This allows us to accomodate other patients who may be in need of medical care
                </p>
              </div>
            </div>
            <div className="mt-4">
              <li className="">No-Show Policy</li>
              <p className="pl-2 text-neutral_body mt-2">
                Policies and procedures for handling patients who do not show up for their appointments without prior
                notic, The No- show fee charge is %/flat appointment fee refundable,{' '}
                {currentPolicy?.currentAppointmentPolicy?.noShowPolicy} Failure to provide adequate notice for appointment
                changes may result in a cancellation fee or impact your appointment scheduling privileges.
              </p>
            </div>
            <div className="mt-4">
              <li className="">Late Arrival Policy</li>
              <p className="pl-2 text-neutral_body mt-2">
                Arrival: {currentPolicy?.currentAppointmentPolicy?.lateArrivalPolicy}
              </p>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppointmentPolicy;
AppointmentPolicy.propTypes = {
  data: PropTypes.object,
  amount: PropTypes.number,
};
