const base = '/patient-auth'

export const auth = {
    forgot_password: `${base}/forgot-password`,
    create_account: `${base}/signup`,
    verify_account: `${base}/verify-account`,
    reset_password: `${base}/reset-password`,
    create_password: `${base}/create-password`,
    change_password: `${base}/change-password`,
    validate_reset_password_code: `${base}/validate-reset-password-code`,
    login: `${base}/login`,
    refresh_token: `${base}/refresh-token`,
    resend_otp: `${base}/resend-otp`,
    update_user_info: `patient-profile/basic-info`,
}