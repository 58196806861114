import React from 'react'
import disability from 'assets/svg/home/disability.svg';
import { SlideUp } from 'components/Animations';

const Disability = () => {

  return (
    <SlideUp className='lg:h-[calc(100%-70px)] h-screen overflow-y-scroll pb-9'>
      <p className='font-campton_m text-[32px] text-neutral_black leading-[38.4px]'>Disability</p>
      <p className='text-14 text-neutral_body font-campton_m mt-3'>Disability is not a limit; it’s a part of the unique mosaic abilities:</p>
      <p className='flex space-x-4 text-neutral_black text-12 my-6 font-campton_r'>
        <span className='border border-neutral_stroke_2 px-2 py-1 rounded-full'> 5 min read</span>
        <span className='border border-neutral_stroke_2 py-1 px-2 rounded-full'> 21 May, 2023</span>
      </p>
      <div className=''>
        <img src={disability} alt="healthy tips" className="w-full rounded-3xl object-cover h-80" />
      </div>
      <div className="md:mb-0 mt-6 lg:h-[calc(100%-100px)] h-full">
        <p className='text-14 font-campton_r text-neutral_body'>
        One’s capabilities are defined as the combination of passion and perseverance. Any individual passionate about a particular goal, skill, or area of life and is persistent, enduring any challenges or setbacks will achieve great results regardless of their abilities or disabilities.
        </p>
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        Helen Keller is an example of someone who overcame her disability and achieved great things. Helen was born deaf and blind, but with the help of her teacher and companion, Anne Sullivan, she learned to communicate and became a world-renowned author, activist, and lecturer. She proved that ability is not limited by disability.
        </p>
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        Your disabilities do not define you, Look beyond them today and focus on your abilities and what you can achieve in becoming great.
        </p>
        <p className='text-14 font-campton_r text-neutral_body'>
        Also, your health is important. Do not neglect your health; eat a balanced diet, stay hydrated, and prioritize your mental health. Always reach out to GabbiHealth’s healthcare provider when faced with any challenges.
        </p>    
      </div>
    </SlideUp>
  )
}

export default Disability