/* eslint-disable max-len */
import Navbar from 'components/Navbar';
import React, { useMemo } from 'react';
import { ReactComponent as Paracetamol } from 'assets/svg/home/paracetamol.svg';
import UserProfile from './UserProfile';
import DefaultModal from 'components/Modals/DefaultModal';
import ActionCards from './Components/ActionCards';
import AppointmentCard from './Components/AppointmentCard';
import Spinner from 'components/Spinner';
import useHomeCompLogic from './useHomeCompLogic';
import TipBox from './Components/TipBox';
import { healthTips } from 'mocks/myRecords';
import { useRecordState } from 'store/modules/myRecords';
import dayjs from 'dayjs';
import ScrollContainer from 'components/ScrollContainer';
import EmptyState from 'components/EmptyState';
import { ReactComponent as MedicationEmpty } from 'assets/svg/medications-empty.svg';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { medications } = useRecordState();
  const {
    loading,
    pathname,
    loggedUser,
    appointments,
    modalContent,
    profilePercentage,
    completeProfile,
    setCompleteProfile,
    is_appointment_loading,
  } = useHomeCompLogic();

  const ongoingMedications = useMemo(() => {
    return medications?.medications?.filter((option) => option.status === 'ongoing')
  }, [medications?.medications])
  const navigate = useNavigate()
  return (
    <div>
      <Navbar title="Home" />
      {pathname === '/profile' ? (
        <UserProfile />
      ) : (
        <div>
          <ScrollContainer>
            <div>
              <div className="p-6 flex flex-col gap-6">
                <div className="border border-1 border-neutral_stroke rounded-[8px] p-6">
                  <h2 className="font-campton_m text-[18px]">Welcome, <span className='capitalize'>{loggedUser?.firstName}</span>!</h2>
                  {profilePercentage !== '100' ?
                    <p className="text-neutral_body font-campton_r text-[14px]">
                      Your GabbiHealth profile is {profilePercentage}% complete.
                      <span
                        className="text-primary_shade_100 underline hover:cursor-pointer ml-1"
                        onClick={() => setCompleteProfile(true)}
                      >
                        Complete my profile
                      </span>
                    </p> :
                    <p className="text-neutral_body font-campton_r text-[14px]">
                      Your GabbiHealth ID is <span className="text-primary_shade_100">{loggedUser?.gNumber}</span>
                    </p>
                  }
                </div>
                <div>
                  <h3 className="font-campton_m text-[14px]">What do you need help with?</h3>
                  <ActionCards />
                </div>
              </div>
              <div className="lg:border-y-[1px] border-neutral_stroke flex flex-col lg:flex-row">
                <div className="w-full lg:w-[65%] p-6 border-b-[1px] lg:border-b-[0px] lg:border-r-[1px] border-neutral_stroke">
                  <p className="text-[14px] font-campton_m mb-4">Upcoming Appointment</p>
                  {is_appointment_loading ? (
                    <Spinner />
                  ) : (
                    <div>
                      {appointments?.appointments?.length > 0 ? (
                        <div className="flex items-center gap-4 w-full overflow-y-scroll">
                          {appointments?.appointments
                            ?.filter((_item, index) => index < 10)
                            .map((item) => (
                              <div key={item?._id} style={{ flex: '0 0 auto' }}>
                                <AppointmentCard item={item} />
                              </div>
                            ))}
                        </div>
                      ) : (
                        <EmptyState
                          illustration={<MedicationEmpty className='w-[60px] h-auto' />}
                          title="Start by booking an appointment."
                          description="All your ongoing medications will be displayed here."
                          className='bg-gray rounded-[16px]'
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="p-6 w-full lg:w-[35%] border-b-[1px] lg:border-none border-neutral_stroke">
                  <p className="text-[14px] font-campton_m mb-4">Ongoing Medication</p>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div>
                      {ongoingMedications?.length > 0 ? (
                        <div className="flex flex-col gap-6 h-[220px] overflow-y-scroll">
                          {ongoingMedications?.map((data) => (
                            <div className="flex gap-2" key={data?._id}>
                              <Paracetamol />
                              <div className="font-campton_r flex flex-col gap-1">
                                <p className="text-14 text-black_100"><span className='capitalize'>{data?.medicationName}</span> {data?.dosage}</p>
                                <p className="text-12">
                                  Frequency: <span className="text-neutral_body">{data?.frequency}</span>
                                </p>
                                <p className="text-12">
                                  Duration: <span className="text-neutral_body">{dayjs(data?.startDate).format('MMM DD, YYYY')} - {dayjs(data?.endDate).format('MMM DD, YYYY')}</span>
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <EmptyState
                          illustration={<MedicationEmpty className='w-[60px] h-auto' />}
                          title="No Medication"
                          description="All your upcoming appointment will be displayed here"
                          className='bg-gray rounded-[16px]'
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="p-6">
                <h3 className="font-campton_m text-[14px] mb-4">Health Tips</h3>
                {healthTips.length > 0 ? (
                  <div className="flex overflow-x-scroll space-x-5">
                    {healthTips.map((item, i) => (
                      <TipBox
                        key={item?.img}
                        img={item?.img}
                        time="5"
                        id={item.id}
                        className='w-[254px] h-[250px]'
                        onClick={() => navigate(`health-tips/${item.id}`)}
                        lastItem={(i + 1) !== healthTips?.length}
                        title={item.title}
                        description={item.description}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="bg-gray rounded-[16px] h-[126px] flex items-center justify-center">
                    <p className="text-[10px] font-campton_r">No Personalized Health Tips Yet</p>
                  </div>
                )}
              </div>
            </div>
          </ScrollContainer>
          {completeProfile &&
            <DefaultModal
              closeModal={() => setCompleteProfile(false)}
              styles="w-full md:w-[457px]"
              position="modal-right"
              title="Complete your Profile"
              variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
            >
              <div className="modal-right-body">
                {modalContent.map((item) => (
                  <div key={item.key}>
                    <div className="mb-4">{item}</div>
                  </div>
                ))}
              </div>
            </DefaultModal>}
        </div>
      )}
    </div>
  );
};

export default Home;
