export const groupDataByDate = (data, dateKey) => {
    if (!data?.length) return []
    const groups = data?.reduce((groups, item) => {
        const date = item?.[dateKey || 'createdAt']?.split('T')[0];
        if (!groups[date]) groups[date] = [];
        groups[date].push(item);
        return groups;
    }, {});
    
    const groupArrays = Object.keys(groups || {}).map((date) => {
        return {
          date,
          items: groups[date]
        };
    });

    return groupArrays.sort((a,b) => new Date(b.date) - new Date(a.date))
}