import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useBillsAndPayment from 'hooks/fetch/useBillsAndPayment';
import useTable from 'hooks/useTable';
import usePaystackPayment from 'hooks/usePaystackPayment';

function useComponentLogic() {
    const [selectedFilter, setSelectedFilter] = useState('All')
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [selectedPaymentHistory, setSelectedPaymentHistory] = useState({})
    const [billingHistory, setBillingHistory] = useState()
    const [paymentHistory, setPaymentHistory] = useState(null)
    const [institutionPolicy, setInstitutionPolicy] = useState()
    const { selectLimit, changePage, params } = useTable();
    const { initializePayment, loading: initializingPayment } = usePaystackPayment();
    const { get_billingHistory, loading: isFetchingBillingHistory } = useBillsAndPayment()
    const { get_outstandingBills, loading: isFetchingOutstandingBills = true } = useBillsAndPayment();
    const { verify_outstanding_bill_payment, verify_bill_part_payment, loading: isVerifyingPayment } = useBillsAndPayment();
    const { get_paymentHistory, loading: isFetchingPaymentHistory = true } = useBillsAndPayment();
    const { get_institution_policy, loading: isFetchingInstitutionPolicy } = useBillsAndPayment();
    const { outstandingBills } = useSelector(state => state.billsAndPayments);
    const [viewPolicy, setViewPolicy] = useState(false)
    const filters = ['All', 'Paid', 'Unpaid', 'Partially paid']

    const formatFilter = (filter) => {
        switch (filter) {
            case 'Paid':
                return 'full';
            case 'Unpaid':
                return 'unpaid';
            case 'Partially paid':
                return 'partial';
            default:
                return ''
        }
    };

    const getBillingHistoryProxy = () => {
        get_billingHistory({ params: { ...params, paymentStatus: formatFilter(selectedFilter) } })
            .then(res => res?.code === 200 && setBillingHistory(res?.data))
    }

    useEffect(() => {
        getBillingHistoryProxy()
    }, [selectedFilter, params.page, params.limit])

    useEffect(() => {
        get_outstandingBills()
    }, []);

    useEffect(() => {
        if (selectedPayment)
            get_institution_policy(selectedPayment?.institutionInfo?.id)
                .then(res => res?.code === 200 && setInstitutionPolicy(res?.data))
    }, [selectedPayment])

    useEffect(() => {
        if (selectedPayment?.billId) {
            get_paymentHistory(selectedPayment?.billId)
                .then(res => res?.code === 200 && setPaymentHistory(res?.data))
        }
    }, [selectedPayment?.billId]);

    return {
        selectedFilter, setSelectedFilter, selectedPayment, setSelectedPayment, verify_bill_part_payment,
        selectedPaymentHistory, setSelectedPaymentHistory, billingHistory, getBillingHistoryProxy,
        paymentHistory, filters, initializingPayment, outstandingBills, isVerifyingPayment, setViewPolicy, viewPolicy,
        selectLimit, changePage, initializePayment, isFetchingOutstandingBills, get_outstandingBills, institutionPolicy,
        isFetchingPaymentHistory, isFetchingBillingHistory, verify_outstanding_bill_payment, isFetchingInstitutionPolicy
    }
}

export default useComponentLogic
