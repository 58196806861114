import React from 'react';
import { ReactComponent as BookAppointment } from '../../../../assets/svg/home/book-appointment.svg';
import { ReactComponent as OutstandingBills } from '../../../../assets/svg/home/outstanding-bills.svg';
import { ReactComponent as LabTests } from '../../../../assets/svg/home/lab-tests.svg';
import { ReactComponent as Medication } from '../../../../assets/svg/home/medication.svg';
import MedicalHistory from 'assets/svg/home/MedicalHistory';
import { useNavigate } from 'react-router-dom';

const ActionCards = () => {
  const navigate = useNavigate();
  const items = [
    {
      cardColor: 'bg-home-light_orange',
      iconBgColor: 'bg-home-orange',
      icon: <BookAppointment />,
      title: 'Book appointment',
      route: '/appointments/book-appointment',
    },
    {
      cardColor: 'bg-home-light_green',
      iconBgColor: 'bg-home-green',
      icon: <OutstandingBills />,
      title: 'Outstanding bills',
      route: '/bills-and-payment'
    },
    {
      cardColor: 'bg-home-light_red',
      iconBgColor: 'bg-home-red',
      icon: <LabTests />,
      title: 'My Lab tests',
      route: '/more?record=lab%20tests'
    },
    {
      cardColor: 'bg-light_blue',
      iconBgColor: 'bg-home-blue',
      icon: <Medication />,
      title: 'My medications',
      route: '/more?record=treatment plan'
    },
    {
      cardColor: 'bg-home-light_orange',
      iconBgColor: 'bg-home-orange',
      icon: <MedicalHistory color="#F59E0B" />,
      route: '/more',
      title: 'Medical history',
    },
    {
      cardColor: 'bg-home-light_purple',
      iconBgColor: 'bg-home-purple',
      icon: <MedicalHistory color="#B821FF" />,
      title: 'My Vital Signs',
      route: '/more?record=vital signs'
    },
  ];

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-[16px] lg:flex mt-3">
      {items.map((item) => (
        <div
          onClick={() => navigate(item.route)}
          key={item.title}
          data-testid={item.title}
          className={`w-full lg:w-[158.5px] h-[91px] rounded-[8px] p-[8px] ${item.cardColor}
          flex flex-col gap-4 items-center justify-center cursor-pointer hover:scale-[1.07] transition ease-in-out duration-500`}
        >
          <div className={`w-[28px] h-[28px] ${item.iconBgColor} rounded-full flex items-center justify-center`}>
            {item.icon}
          </div>
          <p className="text-[12px] text-center font-campton_r transition duration-500">{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default ActionCards;
