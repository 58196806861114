import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const formatDate = (date) => {
  if (!date) return ""
  return new Date(date)?.toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })
}

export const getMonth = (month) => {
  if (!month) return
  return new Date(month).getMonth();
}

export const getYear = (year) => {
  if (!year) return ""
  return dayjs(new Date(year)).format('YYYY')
}

export const calculateTimeDifference = (timeString) => {
  if (!timeString) return ""
  const now = new Date()
  const givenDate = new Date(timeString)

  // Calculate the time difference in milliseconds
  const differenceInMilliseconds = now - givenDate;

  // Convert milliseconds to hours
  const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

  // Format the result
  if (differenceInHours < 1) {
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    return differenceInMinutes + ' minutes ago';
  } else if (differenceInHours < 24) {
    return differenceInHours + ' hour(s) ago';
  } else if (differenceInHours < 24 * 7) {
    const differenceInDays = Math.floor(differenceInHours / 24);
    return differenceInDays + ' day(s) ago';
  } else if (differenceInHours < 24 * 30) {
    const differenceInWeeks = Math.floor(differenceInHours / (24 * 7))
    return differenceInWeeks + ' week(s) ago';
  } else if (differenceInHours < 24 * 365) {
    const differenceInMonths = Math.floor(differenceInHours / (24 * 30))
    return differenceInMonths + ' month(s) ago';
  }
  const differenceInYears = Math.floor(differenceInHours / (24 * 365))
  return differenceInYears + ' year(s) ago';
}

const convertTo24HourFormat = (time12Hour) => {
  if (!time12Hour) return ""
  const timeParts = time12Hour?.split(':')
  let hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1].split(' ')[0]);
  const meridiem = timeParts[1].split(' ')[1];

  if (meridiem === 'PM' && hours < 12) {
    hours += 12;
  } else if (meridiem === 'AM' && hours === 12) {
    hours = 0;
  }

  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return formattedTime;
}

export const insertTimeToDate = (date, time) => {
  if (!date || !time) return ""
  const T = date.replace('00:00:00.', `${convertTo24HourFormat(time)?.split(' ')[0]}:00.`);
  const D = dayjs(T);
  return D.subtract(1, 'hour')
}

export const isTimeInRange = (appointmentDate, startTime, endTime) => {
  if (!appointmentDate || !startTime || !endTime) return null
  const startT = convertTo24HourFormat(startTime)
  const endT = convertTo24HourFormat(endTime)
  const startDateTime = insertTimeToDate(appointmentDate, startT)
  const endDateTime = insertTimeToDate(appointmentDate, endT)
  const currentDate = dayjs(dayjs().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'))

  if (!startDateTime.isSame(currentDate, 'day')) return false
  if (currentDate.isAfter(startDateTime) && currentDate.isBefore(endDateTime)) return true

  return false
}

export const isAppointmentDate = (appointmentDate, startTime) => {
  if (!appointmentDate || !startTime) return null
  const appointmentDateTime = insertTimeToDate(appointmentDate, startTime)
  const currentDate = dayjs(dayjs().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'))

  if (!appointmentDateTime.isSame(currentDate, 'day')) return false
  return true
}