import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RadioButtonSelect from 'components/RadioButtonSelect';

const DietAndNutrition = ({ formData, setFormData, type }) => {
  const [processedFoods, setProcessedFoods] = useState(formData[type].processedFoods);
  const [fruitsAndVegetables, setFruitsAndVegetables] = useState(formData[type].fruitsAndVegetables);
  const [specificDietaryPlan, setSpecificDietaryPlan] = useState(formData[type].specificDietaryPlan);

  const handleSpecificDietaryPlan = (name) => {
    setSpecificDietaryPlan(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], specificDietaryPlan: name}}));

  };
  const handleFruitsAndVegetables = (name) => {
    setFruitsAndVegetables(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], fruitsAndVegetables: name}}));

  };
  const handleProcessedFoods = (name) => {
    setProcessedFoods(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], processedFoods: name}}));

  };

  return (
    <div className="my-4">
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">Do you follow a specific dietary plan?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={specificDietaryPlan === 'yes'}
            onChange={() => handleSpecificDietaryPlan('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={specificDietaryPlan === 'no'}
            onChange={() => handleSpecificDietaryPlan('no')}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          How often do you consume fruits and vegetables?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Not Often"
            variant="col-span-4"
            isChecked={fruitsAndVegetables === 'not often'}
            onChange={() => handleFruitsAndVegetables('not often')}
          />
          <RadioButtonSelect
            label="Often"
            variant="col-span-4"
            isChecked={fruitsAndVegetables === 'often'}
            onChange={() => handleFruitsAndVegetables('often')}
          />
          <RadioButtonSelect
            label="Very Often"
            variant="col-span-4"
            isChecked={fruitsAndVegetables === 'very often'}
            onChange={() => handleFruitsAndVegetables('very often')}
          />
        </div>
      </div>
      <div>
        <p className="font-campton_r text-14 text-neutral_black mb-2">How frequently do you consume processed foods?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Not Often"
            variant="col-span-4"
            isChecked={processedFoods === 'not often'}
            onChange={() => handleProcessedFoods('not often')}
          />
          <RadioButtonSelect
            label="Often"
            variant="col-span-4"
            isChecked={processedFoods === 'often'}
            onChange={() => handleProcessedFoods('often')}
          />
          <RadioButtonSelect
            label="Very Often"
            variant="col-span-4"
            isChecked={processedFoods === 'very often'}
            onChange={() => handleProcessedFoods('very often')}
          />
        </div>
      </div>
    </div>
  );
};

export default DietAndNutrition;
DietAndNutrition.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
