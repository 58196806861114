import Accordion from "components/Accordion";
import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types"
import { ReactComponent as Microscope } from "assets/svg/microscope.svg";
import useUpload from 'hooks/useUpload'
import dayjs from "dayjs";
import useMyRecords from "hooks/fetch/useMyRecords";
import { VisitContext } from "context";

export default function Tests({setSelectedResult}) {
    const { visit, progressNote } = useContext(VisitContext)
    const {get_visit_lab_test} = useMyRecords()

    const [selectedVisit, setSelectedVisit] = useState(visit || [])
    const [labTests, setLabTests] = useState()

    const getLabTest = () => {
        if(!progressNote) {
            get_visit_lab_test(`${visit._id}/lab-test`).then((res) => {
                if(res.code === 200) {
                    setLabTests(res.data)
                }
            }
        )
        }
    }
    const allLabTest = progressNote?.labrequests ?? labTests?.labTest

    const pendingTest = useMemo(() => allLabTest?.filter(item => item?.requestStatus !== 'completed'), [allLabTest])
    const completedTest = useMemo(() => allLabTest?.filter(item => item?.requestStatus === 'completed'), [allLabTest])

    useEffect(() => {
        getLabTest()
    }, [])
    
    return (
        <Accordion
            display={false}
            title={<p className="text-14 text-neutral_body font-campton_r">Tests</p>}
            className="mb-0 border-b border-b-neutral_stroke_2"
        >
            <>
                <div className="border border-neutral_stroke_2 rounded-[16px] p-[16px] mb-[8px]">
                    <p className="text-12 text-neutral_body font-campton_r">Pending test</p>
                    {
                        pendingTest?.length ? pendingTest?.map((test) =>
                            <PendingTest
                                key={test._id}
                                test={test}
                                callback={getLabTest}
                                selectedVisit={selectedVisit}
                                setSelectedVisit={setSelectedVisit}
                            />
                        ) : <p className="font-campton_m text-14 text-neutral_black mb-5">No Pending Test available</p>
                    }
                </div>
                <div className="border border-neutral_stroke_2 rounded-[16px] p-[16px]">
                    <p className="text-12 text-neutral_body font-campton_r">Completed test</p>
                    {
                        completedTest?.length ? completedTest?.map((test) =>
                            <div key={test?._id} className="flex justify-between items-center border-b border-b-neutral_stroke_2 py-[14px]">
                                <div>
                                    <p className="text-14 text-neutral_black font-campton_r mb-[8px]">{test?.testType}</p>
                                    <div
                                        className="text-12 text-neutral_black font-campton_r px-[8px] w-fit
                                        rounded-[100px] border border-neutral_stroke_2 cursor-pointer hide_tap"
                                        onClick={() => setSelectedResult(test)}
                                    >
                                        View result
                                    </div>
                                </div>
                                <Microscope className="w-[64px] h-[64px]" />
                            </div>
                        ) : <p className="font-campton_m text-14 text-neutral_black">No Completed Test available</p>
                    }
                </div>
            </>
        </Accordion>
    )
}

Tests.propTypes = {
    setSelectedResult: PropTypes.func,
}

const PendingTest = ({ setSelectedVisit, test, callback }) => {
    const { params } = useContext(VisitContext)
    const { upload, uploading } = useUpload()
    const { upload_test_result, get_visits } = useMyRecords()

    const uploadTestFile = (file, labTestId) => {
        const timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
        const fileName = `${timestamp}${file?.name}`
        const payload = { testResult: fileName }
        upload(file, payload, timestamp, false)
            .then(res => {
                if (res?.response?.status === 200) {
                    const pathname = `lab-test/${labTestId}`
                    upload_test_result( {testResult: [res.imageUrl]}, pathname )
                        .then(res => {
                            if (res?.code === 200) {
                                setSelectedVisit(res?.data)
                                get_visits({ params })
                                callback()
                            }
                        })
                }
            })
    }
    return (
        <div className="flex justify-between items-center border-b border-b-neutral_stroke_2 py-[14px]">
            <div className="relative">
                <p className="text-14 text-neutral_black font-campton_r mb-[8px]">{test?.testType}</p>
                <label
                    htmlFor="file"
                    className="text-12 text-neutral_black font-campton_r px-[8px] py-[4px] rounded-[100px] border border-neutral_stroke_2 cursor-pointer hide_tap"
                >
                    {!uploading ? 'Upload test result' : 'Uploading...'}
                </label>
                <input
                    data-testid="test-file"
                    type="file"
                    id="file"
                    name="file"
                    accept="image/png,image/jpg,image/jpeg,application/pdf"
                    readOnly={uploading}
                    onChange={(e) => uploadTestFile(e.target.files[0], test?._id)}
                    className="hidden"
                />
            </div>
            <Microscope className="w-[64px] h-[64px]" />
        </div>
    )
}

PendingTest.propTypes = {
    test: PropTypes.object,
    setSelectedVisit: PropTypes.func,
    callback:PropTypes.func
}