import React, { useState } from "react";
import PropTypes from "prop-types"
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg"
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { AnimatePresence, motion } from "framer-motion";

export default function Accordion({ title, children, className, iconPosition = "right", display }) {
    const [state, setState] = useState(display || false)

    return (
        <div className={`${className || 'rounded-[8px] border border-neutral_stroke_2 mb-[24px] p-[16px]'}`}>
            <div
                data-testid="accordion"
                className="flex items-center justify-between cursor-pointer"
                onClick={() => children ? setState(!state) : {}}
            >
                <div className="flex items-center py-[16px]">
                    {
                        iconPosition === 'left' &&
                        <>{state ? <ArrowDown data-testid="open" fill="#205F74" className="mr-[11px]" /> :
                            <ArrowRight data-testid="close" fill="#205F74" className="mr-[11px]" />}
                        </>
                    }
                    {title}
                </div>
                {
                    iconPosition === 'right' &&
                    <>{state ? <ArrowUp data-testid="open" fill="#205F74" /> : <ArrowDown data-testid="close" fill="#205F74" />}</>
                }
            </div>
            <AnimatePresence>
                {
                    state &&
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto', transition: { duration: .4 } }}
                        exit={{ height: 0, transition: { duration: .4 } }}
                        className="overflow-y-hidden"
                    >
                        {children && <div className="pb-[16px]">{children}</div>}
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

Accordion.propTypes = {
    title: PropTypes.element,
    children: PropTypes.any,
    display: PropTypes.bool,
    className: PropTypes.string,
    iconPosition: PropTypes.string
}