import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';

const SocialConnections = ({ formData, setFormData, type }) => {
  const [supportSystem, setSupportSystem] = useState(formData[type].supportSystem);
  const [challenges, setChallenges] = useState(formData[type].challenges);
  const [frequency, setFrequency] = useState(formData[type].frequency);

  const handleSupportSystem = (name) => {
    setSupportSystem(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], supportSystem: name}}));

  };

  const handleChallenges = (name) => {
    setChallenges(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], challenges: name}}));

  };
  const handleFrequency = (name) => {
    setFrequency(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], frequency: name}}));

  };

  return (
    <div className="my-4">
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          Do you have a support system in place to address your emotional needs?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={supportSystem === 'yes'}
            onChange={() => handleSupportSystem('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={supportSystem === 'no'}
            onChange={() => handleSupportSystem('no')}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          How often do you engage in social activities or spend time with friends and family?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Not Often"
            variant="col-span-4 py-3"
            isChecked={frequency === 'not often'}
            onChange={() => handleFrequency('not often')}
          />
          <RadioButtonSelect
            label="Often"
            variant="col-span-4"
            onChange={() => handleFrequency('often')}
            isChecked={frequency === 'often'}
          />
          <RadioButtonSelect
            label="Very Often"
            variant="col-span-4"
            onChange={() => handleFrequency('very often')}
            isChecked={frequency === 'very often'}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          Are there any challenges related to your social interactions?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={challenges === 'yes'}
            onChange={() => handleChallenges('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={challenges === 'no'}
            onChange={() => handleChallenges('no')}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialConnections;
SocialConnections.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
