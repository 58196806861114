import Button from 'components/Buttons/Button';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import { SlideUp } from 'components/Animations';
import { ReactComponent as MedicationIllustration } from 'assets/svg/medications-empty.svg';
import useTable from 'hooks/useTable';
import Table from 'components/Table';
import EmptyState from 'components/EmptyState';
import { useRecordState } from 'store/modules/myRecords';
import { Search } from 'components/Inputs';
import DefaultModal from 'components/Modals/DefaultModal';
import dayjs from 'dayjs';
import Avatar from 'components/Avatar/Avatar';
import { userInitials } from 'utils/ImagePlaceholder';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { referral_list_header } from 'mocks/myRecords';
import useMyRecords from 'hooks/fetch/useMyRecords';
import useDebounce from 'hooks/useDebounce';
import { useAuthState } from 'store/modules/auth';
import ViewReferral from './ViewReferral';

const Referrals = ({ callback }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { loggedUser } = useAuthState();
  const newParams = {
    type: 'patient',
    patientId: loggedUser._id,
  };
  const { selectLimit, changePage, handleSearch, params } = useTable();
  const { referrals } = useRecordState();
  const { get_referrals, loading } = useMyRecords();
  const debounceValue = useDebounce(params.search);
  const handleSingleView = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  useEffect(() => {
    get_referrals(
      debounceValue
        ? {
          ...newParams,
            search: debounceValue,
          }
        : newParams,
    );
  }, [debounceValue]);

  return (
    <>
      <SlideUp>
        <div className="pt-6 pb-4">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-4">
              <Button className="w-fit rounded-full h-8 font-campton_m text-14" theme="transparent" onClick={callback}>
                <p className="flex items-center space-x-4">
                  <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                  <span>Back</span>
                </p>
              </Button>
              <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Referrals</h2>
            </div>
          </div>
          {referrals?.referrals?.length > 0 && (
            <div className="w-[400px]">
              <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            </div>
          )}
          <div className="">
            <Table
              headers={referral_list_header}
              totalCount={referrals?.total}
              perPage={referrals?.limit}
              data={referrals?.referrals}
              hasHeader={referrals?.referrals?.length > 0}
              currentPage={referrals?.page}
              selectLimit={selectLimit}
              changePage={changePage}
              loading={loading}
              emptyState={
                <EmptyState
                  illustration={<MedicationIllustration />}
                  title="No Referrals Available."
                  description="You currently have no referrals.
                                All your Referrals will be displayed here."
                />
              }
            >
              <>
                {referrals?.referrals?.map((item) => (
                  <tr
                    data-testid={item?._id}
                    onClick={() => handleSingleView(item)}
                    key={item?._id}
                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                  >
                    <td className="px-[10px] py-[8px] flex items-center">
                      <Avatar
                        url={item?.institutionInfo?.profilePic}
                        width="40px"
                        height="40px"
                        alt={item?.institutionInfo?.businessName?.split('')[0]}
                        initials={userInitials(
                          item?.institutionInfo?.businessName?.split(' ')[0],
                          item?.institutionInfo?.businessName?.split(' ')[1],
                        )}
                        className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                      />
                      <div>
                        <p className="text-neutral_black text-14 font-campton_m capitalize">
                          {item?.institutionInfo?.businessName}
                        </p>
                        <p className=" text-neutral_body text-12 font-campton_r">
                          {item?.institutionInfo?.emailAddress}
                        </p>
                      </div>
                    </td>
                    <td className="px-[10px] py-[8px] capitalize text-14">
                      {dayjs(item.createdAt).format('MMM DD, YYYY')}
                    </td>
                    <td className="px-[10px] py-[8px] flex items-center">
                      <Avatar
                        url={item?.providerInfo?.profilePic}
                        width="40px"
                        height="40px"
                        alt={item?.providerInfo?.firstName?.split('')[0]}
                        initials={userInitials(item?.providerInfo?.firstName, item?.providerInfo?.lastName)}
                        className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                      />
                      <div>
                        <p className="text-neutral_black text-14 font-campton_m capitalize">
                          {item?.providerInfo?.firstName} {item?.providerInfo?.lastName}
                        </p>
                        <p className=" text-neutral_body text-12 font-campton_r">{item?.providerInfo?.emailAddress}</p>
                      </div>
                    </td>
                    <td className="px-[10px] py-[8px] capitalize">
                      <ArrowRight fill="#A9B2B2" />
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </div>
      </SlideUp>
      {showModal && (
        <DefaultModal
          closeModal={() => setShowModal(false)}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title="View Referral"
        >
          <ViewReferral data={selectedItem} />
        </DefaultModal>
      )}
    </>
  );
};

export default Referrals;
Referrals.propTypes = {
  callback: PropTypes.any,
};
