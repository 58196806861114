import useMakeRequest from 'hooks/useMakeRequest';
import {
  deleteMedicalRecord,
  getPatientProfile,
  editMedicalRecord,
  updateMedicalRecord,
  updateLifestyle,
  getVitals,
  createVitals,
} from 'store/modules/myRecords/actions';
import useTryCatch from 'hooks/useTryCatch';
import { useState } from 'react';

export default function useMedicalRecord() {
  const { makeRequest } = useMakeRequest();
  const { tryCatch } = useTryCatch();
  const [loading, setLoading] = useState(false);

  const get_patient_profile = () => {
    makeRequest({
      action: getPatientProfile(),
      alert: false,
    }).finally(() => setLoading(false));
  };

  const update_medical_record = async (type, id, formData, callback) => {
    const payload = {
      type,
      requestType: 'add',
      ...formData,
    };
    tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: updateMedicalRecord({ pathname: id, payload }), callback }).finally(() =>
        setLoading(false),
      );
    });
  };

  const edit_medical_record = async (type, recordId, formData, callback) => {
    const payload = {
      requestType: 'update',
      type,
      recordId,
      ...formData,
    };
    tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: editMedicalRecord(payload), callback }).finally(() => setLoading(false));
    });
  };

  const delete_medical_record = async (type, id) => {
    const payload = { type, id };
    tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: deleteMedicalRecord(payload) }).finally(() => setLoading(false));
    });
  };

  const create_vitals = async (payload, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: createVitals(payload), callback }).finally(() => setLoading(false));
    });
  };

  const update_liftstyle = async (formData, callback) => {
    tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: updateLifestyle(formData), callback }).finally(() => setLoading(false));
    });
  };

  const get_vitals = (patientId, type) => {
    let pathname = `${patientId}?type=${type}`
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getVitals({ pathname }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  return {
    get_patient_profile,
    update_medical_record,
    loading,
    get_vitals,
    create_vitals,
    update_liftstyle,
    edit_medical_record,
    delete_medical_record,
  };
}
