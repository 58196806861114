/* eslint-disable max-len */
import React, { useState } from 'react';
import Spinner from 'components/Spinner';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Illustration } from 'assets/svg/immmunization-illustration.svg';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddImmunizationHistory';
import { ReactComponent as ImmunizationSolid } from 'assets/icons/medicalRecords/immunization-solid.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../Header';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { useRecordState } from 'store/modules/myRecords';

const ImmunizationHistory = ({ selected, onClick }) => {
  //Redux
  const { immunizationHistory, loading } = useRecordState();
  //Local State
  const [showModal, setShowModal] = useState();
  const [type, setType] = useState();
  const [selectedDetails, setSelectedDetails] = useState();
  //Hooks
  const { delete_medical_record } = useMedicalRecord();

  //Handler function
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={() => handleShowModal('Add') } condition={immunizationHistory?.length > 0} />
        {loading && (
          <div className="mt-10">
            <Spinner />
          </div>
        )}
        {!loading &&
          (immunizationHistory?.length > 0 ? (
            <>
              <div className="mt-6">
                {immunizationHistory.map((item) => (
                  <div
                    className="flex justify-between items-start border-b last-of-type:border-b-0 border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0"
                    key={item?._id}
                  >
                    <div className="flex space-x-4">
                      <ImmunizationSolid className="mt-1" />
                      <div>
                        <p className="font-campton_m text-neutral_black">{item.immunizationName}</p>
                        <div className="text-14 space-x-1 text-neutral_body">
                          <p className="mt-1">
                            Vaccine Number: -{' '}
                            <span className="font-campton_m text-neutral_black ">{item.vaccineNumber}</span>
                          </p>
                          <p className="mt-0.5">
                            Route of Administration: -{' '}
                            <span className="font-campton_m text-neutral_black">{item.routeOfAdministration}</span>
                          </p>
                          <p className="mt-0.5">
                            Site: - <span className="font-campton_m text-neutral_black ">{item.site}</span>
                          </p>
                          <p className="mt-0.5">
                            Date: - <span className="font-campton_m text-neutral_black ">{item.date}</span>
                          </p>
                          <p className="mt-0.5">
                            Location: - <span className="font-campton_m text-neutral_black ">{item.location}</span>
                          </p>
                          <p className="mt-0.5">
                            Manufacturer: -{' '}
                            <span className="font-campton_m text-neutral_black ">{item.manufacturer}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <EditIcon
                        onClick={() => handleShowModal('Edit', () => setSelectedDetails(item))}
                        data-testid="test-edit"
                        className="cursor-pointer"
                      />
                      <DeleteIcon
                        data-testid="test-delete"
                        onClick={() => delete_medical_record('immunizationHistory', item._id)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyState
              illustration={<Illustration />}
              title="No Previous Immunization"
              description="All immunization history will be displayed here."
              btnText="Add Immunization"
              onClick={() => handleShowModal('Add')}
            />
          ))}
        {showModal && (
          <DefaultModal
            closeModal={() => setShowModal(false)}
            styles="min-w-[90%] md:min-w-[730px]"
            title={`${type} Immunization`}
            subTitle="Add your latest immunization record for a complete and up-to-date health profile."
            containerVariant="bg-neutral_white"
          >
            <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
          </DefaultModal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default ImmunizationHistory;

ImmunizationHistory.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
