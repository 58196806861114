import { createSlice } from '@reduxjs/toolkit';
import {
  updateMedicalRecord,
  getPatientProfile,
  deleteMedicalRecord,
  requestUploadUrl,
  updateLifestyle,
  editMedicalRecord,
  getVitals,
  getVisits,
  getLabTests,
  getOngoingVisit,
  getPendingLabTest,
  getProcedure,
  getTherapies,
  getReferrals,
  getMedications,
  getVisitTreatmentPlan,
  getSingleVisit
} from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  visits: {},
  patientInfo: {},
  bloodType: {},
  allergies: [],
  patientId: '',
  visitDetails: {},
  loading: false,
  visitTreatmentPlan: {},
  emergencyContact: [],
  insuranceInformation: [],
  medicalDiagnosis: [],
  familyHospital: [],
  surgeries: [],
  insuranceId: '',
  vitalsHistory: {},
  immunizationHistory: [],
  lifestyleHabits: [],
  familyHistory: [],
  ongoingVisit: [],
  medications: {},
  labTest: {},
  pendingLabTest: [],
};
const setPatientRecord = (state, action) => {
  if (action?.payload?.data) {
    state.bloodType = action.payload.data.medicalRecord.bloodType;
    state.patientId = action.payload.data.medicalRecord.patientId;
    state.allergies = action.payload.data.medicalRecord.allergies;
    state.familyHistory = action.payload.data.medicalRecord.familyHistory;
    state.emergencyContact = action.payload.data.medicalRecord.emergencyContact;
    state.insuranceInformation = action.payload.data.medicalRecord.insuranceInformation;
    state.immunizationHistory = action.payload.data.medicalRecord.immunizationHistory;
    state.lifestyleHabits = action.payload.data.medicalRecord.lifestyleHabits;
    state.medicalDiagnosis = action.payload.data.medicalRecord.medicalDiagnosis;
    state.surgeries = action.payload.data.medicalRecord.surgeries;
    state.familyHospital = action.payload.data.medicalRecord.familyHospital;
    state.patientInfo = action.payload.data;
  }
};

export const myRecords = createSlice({
  name: 'myRecords',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisits.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) state.visits = action.payload.data;
      })
      .addCase(getLabTests.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) state.labTest = action.payload.data;
      })
      .addCase(getOngoingVisit.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) state.ongoingVisit = action.payload.data?.visits;
      })
      .addCase(getPendingLabTest.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) state.pendingLabTest = action.payload.data?.labTest;
      })
      .addCase(getPatientProfile.fulfilled, (state, action) => {
        setPatientRecord(state, action);
        state.loading = false;
      })
      .addCase(updateMedicalRecord.fulfilled, (state, action) => {
        setPatientRecord(state, action);
        state.loading = false;
      })
      .addCase(editMedicalRecord.fulfilled, (state, action) => {
        setPatientRecord(state, action);
        state.loading = false;
      })
      .addCase(deleteMedicalRecord.fulfilled, (state, action) => {
        setPatientRecord(state, action);
        state.loading = false;
      })
      .addCase(requestUploadUrl.fulfilled, (state, action) => {
        state.insuranceId = action.payload.data;
        state.loading = false;
      })
      .addCase(updateLifestyle.fulfilled, (state, action) => {
        setPatientRecord(state, action);
        state.loading = false;
      })
      //GET VITALS
      .addCase(getVitals.fulfilled, (state, action) => {
        state.vitalsHistory = action.payload.data;
        state.loading = false;
      })
      .addCase(getProcedure.fulfilled, (state, action) => {
        state.procedures = action?.payload?.data;
      })
      .addCase(getReferrals.fulfilled, (state, action) => {
        state.referrals = action?.payload?.data;
      })
      .addCase(getTherapies.fulfilled, (state, action) => {
        state.therapies = action?.payload?.data;
      })
      .addCase(getMedications.fulfilled, (state, action) => {
        state.medications = action?.payload?.data;
      })
      .addCase(getVisitTreatmentPlan.fulfilled, (state, action) => {
        state.visitTreatmentPlan = action?.payload?.data;
      })
      .addCase(getSingleVisit.fulfilled, (state, action) => {
        state.visitDetails = action?.payload?.data;
      })
  },
});
// Selectors
const selectMyRecords = (state) => state.myRecords;
//App Redux State
export const useRecordState = () => useAppSelector(selectMyRecords);
export default myRecords.reducer;
