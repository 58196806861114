import React from 'react';
import TextArea from 'components/Inputs/TextArea';
import RadioButtonSelect from 'components/RadioButtonSelect';
import DefaultDatepicker from 'components/Datepicker/Index';
import Button from 'components/Buttons/Button';
import PropTypes from 'prop-types';

const BasicInformation = ({ formData, setFormData, handleChange, cancel, next }) => {
  const handleType = (type, value) => {
    setFormData({ ...formData, [type]: value });
  };

  return (
    <div className="h-full relative">
      <h2 className="text-16 text-neutral_black font-campton_m">Basic Information</h2>
      <div className="flex justify-between flex-col h-full pb-6">
        <div className='pb-[60px]'>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 mt-6">
            <div>
              <p className="font-campton_r text-14 text-neutral_black mb-2">Select Appointment Type</p>
              <div className="grid grid-cols-2 md:gap-4 gap-6">
                <RadioButtonSelect
                  label="In-app"
                  isChecked={formData?.appointmentType === 'inApp'}
                  onChange={() => handleType('appointmentType', 'inApp')}
                />
                <RadioButtonSelect
                  label="On-site"
                  isChecked={formData?.appointmentType === 'onSite'}
                  onChange={() => handleType('appointmentType', 'onSite')}
                />
              </div>
            </div>
            <div>
              <p className="font-campton_r text-14 text-neutral_black mb-2">Select Priority</p>
              <div className="grid grid-cols-2 md:gap-4 gap-6">
                <RadioButtonSelect
                  label="Urgent"
                  isChecked={formData?.priority === 'urgent'}
                  onChange={() => handleType('priority', 'urgent')}
                />
                <RadioButtonSelect
                  label="Routine"
                  isChecked={formData?.priority === 'routine'}
                  onChange={() => handleType('priority', 'routine')}
                />
              </div>
            </div>
          </div>
          <div className="mt-6">
            <DefaultDatepicker
              label="Date"
              startDate={formData?.appointmentDate}
              minDate={new Date()}
              onSelect={(e) => setFormData({ ...formData, appointmentDate: e })}
              name="date"
              value={formData?.appointmentDate}
            />
            <TextArea
              label="Note (optional)"
              id="note"
              name="note"
              rows={5}
              value={formData.note}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="flex justify-end absolute bottom-0 w-full bg-neutral_white py-[4px]">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={cancel}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Next"
            theme="blue"
            onClick={next}
            className="h-[40px] text-16 w-[88px]"
            disabled={!formData.appointmentDate || !formData.priority || !formData.appointmentType}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
BasicInformation.propTypes = {
  formData: PropTypes.object,
  handleChange: PropTypes.func,
  setFormData: PropTypes.func,
  cancel: PropTypes.func,
  next: PropTypes.func,
};
