import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { ReactComponent as Ellipsis } from 'assets/icons/ellipsis.svg';
import useTouchOutside from 'hooks/useTouchOutside';

export default function MoreOptions({ options, position, onSelect, className }) {
    const [show, setShow] = useState(false)
    const containerRef = useTouchOutside(() => setShow(false))

    return (
        <div
            data-testid="more-options"
            className='relative'
            onClick={() => setShow(!show)}
            ref={containerRef}
        >
            <Ellipsis className="cursor-pointer hide_tap" />
            <motion.ul
                initial={{ opacity: 0, y: "20px" }}
                animate={{ opacity: show ? 1 : 0, y: !show ? '20px' : 0 }}
                exit={{ opacity: 0, y: '20px' }}
                onClick={() => setShow(!show)}
                className={`${show ? '' : 'hidden'} absolute ${position} bg-neutral_white p-[16px] rounded-[16px] text-14 font-campton_r more_options_shadow`}
            >
                {
                    options?.map((item) =>
                        <li
                            key={item?.id}
                            data-testid={item?.value}
                            onClick={() => onSelect(item?.value)}
                            className={`
                            cursor-pointer ${className} flex items-center py-[9.5px] hover:bg-[#dfe2e23b] text-neutral_body hover:text-neutral_black hover:pl-[8px] rounded-[50px]
                            `}
                        >
                            <span className="inline-block mr-[10px]">{item?.icon}</span><span className="inline-block">{item?.name}</span>
                        </li>
                    )
                }
            </motion.ul>
        </div>
    )
}

MoreOptions.propTypes = {
    options: PropTypes.array,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    position: PropTypes.string
}