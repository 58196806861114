import React, { useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import DisabledDropdown from './DisabledDropdown';
import { motion } from 'framer-motion';
import DropdownOptions from './DropdownOptions';
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg"

const Dropdown = ({
  disabled,
  label,
  id,
  variant,
  name,
  type,
  styles,
  options,
  dropdownStyles,
  optionContainerVariant,
  containerVariant,
  optionVariant,
  onChange,
  value,
}) => {
  const [labelStyles, setLabelStyles] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState('');
  const ref = useRef();

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleSelect = (item) => {
    onChange(item);
    setShowDropdown(false);
    setLabelStyles(true)
  };

  const handleChange = (e) => setSearch(e.target.value);

  useOnClickOutside([ref], () => setShowDropdown(false), 200);

  const searchOptions = useMemo(() => {
    if (type === 'country') return !search ? options : options?.filter((option) => option?.value?.toLowerCase().includes(search?.toLowerCase()));
    return !search ? options : options?.filter((option) => option?.name?.toLowerCase().includes(search?.toLowerCase()));
  }, [options, search]);

  return (
    <>
      {disabled ? (
        <DisabledDropdown
          variant={variant}
          labelStyles={labelStyles}
          value={value}
          label={label}
          id={id}
          name={name}
          styles={styles}
        />
      ) : (
        <div className={`${variant} rounded-lg relative`} ref={ref}>
          <motion.label
            htmlFor={id}
            animate={{
              scale: 0.8,
              top: !labelStyles && !value?.label ? '8px' : '-16px',
              fontSize: !labelStyles && !value?.label ? '16px' : '14px',
            }}
            className="absolute left-[4px] text-neutral_body mb-2 block text-14
            font-campton_r bg-neutral_white px-1 pt-2 cursor-text"
          >
            {label}
          </motion.label>
          <div
            onClick={toggleDropdown}
            data-testid={`${id}-dropdown`}
            name={name}
            className={`${containerVariant}
            ${showDropdown ? `${dropdownStyles} border-brand_primary border-b-0 rounded-t-lg` : 'border-neutral_stroke rounded-lg'}
            ${styles || 'h-[56px]'} font-campton_r text-14 text-neutral_black w-full cursor-pointer px-4 py-[18px] border`}
          >
            <span className="text-neutral_black capitalize truncate text-clip">{value?.label}</span>
            <span className="h-full absolute top-0 right-0 flex items-center px-[18.5px] cursor-pointer hide_tap">
              {showDropdown ? <ArrowUp fill="#205F74" /> : <ArrowDown fill="#205F74" />}
            </span>
          </div>
          {showDropdown && (
            <DropdownOptions
              type={type}
              optionContainerVariant={optionContainerVariant}
              options={searchOptions}
              setSearch={setSearch}
              onSelect={onChange}
              setShowDropdown={setShowDropdown}
              optionVariant={optionVariant}
              handleSelect={handleSelect}
              handleChange={handleChange}
              search={search}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Dropdown;
Dropdown.propTypes = {
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  styles: PropTypes.string,
  dropdownStyles: PropTypes.string,
  containerVariant: PropTypes.string,
  optionVariant: PropTypes.string,
  options: PropTypes.array,
  optionContainerVariant: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};
