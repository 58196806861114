import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';

const Tobacco = ({ formData, setFormData, type }) => {
  const [smoking, setSmoking] = useState(formData[type].smoking);
  const [recreationalDrugs, setRecreationalDrugs] = useState(formData[type].recreationalDrugs);
  const [alcohol, setAlcohol] = useState(formData[type].alcohol);

  const handleSmoking = (name) => {
    setSmoking(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], smoking: name}}));

  };
  const handleRecreationalDrugs = (name) => {
    setRecreationalDrugs(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], recreationalDrugs: name}}));

  };
  const handleAlcohol = (name) => {
    setAlcohol(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], alcohol: name}}));

  };
  return (
    <div className='my-4'>
     <div className='mb-4'>
        <p className="font-campton_r text-14 text-neutral_black mb-2">Do you smoke cigarettes or use any other tobacco products?</p>
          <div className="grid grid-cols-12 gap-4">
            <RadioButtonSelect
              label="Yes"
              variant="col-span-6"
              isChecked={smoking === 'yes'}
              onChange={() => handleSmoking('yes')}
            />
            <RadioButtonSelect
              label="No"
              variant="col-span-6"
              isChecked={smoking === 'no'}
              onChange={() => handleSmoking('no')}
            />
          </div>
        </div>
        <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">How frequently do you consume alcoholic beverages?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Not Often"
            variant="col-span-4 py-3"
            isChecked={alcohol === 'not often'}
            onChange={() => handleAlcohol('not often')}
          />
          <RadioButtonSelect
            label="Often"
            variant="col-span-4"
            onChange={() => handleAlcohol('often')}
            isChecked={alcohol === 'often'}
          />
          <RadioButtonSelect
            label="Very Often"
            variant="col-span-4"
            onChange={() => handleAlcohol('very often')}
            isChecked={alcohol === 'very often'}
          />
        </div>
      </div>
        <div className='mb-4'>
        <p className="font-campton_r text-14 text-neutral_black mb-2">Do you use any recreational drugs?</p>
          <div className="grid grid-cols-12 gap-4">
            <RadioButtonSelect
              label="Yes"
              variant="col-span-6"
              isChecked={recreationalDrugs === 'yes'}
              onChange={() => handleRecreationalDrugs('yes')}
            />
            <RadioButtonSelect
              label="No"
              variant="col-span-6"
              isChecked={recreationalDrugs === 'no'}
              onChange={() => handleRecreationalDrugs('no')}
            />
          </div>
        </div>
    </div>
  )
}

export default Tobacco
Tobacco.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};