import React, { useMemo, useState } from 'react';
import PropTypes from "prop-types";
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as CloseDrawer } from 'assets/icons/close-drawer.svg';
import { ReactComponent as OpenDrawer } from 'assets/icons/open-drawer.svg';
import { ReactComponent as Logo } from 'assets/icons/logo-icon.svg';
import { ReactComponent as LogoName } from 'assets/icons/logo-name.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Appointment } from 'assets/icons/appointment.svg';
import { ReactComponent as Messaging } from 'assets/icons/messaging.svg';
import { ReactComponent as Bills } from 'assets/icons/bills.svg';
import { ReactComponent as More } from 'assets/icons/globe.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { Link, useLocation } from 'react-router-dom';
import { logout } from 'store/modules/auth/actions';
import { useAppDispatch } from "hooks/useReduxHook";
import { useSelector } from 'react-redux';
import { toggleDrawer } from 'store/modules/global';
import useWindowWidth from 'hooks/useWindowWidth';
import { MEDIUM_WIDTH } from 'constants';

export default function Drawer() {
  const { showDrawer } = useSelector(state => state.global)
  const dispatch = useAppDispatch();
  const windowWidth = useWindowWidth()

  const iconMargin = useMemo(() => {
    return showDrawer ? 'w-[20px] mr-[10px]' : 'shrink-0'
  }, [showDrawer])

  const containerStyle = useMemo(() => {
    if (windowWidth > MEDIUM_WIDTH) return "md:block relative rounded-[16px] mr-[24px] h-full"
    return "fixed left-0 top-0 z-40 h-[100dvh]"
  }, [windowWidth])

  const width = useMemo(() => {
    if (windowWidth > MEDIUM_WIDTH) return showDrawer ? '238px' : '70px'
    return '238px'
  }, [windowWidth, showDrawer])

  const x = useMemo(() => {
    if (windowWidth < MEDIUM_WIDTH) return showDrawer ? '0%' : '-100%'
  }, [windowWidth, showDrawer])

  return (
    <motion.aside
      initial={{ width, x }}
      animate={{ width, x, transition: { duration: 0.5 } }}
      className={`${containerStyle} shrink-0 bg-brand_blue px-[16px] py-[22px]`}
    >
      <button
        data-testid="drawer-control"
        onClick={() => dispatch(toggleDrawer())}
        className="absolute right-[-20px] cursor-pointer hide_tap"
      >
        {showDrawer ? <CloseDrawer /> : <OpenDrawer />}
      </button>
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            className={`flex ${showDrawer ? '' : 'justify-center'
              } items-center pb-[21px] border-b border-b-[#ffffff20]`}
          >
            <Logo className={showDrawer ? 'mr-[6.42px]' : ''} />
            <LogoName className={showDrawer ? 'block' : 'hidden'} />
          </div>
          <div className="py-[32px]  border-b border-b-[#ffffff20]">
            <p className={`text-12 text-neutral_white mb-[16px] ${showDrawer ? 'block' : 'hidden'}`}>Main</p>
            <List
              name="Home"
              showDrawer={showDrawer}
              path="dashboard"
              url="/dashboard"
              icon={<Home className={iconMargin} />}
            />
            <List
              name="Appointments"
              showDrawer={showDrawer}
              path="appointments"
              url="/appointments"
              icon={<Appointment className={iconMargin} />}
            />
            <List
              name="Messaging"
              showDrawer={showDrawer}
              path="messaging"
              url="/messaging"
              icon={<Messaging className={iconMargin} />}
            />
            <List
              name="Bills and Payment"
              showDrawer={showDrawer}
              path="bills"
              url="/bills-and-payment"
              icon={<Bills className={iconMargin} />}
            />
            <List
              name="My Records"
              showDrawer={showDrawer}
              path="more"
              url="/more"
              icon={<More fill='white' className={iconMargin} />}
            />
          </div>
          {/* <div className="py-[32px]">
            <p className={`text-12 text-neutral_white mb-[16px] ${showDrawer ? 'block' : 'hidden'}`}>Resources</p>
            <List
              name="Health Tips"
              showDrawer={showDrawer}
              path="health"
              url="/health-tips"
              icon={<HealthTips className={iconMargin} />}
            />
          </div> */}
        </div>
        <div>
          {/* <List
            name="Settings"
            showDrawer={showDrawer}
            path="settings"
            url="/settings"
            icon={<Settings className={iconMargin} />}
          /> */}
          <div
            data-testid="logout"
            onClick={() => dispatch(logout())}
            className={`
              flex ${showDrawer ? 'px-3.5' : 'justify-center px-2'} items-center rounded-[100px] cursor-pointer py-[9px] hover:bg-[#304D78]
            `}
          >
            <Logout className={`${showDrawer ? 'mr-[13px]' : ''}`} />
            <p className={`${showDrawer ? 'block' : 'hidden'} text-14 font-campton_r text-neutral_white`}>Logout</p>
          </div>
        </div>
      </div>
    </motion.aside>
  );
}

const List = ({ showDrawer, name, path, url, icon }) => {
  const { pathname } = useLocation()
  const [showLabel, setShowLabel] = useState(false)
  const regex = new RegExp(path)
  const match = regex.exec(pathname)
  const dispatch = useAppDispatch();
  const windowWidth = useWindowWidth()

  return (
    <Link
      to={url}
      className={`
        flex ${showDrawer ? '' : 'justify-center'} items-center rounded-[100px] cursor-pointer hide_tap relative
        ${match ? 'bg-brand_primary font-campton_m' : 'hover:bg-[#304D78] font-campton_r'} py-[9px] px-[14px] mb-[16px]
      `}
      onClick={() => windowWidth < MEDIUM_WIDTH && dispatch(toggleDrawer())}
      onMouseOver={() => setShowLabel(true)}
      onMouseOut={() => setShowLabel(false)}
    >

      {icon}
      <p className={`${showDrawer ? 'block' : 'hidden'} text-14 text-neutral_white shrink-0`}>{name}</p>
      <AnimatePresence>
        {showLabel &&
          <motion.div
            initial={{ opacity: 0, x: "-10px" }}
            animate={{ opacity: 1, x: 0, transition: { type: 'spring', duration: .7 } }}
            exit={{ opacity: 0, transition: { duration: .1 } }}
            className={showDrawer ? 'hidden' : 'absolute left-[30px] flex items-center z-20'}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="-1" y="10" width="15.5147" height="15.5147" rx="2.58579" transform="rotate(-45 -1 10)" fill="#143566" />
            </svg>
            <p className='text-14 text-neutral_white shrink-0 bg-brand_blue px-[16px] py-[6px] whitespace-nowrap rounded-[4px]'>{name}</p>
          </motion.div>
        }
      </AnimatePresence>
    </Link>
  )
}

List.propTypes = {
  showDrawer: PropTypes.bool,
  path: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.element
}