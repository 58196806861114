import { useEffect, useState } from "react"
import { appointment } from "services/endpoints/appointment";
import { isTimeInRange } from 'utils/formatDate';

export default function useCheckLiveCallAtIntervals(appointments) {
    const [currentCall, setCurrentCall] = useState()
    const [liveCall, setLiveCall] = useState(false)

    const getOngoingCall = () => {
        const ongoingCall = appointments?.filter((item) => {
            return ['ongoing', 'upcoming'].includes(item?.status?.toLowerCase()) && item?.type === 'inApp' && isTimeInRange(item?.appointmentDate, item?.startTime, item?.endTime)
        })[0]
        setCurrentCall(ongoingCall)
    }

    const checkIfTimeIsInRange = () => {
        if (isTimeInRange(currentCall?.appointmentDate, currentCall?.startTime, currentCall?.endTime))
                setLiveCall(true)
            else
                setLiveCall(false)
    }

    useEffect(() => {
        let interval = 1000 * 30 // 30 seconds
        let time = setInterval(() => {
            if (appointments?.length) getOngoingCall()
        }, interval)
        return () => clearInterval(time)
    }, [appointments])

    useEffect(() => {
        let timer
        let interval = 1000 * 30 // 30 seconds
        if (currentCall) {
            timer = setInterval(() => {
                checkIfTimeIsInRange()
            }, interval)
        }
        return () => clearInterval(timer)
    }, [currentCall])

    useEffect(() => {
        if (appointments?.length) getOngoingCall()
        if (currentCall) checkIfTimeIsInRange()
    }, [appointment, currentCall])

    return { currentCall, liveCall }
}