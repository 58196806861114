/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getMonth, getYear } from 'utils/formatDate';
import { ReactComponent as Calendar } from 'assets/icons/calendar-icon.svg';

const DefaultDatepicker = ({
  showCalender = false,
  startDate,
  label,
  icon,
  maxDate,
  minDate,
  onSelect,
  name,
  containerVariant,
  styles,
  showLabel = true,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  iconStyles
}) => {
  //local State
  const [labelStyles, setLabelStyles] = useState(false);

  //Variables
  const years = useMemo(() => {
    let currentYear = new Date().getFullYear(),
      years = [];
    let startYear = 1980;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }, []);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <div className={`relative  ${containerVariant || 'mb-6'}`}>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex justify-between items-center px-3">
        
            <button className="cursor-pointer text-16" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <select
              data-testid="test-year"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
              className="h-8 rounded-lg flex justify-center items-center focus:border focus:focus:border-brand_primary outline-none w-16 px-0.5"
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              data-testid="test-month"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              className="h-8 rounded-lg flex justify-center items-center focus:border focus:focus:border-brand_primary outline-none"
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button className="cursor-pointer text-16" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        selected={startDate}
        id="datepickerId"
        maxDate={maxDate}
        minDate={minDate}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        onChange={onSelect}
        timeIntervals={30}
        timeCaption={timeCaption}
        autoComplete="off"
        placeholder={`test-${name}`}
        onBlur={() => {
          setLabelStyles(false);
        }}
        onFocus={() => {
          setLabelStyles(true);
        }}
        data-testid={`test-${name}`}
          aria-labelledby={name}
        name={name}
        className={` ${styles} border-neutral_stroke h-[56px] px-[16px] text-14 w-full text-neutral_black outline-0 border font-campton_r  hide_tap rounded-lg focus:border-brand_primary focus:border bg-neutral_white`}
      />
      {showLabel && (
        <motion.label
          htmlFor="datepickerId"
          animate={{
            scale: 0.8,
            top: !labelStyles && !startDate ? '8px' : '-16px',
            fontSize: !labelStyles && !startDate ? '16px' : '14px',
          }}
          className="
          absolute left-2 text-neutral_body mb-2 block text-14
          font-campton_r bg-neutral_white px-1 pt-2 cursor-text"
        >
          {label}
        </motion.label>
      )}
      {showCalender && (
        <div className="absolute inset-y-0 right-4 items-center pl-3 pointer-events-none top-5">
         <Calendar/>
        </div>
      )}
      {icon && <p className={`${iconStyles} absolute right-4 top-5 capitalize`}>{icon}</p>}
    </div>
    );
};
export default DefaultDatepicker;

DefaultDatepicker.propTypes = {
  showCalender: PropTypes.bool,
  label: PropTypes.string,
  styles: PropTypes.string,
  icon: PropTypes.any,
  showLabel: PropTypes.bool,
  dateFormat: PropTypes.any,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  containerVariant: PropTypes.string,
  onSelect: PropTypes.func,
  startDate: PropTypes.any,
  name: PropTypes.string,
  iconStyles: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeCaption: PropTypes.string
};
