import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { userInitials } from 'utils/ImagePlaceholder';
import Avatar from 'components/Avatar/Avatar';
import RescheduleCard from './Components/RescheduleCard';
import dayjs from 'dayjs';
import useAppointment from 'hooks/fetch/useAppointment';
import Dropdown from 'components/Dropdown/Index';
import { cancellation_reasons } from 'mocks/appointment';
import { SlideUp } from 'components/Animations';
import TextArea from 'components/Inputs/TextArea';

const RescheduleAppointment = ({ data, formData, setFormData, reasonData, setReasonData }) => {
  const [reason, setReason] = useState();

  const { get_available_time_slot, loading } = useAppointment();

  const handleChange = (e) => {
    setReasonData({ ...reasonData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    get_available_time_slot(`${data?.providerInfo.id}/available-time`, {
      date: dayjs(formData?.proposedDate).format('YYYY-MM-DD'),
    });
  }, [formData?.proposedDate]);

  return (
    <div className="mt-2 pb-40 overflow-y-scroll">
      <p className="text-14 font-campton_r text-neutral_body">
        Select reason, read appointment policy and authorize rescheduling
      </p>
      <div className="border border-neutral_stroke_2 p-4 rounded-2xl mt-6">
        <p className="font-campton_r text-12 text-neutral_body mb-4">Provider Information</p>
        <div className="flex">
          <Avatar
            url={data?.providerInfo?.profilePic}
            textVariant="!text-12"
            alt={data?.providerInfo?.firstName?.split('')[0]}
            width="32px"
            height="32px"
            className="bg-brand_secondary mr-1.5 w-8 h-8"
            initials={userInitials(data?.providerInfo?.firstName, data?.providerInfo?.lastName)}
          />
          <div className="font-campton_r">
            <p className="text-14 capitalize text-neutral_black ">
              {data?.providerInfo?.firstName} {data?.providerInfo?.lastName}{' '}
              <span className="text-12 text-neutral_body">{`(${
                data?.providerInfo?.gender?.split('')[0] ?? 'N/A'
              })`}</span>
            </p>
            <p className="text-neutral_body text-12">
              {data?.providerInfo?.locationName ?? 'N/A'} | {data?.providerInfo?.specialty ?? 'N/A'},{' '}
              {data?.providerInfo?.qualification ?? 'N/A'}
            </p>
            <p className="text-neutral_body text-12">{data?.providerInfo?.GPINumber ?? 'N/A'}</p>
          </div>
        </div>
      </div>
      <RescheduleCard
        formData={formData}
        setFormData={setFormData}
        loading={loading}
        data={data}
        reasonData={reasonData}
        setReasonData={setReasonData}
      />
      <div className="border-t pt-6 border-neutral_stroke mt-6 border-dashed">
        <Dropdown
          type="select"
          id="reason"
          label="Reason for Rescheduling"
          name="reason"
          options={cancellation_reasons}
          value={reason}
          onChange={(data) => {
            setReason(data);
            setReasonData((prevReasonData) => ({ ...prevReasonData, reason: data.value }));
          }}
        />
        {reasonData.reason === 'others' && (
          <SlideUp>
            <TextArea
              placeholder="Type here..."
              type="text"
              id="reason"
              name="other_reason"
              variant="mt-4"
              onChange={handleChange}
            />
          </SlideUp>
        )}
      </div>
    </div>
  );
};

export default RescheduleAppointment;
RescheduleAppointment.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setReasonData: PropTypes.func,
  reasonData: PropTypes.object,
};
