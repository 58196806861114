import React from "react";

export const messages = [
    {
        id: 1,
        title: 'Dr.',
        firstName: "Chinedu",
        lastName: "Ikechi",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    },
    {
        id: 2,
        title: 'Dr.',
        firstName: "Success",
        lastName: "Ologunsua",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    },
    {
        id: 3,
        title: 'Dr.',
        firstName: "Akinpelu",
        lastName: "TIimTim",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    },
    {
        id: 4,
        title: 'Dr.',
        firstName: "Barakat",
        lastName: "Salam",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    },
    {
        id: 5,
        title: 'Dr.',
        firstName: "Marcus",
        lastName: "Rashford",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    },
    {
        id: 6,
        title: 'Dr.',
        firstName: "Ngolo Kante",
        lastName: "Ikechi",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    },
    {
        id: 7,
        title: 'Dr.',
        firstName: "Ngolo",
        lastName: "Kante",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    },
    {
        id: 8,
        title: 'Dr.',
        firstName: "Bruno",
        lastName: "Fernades",
        date: "May 20",
        subject: "Surgery",
        body: "That shouldn’t be a problem"
    }
]

export const message_options = [
    {
        id: 1,
        name: 'View details',
        value: "details",
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 12C6.07624 12 4.39562 11.3006 3.00437 9.92188C2.43352 9.35418 1.94786 8.70685 1.56249 8C1.94776 7.29309 2.43343 6.64574 3.00437 6.07812C4.39562 4.69938 6.07624 4 7.99999 4C9.92374 4 11.6044 4.69938 12.9956 6.07812C13.5676 6.6456 14.0543 7.29295 14.4406 8C13.99 8.84125 12.0269 12 7.99999 12ZM7.99999 5C7.40665 5 6.82663 5.17595 6.33328 5.50559C5.83994 5.83524 5.45542 6.30377 5.22836 6.85195C5.00129 7.40013 4.94188 8.00333 5.05764 8.58527C5.17339 9.16721 5.45912 9.70176 5.87867 10.1213C6.29823 10.5409 6.83278 10.8266 7.41472 10.9424C7.99667 11.0581 8.59987 10.9987 9.14804 10.7716C9.69622 10.5446 10.1648 10.1601 10.4944 9.66671C10.824 9.17336 11 8.59334 11 8C10.9992 7.2046 10.6828 6.44202 10.1204 5.87959C9.55797 5.31716 8.79539 5.00083 7.99999 5ZM7.99999 10C7.60443 10 7.21775 9.8827 6.88885 9.66294C6.55996 9.44318 6.30361 9.13082 6.15224 8.76537C6.00086 8.39991 5.96125 7.99778 6.03842 7.60982C6.11559 7.22186 6.30608 6.86549 6.58578 6.58579C6.86549 6.30608 7.22185 6.1156 7.60981 6.03843C7.99778 5.96126 8.39991 6.00087 8.76536 6.15224C9.13081 6.30362 9.44317 6.55996 9.66293 6.88886C9.8827 7.21776 9.99999 7.60444 9.99999 8C9.99999 8.53043 9.78928 9.03914 9.41421 9.41421C9.03913 9.78929 8.53043 10 7.99999 10Z" fill="#748181" />
        </svg>
    },
    {
        id: 2,
        name: 'Mark as read',
        value: "read",
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2775 5.58359L8.2775 1.58359C8.19533 1.52877 8.09877 1.49951 8 1.49951C7.90123 1.49951 7.80467 1.52877 7.7225 1.58359L1.7225 5.58359C1.65401 5.62928 1.59786 5.69119 1.55904 5.76381C1.52023 5.83642 1.49995 5.9175 1.5 5.99984V12.4998C1.5 12.7651 1.60536 13.0194 1.79289 13.2069C1.98043 13.3945 2.23478 13.4998 2.5 13.4998H13.5C13.7652 13.4998 14.0196 13.3945 14.2071 13.2069C14.3946 13.0194 14.5 12.7651 14.5 12.4998V5.99984C14.5001 5.9175 14.4798 5.83642 14.441 5.76381C14.4021 5.69119 14.346 5.62928 14.2775 5.58359ZM8 2.60046L13.1194 6.01359L8.93188 8.99984H7.06938L2.88188 6.01359L8 2.60046ZM2.5 12.4998V6.97046L6.61875 9.90796C6.70355 9.96853 6.80516 10.0011 6.90938 10.0011H9.09062C9.19484 10.0011 9.29645 9.96853 9.38125 9.90796L13.5 6.97046V12.4998H2.5Z" fill="#748181" />
        </svg>
    },
    {
        id: 3,
        name: 'Delete message',
        value: "delete",
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 3H11V2.5C11 2.10218 10.842 1.72064 10.5607 1.43934C10.2794 1.15804 9.89782 1 9.5 1H6.5C6.10218 1 5.72064 1.15804 5.43934 1.43934C5.15804 1.72064 5 2.10218 5 2.5V3H2.5C2.36739 3 2.24021 3.05268 2.14645 3.14645C2.05268 3.24021 2 3.36739 2 3.5C2 3.63261 2.05268 3.75979 2.14645 3.85355C2.24021 3.94732 2.36739 4 2.5 4H3V13C3 13.2652 3.10536 13.5196 3.29289 13.7071C3.48043 13.8946 3.73478 14 4 14H12C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V4H13.5C13.6326 4 13.7598 3.94732 13.8536 3.85355C13.9473 3.75979 14 3.63261 14 3.5C14 3.36739 13.9473 3.24021 13.8536 3.14645C13.7598 3.05268 13.6326 3 13.5 3ZM6 2.5C6 2.36739 6.05268 2.24021 6.14645 2.14645C6.24021 2.05268 6.36739 2 6.5 2H9.5C9.63261 2 9.75979 2.05268 9.85355 2.14645C9.94732 2.24021 10 2.36739 10 2.5V3H6V2.5ZM12 13H4V4H12V13ZM7 6.5V10.5C7 10.6326 6.94732 10.7598 6.85355 10.8536C6.75979 10.9473 6.63261 11 6.5 11C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5V6.5C6 6.36739 6.05268 6.24021 6.14645 6.14645C6.24021 6.05268 6.36739 6 6.5 6C6.63261 6 6.75979 6.05268 6.85355 6.14645C6.94732 6.24021 7 6.36739 7 6.5ZM10 6.5V10.5C10 10.6326 9.94732 10.7598 9.85355 10.8536C9.75979 10.9473 9.63261 11 9.5 11C9.36739 11 9.24021 10.9473 9.14645 10.8536C9.05268 10.7598 9 10.6326 9 10.5V6.5C9 6.36739 9.05268 6.24021 9.14645 6.14645C9.24021 6.05268 9.36739 6 9.5 6C9.63261 6 9.75979 6.05268 9.85355 6.14645C9.94732 6.24021 10 6.36739 10 6.5Z" fill="#748181" />
        </svg>
    }
]