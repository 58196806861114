import React, { useState } from 'react';
import { SlideUp } from 'components/Animations';
import Navbar from 'components/Navbar';
import Steps from 'components/Steps';
import SubNavbar from 'components/SubNavbar';
import useMultiStepForm from 'hooks/useMultiStepForm';
import { useNavigate } from 'react-router-dom';
import BasicInformation from './BasicInformation';
import SelectProvider from './SelectProvider';
import PreviewInformation from './PreviewInformation';
import Payment from './Payment';

const BookAppointment = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    appointmentType: '',
    priority: '',
    appointmentDate: '',
    startTime: '',
    endTime: '',
    note: '',
    reference: '',
    providerId: '',
    institutionId: '',
  });
  const [provider, setProvider] = useState({});
  const appointmentDetails = {
    appointmentDate: formData?.appointmentDate,
    startTime: formData?.startTime,
    endTime: formData?.endTime,
    type: formData?.appointmentType,
    priority: formData?.priority,
    note: formData?.note,
    providerInfo: {
      institutionId: provider?.institutionId ?? "",
      profilePic: provider?.profilePic ?? "",
      firstName: provider?.firstName ?? "",
      lastName: provider?.lastName ?? "",
      emailAddress: provider?.emailAddress ?? "",
      title: provider?.title ?? "",
      specialty: provider?.specialty ?? "",
      qualification: provider?.qualification ?? "",
      locationName: provider?.locationName ?? "",
      GPINumber: provider?.GPINumber ?? "",
      gender: provider?.gender ?? "",
    },
  };
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { step, next, back } = useMultiStepForm([
    <BasicInformation
      key={1}
      formData={formData}
      handleChange={handleChange}
      setFormData={setFormData}
      cancel={() => navigate(-1)}
      next={() => next()}
    />,
    <SelectProvider
      formData={formData}
      setFormData={setFormData}
      key={2}
      back={() => back()}
      next={() => next()}
      provider={provider}
      setProvider={setProvider}
    />,
    <PreviewInformation
      back={() => back()}
      data={appointmentDetails}
      formData={formData}
      setFormData={setFormData}
      next={() => next()}
      key={3}
    />,
    <Payment
      key={4}
      back={() => back()}
      formData={formData}
      data={appointmentDetails}
    />
  ]);

  return (
    <>
      <Navbar title="Appointments" />
      <SubNavbar back="Appointments" backClick='/appointments' current="Book an appointment" />
      <SlideUp>
        <Steps
          step={step}
          description1="Appointment type, priority, date and note"
          step2="Select Provider"
          description2="Select provider for this appointment based off specialty"
        />
      </SlideUp>
    </>
  );
};

export default BookAppointment;
