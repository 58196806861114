import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as Calendar } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as Door } from 'assets/icons/door.svg';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import Status from "components/Status";
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import Button from "components/Buttons/Button";

export default function Tracker({ location, date, id, icon, status, capsule, title, purpose, onClick, buttonName }) {
    return (
        <section className="flex justify-between items-center bg-[#EFF8FB] rounded-[16px] px-[24px] py-[16px] mb-[16px]">
            <div className="w-[85%] mr-[8px]">
                {capsule &&
                    <Status
                        status={capsule}
                        className="mb-[8px] text-12 bg-green text-neutral_white"
                    />
                }
                {purpose && <p className='text-12 text-neutral_body mb-[12px] font-campton_r'>{purpose}</p>}
                <p className='text-14 font-campton_m mb-[8px] text-neutral_black capitalize'>{title}</p>
                {location &&
                    <div className='flex mr-[8px]'>
                        <Location className='mr-[8px]' />
                        <p className='text-12 font-campton_r mb-[8.5px] text-neutral_body'>
                            {location}
                        </p>
                    </div>
                }
                {date && <div className='flex ml-[2px]'>
                    <Calendar className='mr-[8px]' />
                    <p className='text-12 font-campton_r mb-[8.5px] text-neutral_body'>
                        {formatDate(date)} {formatTwelveHour(date)} {status && <>- <span className='text-green capitalize'>{status?.toLowerCase()}</span></>}
                    </p>
                </div>
                }
                {id && <div className='flex'>
                    <Door className='mr-[8px]' />
                    <p className='text-12 font-campton_r text-neutral_body'>#{id}</p>
                </div>
                }
                {buttonName &&
                    <Button
                        theme="darkBlue"
                        className="max-h-[32px] mt-[12.5px]"
                        type="button"
                        onClick={onClick}
                    >
                        <p className="font-campton_r text-12 py-[4px]">{buttonName}</p>
                    </Button>
                }
            </div>
            {icon}
        </section>
    )
}

Tracker.propTypes = {
    location: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.string,
    icon: PropTypes.element,
    status: PropTypes.string,
    capsule: PropTypes.string,
    title: PropTypes.string,
    purpose: PropTypes.string,
    onClick: PropTypes.func,
    buttonName: PropTypes.string
}