import Dropdown from 'components/Dropdown/Index';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RadioButtonSelect from 'components/RadioButtonSelect';
import { sleepingSchedules } from 'mocks/medicalRecords';


const SleepPatterns = ({ formData, setFormData, type  }) => {
  const [sleepHours, setSleepHours] = useState({
    id: formData[type].avgSleepHours,
    label: formData[type].avgSleepHours,
    value: formData[type].avgSleepHours
  });
  const [sleepingSchedule, setSleepingSchedule] = useState(formData[type].consistentSleepSchedule);
  const [sleepingDifficulty, setSleepingDifficulty] = useState(formData[type].sleepDifficulty);
  const handleSleepSchedule = (name) => {
    setSleepingSchedule(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], consistentSleepSchedule: name}}));
  };
  const handleSleepDifficulty = (name) => {
    setSleepingDifficulty(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], sleepDifficulty: name}}));
  };
  const handleSleephours = (data) => {
    setSleepHours(data);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], avgSleepHours: data.value}}));
  }
  return (
    <div className="my-4">
      <Dropdown
        label="Average no of sleep each night?"
        variant="mb-4"
        name="avgSleepHours"
        id="avgSleepHours"
        value={sleepHours}
        optionContainerVariant="w-full md:min-h-[100px] min-h-[150px]"
        options={sleepingSchedules}
        onChange={(data) =>handleSleephours(data)}
      />
      <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
        <div>
        <p className="font-campton_r text-14 text-neutral_black mb-2">Have a consistent sleep schedule?</p>
          <div className="grid grid-cols-12 gap-4">
            <RadioButtonSelect
              label="Yes"
              variant="col-span-6"
              isChecked={sleepingSchedule === 'yes'}
              onChange={() => handleSleepSchedule('yes')}
            />
            <RadioButtonSelect
              label="No"
              variant="col-span-6"
              isChecked={sleepingSchedule === 'no'}
              onChange={() => handleSleepSchedule('no')}
            />
          </div>
        </div>
        <div>
        <p className="font-campton_r text-14 text-neutral_black mb-2">Experience any difficulties with sleep?</p>
          <div className="grid grid-cols-12 gap-4">
            <RadioButtonSelect
              label="Yes"
              variant="col-span-6"
              isChecked={sleepingDifficulty === 'yes'}
              onChange={() => handleSleepDifficulty('yes')}
            />
            <RadioButtonSelect
              label="No"
              variant="col-span-6"
              isChecked={sleepingDifficulty === 'no'}
              onChange={() => handleSleepDifficulty('no')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SleepPatterns;
SleepPatterns.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
