import { useEffect, useState } from "react"

export default function useDebounce(value, delay) {
    const [debounceValue, setDebounceValue] = useState(value)

    useEffect(() => {
        if(value === '') return setDebounceValue(value)
        const handler = setTimeout(() => setDebounceValue(value), delay || 1000)

        return () => clearTimeout(handler)
    }, [value])

    return debounceValue
}