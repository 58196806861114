/* eslint-disable no-undef */
import { PAYSTACK_CURRENCY } from "constants";
import { useState } from "react";

export default function usePaystackPayment() {
    const [loading, setLoading] = useState(false)

    const initializePaystack = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = 'https://js.paystack.co/v2/inline.js';
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    const initializePayment = async ({ email, amount, description, institutionId, patientId }, onSuccess, onCancel) => {
        try {
            setLoading(true)
            const initRes = await initializePaystack()
            if (!initRes) return alert('Paystack gateway error')

            const paystack = new PaystackPop();
            paystack.newTransaction({
                key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY, // Replace with your public key
                email: email.toLowerCase(),
                amount: amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
                currency: PAYSTACK_CURRENCY,
                ref: `GBH-${Math.floor((Math.random() * 1000000000) + 1)}`, // Replace with a reference you generated
                metadata: {
                    email: email.toLowerCase(),
                    description,
                    institutionId,
                    patientId
                },
                onSuccess: async (transaction) => {
                    onSuccess(transaction?.reference)
                    setLoading(false)
                },
                onCancel: () => {
                    onCancel()
                    setLoading(false)
                }
            });
        } catch (error) {
            return error
        } finally {
            setLoading(false)
        }
    }

    return { initializePayment, loading }
}