import Button from 'components/Buttons/Button';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import useAuth from 'hooks/fetch/useAuth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordForm = (callback) => {
  const { forgot_password, loading } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    emailAddress: '',
  });

  const receiveOTP = () => {
    forgot_password({ payload: { ...formData } })
      .then(res => {
        if (res?.code === 200) {
          navigate(`/reset-password?email=${formData.emailAddress}`)
          callback
        }
      })
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <FormWrapper
      title="Forgot Password"
      description={
        <>
          <span className="text-neutral_body font-campton_r">Ok, let’s access your account!</span>
          <br /> <span className='text-primary_shade_400 font-campton_sb'>First, what’s your email?</span>
        </>
      }
      caption="Email address should be your registered email"
      className="h-full md:mt-12 mt-10 w-full sm:w-[387px] mx-auto"
      onSubmit={receiveOTP}
    >
      <div className="flex md:justify-center justify-between items-center flex-col w-full max-w-md mx-auto md:mt-12 mt-8">
        <div className="mb-10 w-full">
          <Input
            label="Email Address"
            type="text"
            id="email"
            name="emailAddress"
            readOnly={loading}
            value={formData?.emailAddress}
            onChange={handleChange}
          />
        </div>
        <>
          <Button
            name="Receive OTP"
            theme="blue"
            className="w-full"
            disabled={!formData?.emailAddress}
            loading={loading}
          />
          <Button
            className="py-2 w-full border border-neutral_stroke_2 flex justify-center items-center rounded-full mt-6 h-12 cursor-pointer hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary text-neutral_body md:text-16 text-14"
            onClick={() => navigate('/')}
          >
            <p className="font-campton_m">
              Remember password?{' '}
              <span className="text-brand_secondary" to="/">
                Login
              </span>
            </p>
          </Button>
        </>
      </div>
    </FormWrapper>
  );
};

export default ForgotPasswordForm;
