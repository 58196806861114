import Button from 'components/Buttons/Button';
import DefaultDatepicker from 'components/Datepicker/Index';
import Dropdown from 'components/Dropdown/Index';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import TextArea from 'components/Inputs/TextArea';
import dayjs from 'dayjs';
import useMyRecords from 'hooks/fetch/useMyRecords';
import { frequencyTypes, routeOfAdministrationList } from 'mocks/myRecords';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import PropTypes from 'prop-types';

const NewMedication = ({ callback, selectedItem, type = 'new' }) => {
  const { loggedUser } = useAuthState();
  const [formData, setFormData] = useState({
    frequency: '',
    medicationName: '',
    dosage: '',
    routeOfAdministration: '',
    startDate: '',
    endDate: '',
    note: '',
  });
  const [routeOfAdministration, setRouteOfAdministration] = useState('');
  const [frequency, setFrequency] = useState('');
  const { loading, create_medication, edit_medication } = useMyRecords();

  useEffect(() => {
    if (type === 'edit' && selectedItem) {
      setFormData({
        medicationName: selectedItem?.medicationName,
        dosage: selectedItem?.dosage.split(' '),
        frequency: selectedItem?.frequency,
        routeOfAdministration: selectedItem?.routeOfAdministration,
        startDate: new Date(selectedItem?.startDate),
        endDate: selectedItem?.endDate ? new Date(selectedItem?.endDate) : '',
        note: selectedItem?.note,
      });
      setFrequency({
        value: selectedItem?.frequency,
        label: selectedItem?.frequency,
      });
      setRouteOfAdministration({
        value: selectedItem?.routeOfAdministration,
        label: selectedItem?.routeOfAdministration,
      });
    }
  }, [selectedItem, type]);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const createMedication = () => {
    const payload = {
      ...formData,
      startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
      endDate: formData.endDate ? dayjs(formData.endDate).format('YYYY-MM-DD') : '',
      patientId: loggedUser?._id,
    };
    type !== 'edit' && selectedItem
      ? create_medication(removeSpecificKeys(payload), callback)
      : edit_medication(
        {
          ...removeSpecificKeys(payload, 'patientId'),
          medicationId: selectedItem?._id,
          createdBy: selectedItem.createdBy.patientId ? 'patient' : 'provider',
        },
        callback,
      );
  };

  const differenceInMilliseconds = new Date(formData.endDate).getTime() - new Date(formData.startDate).getTime();
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  const disabledButton =
    !formData.frequency ||
    !formData.medicationName ||
    !formData.dosage ||
    !formData.routeOfAdministration ||
    !formData.startDate

  return (
    <FormWrapper className="relative">
      <div className='modal-right-body'>
        <div className="grid grid-cols-1 gap-2">
          <Input
            label="Medication Name"
            type="text"
            id="name"
            name="medicationName"
            readOnly={loading}
            value={formData?.medicationName}
            onChange={handleChange}
          />
          <Input
            label="Dosage"
            type="text"
            id="dosage"
            name="dosage"
            readOnly={loading}
            value={formData?.dosage}
            onChange={handleChange}
          />
          <Dropdown
            type="select"
            id="frequency"
            label="Frequency"
            variant="my-2"
            name="frequency"
            options={frequencyTypes}
            value={frequency}
            onChange={(data) => {
              setFrequency(data);
              setFormData({ ...formData, frequency: data.value });
            }}
          />
          <Dropdown
            type="select"
            id="routeOfAdministration"
            label="Route of Administration"
            name="routeOfAdministration"
            options={routeOfAdministrationList}
            value={routeOfAdministration}
            onChange={(data) => {
              setRouteOfAdministration(data);
              setFormData({ ...formData, routeOfAdministration: data.value });
            }}
          />
          <DefaultDatepicker
            label="Start Date"
            containerVariant="mt-[14px]"
            startDate={formData.startDate}
            minDate={new Date()}
            onSelect={(e) => setFormData({ ...formData, startDate: e })}
            name="startDate"
          />
          <div>
            <DefaultDatepicker
              label="End Date"
              containerVariant="mt-[14px]"
              startDate={formData.endDate}
              minDate={formData.startDate}
              onSelect={(e) => setFormData({ ...formData, endDate: e })}
              name="endDate"
            />
            {formData.endDate && formData.startDate && (
              <p className="font-campton_r text-14 text-neutral_body mt-0.5">{Math.round(differenceInDays)} day(s)</p>
            )}
          </div>
          <TextArea
            label="Add note"
            type="text"
            id="note"
            rows={3}
            variant="mt-[14px]"
            name="note"
            readOnly={loading}
            value={formData?.note}
            onChange={(e) => setFormData({ ...formData, note: e.target.value })}
          />
        </div>
      </div>
      <div className="modal-right-button-container">
        <Button
          name={type !== 'edit' ? 'Add medication' : 'Save changes'}
          theme="blue"
          loading={loading}
          onClick={createMedication}
          className="w-[147px] h-11 py-2 px-8"
          disabled={disabledButton}
        />
      </div>
    </FormWrapper>
  );
};

export default NewMedication;
NewMedication.propTypes = {
  callback: PropTypes.func,
  selectedItem: PropTypes.object,
  type: PropTypes.string
};
