import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import EmptyState from "components/EmptyState"
import { ReactComponent as EmptyMessage } from 'assets/svg/empty-message.svg'
import { MessagingContext } from "context"
import TextEditor from "components/TextEditor"
import { motion } from "framer-motion"
import Message from "./Message"
import MoreOptions from "components/Options/MoreOptions"
import DefaultModal from "components/Modals/DefaultModal"
import MessageDetails from "./MessageDetails"
import DeleteMessage from "./DeleteMessage"
import useMessaging from "hooks/fetch/useMessaging"
import Spinner from "components/Spinner"
import { message_options } from "mocks/messaging"
import { EditorState, convertToRaw } from 'draft-js'
import useUpload from "hooks/useUpload"
import draftToHtml from 'draftjs-to-html'
import dayjs from 'dayjs'

export default function SelectedMessage({ className }) {
    const { selectedMessage, setCreateMessage, setSelectedMessage } = useContext(MessagingContext)
    const { get_conversation, create_message, loading } = useMessaging()
    const [selectedOption, setSelectedOption] = useState()
    const [messages, setMessages] = useState([])
    const [file, setFile] = useState()
    const { upload, uploading } = useUpload()
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const message = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    useEffect(() => {
        if (selectedMessage?.conversationId) {
            setMessages([])
            get_conversation(selectedMessage?.conversationId)
                .then((res) => setMessages(res?.data || []))
        }
    }, [selectedMessage?.conversationId])

    useEffect(() => {
        const element = document.getElementById('conversation-container')
        if (messages.length) element.scrollTop = element.scrollHeight
    }, [messages])

    const handleFile = (e) => {
        setFile(e.target.files[0])
    }

    const handleEditorState = (e) => {
        setEditorState(e)
    }

    const submit = (e) => {
        e.preventDefault()
        if (file) {
            let timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
            upload(file, { file: `${timestamp}${file?.name}` }, timestamp, false)
                .then(res => {
                    if (res?.response?.status === 200) {
                        create_message({
                            conversationId: selectedMessage?.conversationId,
                            message, file: res?.imageUrl, recipientId: selectedMessage?.recipient?.id,
                            fileType: file?.type?.split("/")[1]
                        })
                            .then(res => {
                                if (res?.code === 200) {
                                    setEditorState(() => EditorState.createEmpty())
                                    get_conversation(selectedMessage?.conversationId)
                                        .then((res) => setMessages(res?.data || []))
                                }
                            })
                    }
                })
        } else {
            create_message({
                conversationId: selectedMessage?.conversationId,
                message, recipientId: selectedMessage?.recipient?.id
            })
                .then(res => {
                    if (res?.code === 200) {
                        setEditorState(() => EditorState.createEmpty())
                        get_conversation(selectedMessage?.conversationId)
                            .then((res) => setMessages(res?.data || []))
                    }
                })
        }
    }

    return (
        <section className={`${className} w-full`}>
            {selectedMessage?.subject ?
                <motion.div
                    initial={{ opacity: 0, x: "-40px" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0 }}
                    className="relative h-[90vh] sm:h-[84.5vh]"
                >
                    <div className="border-b border-b-[#ECEEEE] py-[17.5px] px-[24px] flex justify-between sm:items-center sticky top-[79px] bg-neutral_white">
                        <p data-testid={selectedMessage?.subject} className="text-16 font-campton_m text-neutral_black capitalize">{selectedMessage?.subject}</p>
                        <MoreOptions
                            options={message_options}
                            className="w-[150px]"
                            position="right-0"
                            onSelect={setSelectedOption}
                        />
                    </div>
                    <div id="conversation-container" className="p-[24px] h-[65vh] sm:h-[61vh] overflow-y-auto">
                        {messages?.length ?
                            messages?.map((message, i) =>
                                <Message
                                    key={message?.createdAt}
                                    source="to"
                                    index={i}
                                    message={message}
                                />
                            ) : <Spinner />
                        }
                    </div>
                    <form
                        onSubmit={submit}
                        className="editor_shadow px-[24px] pt-[16px] pb-[24px] absolute bottom-0 left-0 w-full bg-neutral_white"
                    >
                        <TextEditor
                            editorState={editorState}
                            handleEditorStateChange={handleEditorState}
                            file={file}
                            disabled={loading || uploading || (!message.split('<p></p>')[0] && !file)}
                            loading={loading || uploading}
                            handleFile={handleFile}
                        />
                    </form>
                </motion.div> : ''
            }
            {!loading && !selectedMessage?.conversationId ?
                <div className="hidden sm:block h-[84.5vh]">
                    <EmptyState
                        illustration={<EmptyMessage />}
                        title="No Message Selected"
                        description="Messages sent will be displayed here."
                        btnText="New Message"
                        onClick={() => setCreateMessage(true)}
                    />
                </div> : ''
            }
            {selectedOption === 'details' ?
                <DefaultModal
                    closeModal={() => setSelectedOption()}
                    styles="w-[90%] md:w-[730px]"
                    title="Message Details"
                    containerVariant="bg-neutral_white"
                >
                    <MessageDetails ccProviders={messages[0]?.ccProviders} />
                </DefaultModal> : ''
            }
            {selectedOption === 'delete' ?
                <DefaultModal
                    closeModal={() => setSelectedOption()}
                    styles="w-[90%] sm:w-[327px]"
                    //title="Message Details"
                    containerVariant="bg-neutral_white"
                >
                    <DeleteMessage
                        reset={() => {
                            setMessages([])
                            setSelectedMessage()
                        }}
                        cancel={() => setSelectedOption()}
                    />
                </DefaultModal> : ''
            }
        </section>
    )
}

SelectedMessage.propTypes = {
    className: PropTypes.string
}