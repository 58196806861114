import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Check } from 'assets/icons/check.svg';

const Steps = ({ step, description1, step2, description2 }) => {
  return (
    <div className="md:flex md:p-[24px]">
      <div className="p-[24px] md:border border-neutral_stroke_2 rounded-[16px] shrink-0 w-full md:w-[290px] mr-[24px] md:h-fit hidden lg:block">
        <div className="flex mb-[8px]">
          <div
            className={`flex justify-center items-center w-[20px] h-[20px] ${step?.key > 0 ? 'bg-success' : 'bg-[#748181]'
              } rounded-full shrink-0`}
          >
            {step?.key > 1 ? <Check /> : <p className="text-12 text-neutral_white text-center">1</p>}
          </div>
          <div className="ml-[16px]">
            <p className="text-14 text-neutral_black font-campton_m mb-[2px]">Basic Information</p>
            <p className="text-12 text-neutral_body font-campton_r pb-[10px] border-l border-l-success ml-[-26px] pl-[26px]">
              {description1}
            </p>
          </div>
        </div>
        <div className={`flex mb-[8px] ${step?.key < 2 ? 'opacity-60' : ''}`}>
          <div
            className={`flex justify-center items-center w-[20px] h-[20px] ${step?.key > 1 ? 'bg-success' : 'bg-[#748181]'
              } rounded-full shrink-0`}
          >
            {step?.key > 2 ? <Check /> : <p className="text-12 text-neutral_white text-center">2</p>}
          </div>
          <div className="ml-[16px]">
            <p className="text-14 text-neutral_black font-campton_m mb-[2px]">{step2}</p>
            <p
              className={`
                                text-12 text-neutral_body font-campton_r pb-[10px] border-l ml-[-26px] pl-[26px]
                                ${step?.key > 1 ? 'border-l-success' : 'border-l-[#748181]'} 
                                `}
            >
              {description2}
            </p>
          </div>
        </div>
        <div className={`flex mb-[8px] ${step?.key < 3 ? 'opacity-60' : ''}`}>
          <div
            className={`flex justify-center items-center w-[20px] h-[20px] ${step?.key > 2 ? 'bg-success' : 'bg-[#748181]'
              } rounded-full shrink-0`}
          >
            {step?.key > 3 ? <Check /> : <p className="text-12 text-neutral_white text-center">3</p>}
          </div>
          <div className="ml-[16px]">
            <p className="text-14 text-neutral_black font-campton_m mb-[2px]">Preview Information</p>
            <p
              className={`
                                text-12 text-neutral_body font-campton_r pb-[10px] border-l ml-[-26px] pl-[26px]
                                ${step?.key > 2 ? 'border-l-success' : 'border-l-[#748181]'} 
                                `}
            >
              Preview & ensure information provided is correct
            </p>
          </div>
        </div>
        <div className={`flex ${step?.key < 4 ? 'opacity-60' : ''}`}>
          <div
            className={`flex justify-center items-center w-[20px] h-[20px]
            ${step?.key > 3 ? 'bg-success' : 'bg-[#748181]'} rounded-full shrink-0`}
          >
            <p className="text-12 text-neutral_white text-center">4</p>
          </div>
          <div className="ml-[16px]">
            <p className="text-14 text-neutral_black font-campton_m mb-[2px]">Payment</p>
            <p className="text-12 text-neutral_body font-campton_r ml-[-26px] pl-[26px]">
              Confirm appointment by making payment
            </p>
          </div>
        </div>
      </div>
      <div className="p-[24px] md:border border-neutral_stroke_2 rounded-[16px] w-full h-[86vh] md:h-[74vh]">{step}</div>
    </div>
  );
};

export default Steps;
Steps.propTypes = {
  step: PropTypes.object,
  description1: PropTypes.string,
  step2: PropTypes.string,
  description2: PropTypes.string,
};
