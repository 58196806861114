import React, { useMemo, useRef, useState } from 'react';
import Pin from 'components/Inputs/Pin';
import Button from 'components/Buttons/Button';
import useExtractPIN from 'hooks/useExtractPIN';
import usePIN from 'hooks/usePIN';
import LoginSuccess from 'components/Modals/LoginSuccess';
import PropTypes from 'prop-types';
import useAuth from 'hooks/fetch/useAuth';
import useResendOtp from 'hooks/useResendOtp';
import { useNavigate } from 'react-router-dom';
import DefaultModal from 'components/Modals/DefaultModal';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';

const VerifyEmail = ({ variant, btnStyles, userStatus, email }) => {
  const inputRef = useRef([]);
  const [showModal, setShowModal] = useState(false);
  const { timeLeft, resetTimer } = useResendOtp();
  const navigate = useNavigate();
  const { get_patient_profile } = useMedicalRecord();
  const { otp, onChange, onPress, onPaste } = usePIN(6, inputRef);
  const { verify_account, validate_reset_password_code, resend_otp, forgot_password, loading, verifying } = useAuth();
  const { extractPIN } = useExtractPIN();

  const disabled = useMemo(() => {
    return extractPIN(otp)?.length < 6 ? true : false;
  }, [otp]);

  const handleLogin = () => {
    get_patient_profile();
    setShowModal(true);
  };

  const resentOtp = () => {
    userStatus === 'new'
      ? resend_otp({ emailAddress: email }, resetTimer)
      : forgot_password({ payload: { emailAddress: email } })
        .then(res => {
          if (res?.code === 200) {
            navigate(`/reset-password?email=${email}`)
            resetTimer()
          }
        })
  };

  const verifyAccount = () => {
    userStatus === 'new'
      ? verify_account(otp, handleLogin)
      : validate_reset_password_code({ code: otp, emailAddress: email })
        .then(res => {
          if (res?.code === 200) navigate(`/reset-password`)
        })
  };

  return (
    <>
      <div className={variant}>
        <div className="flex md:space-x-7 space-x-3 justify-center">
          <Pin onChange={onChange} onPaste={onPaste} onPress={onPress} pin={otp} inputRef={inputRef} />
        </div>
        <p className="font-campton_m text-neutral_body mt-6 text-center">
          Didn’t receive code?
          <span
            className={`${timeLeft === 0 ? 'text-brand_secondary cursor-pointer underline' : 'text-neutral_body'
              } ml-0.5`}
            onClick={timeLeft === 0 ? resentOtp : null}
          >
            Resend
          </span>
          {timeLeft === 0 ? '' : <span className="text-neutral_body"> in </span>}
          {timeLeft === 0 ? '' : <span className="text-neutral_black">{timeLeft}s</span>}
        </p>
        <Button
          name="Submit"
          theme="blue"
          loading={loading}
          className={`w-full ${btnStyles || 'mt-10'}`}
          disabled={loading || disabled}
          onClick={verifyAccount}
        />
        {userStatus === 'new' && (
          <Button
            className="py-2 w-full border border-neutral_stroke_2 flex justify-center items-center rounded-full mt-6 h-12 cursor-pointer hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary text-neutral_body"
            onClick={() => navigate('/')}
          >
            <p className=" font-campton_r">
              Have an account? <span className="text-brand_secondary font-campton_m">Login</span>
            </p>
          </Button>
        )}
      </div>
      {verifying && (
        <DefaultModal
          title="Verifying OTP..."
          variant="text-neutral_white font-campton_m text-14"
          styles="flex flex-col items-center justify-end bg-[#153E4C] rounded-xl h-[165px] md:p-16 px-8 py-10"
          authModal={true}
        />
      )}
      {showModal && (
        <DefaultModal
          closeModal={() => navigate('/dashboard')}
          title="Welcome to GabbiHealth!"
          variant="rounded-[100px] bg-[#274572] py-2 px-4 text-neutral_white font-campton_sb text-center"
          styles="md:w-[520px] w-[320px] flex flex-col items-center justify-center bg-neutral_white md:p-16 px-4 py-10 rounded-3xl"
          authModal={true}
        >
          <LoginSuccess />
        </DefaultModal>
      )}
    </>
  );
};

export default VerifyEmail;
VerifyEmail.propTypes = {
  variant: PropTypes.string,
  btnStyles: PropTypes.string,
  userStatus: PropTypes.string,
  email: PropTypes.string
};
