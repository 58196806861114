/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import healthVitals from 'assets/svg/home/vitals.svg';
import { SlideUp } from 'components/Animations';

const HealthVitals = () => {
  return (
    <SlideUp className='h-full overflow-y-scroll'>
      <p className='font-campton_m text-[32px] text-neutral_black leading-[38.4px]'>What vital signs tracking is all about and how it works</p>
      <p className='text-14 text-neutral_body font-campton_m mt-3'>Tracking your health vitals, such as blood pressure, heart rate, and oxygen levels, is important for several reasons:</p>
      <p className='flex space-x-4 text-neutral_black text-12 my-6 font-campton_r'>
        <span className='border border-neutral_stroke_2 px-2 py-1 rounded-full'> 5 min read</span>
        <span className='border border-neutral_stroke_2 py-1 px-2 rounded-full'> 21 May, 2023</span>
      </p>
      <div className="">
        <img src={healthVitals} alt="healthy tips" className="w-full rounded-3xl object-cover lg:h-64 h-40" />
      </div>
      <div className='md:mb-0 mt-6 lg:h-[calc(100%-200px)] h-full'>
        <p className='text-14 font-campton_r text-neutral_body'>
          <span className='text-neutral_black font-campton_m mr-1'>1. Early Detection of Health Issues:</span>Regular monitoring can help detect potential health problems before they become serious, allowing for early intervention and treatment
        </p>
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
          <span className='text-neutral_black font-campton_m mr-1'>2. Managing Chronic Conditions:</span>For individuals with chronic conditions like hypertension, diabetes, or heart disease, tracking vitals is essential to ensure these conditions are well-managed and controlled.
        </p>
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
          <span className='text-neutral_black font-campton_m mr-1'>Promoting Healthy Lifestyle Choices:</span>Monitoring can motivate individuals to make healthier choices, such as maintaining a balanced diet, exercising regularly, and managing stress.
        </p>
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        <span className='text-neutral_black font-campton_m mr-1'> Personalized Health Management:</span>It allows for a personalized approach to healthcare, helping individuals and healthcare providers tailor treatment and prevention strategies.
        </p>
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        Remember that while tracking vitals is valuable, it should always be done in conjunction with guidance from a healthcare professional to ensure accurate interpretation and effective health management
        </p>    
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        Consult a GabbiHealth's healthcare professional today for accurate interpretation and effective health management using the patient's portal
        </p>        
      </div>
    </SlideUp>
  )
}

export default HealthVitals