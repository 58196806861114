import React from "react";
import PropTypes from "prop-types"
import DefaultModal from "components/Modals/DefaultModal";

export default function BillingPolicy({ setViewPolicy, institutionPolicy, institutionInfo }) {
    const paymentDueDate = institutionPolicy?.currentBillingPolicy?.daysAfterBilledDate
    const latePayment = institutionPolicy?.currentBillingPolicy?.latePayment

    return (
        <DefaultModal
            closeModal={() => setViewPolicy(false)}
            styles="w-full md:w-[457px]"
            position="modal-right"
            title="Billing Policy"
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
            <div className="modal-right-body font-campton_r">
                <p className="mb-[16px]">
                    At <span className="capitalize-first inline-block">{institutionInfo?.businessName}</span>, we are committed to providing you with the best possible healthcare experience.
                    To ensure a smooth and seamless payment process for both our patients we have established the following billing policy:
                </p>
                <Title text="Payment Plan:" />
                <p className="capitalize-first mb-[16px]">
                    {institutionInfo?.businessName}, accepts various payment methods for medical services. Patients have the following payment options:
                </p>
                <ul className="list-decimal pl-[16px] mb-[16px]">
                    <li>
                        Full Payment: Patients can choose to pay the full amount for medical services at the time of service.
                    </li>
                    <li>
                        Installments Payment: <span className="capitalize-first inline-block">{institutionInfo?.businessName}</span>, also offers the flexibility of paying for medical services in installments. This option allows patients to make partial payments at scheduled intervals.
                    </li>
                </ul>
                <Title text="Select Payment Method:" />
                <p className="capitalize-first mb-[16px]">
                    {institutionInfo?.businessName}, accepts the following payment methods for all medical services: Online Payment(payment directly from the application) Cash, Card and Insurance Coverage(Patients can use their insurance coverage to cover the cost of medical services, depending on their insurance plan and coverage).
                </p>
                <Title text="Payment Due Date:" />
                <p className="mb-[16px]">
                    The payment due date is the deadline for patients to settle outstanding balances or make payments for services rendered. Patients are required to make payments within {paymentDueDate} Days after the billed date.
                </p>
                <Title text="Late Payment Fee:" />
                <p className="capitalize-first mb-[16px]">
                    {latePayment?.choice === 'yes' ?
                        `There is an extra cost for late payment fee, this started counting after the due payment day which is ${latePayment?.feePercentage}% of the bill.` :
                        `${institutionInfo?.businessName}, does not impose extra fees for late payments or overdue balances.`
                    }
                </p>
            </div>
        </DefaultModal>
    )
}

const Title = ({ text }) => {
    return (
        <p className="text-neutral_black font-campton_m mb-[16px]">{text}</p>
    )
}

BillingPolicy.propTypes = {
    setViewPolicy: PropTypes.func,
    institutionPolicy: PropTypes.object,
    institutionInfo: PropTypes.object
}