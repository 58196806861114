import React from 'react';
import cancer from 'assets/svg/home/cancer.svg';
import { SlideUp } from 'components/Animations';

const ProstateCancer = () => {
  return (
    <SlideUp className='lg:h-[calc(100%-260px)] h-screen overflow-y-scroll pb-4'>
      <p className="font-campton_m text-[32px] text-neutral_black leading-[38.4px]">Prostate cancer</p>
      <p className="text-14 text-neutral_body font-campton_m mt-3">
        Prostate is a type of cancer that develops in the prostate, a small gland that produces seminal fluid in men.
      </p>
      <p className="flex space-x-4 text-neutral_black text-12 my-6 font-campton_r">
        <span className="border border-neutral_stroke_2 px-2 py-1 rounded-full"> 5 min read</span>
        <span className="border border-neutral_stroke_2 py-1 px-2 rounded-full"> 21 May, 2023</span>
      </p>
      <div className="h-48">
        <img src={cancer} alt="healthy tips" className="w-full rounded-3xl object-cover h-48" />
      </div>
      <div className="md:mb-0 mb-6 mt-6 lg:h-[calc(100%-100px)] h-full">
        <div>
          <p className="text-14 font-campton_r text-neutral_body">
            <span className="text-neutral_black font-campton_m mr-1">Symptoms:</span> - Symptoms can include difficulty
            urinating, blood in urine or semen, erectile dysfunction, and discomfort in the pelvic area. Early stages
            may be asymptomatic. Risk Factors:
          </p>
          <ul className="text-14 font-campton_r text-neutral_body">
            <li>1. Age: Risk increases with age</li>
            <li>2. Family history: Having a close relative with prostate cancer increases risk.</li>
            <li>3. Ethnicity: African American men have a higher risk.</li>
            <li>4. High-fat diet. </li>
          </ul>
        </div>
        <div className='text-14 mt-6'>
          <span className="text-neutral_black font-campton_m mr-1">Diagnosis:</span>
          <ul className="text-14 font-campton_r text-neutral_body">
            <li>1. Prostate-specific antigen (PSA) blood test</li>
            <li>2. Digital rectal exam (DRE)</li>
            <li>3. Biopsy for confirmation</li>
          </ul>
        </div>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">Treatment</span>Treatment options vary based on the stage and aggressiveness of the cancer. Options may include surgery,
          radiation therapy, hormone therapy, or active surveillance. Prevention: - Healthy lifestyle choices, including
          a balanced diet and regular exercise, may reduce the risk. Early detection through regular screenings is
          crucial.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">Awareness:</span>Understanding risk factors and being aware of potential symptoms is important for early detection and
          effective treatment. Consulting with healthcare professionals for personalized advice, especially if there’s a
          family history or if you’re experiencing any symptoms associated with prostate issues, is essential.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          Regular check-ups and discussions with your doctor play a crucial role in maintaining prostate health. Book an
          appointment today with a healthcare provider through GabbiHealth’s patient portal to get personalized health
          advice.
        </p>
      </div>
    </SlideUp>
  );
};

export default ProstateCancer;
