import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Inputs';
import Button from 'components/Buttons/Button';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import FormWrapper from 'components/FormWrapper';
import useVitalsAnalytics from 'hooks/useVitalsAnalytics';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';

const AddVitals = ({ callback }) => {
  const { getVitals } = useVitalsAnalytics({ className: 'h-[200px]' });
  const { create_vitals, loading } = useMedicalRecord()
  const [formData, setFormData] = useState({
    sysBloodPressure: '',
    diaBloodPressure: '',
    heartRate: '',
    respiratoryRate: '',
    temperature: '',
    oxygenSaturation: '',
    weight: '',
    height: '',
    bmi: '',
  });

  const handleChange = (e) => {
    if (isNaN(e.target.value)) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (formData.height && formData.weight) {
      setFormData({ ...formData, bmi: (formData.weight / (formData.height ** 2)).toString() });
    } else {
      setFormData({ ...formData, bmi: '' });
    }
  }, [formData.height, formData.weight]);

  const callbackFunc = () => {
    getVitals()
    callback()
  }

  const createVitals = () => {
    const newFormData = removeSpecificKeys(formData)
    create_vitals(newFormData, callbackFunc)
  }

  const disabled = useMemo(() => {
    if (Object.values(formData).every(v => v === '')) return true
    if (!formData.sysBloodPressure && formData?.diaBloodPressure) return true
    if (formData.sysBloodPressure && !formData?.diaBloodPressure) return true
    return loading
}, [formData, loading])

  return (
    <FormWrapper onSubmit={createVitals}>
      <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
        <div className="col-span-12 md:col-span-6">
          <Input
            label="Blood Pressure (SYS)"
            type="text"
            id="sysBloodPressure"
            name="sysBloodPressure"
            value={formData?.sysBloodPressure}
            onChange={handleChange}
            disabled={loading}
            measurement="mmHg"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            label="Blood Pressure (DIA)"
            type="text"
            id="diaBloodPressure"
            name="diaBloodPressure"
            value={formData?.diaBloodPressure}
            disabled={loading}
            onChange={handleChange}
            measurement="mmHg"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            label="Heart Rate"
            type="text"
            id="heartRate"
            name="heartRate"
            value={formData?.heartRate}
            disabled={loading}
            onChange={handleChange}
            measurement="bpm"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            label="Respiratory Rate"
            type="text"
            id="respiratoryRate"
            name="respiratoryRate"
            value={formData?.respiratoryRate}
            disabled={loading}
            onChange={handleChange}
            measurement="bpm"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            label="Temperature"
            type="text"
            id="temperature"
            name="temperature"
            value={formData?.temperature}
            disabled={loading}
            onChange={handleChange}
            measurement={<><sup>o</sup>C</>}
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            label="Oxygen Saturation"
            type="text"
            id="oxygenSaturation"
            name="oxygenSaturation"
            value={formData?.oxygenSaturation}
            disabled={loading}
            onChange={handleChange}
            measurement="%"
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <Input
            label="Height"
            type="text"
            id="height"
            name="height"
            value={formData?.height}
            disabled={loading}
            onChange={handleChange}
            measurement="meters"
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <Input
            label="Weight"
            type="text"
            id="weight"
            name="weight"
            value={formData?.weight}
            disabled={loading}
            onChange={handleChange}
            measurement="kg"
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <Input
            label="BMI"
            type="text"
            id="bmi"
            name="bmi"
            value={Number(formData?.bmi).toFixed(2)}
            disabled={true}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex justify-end mt-12">
        <Button
          name="Save"
          className="font-campton_m h-[40px] w-28"
          theme="blue"
          disabled={disabled}
          loading={loading}
        />
      </div>
    </FormWrapper>
  );
};

export default AddVitals;
AddVitals.propTypes = {
  callback: PropTypes.func,
};
