import Button from 'components/Buttons/Button';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import useAuth from 'hooks/fetch/useAuth';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { setUserInfo } from 'store/modules/auth';
import { useAppDispatch } from 'hooks/useReduxHook';

const LoginForm = ({ type, TabsHeader, setSelectedTab }) => {
  const { login_user, loading } = useAuth();
  const { get_patient_profile } = useMedicalRecord();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    password: '',
    email_or_phone_or_gNumber: '',
  });

  useEffect(() => {
    dispatch(setUserInfo({}));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const signIn = () => {
    login_user(
      {
        [type?.value]: formData.email_or_phone_or_gNumber,
        password: formData.password,
      },
      () => get_patient_profile(),
    );
  };
  return (
    <FormWrapper
      description={
        <>
          {' '}
          <span className=" font-campton_r">Welcome back!</span>
          <br />
          <span className='text-primary_shade_400 font-campton_sb'>Access your account</span>
        </>
      }
      className="sm:h-full md:mt-6 w-full sm:w-[387px] mx-auto"
      onSubmit={signIn}
    >
      <div className="flex justify-between flex-col w-full max-w-md md:mt-9 mt-6 ">
        <ul 
          className="overflow-x-scroll xl:overflow-x-hidden scrollbar-hide overflow-scroll-none
           font-dmsans_m border border-neutral_stroke_2 flex justify-between items-center w-full py-2 px-3 mb-6 rounded-full">
          {TabsHeader?.map((tab) => (
            <li
              className={`tabs__item ${
                type?.name === tab?.name
                  ? 'bg-brand_blue text-neutral_white font-campton_m py-2 px-3 rounded-full'
                  : 'font-campton_r text-neutral_body'
              } cursor-pointer `}
              data-testid="test-tab"
              onClick={() => setSelectedTab(tab)}
              key={tab?.value}
            >
              <div className={` min-w-max whitespace-no-wrap lg:text-14 text-12 capitalize  `}>{tab?.name}</div>
            </li>
          ))}
        </ul>
        <p className='text-left mb-4 font-campton_r text-neutral_body'>
          Enter your <span className="lowercase">{type.name}</span> and password
        </p>
        <div className="w-full">
          <div className="mb-5">
            <Input
              label={type.name}
              type={type.value === 'emailAddress' ? 'email' : 'text'}
              id={type.value}
              name="email_or_phone_or_gNumber"
              readOnly={loading}
              value={formData?.email_or_phone_or_gNumber}
              onChange={handleChange}
              showError={false}
            />
          </div>
          <div className="mb-9">
            <Input
              label="Password"
              type="password"
              id="password"
              name="password"
              readOnly={loading}
              value={formData?.password}
              onChange={handleChange}
              showError={false}
            />
          </div>
        </div>
        <div className="w-full">
          <p className="font-campton_m text-neutral_body mb-6 text-center md:text-16 text-14">
            Forgot password?{' '}
            <Link className="text-brand_secondary cursor-pointer" to="/forgot-password">
              Reset it here
            </Link>
          </p>
          <Button name="Login" theme="blue" className="w-full" disabled={!formData?.password} loading={loading} />
          <Button
            className="py-2 w-full border border-neutral_stroke_2 flex justify-center items-center rounded-full mt-6 h-12 cursor-pointer hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary text-neutral_body"
            onClick={() => navigate('/create-account')}
          >
            <p className=" font-campton_r">
              Don’t have an account? <span className="text-brand_secondary font-campton_m">Create account</span>{' '}
            </p>
          </Button>
        </div>
      </div>
    </FormWrapper>
  );
};

export default LoginForm;
LoginForm.propTypes = {
  type: PropTypes.object,
  setSelectedTab: PropTypes.func,
  TabsHeader: PropTypes.array,
};
