import React, { useRef, useState } from "react";
import PropTypes from "prop-types"
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { SlideUp } from "components/Animations";
import Button from "components/Buttons/Button";
import useUpload from "hooks/useUpload";
import useMyRecords from "hooks/fetch/useMyRecords";
import dayjs from "dayjs";
import { Input } from "components/Inputs";
import Upload from "components/Upload";

export default function AddTestResult({ callback }) {
    const fileRef = useRef()
    const [file, setFile] = useState(null)
    const [testName, setTestName] = useState('')
    const { upload, uploading } = useUpload()
    const { add_lab_test, loading } = useMyRecords()

    const uploadTestFile = () => {
        const timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
        const fileName = `${timestamp}${file?.name}`
        const payload = { testResult: fileName }
        upload(file, payload, timestamp, false)
            .then(res => {
                if (res?.response?.status === 200) {
                    const payload = { testResult: res.imageUrl, testType: testName }
                    add_lab_test(payload)
                        .then(res => {
                            if (res?.code === 201) callback()
                        })
                }
            })
    }

    return (
        <SlideUp>
            <div className="my-[24px]">
                <Input
                    label="Test Name"
                    type="text"
                    id="testName"
                    name="testName"
                    readOnly={loading}
                    value={testName}
                    onChange={(e) => setTestName(e.target.value)}
                />
            </div>
            {!file && <Upload id="upload-test" setFile={setFile} loading={uploading} fileRef={fileRef} />}
            {file ?
                <div className="flex justify-between items-center px-[16px] py-[8px] border border-neutral_stroke_1 rounded-[8px]">
                    <div className="flex">
                        <File className="mr-[8px]" />
                        <div>
                            <p className="text-14 font-campton_r text-neutral_black">{file?.name}</p>
                            {file && <p className="text-14 font-campton_r text-neutral_black">{(file?.size / 1024).toFixed(2)} KB</p>}
                        </div>
                    </div>
                    <Close data-testid='delete-file' className="cursor-pointer" onClick={() => setFile()} />
                </div> : ''
            }
            <div className="flex justify-end mt-[60px]">
                <Button
                    className="w-[130px] rounded-full h-[40px] font-campton_m"
                    theme="blue"
                    disabled={!file || !testName}
                    name="Upload result"
                    onClick={uploadTestFile}
                    loading={uploading || loading}
                />
            </div>
        </SlideUp>
    )
}

AddTestResult.propTypes = {
    callback: PropTypes.func
}