import React, { useState } from "react"
import PropTypes from "prop-types"
import ViewProfileImage from "components/Modals/ViewProfileImage"

export default function Avatar({ url, alt, initials, width, height, className, textVariant, canViewProfile }) {
    const profileUrl = url?.includes('https:') ? url : `https://photow-profile-images.s3.us-west-2.amazonaws.com/${url}`
    const [showProfile, setShowProfile] = useState(false)

    return (
        <div className={`${className} bg-brand_primary rounded-full flex justify-center items-center text-white`}>
            {url ?
                <img
                    src={profileUrl}
                    width={width}
                    height={height}
                    alt={alt}
                    data-testid="avatar-image"
                    className={`rounded-full bg-green_light object-cover object-top w-full h-full`}
                    onClick={() => profileUrl && setShowProfile(true)}
                /> :
                <p className={`${textVariant} text-16 text-neutral_white uppercase font-campton_b leading-none`}>{initials}</p>
            }
            {canViewProfile && showProfile && <ViewProfileImage imageUrl={profileUrl} onClick={() => setShowProfile(false)} />}
        </div>
    )
}

Avatar.propTypes = {
    url: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    initials: PropTypes.string,
    textVariant: PropTypes.string,
    canViewProfile: PropTypes.bool
}