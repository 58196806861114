import React, { useEffect, useState } from 'react';
import { ReactComponent as WhiteLogo } from '../assets/svg/logo-white.svg';
import careMedicine from '../assets/svg/careMedicine.svg';
import searching from '../assets/svg/searching.svg';
import onlineConsultationCare from '../assets/svg/onlineConsultationCare.svg';

export default function Slider() {
  const [current, setCurrent] = useState(0);
  let messages = [
    {
      title: 'Connect with a licensed professional virtually',
      caption: 'Get the support you need, when you need it support you need, when you need it.',
      image: onlineConsultationCare,
      id: 0,
    },
    {
      title: 'Connect with a licensed professional virtually',
      caption: `Get the support you need, when you need it support you need, when you need it.`,
      image: careMedicine,
      id: 1,
    },
    {
      title: 'Connect with a licensed professional virtually',
      caption: `Get the support you need, when you need it support you need, when you need it.`,
      image: searching,
      id: 2,
    },
  ];

  useEffect(() => {
    let position = 0;
    const element = document.getElementById('slider');

    let timer = setInterval(() => {
      if (current + 1 < messages.length) {
        position = (current + 1) * 100;
        setCurrent((prevState) => prevState + 1);
      }
      if (current + 1 === messages.length) {
        position = 0;
        setCurrent(0);
      }
      element.style.transform = `translateX(-${position}%)`;
    }, 5000);

    return () => clearInterval(timer);
  }, [current]);

  return (
    <section className="hidden sticky rounded-2xl py-10 shrink-0 overflow-hidden top-0 lg:flex lg:flex-col lg:flex-[.4] h-full">
      <div className="h-full flex justify-between flex-col">
        <div className="px-40">
          <WhiteLogo />
        </div>
        <div className="flex  mt-[2rem] transition ease-in-out duration-1000 text-neutral_white" id="slider">
          {messages.map((message) => (
            <div className="w-full shrink-0 md:px-10 2xl:px-60 xl:px-40" key={message.id}>
              <div className="flex w-full justify-center items-center">
                <img src={message.image} className="" alt="services" />
              </div>
              <div className="text-center mt-12">
                <h2 className="text-32 text-white font-campton_sb leading-[38px] mb-6">{message.title}</h2>
                <p className="text-18 font-campton_r font-normal leading-24">{message.caption}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex space-x-1.5 justify-center w-24 items-center mx-auto h-3 mt-8 2xl:mt-16">
          {messages.map((item) => (
            <div className={`${
                  item?.id === current ? "bg-[#36A1C5] w-[70%]" : "bg-[#ECEEEE] w-[15%]"
                } h-3 first-of-type:rounded-lg rounded-full` } 
                key={item?.id}
                >

            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
