import Accordion from "components/Accordion";
import React, { useContext } from "react";
import { ReactComponent as Note } from 'assets/icons/note.svg';
import { VisitContext } from "context";

export default function ClinicalAssessment() {
    const { visit, progressNote } = useContext(VisitContext)
    const primaryDiagnosis = progressNote?.primaryDiagnosis?.primaryDiagnosis ?? visit?.primaryDiagnosis?.primaryDiagnosis
    const secondaryDiagnosis = progressNote?.secondaryDiagnosis?.secondaryDiagnosis ?? visit?.secondaryDiagnosis?.secondaryDiagnosis 
    
    return(
        <Accordion
            display={false}
            title={<p className="text-14 text-neutral_body font-campton_r">Clinical Assessment</p>}
            className="mb-0 border-b border-b-neutral_stroke_2"
        >
        <div>
            {primaryDiagnosis ?
                        <div className="mb-[10px] max-h-[50%]">
                            <p className="text-14 text-neutral_black font-campton_m"><Note className="inline" fill="#143566" /> Primary Diagnosis</p>
                            <p className="text-14 text-neutral_body font-campton_r truncate mt-2.5">{primaryDiagnosis}</p>
                        </div> : <p className="text-16 text-neutral_black font-campton_m">No Primary Diagnosis Available</p>
                    }
            {secondaryDiagnosis ?
                <div className="h-[50%]">
                    <p className="text-14 text-neutral_black font-campton_m"><Note className="inline" fill="#143566"  /> Secondary Diagnosis</p>
                    <p className="text-14 text-neutral_body font-campton_r truncate mt-2.5">{secondaryDiagnosis}</p>
                </div> : <p className="text-16 text-neutral_black font-campton_m">No Secondary Diagnosis Available</p>
            }
        </div>
        </Accordion>
    )
}