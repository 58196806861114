/* eslint-disable max-len */
import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Illustration } from 'assets/svg/family-history-illustration.svg';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddFamilyHistory';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { ReactComponent as FamilyHistorySolid } from 'assets/icons/medicalRecords/family-history-solid.svg';
import Header from '../Header';
import { useRecordState } from 'store/modules/myRecords';

const FamilyHistory = ({ selected, onClick }) => {
  //Redux Store
  const { familyHistory, loading } = useRecordState();
  const [selectedDetails, setSelectedDetails] = useState();

  //Local State
  const [type, setType] = useState();
  const [showModal, setShowModal] = useState();
  //Hooks
  const { delete_medical_record } = useMedicalRecord();

  //Handler function
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={() => handleShowModal('Add') } condition={familyHistory?.length > 0} />
        {loading && (
          <div className="mt-10">
            <Spinner />
          </div>
        )}

        {!loading &&
          (familyHistory?.length > 0 ? (
            <>
              <div className="mt-6">
                {familyHistory.map((item) => (
                  <div
                    className="flex justify-between items-center border-b last-of-type:border-b-0 border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0"
                    key={item?._id}
                  >
                    <div className="flex space-x-4">
                      <FamilyHistorySolid className="mt-1" />
                      <div>
                        <p className="capitalize font-campton_m text-neutral_black text-14">{item.hereditaryDisease}</p>
                        <p className="capitalize text-12 text-neutral_body">
                          Relationship:{' '}
                          <span className="font-campton_r text-neutral_black ">{item.patientRelationship}</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <EditIcon
                        data-testid="test-edit"
                        onClick={() => handleShowModal('Edit', () => setSelectedDetails(item))}
                        className="cursor-pointer"
                      />
                      <DeleteIcon
                        data-testid="test-delete"
                        onClick={() => delete_medical_record('familyHistory', item._id)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyState
              illustration={<Illustration />}
              title="No Previous Family History"
              description="All added family history will be displayed here."
              btnText="Add Family History"
              onClick={() => handleShowModal('Add')}
            />
          ))}
        {showModal && (
          <DefaultModal
            closeModal={() => setShowModal(false)}
            styles="min-w-[90%] md:min-w-[730px]"
            title={`${type} Family History`}
            subTitle="Select from the dropdown bellow to add a record of family illnesses."
            containerVariant="bg-neutral_white"
          >
            <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
          </DefaultModal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default FamilyHistory;
FamilyHistory.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
