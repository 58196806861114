import { useState } from 'react';
import {
  forgotPassword,
  createAccount,
  verifyAccount,
  resetPassword,
  validateResetPasswordCode,
  login,
  resendOtp,
  updateUserInfo,
  createPassword,
} from 'store/modules/auth/actions';
import useInputValidate from '../useInputValidate';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import { extractPIN } from 'utils/extractPin';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';

export default function useAuth() {
  const { validate } = useInputValidate();
  const { makeRequest, makeAnonymousRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const { tryCatch } = useTryCatch();

  const forgot_password = async ({ payload }) => {
    return tryCatch(async () => {
      // check if email is valid
      if (!validate({ name: 'emailAddress', value: payload.emailAddress })) return;
      setLoading(true);
      return makeRequest({ action: forgotPassword(payload) })
        .then(res => res)
        .finally(() => setLoading(false));
    });
  };

  const resend_otp = async (formData, callback) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate(formData)) return;
      setLoading(true);
      makeRequest({ action: resendOtp(formData), callback }).finally(() => setLoading(false));
    });
  };

  const create_account = async (formData, callback) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate(formData)) return;
      setLoading(true);
      makeRequest({ action: createAccount(formData), to: `/verify-account`, callback }).finally(() =>
        setLoading(false),
      );
    });
  };

  const verify_account = async (pin, callback) => {
    return tryCatch(async () => {
      const verificationToken = extractPIN(pin);
      setVerifying(true);
      return makeRequest({
        action: verifyAccount({ verificationToken }),
        callback,
      })
        .then((res) => res)
        .finally(() => setVerifying(false));
    });
  };

  const validate_reset_password_code = async (payload) => {
    return tryCatch(async () => {
      const code = extractPIN(payload.code);
      setVerifying(true);
      return makeRequest({ action: validateResetPasswordCode({ emailAddress: payload.emailAddress, code }) })
        .then((res) => res)
        .finally(() => setVerifying(false));
    });
  };

  const reset_password = async (formData) => {
    tryCatch(() => {
      const enhancedFormData = formData;
      removeSpecificKeys(enhancedFormData, ['confirm_password']);
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({
        action: resetPassword({ ...enhancedFormData }),
        to: '/',
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const create_password = async (formData) => {
    tryCatch(() => {
      const enhancedFormData = formData;
      removeSpecificKeys(enhancedFormData, ['confirm_password']);
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({
        action: createPassword({ ...enhancedFormData }),
        to: '/',
        successMessage: 'Password created successfully'
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const change_password = ({ payload }) => {
    const url = '/patient-auth/change-password'
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, method: 'patch', payload })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const update_userInfo = async (formData, callback) => {
    tryCatch(() => {
      removeSpecificKeys(formData, ['emailAddress', 'phoneNumber']);
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({
        action: updateUserInfo(formData),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const login_user = (formData, callback) => {
    return tryCatch(async () => {
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({ action: login(formData), to: `/dashboard`, callback }).then((res) => {
        if (res.code === 400 && res.message === "Account is not verified, please verify your email to proceed") {
          const enhancedFormData = formData;
          removeSpecificKeys(enhancedFormData, ['password']);
          makeRequest({ action: resendOtp(formData), to: `/verify-account` })
        }
      }).finally(() => setLoading(false));
    });
  };

  const disable_account = ({ payload }) => {
    const url = '/patient-profile/disable'
    return tryCatch(async () => {
      setLoading(true)
      return makeAnonymousRequest({ url, method: 'patch', payload })
        .then(res => res)
        .finally(() => setLoading(false))
    })
  }

  const delete_account = ({ payload }) => {
    const url = '/patient-profile/delete'
    return tryCatch(async () => {
      setLoading(true)
      return makeAnonymousRequest({ url, method: 'delete', payload })
        .then(res => res)
        .finally(() => setLoading(false))
    })
  }

  return {
    forgot_password,
    create_account,
    verify_account,
    loading,
    verifying,
    update_userInfo,
    create_password,
    login_user,
    validate_reset_password_code,
    reset_password,
    resend_otp,
    disable_account,
    delete_account,
    change_password
  };
}
