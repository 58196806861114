import React, { Suspense } from 'react';
import Spinner from 'components/Spinner';
import { Outlet } from 'react-router-dom';
import Drawer from 'components/Drawer';
import RequireAuth from 'HOC/RequireAuth';

export default function AppLayout() {
  return (
    <RequireAuth>
      <div className="flex relative py-0 md:py-[24px] px-0 md:px-[32px] h-screen bg-[#F3FAFC]">
        <Drawer />
        <div className="w-full bg-neutral_white rounded-[16px] max-h-screen overflow-hidden">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </RequireAuth>
  );
}
