import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Contact } from 'assets/svg/lifestyle-illustration.svg';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../Header';
import { ReactComponent as LifestyleSolid } from 'assets/icons/medicalRecords/lifestyle-solid.svg';
import { ReactComponent as ArrowRightSolid } from 'assets/icons/arrow-right-solid.svg';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/Lifestyle/Index';
import ProgressBar from 'components/Progressbar';
import { useRecordState } from 'store/modules/myRecords';
import useHomeCompLogic from 'pages/Dashboard/Home/useHomeCompLogic';

const LifestyleHabit = ({ callback, selected, onClick }) => {
  //Redux Store
  const { lifestyleHabits , loading} = useRecordState();
  //Local state
  const [showModal, setShowModal] = useState();
  const [type, setType] = useState();
  const [selectedDetails, setSelectedDetails] = useState();

const {totalLifestyleHabitAnswers, NewLifestyleData} = useHomeCompLogic()
  //Handler function
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };

  const totalAnsweredCategory = (obj) => {
    let keysWithValuesCount = 0;

    // Iterate through each key in the object
    for (const key in obj) {
        // Check if the value is an object (nested object)
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            // If it's an object, recursively check its keys
            keysWithValuesCount += totalAnsweredCategory(obj[key]);
        } else if (obj[key] !== "" && obj[key] !== undefined) {
                keysWithValuesCount++;
                break; // Exit the loop if at least one non-empty value is found
        }
    }

    return keysWithValuesCount;
} 

  return (
    <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, y: '20px' }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
        <Header selected={selected} onClick={onClick} callback={callback} />

      <div>
        {loading && (
          <div className="mt-10">
            <Spinner />
          </div>
        )}
        {!loading &&
          (totalLifestyleHabitAnswers !== 0 ? (
            <div data-testid='lifestyle' className="flex justify-between cursor-pointer" onClick={() => handleShowModal('Edit', () => setSelectedDetails(lifestyleHabits))}>
              <div className="flex space-x-4">
                <LifestyleSolid />
                <div className="">
                  <p className="font-campton_m text-neutral_black">
                    You have responded to a total of {totalLifestyleHabitAnswers} questions in {totalAnsweredCategory(NewLifestyleData)} categories
                  </p>
                  <p className="font-campton_r text-neutral_body text-14 w-[80%] mt-1">
                    Get access to all round health tips when you help us get to know more about your lifestyle habits
                  </p>
                  <ProgressBar progress={totalLifestyleHabitAnswers} total={32}/>
                </div>
              </div>
              <ArrowRightSolid />
            </div>
          ) : (
            <EmptyState
              illustration={<Contact />}
              title="No Lifestyle Habits"
              description="All lifestyle habits will be displayed here when added."
              onClick={() => handleShowModal('Add')}
              btnText="Add Lifestyle Habits"
            />
          ))}
      </div>
      {showModal && (
        <DefaultModal
          closeModal={() => setShowModal(false)}
          styles="min-w-[90%] md:min-w-[730px]"
          title={`${type} Lifestyle Habits`}
          containerVariant="bg-neutral_white"
        >
          <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
        </DefaultModal>
      )}
</motion.div>
</AnimatePresence>
  );
};

export default LifestyleHabit;
LifestyleHabit.propTypes = {
  callback: PropTypes.func,
  selected: PropTypes.string,
  onClick: PropTypes.func,
};