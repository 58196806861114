import { createCustomAsyncThunk } from 'utils/createThunk'
import { messaging } from 'services/endpoints'

const createConversation = createCustomAsyncThunk('messaging/createConversation', 'post', messaging.create_conversation)
const getConversations = createCustomAsyncThunk('messaging/getConversations', 'get', messaging.get_conversations)
const getConversation = createCustomAsyncThunk('messaging/getConversation', 'get', messaging.get_conversation)
const deleteConversation = createCustomAsyncThunk('messaging/deleteConversation', 'delete', messaging.get_conversation)
const createMessage = createCustomAsyncThunk('messaging/createMessage', 'post', messaging.create_message)

export {
    createConversation,
    deleteConversation,
    getConversations,
    getConversation,
    createMessage
}