import React, { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from "framer-motion"
import Pagination from "components/Pagination"
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg"
import CustomSkeleton from "components/Skeleton"

export default function Table({
    headers, data, title, children, loading, emptyState, tableContainer, filter,
    totalCount, currentPage, perPage, changePage, selectLimit, hasHeader = true, hasPagination = true, hasTable = true
}) {

    let ref = useRef()
    const [display, setDisplay] = useState(false)
    const [limit, setLimit] = useState('20 Entries')
    const changeCurrentPage = (e) => {
        if (changePage) changePage(e.selected + 1);
    }

    const changeLimit = (e) => {
        setLimit(e.name)
        selectLimit(e.value)
    }

    const listener = (e) => {
        if (!ref?.current?.contains(e.target)) setDisplay(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', listener);
        return () => document.removeEventListener('mousedown', listener);
    }, [])

    const limits = [
        {
            id: 0,
            name: '10 Entries',
            value: 10
        },
        {
            id: 1,
            name: '20 Entries',
            value: 20
        },
        {
            id: 2,
            name: '30 Entries',
            value: 30
        }
    ]

    return (
        <div className='h-full'>
            <div className="sm:flex justify-between mb-[8px]">
                <p className="text-16 text-neutral_black font-campton_m">{title}</p>
                {filter}
            </div>
            <div className="w-full overflow-x-auto">
                {
                    hasTable ?
                        <table className={`w-full ${tableContainer || 'min-w-[700px]'}`}>
                            {hasHeader && <thead>
                                <tr className="border-b border-b-neutral_stroke_1">
                                    {headers?.map((header) =>
                                        <th
                                            key={header}
                                            className="text-14 text-left text-neutral_body font-campton_r capitalize py-[18px] whitespace-nowrap px-4"
                                        >
                                            {header}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            }
                            <tbody>
                                {data?.length && !loading ? children : <></>}
                            </tbody>
                        </table> :
                        children
                }
                {!loading && !data?.length && <div className="py-[20px]">{emptyState}</div>}
                {hasTable && loading &&
                    <div className="py-[20px]">
                        <CustomSkeleton count={4} className="mb-[8px] py-[4px]" />
                    </div>
                }
            </div>

            {data?.length && hasPagination && !loading ?
                <div className="mt-10 sm:flex justify-between items-center flex-wrap ml-4">
                    <div className="flex justify-between sm:justify-start mb-[16px] sm:mb-0 items-center text-14 text-[#3A434B] font-campton_r">
                        <p className='mr-[10px]'>Showing:</p>
                        <div ref={ref} className="w-[128px] relative">
                            <div
                                data-testid="limit"
                                onClick={() => setDisplay(!display)}
                                className={`flex items-center border rounded-[100px] px-[16px] py-[9.5px] cursor-pointer ${display ? 'border-brand_primary' : 'border-neutral_stroke'}`}
                            >
                                <p data-testid={`test-${limit}`} className="mr-[10.5px]">{limit}</p>
                                {display ? <ArrowUp fill="#205F74" /> : <ArrowDown fill="#205F74" />}
                            </div>
                            <AnimatePresence>
                                {display &&
                                    <motion.div
                                        animate={{ opacity: 1, scale: 1 }}
                                        initial={{ opacity: 0, scale: 0 }}
                                        exit={{ opacity: 0, scale: 0 }}
                                        className="absolute bottom-[46px] py-[8px] px-[8px] max-h-[216px] overflow-auto bg-neutral_white drop-shadow-md lg:drop-shadow-md w-[128px] rounded-[16px]"
                                    >
                                        {limits?.map((option) =>
                                            <div
                                                key={option?.id || option.value}
                                                data-testid={option?.name}
                                                onClick={() => {
                                                    setDisplay(false)
                                                    changeLimit({ name: option?.name, value: option?.value })
                                                }}
                                                className={`py-[8px] px-[10px] flex items-center justify-between cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3] rounded-[100px]`}
                                            >
                                                <p className="text-14 font-campton_r capitalize">{option?.name}</p>
                                            </div>
                                        )}
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </div>
                    </div>
                    <Pagination
                        totalCount={Number(totalCount)}
                        perPage={Number(perPage)}
                        currentPage={Number(currentPage) || 0}
                        changePage={changeCurrentPage}
                    />
                </div> : ''
            }
        </div>
    )
}

Table.propTypes = {
    headers: PropTypes.array,
    data: PropTypes.array,
    title: PropTypes.string,
    children: PropTypes.element,
    buttonName: PropTypes.string,
    buttonFnc: PropTypes.func,
    handleSearch: PropTypes.func,
    loading: PropTypes.bool,
    totalCount: PropTypes.any,
    perPage: PropTypes.any,
    emptyState: PropTypes.element,
    currentPage: PropTypes.any,
    changePage: PropTypes.func,
    selectLimit: PropTypes.func,
    hasFilter: PropTypes.bool,
    hasTable: PropTypes.bool,
    hasPagination: PropTypes.bool,
    hasHeader: PropTypes.bool,
    filterFnc: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    tableContainer: PropTypes.string,
    selectedFieldsToExport: PropTypes.array,
    filter: PropTypes.element
}
