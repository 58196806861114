import React from "react"
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import { motion } from "framer-motion";
import Spinner from "components/Spinner";

export default function Suggestion({ suggestions, onSelect, loading }) {
    return (
        <div className="border border-neutral_stroke rounded-[8px] px-[16px] py-[12px] max-h-[114px] overflow-y-auto">
            {
                loading ? <Spinner/> :
                suggestions?.map((suggestion, index) =>
                    <motion.div
                        key={suggestion?._id}
                        initial={{ opacity: 0, y: "10px" }}
                        animate={{ opacity: 1, y: 0, transition: { duration: .5, delay: index === 0 ? 0 : (.1 * index) } }}
                        exit={{ opacity: 0 }}
                        data-testid={suggestion?._id}
                        onClick={() => onSelect(suggestion)}
                        className={`
                        flex justify-between items-center ${(index + index) !== suggestions?.length ? 'mb-[8px]' : ''} cursor-pointer
                        hide_tap hover:bg-[#F2F3F3] rounded-[4px] px-[12px] py-[4px]
                        `}
                    >
                        <div className="flex items-center">
                            <Avatar
                                url={suggestion?.profile_picture}
                                width="32px"
                                height="32px"
                                alt={suggestion?.firstName}
                                initials={`${suggestion?.firstName?.split('')[0] || ''}${suggestion?.lastName?.split('')[0] || ''}`}
                                className="w-[32px] h-[32px] mr-[8px] shrink-0"
                            />
                            <p className="text-14 text-neutral_black font-campton_r">{suggestion?.firstName} {suggestion?.lastName}</p>
                        </div>
                        <p className="text-14 text-neutral_body font-campton_r">{suggestion?.emailAddress}</p>
                    </motion.div>
                )
            }
        </div>
    )
}

Suggestion.propTypes = {
    suggestions: PropTypes.array,
    onSelect: PropTypes.func,
    loading: PropTypes.bool
}