import { createSlice } from '@reduxjs/toolkit';
import {
  createAccount,
  forgotPassword,
  verifyAccount,
  resetPassword,
  login,
  resendOtp,
  getCountries,
  refreshToken,
  updateUserInfo,
} from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  loggedUser: {},
  signedUpUser: {},
  recoverUser: {},
  userInfo: {},
  isLoggedIn: false,
  isSignedUp: false,
  loading: false,
  error: null,
  countryList: [],
  isLoadingCountry: false,
  refreshToken: '',
  authTokenExpiry: '',
  authToken: '',
};

const setLoggedUser = (state, action) => {
  state.loggedUser = action.payload.data;
  state.isLoggedIn = true;
  state.authToken = action?.payload?.data?.authToken;
  state.refreshToken = action?.payload?.data?.refreshToken;
  state.authTokenExpiry = action?.payload?.data?.authTokenExpiry;
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        setLoggedUser(state, action)
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.recoverUser = action.payload.data;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.recoverUser = action.payload.data;
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        state.loggedUser = action.payload.data;
        state.isLoggedIn = true;
        state.isSignedUp = true;
        state.authToken = action?.payload?.data?.authToken;
        state.authTokenExpiry = action?.payload?.data?.authTokenExpiry;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loggedUser = action.payload.data;
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.recoverUser = action.payload.data;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countryList = action.payload.data;
        state.isLoadingCountry = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        setLoggedUser(state, action)
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.loggedUser = { ...state.loggedUser, ...action.payload.data }
        }
      })
  },
});
// Selectors
const selectAuth = (state) => state.auth;
// Action creators are generated for each case reducer function
export const { setUserInfo } = auth.actions;
//App Redux State
export const useAuthState = () => useAppSelector(selectAuth);

export default auth.reducer;
