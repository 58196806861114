import React from 'react'
import PropTypes from 'prop-types';
import { useAuthState } from 'store/modules/auth';

const Pin = ({ onChange, onPress, onPaste, inputRef, pin, type }) => {
  const { loading } = useAuthState()

  return (
    <>
      {pin.map((currentInput, index) =>
        <div key={currentInput.key}>
          <input
            data-testid={`otp_input-${index}`}
            id={`otp_input-${index}`}
            ref={(el) => (inputRef.current[currentInput.key] = el)}
            onChange={(e) => onChange(e, currentInput)}
            onKeyDown={(e) => onPress(e, currentInput)}
            onPaste={(e) => onPaste(e)}
            inputMode='numeric'
            type={type || "text"}
            className="w-[42px] h-[42px] md:w-[50px] md:h-[50px] md:text-16 text-14 text-center md:p-4 p-3 outline-0
            text-neutral_black  border font-campton_r font-normal hide_tap border-neutral_stroke rounded-[8px]
            focus:border-brand_primary focus:border bg-neutral_white"
            placeholder=""
            maxLength={1}
            autoComplete="off"
            readOnly={loading}
            value={currentInput.value}
            autoFocus={currentInput.isFocus}
          />
        </div>
      )}
    </>
  )
}

export default Pin
Pin.propTypes = {
  onChange: PropTypes.func,
  onPress: PropTypes.func,
  onPaste: PropTypes.func,
  type: PropTypes.string,
  inputRef: PropTypes.object,
  pin: PropTypes.array,
};