import React, { useState } from 'react';
import { ReactComponent as Note } from 'assets/icons/note.svg';
import { ReactComponent as Hospital } from 'assets/icons/hospital.svg';
import { ReactComponent as Test } from 'assets/icons/test.svg';
import { ReactComponent as Vitals } from 'assets/icons/vitals.svg';
import { ReactComponent as Treatment } from 'assets/icons/flower.svg';
import { ReactComponent as Edit } from 'assets/icons/pencil.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Disabled } from 'assets/icons/disabled.svg';
import PatientHealthRecord from '../Tabs/PatientHealthRecords/PatientHealthRecord';
import VitalSigns from '../Tabs/Vitals/Vitals';
import Visits from '../Tabs/Visits';
import LabTests from '../Tabs/LabTests';
import TreatmentPlan from '../Tabs/TreatmentPlan';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function useTabOptions() {
    const [editProfile, setEditProfile] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [termination, setTermination] = useState({ reason: '', status: false })
    const [searchParams] = useSearchParams();
    const record = searchParams.get('record');
    const navigate = useNavigate();

    const myRecordsTabs = [
        {
            id: 1,
            title: 'personal health records',
            caption: 'Blood type, vitals, emergency contact, family hospital, medical history, surgeries, allergies.',
            icon: <Note className='shrink-0' fill='#F59E0B'/>,
            component: <PatientHealthRecord />,
            method: () => navigate('?record=personal health records'),
            arrow: true
        },
        {
            id: 2,
            title: 'vital signs',
            caption: 'Blood pressure, temperature, heart rate, respiratory rate.',
            icon: <Vitals fill='#DF3939' className='shrink-0' />,
            component: <VitalSigns />,
            method: () => navigate('?record=vital signs'),
            arrow: true
        },
        {
            id: 3,
            title: 'visit history',
            caption: 'View ongoing & past visit records.',
            icon: <Hospital fill='#36A1C5' className='shrink-0' />,
            component: <Visits />,
            method: () => navigate('?record=visit history'),
            arrow: true
        },
        {
            id: 4,
            title: 'lab tests',
            caption: 'View pending & past laboratory tests.',
            icon: <Test fill='#DF3939' className='shrink-0' />,
            component: <LabTests />,
            method: () => navigate('?record=lab tests'),
            arrow: true
        },
        {
            id: 5,
            title: 'treatment plan',
            caption: 'Medications, therapies, referral, procedures, lifestyle recommendation.',
            icon: <Treatment fill='#23CE6B' className='shrink-0' />,
            component: <TreatmentPlan/>,
            method: () => navigate('?record=treatment plan'),
            arrow: true
        },
    ]

    const settingsTabs = [
        {
            id: 1,
            title: 'Edit Profile',
            caption: 'Name, email address, gender, date of birth.',
            icon: <Edit fill='#FF7A21' className='shrink-0' />,
            method: () => setEditProfile(true),
            arrow: true
        },
        // {
        //     id: 2,
        //     title: 'Notifications',
        //     caption: 'Allow for push notification.',
        //     icon: <Bell fill='#F59E0B' className='shrink-0 w-[20px] h-[20px]' />,
        //     component: <div></div>,
        //     showSwitch: true,
        //     status: notification,
        //     method: () => setNotification(!notification)
        // },
        {
            id: 3,
            title: 'Change Password',
            caption: 'Change your old password to a new one.',
            icon: <Lock fill='#23CE6B' className='shrink-0 w-[20px] h-[20px]' />,
            method: () => setChangePassword(true),
            arrow: true
        },
    ]

    const terminationTabs = [
        {
            id: 1,
            title: 'Temporarily disable my account',
            icon: <Disabled fill='#748181' className='shrink-0 w-[20px] h-[20px]' />,
            method: () => setTermination({ reason: 'disable', status: true }),
            arrow: false
        },
        {
            id: 2,
            title: 'Delete my account',
            icon: <Delete fill='#DF3939' className='shrink-0 w-[20px] h-[20px]' />,
            method: () => setTermination({ reason: 'delete', status: true }),
            arrow: false
        }
    ]

    return {
        myRecordsTabs,
        terminationTabs,
        settingsTabs,
        termination,
        record,
        setTermination,
        editProfile,
        setEditProfile,
        changePassword,
        setChangePassword
    }
}