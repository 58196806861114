import React from 'react';
import { ReactComponent as Calendar } from 'assets/svg/appointment/calendar.svg';
import DefaultDatepicker from 'components/Datepicker/Index';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import { useAppointmentState } from 'store/modules/appointments';

const RescheduleCard = ({ formData, setFormData, loading }) => {
  const { availableTime } = useAppointmentState();

  return (
    <>
      <div className="relative">
        <Calendar className="text-18 absolute z-10 left-0 cursor-pointer bottom-5" />
        <DefaultDatepicker
          label="Date"
          startDate={formData?.proposedDate}
          minDate={new Date()}
          onSelect={(e) => setFormData(prevFormData => ({ ...prevFormData, proposedDate: e }))}
          name="date"
          styles="border-none"
          showLabel={false}
          value={formData?.proposedDate}
          showCalender={false}
          showTimeIcon={false}
          iconStyles="text-brand_primary text-14 font-campton_m"
          icon="(change)"
        />
      </div>
      {loading && <Spinner />}
      {!loading && availableTime?.length > 0 && (
        <div className="grid grid-cols-4 gap-2">
          {availableTime.map((item) => (
            <div
              className={` ${
                formData?.proposedStartTime === item
                  ? 'bg-[#F2F6FC] border-brand_blue text-brand_blue'
                  : 'text-neutral_body border-neutral_stroke_2'
              } px-2 py-[7px] border  rounded-full items-center font-campton_r text-12 cursor-pointer flex justify-center`}
              data-testid={`test-${item}`}
              onClick={() => {
                setFormData(prevFormData => ({ ...prevFormData, proposedStartTime: item}))
              }}
              key={item}
            >
              {item}
            </div>
          ))}
        </div>
      )}
      {!loading && !availableTime?.length && (
        <p className="font-campton_m text-neutral_black text-center">
          No Available Time, Kindly select another provider
        </p>
      )}
    </>
  );
};

export default RescheduleCard;
RescheduleCard.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  loading: PropTypes.bool,
};
