import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Avatar from 'components/Avatar/Avatar';
import { userInitials } from 'utils/ImagePlaceholder';

const ViewProcedure = ({ data }) => {
  return (
    <div className='modal-right-body'>
      <div className="border border-neutral_stroke_2 rounded-lg py-4 px-6 text-14 font-campton_r mb-6">
        <p className="font-campton_r text-12 text-neutral_body mb-4">Details</p>
        <div className="pb-4 border-b border-neutral_stroke_2">
          <p className="text-neutral_black">Procedure Name</p>
          <p className="text-neutral_body">{data?.procedureName}</p>
        </div>
        <div className="flex justify-between py-4 border-b border-neutral_stroke_2">
          <div>
            <p className="text-neutral_black">Procedure Date</p>
            <p className="text-neutral_body">{dayjs(data?.procedureDate).format('MMMM DD, YYYY')}</p>
          </div>
          <div>
            <p className="text-neutral_black">Procedure Time</p>
            <p className="text-neutral_body">{data?.procedureTime}</p>
          </div>
        </div>
        <div className="py-4 border-b border-neutral_stroke_2">
          <p className="text-neutral_black">Preprocedure Assessment</p>
          <p className="text-neutral_body capitalize-first">{data?.preProcedureAssessment}</p>
        </div>
        <div className="pt-4 border-neutral_stroke_2">
          <p className="text-neutral_black">Preprocedure Instructions</p>
          <p className="text-neutral_body capitalize-first">{data?.preProcedureInstructions}</p>
        </div>
      </div>
      <div className="border border-neutral_stroke_2 p-4 rounded-2xl">
        <p className="text-neutral_body font-campton_r text-12 mb-4">Provider Information</p>
        <div className="flex justify-between items-start mt-4">
          <div className="flex">
            <Avatar
              url={data?.providerInfo?.profilePic}
              width="32px"
              height="32px"
              textVariant="!text-12"
              alt={data?.providerInfo?.firstName || data?.institutionInfo?.businessName}
              initials={userInitials(
                data?.providerInfo?.firstName || data?.institutionInfo?.businessName?.split(' ')[0],
                data?.providerInfo?.lastName || data?.institutionInfo?.businessName?.split(' ')[1],
              )}
              className="bg-brand_secondary mr-1.5 w-8 h-8"
            />
            <div className="font-campton_r">
              <p className="text-neutral_black text-14 capitalize">
                {data?.providerInfo?.firstName
                  ? `${data?.providerInfo?.firstName} ${data?.providerInfo?.lastName}`
                  : `${data?.institutionInfo.businessName}`}
              </p>
              <p className="text-12 text-neutral_body">{data?.providerInfo?.emailAddress ?? data.institutionInfo.emailAddress ?? ''}</p>
              {data.providerInfo.firstName && <p className="text-12 text-neutral_body">
                {data?.providerInfo?.title ? `${data?.providerInfo?.title} |` : ''}{' '}
                {data?.providerInfo?.specialty ? `${data?.providerInfo?.specialty} |` : ''}{' '}
                {data?.providerInfo?.qualification ? `${data?.providerInfo?.qualification}` : ''}
              </p>}
            </div>
          </div>
          <p className="text-12 text-neutral_body font-campton_r">{data?.providerInfo?.GPINumber ?? ''}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewProcedure;
ViewProcedure.propTypes = {
  callback: PropTypes.any,
  data: PropTypes.object,
};
