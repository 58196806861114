import React, { useMemo } from "react";
import PropTypes from 'prop-types';

export default function Button({ name, disabled, onClick, children, theme, loading, type, className }) {
    const style = useMemo(() => {
        if (theme === 'transparent' && !disabled) return 'bg-neutral_white border-neutral_stroke_2 text-neutral_body hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary'
        if (theme === 'transparent' && (disabled || loading)) return 'bg-[#F7F8F8] border-neutral_stroke_2 text-neutral_body'
        if (theme === 'blue' && (disabled || loading)) return 'bg-primary_tint_200 text-neutral_white'
        if (theme === 'blue' && !disabled) return 'bg-brand_primary hover:bg-primary_tint_400 text-neutral_white'
        if (theme === 'darkBlue' && !disabled) return 'bg-[#143566] hover:bg-primary_tint_400 text-neutral_white'
        if (theme === 'orange' && (disabled || loading)) return 'bg-orange_100 text-orange_200'
        if (theme === 'orange' && !disabled) return 'bg-orange_400 hover:bg-brand_secondary text-neutral_white'
        if (theme === 'light_orange') return 'bg-orange_400 hover:bg-brand_secondary text-neutral_white'
        if (theme === 'green' && !disabled) return 'bg-success text-neutral_white'
        if (theme === 'green' && (disabled || loading)) return 'bg-[#A3EFC3] text-neutral_white'
    }, [disabled, loading])

    return (
        <button
            disabled={disabled || loading}
            onClick={onClick}
            type={type || 'submit'}
            className={
                `${style} ${className} px-[8px] whitespace-nowrap border flex justify-center items-center
                transition ease-in-out duration-500 font-campton_m rounded-[100px] hide_tap h-12 `
            }
        >
            {
                loading ?
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" height="24" viewBox="0 0 128 128">
                        <g>
                            <path d="M75.4 126.63a11.43 11.43 0 01-2.1-22.65 40.9 40.9 0 0030.5-30.6 11.4 11.4 0 1122.27 4.87h.02a63.77 63.77 0 01-47.8 48.05v-.02a11.38 11.38 0 01-2.93.37z" fill={theme !== 'transparent' ? "#FFFFFF" : "#36A1C5"} />
                            <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite" />
                        </g>
                    </svg> : name || children
            }
        </button>
    )
}

Button.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.element,
    theme: PropTypes.string,
    loading: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
};