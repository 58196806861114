
import { availableTests } from "mocks/myRecords"
import { useEffect, useState } from "react"

export default function useTestTypes(testType) {
    const [tests, setTests] = useState([])

    useEffect(() => {
        if (testType) {
            const selectedTestProperties = availableTests?.filter(t => t.name !== testType)[0]?.categories
            setTests(selectedTestProperties)
        }
    }, [testType])

    const updateTest = (testType, testProperties) => {
        const testTypeExist = availableTests.find(item => item.name === testType)
        if (!testTypeExist) return setTests([]) // If test is not in out library return
        let newTest = availableTests?.filter(t => t.name === testType)[0]?.categories
        Object.entries(testProperties).map((item) => {
            const [key, value] = item
            const updatedTest = newTest.map((t) => {
                if (t.name === key) {
                    return { ...t, value };
                }
                return t;
            });
            newTest = updatedTest
            return item
        })
        setTests(newTest)
    };

    return { availableTests, tests, updateTest }
}