import React, { useContext } from "react"
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import { motion } from "framer-motion";
import parse from 'html-react-parser';
import { MessagingContext } from "context";

export default function List({ message, onClick, index }) {
    const { selectedMessage } = useContext(MessagingContext)

    return (
        <motion.div
            initial={{ opacity: 0, x: "10px" }}
            animate={{ opacity: 1, x: 0, transition: { duration: .5, delay: index === 0 ? 0 : (.1 * index) } }}
            exit={{ opacity: 0 }}
            data-testid={message?.conversationId}
            onClick={() => onClick(message)}
            className={`
                flex py-[12px] px-[24px] hide_tap h-[80px]
                ${selectedMessage?.conversationId === message?.conversationId ? 'border-r border-r-brand_primary bg-[#F7FBFD]' : 'hover:bg-[#dfe2e23b] cursor-pointer'}
            `}
        >
            <Avatar
                url={message?.recipient?.profile_picture}
                width="40px"
                height="40px"
                alt={message?.recipient?.firstName?.split('')[0]}
                initials={`${message?.recipient?.firstName?.split('')[0] || ''}${message?.recipient?.lastName?.split('')[0] || ''}`}
                className="w-[40px] h-[40px] mr-[16px] shrink-0"
            />
            <div className="w-full overflow-hidden">
                <div className="flex items-center justify-between capitalize">
                    <p className="text-14 text-neutral_black font-campton_m">
                        {message?.recipient?.qualification ? `${message?.recipient?.qualification}.` : ''} {message?.recipient?.firstName} {message?.recipient?.lastName}
                    </p>
                    <p className="text-12 text-neutral_body font-campton_r ml-[10px]">
                        {new Date(message?.createdAt).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                    </p>
                </div>
                <p className="text-12 text-neutral_black font-campton_m truncate capitalize">{message?.subject}</p>
                <div className="text-12 text-neutral_body font-campton_r truncate message-container">{parse(message?.message?.message)}</div>
            </div>
        </motion.div>
    )
}

List.propTypes = {
    message: PropTypes.object,
    index: PropTypes.number,
    onClick: PropTypes.func
}