import React, { useCallback, useEffect, useMemo, useState } from "react"
import Navbar from "components/Navbar"
import { SlideUp } from "components/Animations"
import { MessagingContext } from "context"
import useWindowWidth from "hooks/useWindowWidth"
import DefaultModal from "components/Modals/DefaultModal"
import NewMessage from "./Components/Forms/NewMessage"
import SelectedMessage from "./Components/SelectedMessage"
import { Search } from "components/Inputs"
import { ReactComponent as AddButton } from "assets/icons/add-button.svg";
import List from "./Components/List"
import useMessaging from "hooks/fetch/useMessaging"
import { useSelector } from "react-redux"
import { MEDIUM_WIDTH } from "constants"

export default function Messaging() {
    const { conversations } = useSelector(state => state.messaging)
    const [currentTab, setCurrentTab] = useState('Inbox')
    const tabs = ['Inbox', 'Outbox', 'Trash']
    const [selectedMessage, setSelectedMessage] = useState()
    const [createMessage, setCreateMessage] = useState(false)
    const windowWidth = useWindowWidth()
    const [search, setSearch] = useState('')
    const { get_conversations } = useMessaging()

    const selectMessage = useCallback((e) => {
        setSelectedMessage(e)
    }, [selectedMessage])

    const handleSearch = (e) => {
        setSearch(e.target.value.toLowerCase())
    }

    const searchConversations = () => {
        return conversations?.filter((conversation) => {
            const doctorName = `${conversation?.recipient?.firstName} ${conversation?.recipient?.lastName}`
            return conversation?.subject?.toLowerCase()?.includes(search) ||
                conversation?.message?.message?.toLowerCase()?.includes(search) ||
                doctorName?.toLowerCase()?.includes(search)
        })
    }

    const conversationList = useMemo(() => {
        return !search ? conversations : searchConversations()
    }, [conversations, search])

    const contextValue = useMemo(() => {
        return {
            selectMessage,
            selectedMessage,
            setSelectedMessage,
            createMessage,
            setCreateMessage,
            currentTab
        }
    }, [selectedMessage, createMessage, currentTab])

    useEffect(() => {
        get_conversations({ type: currentTab === 'Trash' ? 'trash' : 'messages' })
    }, [currentTab])

    return (
        <div>
            <Navbar
                onClick={() => setSelectedMessage()}
                title={(!selectedMessage && windowWidth < (MEDIUM_WIDTH || 769)) || (windowWidth > (MEDIUM_WIDTH || 768)) ? "Messaging" : ""}
            />
            <SlideUp>
                <MessagingContext.Provider value={contextValue}>
                    <div className="flex">
                        <section className="border-r border-r-[#ECEEEE] w-full sm:w-[351px] shrink-0">
                            {(!selectedMessage && windowWidth < (MEDIUM_WIDTH || 769)) || (windowWidth > (MEDIUM_WIDTH || 768)) ?
                                <>
                                    <div className="sticky top-[78px] z-10 bg-neutral_white">
                                        <ul className="flex border-b border-b-[#ECEEEE]">
                                            {tabs.map((tab, i) =>
                                                <li
                                                    key={tab}
                                                    onClick={() => {
                                                        setSelectedMessage()
                                                        setCurrentTab(tab)
                                                    }}
                                                    className={`
                                                                py-[23px] w-full sm:w-[116.67px] font-campton_m text-14
                                                                text-center ${(i + 1) !== tabs.length ? 'border-r border-r-[#ECEEEE]' : ''} cursor-pointer
                                                                ${currentTab === tab ? 'text-secondary_shade_100 bg-secondary_tint' : 'text-neutral_body bg-neutral_white'}
                                                            `}
                                                >
                                                    {tab}
                                                </li>
                                            )}
                                        </ul>
                                        <div className="flex items-center mb-[24px] pt-[24px] px-[24px]">
                                            <Search
                                                type="search"
                                                id="search"
                                                name="search"
                                                placeholder="Search"
                                                onChange={handleSearch}
                                                className="w-full"
                                            />
                                            <AddButton
                                                data-testid="add-message"
                                                className="max-w-[40px] ml-[16px] cursor-pointer hide_tap"
                                                onClick={() => setCreateMessage(true)}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:h-[65vh] overflow-y-auto overflow-x-hidden">
                                        {conversationList?.map((message, i) =>
                                            <List
                                                key={message?.conversationId}
                                                message={message}
                                                index={i}
                                                onClick={selectMessage}
                                            />
                                        )}
                                    </div>
                                </>
                                : <SelectedMessage className="block sm:hidden" />
                            }
                        </section>
                        <SelectedMessage className="hidden sm:block" />
                    </div>
                    {createMessage && (
                        <DefaultModal
                            closeModal={() => setCreateMessage(false)}
                            styles="w-[90%] md:w-[730px]"
                            title="New Message"
                            containerVariant="bg-neutral_white"
                        >
                            <NewMessage />
                        </DefaultModal>
                    )}
                </MessagingContext.Provider>
            </SlideUp>
        </div>
    )
}