import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Illustration } from 'assets/svg/blood-type-illustration.svg';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddBloodType';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../Header';
import { ReactComponent as BloodTypeSolid } from 'assets/icons/medicalRecords/blood-type-solid.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { useRecordState } from 'store/modules/myRecords';

const BloodType = ({ callback, selected, onClick }) => {

  //Redux
  const { patientInfo, loading, bloodType, patientId } = useRecordState();
  //Hooks
  const { delete_medical_record } = useMedicalRecord();
  //Local State
  const [showModal, setShowModal] = useState();
  const [type, setType] = useState();
  //Handler functions
  const handleShowModal = (type) => {
    setType(type);
    setShowModal(!showModal);
  };
  const deleteMedicalRecord = () => {
    delete_medical_record('bloodType', patientId);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={callback} />
          {loading && (
            <div className="mt-10">
              <Spinner />
            </div>
          )}
          <h2 className="mb-[24px] flex justify-between items-center text-18 text-[#0D0F11] font-campton_m ">
            Blood Type
          </h2>
          {!loading &&
            (patientInfo?.medicalRecord?.bloodType?.bloodType && patientInfo?.medicalRecord?.bloodType?.genotype ? (
              <>
                <div className="flex justify-between">
                  <div className="font-campton_r flex space-x-4 text-14">
                    <BloodTypeSolid className="mt-1" />
                    <div>
                      <p className="text-neutral_body">
                        {' '}
                        Blood Group: <span className="text-neutral_black">{bloodType.bloodType}</span>
                      </p>
                      <p className="text-neutral_body mt-1">
                        {' '}
                        Genotype: <span className="text-neutral_black">{bloodType.genotype}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <EditIcon
                      onClick={() => handleShowModal('Edit')}
                      className="cursor-pointer"
                      data-testid="test-edit"
                    />
                    <DeleteIcon onClick={deleteMedicalRecord} data-testid="test-delete" className="cursor-pointer" />
                  </div>
                </div>
              </>
            ) : (
              <EmptyState
                illustration={<Illustration />}
                title="No Blood Type"
                description="Enter your blood type for personalized health insights and emergency information."
                btnText="Add Blood Type"
                onClick={() => handleShowModal('Add')}
              />
            ))}
        {showModal && (
          <DefaultModal
            closeModal={handleShowModal}
            styles="min-w-[90%] md:min-w-[730px]"
            title={`${type} Blood Type`}
            subTitle="Select your blood group and genotype from the dropdown."
            containerVariant="bg-neutral_white"
          >
            <Form callback={handleShowModal} type={type} />
          </DefaultModal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default BloodType;
BloodType.propTypes = {
  callback: PropTypes.func,
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
