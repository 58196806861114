import ResetPasswordForm from 'components/OnboardingForms/ResetPasswordForm'
import VerifyAccountForm from 'components/OnboardingForms/VerifyAccountForm'
import AuthLayout from 'layouts/AuthLayout'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams]);
  
  return (
    <AuthLayout
      backButton="Create account"
      to="/create-account?step=1"
      right={params?.email ? <VerifyAccountForm email={params?.email}/> : <ResetPasswordForm invitedUser={params.emailAddress}/>
      }
    />
  )
}

export default ResetPassword