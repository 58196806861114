import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';
import { Input } from 'components/Inputs';

const PhysicalExercise = ({ formData, setFormData, type }) => {
//Local State
  const [frequency, setFrequency] = useState(formData[type].frequency);
  const [duration, setDuration] = useState(formData[type].duration);

  //Handler functions
  const handleFrequency = (name) => {
    setFrequency(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], frequency: name}}));
  };
  const handleDuration = (name) => {
    setDuration(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], duration: name}}));
  };
  return (
    <div className="my-4">
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">How often do you engage in physical exercise?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Not Often"
            variant="col-span-4 py-3"
            isChecked={frequency === 'not often'}
            onChange={() => handleFrequency('not often')}
          />
          <RadioButtonSelect
            label="Often"
            variant="col-span-4"
            onChange={() => handleFrequency('often')}
            isChecked={frequency === 'often'}
          />
          <RadioButtonSelect
            label="Very Often"
            variant="col-span-4"
            onChange={() => handleFrequency('very often')}
            isChecked={frequency === 'very often'}
          />
        </div>
      </div>
      <div>
        <p className="font-campton_r text-14 text-neutral_black mb-2">How long do you engage in each session? </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Less than 30 mins"
            variant="col-span-4"
            isChecked={duration === 'less than 30 mins'}
            onChange={() => handleDuration('less than 30 mins')}
          />
          <RadioButtonSelect
            label="30 mins-1hrs"
            variant="col-span-4"
            isChecked={duration === '30 mins-1hrs'}
            onChange={() => handleDuration('30 mins-1hrs')}
          />
          <RadioButtonSelect
            label="Over 1 hr"
            variant="col-span-4"
            isChecked={duration === 'Over 1 hr'}
            onChange={() => handleDuration('Over 1 hr')}
          />
        </div>
      </div>
      <div className="mt-4">
        <Input
          label="Type of exercises you prefer"
          type="text"
          id="typeOfExercise"
          name="typeOfExercise"
          value={formData[type]?.typeOfExercise}
          onChange={(e) => setFormData((formData) => ({ ...formData, [type]: { ...formData[type], typeOfExercise: e.target.value } }))}
          showError={false}
        />
      </div>
    </div>
  );
};

export default PhysicalExercise;
PhysicalExercise.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
