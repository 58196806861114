/* eslint-disable max-len */
import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

const Pagination = ({ totalCount, currentPage, changePage, perPage }) => {
  const totalPages = Math.ceil(totalCount / perPage)

  return (
    <ReactPaginate
      previousLabel={
        currentPage !== 1 && <span data-testid="previous" className="mr-1">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
            <path d="M18.3537 20.6465C18.4001 20.693 18.437 20.7481 18.4621 20.8088C18.4872 20.8695 18.5002 20.9346 18.5002 21.0003C18.5002 21.066 18.4872 21.131 18.4621 21.1917C18.437 21.2524 18.4001 21.3076 18.3537 21.354C18.3072 21.4005 18.252 21.4373 18.1914 21.4625C18.1307 21.4876 18.0656 21.5006 17.9999 21.5006C17.9342 21.5006 17.8692 21.4876 17.8085 21.4625C17.7478 21.4373 17.6926 21.4005 17.6462 21.354L12.6462 16.354C12.5997 16.3076 12.5628 16.2524 12.5376 16.1917C12.5125 16.131 12.4995 16.066 12.4995 16.0003C12.4995 15.9346 12.5125 15.8695 12.5376 15.8088C12.5628 15.7481 12.5997 15.693 12.6462 15.6465L17.6462 10.6465C17.74 10.5527 17.8672 10.5 17.9999 10.5C18.1326 10.5 18.2598 10.5527 18.3537 10.6465C18.4475 10.7403 18.5002 10.8676 18.5002 11.0003C18.5002 11.133 18.4475 11.2602 18.3537 11.354L13.7068 16.0003L18.3537 20.6465Z" fill="#748181" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#ECEEEE" />
          </svg>
        </span>
      }
      nextLabel={
        totalPages !== currentPage &&
        <span data-testid="next" className="ml-1">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="white" />
            <path d="M19.3535 16.354L14.3535 21.354C14.3071 21.4005 14.2519 21.4373 14.1912 21.4625C14.1305 21.4876 14.0655 21.5006 13.9998 21.5006C13.9341 21.5006 13.869 21.4876 13.8083 21.4625C13.7476 21.4373 13.6925 21.4005 13.646 21.354C13.5996 21.3076 13.5627 21.2524 13.5376 21.1917C13.5125 21.131 13.4995 21.066 13.4995 21.0003C13.4995 20.9346 13.5125 20.8695 13.5376 20.8088C13.5627 20.7481 13.5996 20.693 13.646 20.6465L18.2929 16.0003L13.646 11.354C13.5522 11.2602 13.4995 11.133 13.4995 11.0003C13.4995 10.8676 13.5522 10.7403 13.646 10.6465C13.7399 10.5527 13.8671 10.5 13.9998 10.5C14.1325 10.5 14.2597 10.5527 14.3535 10.6465L19.3535 15.6465C19.4 15.693 19.4369 15.7481 19.4621 15.8088C19.4872 15.8695 19.5002 15.9346 19.5002 16.0003C19.5002 16.066 19.4872 16.131 19.4621 16.1917C19.4369 16.2524 19.4 16.3076 19.3535 16.354Z" fill="#748181" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#ECEEEE" />
          </svg>
        </span>
      }
      breakLabel={<div>...</div>}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      forcePage={currentPage - 1}
      onPageChange={changePage}
      activeClassName={'block bg-brand_primary text-center text-neutral_white h-[32px] min-w-[32px] rounded-[100px]'}
      previousClassName={'text-neutral_body w-2/5 text-left'}
      disabledLinkClassName={'text-gray-[#B8B8B810]'}
      previousLinkClassName={
        'relative inline-flex items-center bg-white px-1 py-2 text-sm text-neutral_body'
      }
      nextClassName={'text-neutral_body w-2/5 text-right'}
      nextLinkClassName={
        'relative inline-flex items-center rounded-r-md bg-white px-2 py-2 2xl:ml-4/5 text-sm text-neutral_body hover:text-gray-500 focus:z-20'
      }
      pageClassName={'text-neutral_body ml-2'}
      pageLinkClassName={
        'relative inline-flex items-center px-2 py-0.5 text-sm rounded-sm'
      }
      breakClassName={'text-neutral-500'}
      breakLinkClassName={'relative inline-flex items-center px-6 py-2 text-18'}
      containerClassName={'flex items-center space-x-4px text-16 rounded-md px-2 text-neutral_body font_campton_r'}
    />
  );
};

export default Pagination;

Pagination.propTypes = {
  totalPages: PropTypes.any,
  changePage: PropTypes.func,
  styles: PropTypes.string,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};