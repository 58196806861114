import React from 'react';
import PropTypes from 'prop-types';

const StatusCard = ({ icon, title, description, status, containerVariant, loading, variant, onClick , date}) => {
  return (
    <div className={`${containerVariant} cursor-pointer`} onClick={onClick} data-testid={`test-${title}`}>
      <div className={`${variant} hover:bg-primary_tint_50 py-6 px-4 rounded-lg`}>
        {icon}
        <p className="text-14 font-campton_m text-[#0D0F11] mt-4">{title}</p>
        <div className="text-12 font-campton_r mt-1">
          <p className="text-neutral_body card-description">{description}</p>
         { date ? 
         <div className='flex justify-between mt-4 font-campton_m text-12'>
          <p className='text-neutral_body'>Last modified </p>
          <p>{date}</p>
         </div> : <p className="text-[#0D0F11] mt-4 font-campton_m">{loading ? '...' : status} added</p> 
         }
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
StatusCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.any,
  description: PropTypes.string,
  containerVariant: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.any,
  status: PropTypes.any,
  styles: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};
