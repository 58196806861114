import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import {
  getLabTests, getOngoingVisit, getPendingLabTest, getVisits, uploadTestResult,
  getProcedure,
  getReferrals,
  getTherapies,
  getMedications,
  createMedications,
  getVisitTreatmentPlan,
  getVisitLabTest,
  getSingleProgressNote,
  addLabTest,
  getProgressNotes,
  getSingleVisit,
  editMedications
} from 'store/modules/myRecords/actions';

export default function useMyRecords() {
  const { makeRequest, makeAnonymousRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const get_visits = async ({ params }) => {
    return tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: getVisits({ params }), alert: false })
        .finally(() => setLoading(false))
    })
  }

  const get_ongoing_visit = async ({ params }) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getOngoingVisit({ params }), alert: false })
        .finally(() => setLoading(false))
    })
  }

  const get_progress_notes = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getProgressNotes({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const get_single_progress_note = (pathname) => {
    // const url = `/progress-note/${progressNoteId}`
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getSingleProgressNote({pathname }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const get_lab_tests = async ({ params }) => {
    return tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: getLabTests({ params }), alert: false })
        .finally(() => setLoading(false))
    })
  }

  const get_pending_lab_tests = async ({ params }) => {
    return tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: getPendingLabTest({ params }), alert: false })
        .finally(() => setLoading(false))
    })
  }

  const upload_test_result = async (payload, pathname) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: uploadTestResult({ pathname, payload }), alert: true })
        .finally(() => setLoading(false))
    })
  }

  const get_procedures = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getProcedure({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_therapies = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getTherapies({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_referrals = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getReferrals({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  }

  const get_medications = (id, params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getMedications({ pathname: id, params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  }

  const create_medication = (formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: createMedications(formData), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const edit_medication = (formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: editMedications(formData), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_visit_lab_test = (id) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getVisitLabTest({ pathname: id }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  }

  const get_visit_treatment_plan = (id) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getVisitTreatmentPlan({ pathname: id }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  }

  const add_lab_test = async (payload) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: addLabTest({ payload }), alert: true })
        .finally(() => setLoading(false))
    })
  }

  const get_single_visit = async ( id ) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getSingleVisit({ pathname: id }), alert: false })
        .finally(() => setLoading(false))
    })
  }

  const delete_medication = async ( payload ) => {
    const url = 'medications/patient/'
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, method: 'delete' , payload  })
        .finally(() => setLoading(false))
    })
  }

  return {
    get_visits,
    get_ongoing_visit,
    delete_medication,
    get_lab_tests,
    get_pending_lab_tests,
    upload_test_result,
    get_medications,
    loading,
    get_single_progress_note,
    get_referrals,
    get_progress_notes,
    get_therapies,
    get_visit_lab_test,
    get_single_visit,
    edit_medication,
    create_medication,
    get_procedures,
    get_visit_treatment_plan,
    add_lab_test
  }
}