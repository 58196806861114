import { useState } from 'react'
import useMakeRequest from 'hooks/useMakeRequest'
import useTryCatch from 'hooks/useTryCatch'
import { removeSpecificKeys } from 'utils/removeSpecificKeys'
import { createConversation, createMessage, deleteConversation, getConversation, getConversations } from 'store/modules/messaging/actions'

export default function useMessaging() {
    const { makeRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const create_conversation = async (formData) => {
        return tryCatch(async () => {
            removeSpecificKeys(formData, ['recipient', 'cc'])
            const enhancedFormData = { ...formData, ccProviders: formData.ccProviders.map((item) => item?.id) }
            setLoading(true)
            return makeRequest({ action: createConversation({ payload: enhancedFormData }) })
                .finally(() => setLoading(false))
        });
    }

    const create_message = async (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createMessage({ payload: formData }), alert: false })
                .finally(() => setLoading(false))
        });
    }

    const get_conversations = async (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getConversations({ params }), alert: false })
                .finally(() => setLoading(false))
        });
    }

    const get_conversation = async (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getConversation({ pathname: id }), alert: false })
                .finally(() => setLoading(false))
        })
    }

    const delete_conversation = async (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: deleteConversation({ pathname: id }), alert: false })
                .finally(() => setLoading(false))
        })
    }

    return {
        create_conversation,
        create_message,
        get_conversation,
        get_conversations,
        delete_conversation,
        loading
    }
}
