import React from 'react';
import PropTypes from 'prop-types';

const EmptyState = ({ illustration, title, description, btnText, onClick }) => {
  return (
    <div className="flex w-full p-4 h-[40vh] flex-col bg-gray justify-center items-center">
      <div>{illustration}</div>
      <p className="font-campton_m md:text-18 text-neutral_black text-16 mt-8">{title}</p>
      <p className="font-campton_r text-14 mt-1 text-neutral_body w-2/5 mx-auto text-center">{description}</p>
      <button
        onClick={onClick}
        className="w-fit text-14 h-8 px-3 rounded-[100px] font-campton_m text-neutral_white bg-brand_primary mt-6"
      >
        {btnText}
      </button>
    </div>
  );
};

export default EmptyState;
EmptyState.propTypes = {
  illustration: PropTypes.any,
  description: PropTypes.string,
  title: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
};
