import Accordion from "components/Accordion";
import Avatar from "components/Avatar/Avatar";
import React, { useContext } from "react";
import { ReactComponent as Timer } from "assets/icons/timer.svg";
import { VisitContext } from "context";
import { ReactComponent as Examination } from "assets/svg/examination.svg";
import EmptyState from "components/EmptyState";
import dayjs from "dayjs";

export default function ChiefComplaint() {
    const { visit, progressNote } = useContext(VisitContext)
    const chiefComplaint = progressNote?.chiefComplaint || visit?.chiefComplaint

    return (
        <Accordion
            display={false}
            title={<p className="text-14 text-neutral_body font-campton_r">Chief Complaint</p>}
            className="mb-0 border-b border-b-neutral_stroke_2"
        >
            {
                chiefComplaint?.length ? chiefComplaint?.map((item) =>
                    <div key={item?._id}>
                        <div className="flex items-center">
                            <Timer />
                            <p className="text-12 font-campton_r text-neutral_body uppercase ml-[16px]">{dayjs(item.createdAt).format('DD, MMM YYYY')}, {dayjs(item.createdAt).format('hh:mm A')}</p>
                        </div>
                        <div className="border-dashed border-l border-l-neutral_stroke_2 py-[16px] ml-[15px]">
                            <div className="border border-neutral_stroke_2 rounded-[8px] p-[16px] ml-[32px]">
                                <div className='flex mb-[8px]'>
                                    <Avatar
                                        url={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTga82Zqlb6VqnCZcxG2ur7hI6Fw8aJTtJLvDhiunx0AxCd6j8lmMVt1cIA-Tw-S3xV754&usqp=CAU'}
                                        width="32px"
                                        height="32px"
                                        //alt={loggedUser?.firstName?.split('')[0]}
                                        className="w-[32px] h-[32px] shrink-0"
                                    />
                                    <div className='ml-[8px]'>
                                        <p className='text-14 font-campton_m text-neutral_black'>{item?.provider?.firstName} {item?.provider?.lastName}</p>
                                        <p className='text-12 font-campton_r text-neutral_body'>{item?.provider?.emailAddress}</p>
                                    </div>
                                </div>
                                <p className=" font-campton_r text-12 text-neutral_body leading-[20px]">{item?.note}</p>
                            </div>
                        </div>
                    </div>
                ) :
                    <EmptyState
                        illustration={<Examination />}
                        title="No Chief Complaint"
                        description="Chief complaint will be displayed here when added"
                    />
            }
        </Accordion>
    )
}