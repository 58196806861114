import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as Video } from 'assets/svg/appointment/video.svg';
import { ReactComponent as Onsite } from 'assets/svg/appointment/onsite.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as Ongoing } from 'assets/icons/ongoing-status.svg';
import { ReactComponent as NotOngoing } from 'assets/icons/not-ongoing-status.svg';
import { userInitials } from 'utils/ImagePlaceholder';
import { insertTimeToDate, isAppointmentDate } from 'utils/formatDate';

export default function AppointmentList({ item, handleSingleView, lastItem }) {
  const isDate = isAppointmentDate(item?.appointmentDate, item?.startTime);

  return (
    <div className="pl-[10px] flex relative">
      <div className="h-[46px] bg-neutral_white pt-[4px] absolute left-[3.5px] z-2">
        {isDate && item.status === 'upcoming' ? <Ongoing /> : <NotOngoing />}
      </div>
      <div
        className={`
        ${isDate && item.status === 'upcoming' ? 'border-success' : 'border-neutral_stroke_2'}
        ${!lastItem ? 'border-l' : ''} sm:flex w-full pb-[20px]
        `}
      >
        <p className="text-14 text-neutral_body ml-[14px] mb-[8px] sm:mb-0 uppercase">
          {dayjs(insertTimeToDate(item.appointmentDate, item.startTime)).format('DD, MMM')}
        </p>
        <div
          className="
          border border-neutral_stroke_2 p-4 rounded-2xl mt-6 first-of-type:mt-0 
          cursor-pointer flex justify-between items-start w-full ml-[14px]"
          data-testid={item?._id}
          onClick={() => handleSingleView(item)}
        >
          <div className="flex space-x-2 w-full">
            <Avatar
              url={item?.providerInfo?.profilePic}
              width="42px"
              height="42px"
              alt={item?.providerInfo?.firstName?.split('')[0]}
              initials={userInitials(item?.providerInfo?.firstName || '', item?.providerInfo?.lastName || '')}
              className="bg-brand_secondary w-8 h-8 shrink-0"
            />
            <div>
              <p className="text-14 font-campton_m text-primary_300 capitalize">
                {item.providerInfo?.title}. {item.providerInfo?.firstName} {item.providerInfo?.lastName}
              </p>
              <p className="text-12 font-campton_r text-neutral_body capitalize-first">
                {item.providerInfo?.specialty ?? 'N/A'}, {item.providerInfo?.qualification ?? 'N/A'}
              </p>
              <div className="flex flex-wrap">
                <p className="text-12 text-neutral_body items-center flex bg-primary_tint_50 border border-neutral_stroke_2 px-2 py-1 h-7 rounded-full min-w-max mr-[8px] mt-3">
                  <Clock fill="#748181" width="15" heigth="15" className="mr-[4px]" />
                  <span>
                    {item.startTime
                      ? dayjs(insertTimeToDate(item.appointmentDate, item.startTime)).format('hh : mm A')
                      : 'N/A'}
                  </span>
                </p>
                <p className="text-12 text-neutral_body flex bg-primary_tint_50 border border-neutral_stroke_2 p-2 px-2 py-1 h-7 rounded-full min-w-max mt-3">
                  {item.type === 'onSite' ? <Onsite className="mr-[4px]" /> : <Video className="mr-[4px]" />}
                  <span>{item.type === 'onSite' ? 'Onsite' : 'Telehealth'}</span>
                </p>
              </div>
            </div>
          </div>
          <ArrowRight fill="#748181" />
        </div>
      </div>
    </div>
  );
}

AppointmentList.propTypes = {
  item: PropTypes.object,
  handleSingleView: PropTypes.func,
  lastItem: PropTypes.bool,
};
