import Button from 'components/Buttons/Button';
import React from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back-bg.svg';
import PropTypes from 'prop-types';

const Header = ({ onClick, selected, callback, condition }) => {
  return (
    <>
      {selected && (
        <div className="flex justify-between items-center my-6">
          <div className="flex items-center space-x-4">
            <Button className="w-fit rounded-full h-8 font-campton_m text-14" theme="transparent" onClick={onClick}>
              <p className="flex items-center space-x-4">
                <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                <span>Back</span>
              </p>
            </Button>
            <h2 className="text-14 sm:text-16 text-neutral_body font-campton_m my-6 border-l border-neutral_stroke_2 pl-4">
              Personal Health Records
            </h2>
            <h2
              className={`text-14 sm:text-16 text-[#0D0F11] font-campton_m ${
                selected && 'border-l border-neutral_stroke_2 pl-4'
              }`}
            >
              {selected}
            </h2>
          </div>
          { condition &&
          <Button
            className="w-fit rounded-full h-8 font-campton_m text-14"
            theme="blue"
            name={`Add ${selected}`}
            onClick={callback}
          />
          }
        </div>
      )}
    </>
  );
};

export default Header;
Header.propTypes = {
  callback: PropTypes.func,
  selected: PropTypes.string,
  onClick: PropTypes.func,
  condition: PropTypes.bool
};
