import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import {
  bookAppointment,
  getAppointments,
  getAvailableTimeSlot,
  intializeTransaction,
  getServices,
  getCurrentPolicy,
  cancelAppointment,
  rescheduelAppointment,
} from 'store/modules/appointments/actions';

export default function useAppointment() {
  const { makeRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const get_appointments = (params) => {
    tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getAppointments({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const book_appointment = async (formData) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: bookAppointment(formData), to: '/appointments' })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const get_available_time_slot = async (id, params) => {
    tryCatch(async () => {
      setLoading(true);
      return makeRequest({
        action: getAvailableTimeSlot({ pathname: id, params }),
        alert: false,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const initialize_transaction = async (formData) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({
        action: intializeTransaction(formData),
        alert: false,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const get_services = async (params) => {
    tryCatch(async () => {
      setLoading(true);
      return makeRequest({
        action: getServices({ params }),
        alert: false,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const get_current_Policy = async (id) => {
    tryCatch(async () => {
      setLoading(true);
      return makeRequest({
        action: getCurrentPolicy({ pathname: id }),
        alert: false,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const cancel_appointment = async (id, payload, callback) => {
    tryCatch(async () => {
      setLoading(true);
      return makeRequest({
        action: cancelAppointment({ pathname: id, payload }),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const reschedule_appointment = async (id, payload, callback) => {
    tryCatch(async () => {
      setLoading(true);
      return makeRequest({
        action: rescheduelAppointment({ pathname: id, payload }),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  return {
    get_appointments,
    get_available_time_slot,
    loading,
    initialize_transaction,
    get_current_Policy,
    book_appointment,
    reschedule_appointment,
    get_services,
    cancel_appointment,
  };
}
