/* eslint-disable no-use-before-define */
import React from 'react';
import AuthLayout from 'layouts/AuthLayout';
import FormWrapper from 'components/FormWrapper';
import CreateAccountForm from 'components/OnboardingForms/CreateAccountForm';
import VerifyEmail from 'components/OnboardingForms/VerifyEmail';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'store/modules/auth';

export default function CreateAccount() {
  const [searchParams] = useSearchParams();
  const { loggedUser, userInfo, recoverUser } = useAuthState();
  const step = Number(searchParams.get('step'));
  const navigate = useNavigate()
  const pathname = window.location.pathname;

  const params = Object.fromEntries([...searchParams]);

  return (
    <div>
      <AuthLayout
        left={true}
        backButton="Login"
        to="/"
        right={
          <FormWrapper
            title="Create Account"
            showBackbutton={true}
            step={step}
            onClick={() => navigate('/create-account')}
            description={
              step === 1 || pathname.includes('verify-account') ? (
                <>
                  Nice to meet you, <span className="capitalize">{userInfo.firstName || recoverUser?.firstName || params.firstName}</span>
                  <br /> <span className='text-[#0F2E38] font-campton_sb'>{`${pathname.includes('verify-account') ? 'Just one last step' : 'Help us get to know you'}`}</span>
                </>
              ) : (
                <>
                  Ok, let’s set up your profile!
                  <br /> <span className='text-[#0F2E38] font-campton_sb'>First, what’s your name?</span>
                </>
              )
            }
            caption={
              pathname.includes('create-account') ? (
                'This is how you will appear on GabbiHealth'
              ) : (
                <>
                  Enter the 6-digit otp sent to{' '}
                  <span className="text-brand_secondary font-campton_m">{loggedUser?.emailAddress || recoverUser?.emailAddress || params.emailAddress}</span>
                </>
              )
            }
            className="sm:h-full md:mt-12 mt-8"
          >
            {pathname.includes('create-account') && <CreateAccountForm />}
            {pathname === '/verify-account' && <VerifyEmail variant="mt-10" userStatus="new" />}
          </FormWrapper>
        }
      />
    </div>
  );
}
