/* eslint-disable max-len */
import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Contact } from 'assets/svg/contact-illustration.svg';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddEmergencyContact';
import { userInitials } from 'utils/ImagePlaceholder';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import Header from '../Header';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useRecordState } from 'store/modules/myRecords';

const EmergencyContact = ({ selected, onClick }) => {
  //Hooks
  const { emergencyContact, loading } = useRecordState();

  //Local State
  const [showModal, setShowModal] = useState();
  const [selectedDetails, setSelectedDetails] = useState();
  const [type, setType] = useState();
  //Hooks
  const { delete_medical_record } = useMedicalRecord();

  //Handler function
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={() => handleShowModal('Add')} condition={emergencyContact?.length > 0} />
        {loading && (
          <div className="mt-10">
            <Spinner />
          </div>
        )}

        {!loading &&
          (emergencyContact?.length > 0 ? (
            <>
              <div className="mt-6">
                {emergencyContact.map((item) => (
                  <div
                    className="flex justify-between items-start space-y-6 border-b last-of-type:border-b-0 border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0"
                    key={item?._id}
                  >
                    <div className="flex space-x-4">
                      <div className="h-10 w-10 bg-brand_secondary flex justify-center items-center rounded-full text-neutral_white uppercase font-campton_sb">
                        {userInitials(item.firstName, item.lastName)}
                      </div>
                      <div>
                        <div className="flex space-x-2 items-center">
                          <p className="capitalize font-campton_m">
                            {item.firstName} {item.lastName}
                          </p>
                          <p className="text-green_100 bg-green_light py-0.5 px-2 rounded-full capitalize text-14">
                            {item.relationship}
                          </p>
                        </div>
                        <div className="text-14 font-campton_r text-neutral_body flex items-center space-x-2 mt-1">
                          <p className=" ">{item.emailAddress} </p> <Dot /> <p>+{item.phoneNumber}</p>
                        </div>
                        <p className="font-campton_m text-14 text-neutral_body mt-1">{item.address}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2 items-center">
                      <EditIcon
                        onClick={() => handleShowModal('Edit', () => setSelectedDetails(item))}
                        data-testid="test-edit"
                        className="cursor-pointer"
                      />
                      <DeleteIcon
                        onClick={() => delete_medical_record('emergencyContact', item._id)}
                        className="cursor-pointer"
                        data-testid="test-delete"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyState
              illustration={<Contact />}
              title="No Emergency Contact"
              description="Set up your emergency contact to ensure a swift response when it matters most."
              btnText="Add Emergency Contact"
              onClick={() => handleShowModal('Add')}
            />
          ))}
        {showModal && (
          <DefaultModal
            closeModal={() => setShowModal(false)}
            styles="min-w-[90%] md:min-w-[730px]"
            title={` ${type} Emergency Contact`}
            subTitle="Input details of emergency contact in the fields below."
            containerVariant="bg-neutral_white"
          >
            <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
          </DefaultModal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default EmergencyContact;
EmergencyContact.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
