import Button from "components/Buttons/Button";
import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';

export default function Title({ showButton, onClick, name }) {
    return (
        <div className='flex items-center sticky top-0 z-10 bg-neutral_white w-full pt-[24px] pb-[16px]'>
            {
                showButton ?
                    <div className='pr-[16px] mr-[16px] border-r border-r-[#ECEEEE]'>
                        <Button
                            theme="transparent"
                            className="w-[82px] max-h-[32px]"
                            type="button"
                            onClick={onClick}
                        >
                            <>
                                <ArrowLeft />Back
                            </>
                        </Button>
                    </div> : ''
            }
            <h2 className='text-18 text-neutral_black font-campton_m'>{name}</h2>
        </div>
    )
}

Title.propTypes = {
    showButton: PropTypes.bool,
    onClick: PropTypes.func,
    name: PropTypes.string
}