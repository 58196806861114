import ForgotPasswordForm from 'components/OnboardingForms/ForgotPasswordForm';
import VerifyAccountForm from 'components/OnboardingForms/VerifyAccountForm';
import AuthLayout from 'layouts/AuthLayout';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from 'store/modules/auth';

const ForgotPassword = () => {
  const { recoverUser } = useAuthState()
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('email')

  return (
    <AuthLayout
      right={email === recoverUser?.emailAddress ?
        <VerifyAccountForm email={email} /> :
        <ForgotPasswordForm callback={() => setSearchParams({ email: recoverUser.emailAddress })} />
      }
    />
  );
};

export default ForgotPassword;
