import DefaultDatepicker from 'components/Datepicker/Index';
import Dropdown from 'components/Dropdown/Index';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import React, { useEffect, useState } from 'react';
import { immunizationTypes } from 'mocks/medicalRecords';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Button from 'components/Buttons/Button';
import { useRecordState } from 'store/modules/myRecords';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';

const AddImmunizationHistory = ({ type, callback, selectedRecord }) => {
  //local State
  const [name, setName] = useState();
  const [formData, setFormData] = useState({
    name: '',
    vaccineNumber: '',
    routeOfAdministration: '',
    site: '',
    date: '',
    location: '',
    manufacturer: '',
  });
  //Hooks
  const { update_medical_record, edit_medical_record } = useMedicalRecord();

  //Redux
  const { loading, patientId } = useRecordState();

  //Handler function
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Effect
  useEffect(() => {
    if (selectedRecord && type === 'Edit') {
      setFormData({
        vaccineNumber: selectedRecord?.vaccineNumber,
        routeOfAdministration: selectedRecord?.routeOfAdministration,
        site: selectedRecord?.site,
        location: selectedRecord?.location,
        manufacturer: selectedRecord?.manufacturer,
        name: selectedRecord?.immunizationName,
        date: new Date(selectedRecord?.date)
      });

      setName({
        id: selectedRecord?.immunizationName,
        name: selectedRecord.immunizationName,
        label: selectedRecord.immunizationName,
      });
    }
  }, [selectedRecord, type]);

  const updateMedicalRecord = () => {
    const payload = {
      ...formData,
      date: dayjs(formData.date).format('YYYY-MM-DD'),
    };

    type === 'Edit'
      ? edit_medical_record('immunizationHistory', selectedRecord._id,  payload, callback)
      : update_medical_record('immunizationHistory', patientId, payload, callback);
  };

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={updateMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <Dropdown
              label="Immunization Name"
              variant="lg:col-span-6 col-span-12"
              name="name"
              id="name"
              value={name}
              disabled={loading}
              optionContainerVariant="w-full md:min-h-[100px] min-h-[150px]"
              options={immunizationTypes}
              onChange={(data) => {
                setName(data);
                setFormData({ ...formData, name: data.value });
              }}
            />
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Vaccine Number"
                type="text"
                id="vaccineNumber"
                name="vaccineNumber"
                readOnly={loading}
                value={formData?.vaccineNumber}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Route of Administration"
                type="text"
                id="routeOfAdministration"
                name="routeOfAdministration"
                readOnly={loading}
                value={formData?.routeOfAdministration}
                onChange={handleChange}
              />
              <p className="text-12 font-campton_r text-neutral_body">The means at which the substance was applied</p>
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Site"
                type="text"
                id="site"
                name="site"
                readOnly={loading}
                value={formData?.site}
                onChange={handleChange}
              />
              <p className="text-12 text-neutral_body font-campton_r">The specific locations on the body that the drug was administered</p>
            </div>
            <DefaultDatepicker
              label="Date"
              containerVariant="lg:col-span-4 md:col-span-6 col-span-12"
              startDate={formData.date}
              maxDate={new Date()}
              onSelect={(e) => setFormData({...formData, date: e})}
              name="date"
              value={formData?.date}
            />
            <div className="lg:col-span-4 md:col-span-6 ol-span-12">
              <Input
                label="Location"
                type="text"
                id="location"
                name="location"
                readOnly={loading}
                value={formData?.location}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="lg:col-span-4 md:col-span-6 ol-span-12">
              <Input
                label="Manufacturer"
                type="text"
                id="manufacturer"
                name="manufacturer"
                readOnly={loading}
                value={formData?.manufacturer}
                onChange={handleChange}
                showError={false}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button name="Add" theme="blue" className="w-28 h-10" disabled={checkEmptyProperties(formData)} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddImmunizationHistory;
AddImmunizationHistory.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};
