import React, { useContext } from "react"
import PropTypes from "prop-types"
import { MessagingContext } from "context"

export default function MessageDetails({ ccProviders }) {
    const { selectedMessage } = useContext(MessagingContext)
    const providers = ccProviders?.map((item) => `${selectedMessage?.recipient?.qualification ? `${selectedMessage?.recipient?.qualification}.` : ''} ${item?.firstName} ${item?.lastName} (${item?.email}), `)

    return (
        <div className="h-[350px]">
            <h3 className="text-16 text-neutral_black font-campton_m mb-[8px]">{selectedMessage?.subject}</h3>
            <List
                name="From:"
                details="Dr. John Alao (johnalao@gmail.com)"
            />
            <List
                name="Recipient:"
                details={`
                ${selectedMessage?.recipient?.qualification ? `${selectedMessage?.recipient?.qualification}.` : ''} ${selectedMessage?.recipient?.firstName} ${selectedMessage?.recipient?.lastName} (${selectedMessage?.recipient?.email})
                `}
            />
            <List
                name="Cc:"
                details={providers?.toString()}
            />
        </div>
    )
}

const List = ({name, details}) => {
    return (
        <div className="mb-[8px] text-14 font-campton_r flex">
            <p className="text-neutral_body mr-[8px]">{name}</p>
            <p className="text-neutral_black">{details}</p>
        </div>
    )
}

List.propTypes = {
    details: PropTypes.string,
    name: PropTypes.string
}
MessageDetails.propTypes = {
    ccProviders: PropTypes.array
}