import React from 'react';
import dayjs from 'dayjs';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import { ReactComponent as Timer } from 'assets/icons/timer.svg';
import { ReactComponent as Examination } from 'assets/svg/examination.svg';
import EmptyState from 'components/EmptyState';
import PropTypes from 'prop-types';

const LabRequest = ({labRequests}) => {
  return (
    <>
      {labRequests && (
        <div className="bg-[#FAFAFA] mt-2">
          <div className="flex w-full border-b-[0.5px] border-neutral_stroke_2 py-2.5 px-4">
            <Document className="mr-3" />
            <p className="font-campton_r text-brand_secondary text-12 ">Tests</p>
          </div>
          <div className="p-3">
            {labRequests ? (
              labRequests?.map((item) => (
                <div key={item?._id}>
                  <div className="flex items-center">
                    <Timer />
                    <p className="text-10 font-campton_r text-neutral_body uppercase ml-[16px]">
                      {dayjs(item.createdAt).format('DD, MMM YYYY')}, {dayjs(item.createdAt).format('hh:mm A')}
                    </p>
                  </div>
                  <div className="border-dashed border-l border-l-neutral_stroke_2 py-2 ml-[15px] ">
                    <div className="border border-neutral_stroke_2 p-2 ml-3 bg-neutral_white">
                      <div className="ml-[8px] text-10">
                        <p className="font-campton_m text-neutral_black capitalize">
                          {item?.requestedBy?.firstName} {item?.requestedBy?.lastName}
                        </p>
                        <p className=" font-campton_r text-[8px] text-neutral_body leading-3 mt-2">{item?.testType}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <EmptyState
                illustration={<Examination />}
                title="No Tests"
                description="All lab tests will be displayed here when added"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LabRequest;
LabRequest.propTypes = {
    labRequests: PropTypes.array
  };
