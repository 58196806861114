/* eslint-disable react/no-unescaped-entities */
import { SlideUp } from 'components/Animations';
import { privacyPolicy } from 'mocks/termsAndCondition';
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <SlideUp>
      <div className="lg:grid grid-cols-12 gap-16">
        <div className="lg:col-span-8">
          <div>
            <p className="md:text-18 text-16  font-campton_m text-neutral_black">Introduction</p>
            <div className="md:text-16 text-14 font-campton_r mt-2">
              <p className="text-neutral_body">
                This Privacy Policy provides information and describes how Gabbi Health ('Company', 'we', 'us')
                collects, uses, and shares your personal information when you use our electronic medical records (EMR)
                or telehealth services (collectively, the 'Services').
              </p>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="personalInfo">
            <p className="font-campton_m md:text-18 text-16  text-neutral_black">1. Personal Information We Collect</p>
            <div className="text-neutral_body font-campton_r mt-1 leading-6 md:text-16 text-14">
              <p className="mb-4">You have the following choices regarding your personal information:</p>
              <p>When you use our Services, you may provide us with personal information about yourself, including:</p>
              <div className="pl-6 mt-4">
                <div>
                  <p className="font-campton_m text-neutral_black">1.1 Demographic information:</p>
                  <p className="mt-2">
                    This includes your name, date of birth, gender, contact information, and medical insurance
                    information.
                  </p>
                </div>
                <div className="mt-4">
                  <p className="font-campton_m text-neutral_black">1.2 Medical history</p>
                  <p className="mt-2">
                    This includes your past medical history, including diagnoses, treatments, and medications.
                  </p>
                </div>
                <div className="mt-4">
                  <p className="font-campton_m text-neutral_black">1.3 Billing information</p>
                  <p className="mt-2">This includes your billing address and payment information.</p>
                </div>
              </div>
              <p className="text-neutral_body font-campton_r mt-4">
                We may also collect personal information from third parties, such as:
              </p>
              <div className="pl-6 mt-4">
                <div>
                  <p className="font-campton_m text-neutral_black">1.4 Your healthcare providers</p>
                  <p className="mt-2">
                    We may receive information from your healthcare providers, such as your medical records and
                    laboratory results.
                  </p>
                </div>
                <div className="mt-4">
                  <p className="font-campton_m text-neutral_black">1.5 Other healthcare organizations</p>
                  <p className="mt-2">
                    We may receive information from other healthcare organizations that you have authorized to share
                    your information with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="useOfInfo">
            <p className="font-campton_m md:text-18 text-16  text-neutral_black">2. How We Use Your Personal Information</p>
            <p className="mt-4 text-neutral_body font-campton_r md:text-16 text-14">
              We use your personal information to provide and improve our Services, including:
            </p>
            <div className="pl-6 mt-4 text-neutral_body font-campton_r md:text-16 text-14">
              <div>
                <p className="font-campton_m text-neutral_black">2.1 To provide you with medical care: </p>
                <p className="mt-2">
                  We use your personal information to provide you with medical care,including processing your
                  appointments, sending you reminders, and communicating with your healthcare providers.
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">2.2 To improve our Services:</p>
                <p className="mt-2">
                  We use your personal information to improve our Services, such as developing new features and
                  products.
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">2.3 To conduct research:</p>
                <p className="mt-2">
                  We may use your personal information to conduct research, such as improving our understanding of
                  health conditions.
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">2.4 To protect our rights and property:</p>
                <p className="mt-2">
                  We may use your personal information to protect our rights and property,such as enforcing our Terms of
                  Service and preventing fraud.
                </p>
              </div>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="sharing">
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">3. Sharing Your Personal Information</p>
            <p className="mt-4 text-neutral_body font-campton_r md:text-16 text-14">
              We may share your personal information with the following types of third parties:
            </p>
            <div className="pl-6 mt-4 text-neutral_body font-campton_r md:text-16 text-14">
              <div>
                <p className="font-campton_m text-neutral_black">3.1 Healthcare providers: </p>
                <p className="mt-2">
                  We may share your personal information with your healthcare providers to provide you with care.
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">3.2 Service providers:</p>
                <p className="mt-2">
                  We may share your personal information with third party service providers who help us operate our
                  Services, such as data analytics providers and cloud storage providers.
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">3.3 Business partners:</p>
                <p className="mt-2">
                  We may share your personal information with our business partners to offer you products or services
                  that may be of interest to you.
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">3.4 Law enforcement:</p>
                <p className="mt-2">
                  We may share your personal information with law enforcement if we are required to do so by law or if
                  we believe that there is a threat to the safety of you or others.
                </p>
              </div>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="choices">
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">4. Your Choices</p>
            <p className="mt-4 text-neutral_body font-campton_r md:text-16 text-14">
              You have the following choices regarding your personal information:
            </p>
            <div className="pl-6 mt-4 text-neutral_body font-campton_r md:text-16 text-14">
              <div>
                <p className="font-campton_m text-neutral_black">4.1 Access your personal information </p>
                <p className="mt-2">
                  You may request access to your personal information by contacting us at [email protected].
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">4.2 Correct your personal information:</p>
                <p className="mt-2">
                  You may request that we correct any inaccurate personal information we have about you by contacting us
                  at [email protected].
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">4.3 Delete your personal information:</p>
                <p className="mt-2">
                  You may request that we delete your personal information by contacting us at [email protected].
                </p>
              </div>
              <div className="mt-4">
                <p className="font-campton_m text-neutral_black">4.4 Restrict our use of your personal information:</p>
                <p className="mt-2">
                  You may request that we restrict our use of your personal information by contacting us at [email
                  protected].
                </p>
              </div>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="dataSecurity">
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">5. Data Security</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              We use a variety of security measures to protect your personal information, including encryption and
              access controls.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="changes">
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">6. Changes to this Privacy Policy</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              We may update this Privacy Policy from time to time. If we make any material changes, we will notify you
              by email or by posting a notice on our website.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="contactUs">
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">7. Contact Us</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              If you have any questions about this Privacy Policy, please contact us at [email protected].
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id="telehealth">
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">
              8. Additional Information about Telehealth Privacy
            </p>
            <div className="text-neutral_body font-campton_r mt-1 leading-6 md:text-16 text-14">
              <p>
                In addition to the information in this Privacy Policy, the following information applies to your use of
                our telehealth services:
              </p>
              <ul className="list-disc pl-6">
                <li>
                  Your telehealth sessions are confidential. We will not disclose the contents of your telehealth
                  sessions to anyone without your consent, except as required by law or to protect the safety of you or
                  others.
                </li>
                <li>
                  We use secure technology to protect your telehealth sessions. Your telehealth sessions are encrypted
                  and transmitted over a secure connection.
                </li>
                <li>
                  You have the right to terminate your telehealth session at any time. You may terminate your telehealth
                  session at any time by clicking the
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-span-4 bg-primary_tint_50 px-6 py-2 rounded-2xl h-fit hidden lg:block">
          <ul className="font-campton_r text-14 text-neutral_black list-decimal px-6">
            {privacyPolicy.map((item) => (
              <li className="py-2.5 cursor-pointer" key={item.name}>
                <a href={`#${item.link}`}>{item.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </SlideUp>
  );
};

export default PrivacyPolicy;
