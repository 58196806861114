import { SlideUp } from 'components/Animations';
import StatusCard from 'components/StatusCard';
import React, { useEffect, useState } from 'react';
import { ReactComponent as MedicalHistoryIcon } from 'assets/icons/medicalRecords/medical-history.svg';
import { ReactComponent as Contact } from 'assets/icons/medicalRecords/emergency-contact.svg';
import { ReactComponent as Hospital } from 'assets/icons/medicalRecords/family-hospital.svg';
import { ReactComponent as Immunization } from 'assets/icons/medicalRecords/immunization.svg';
import { ReactComponent as BloodDrop } from 'assets/icons/medicalRecords/blood-type.svg';
import { ReactComponent as SurgeriesIcon } from 'assets/icons/medicalRecords/surgeries.svg';
import { ReactComponent as AllergiesIcon } from 'assets/icons/medicalRecords/allergies.svg';
import { ReactComponent as History } from 'assets/icons/medicalRecords/family-history.svg';
import { ReactComponent as Lifestyle } from 'assets/icons/medicalRecords/lifestyle.svg';
import { ReactComponent as Insurance } from 'assets/icons/medicalRecords/insurance.svg';
import {
  Allergies,
  BloodType,
  EmergencyContact,
  FamilyHistory,
  FamilyHospital,
  ImmunizationHistory,
  MedicalHistory,
  Surgeries,
  InsuranceInfo,
  LifestyleHabit,
} from './Components/Views';

import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import dayjs from 'dayjs';
import { useRecordState } from 'store/modules/myRecords';

const PatientHealthRecord = () => {
  const { patientInfo, loading } = useRecordState();
  const { get_patient_profile } = useMedicalRecord();
  const [view, setView] = useState('');

  useEffect(() => {
    get_patient_profile()
  }, []);

  const views = {
    'Blood Type': BloodType,
    'Family History': FamilyHistory,
    'Family Hospital': FamilyHospital,
    'Emergency Contact': EmergencyContact,
    'Medical History': MedicalHistory,
    Surgeries: Surgeries,
    'Insurance Information': InsuranceInfo,
    'Lifestyle Habit': LifestyleHabit,
    Allergies: Allergies,
    Immunization: ImmunizationHistory,
  };
  const View = views[view];

  return (
    <>
      {!view && <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m my-6">Personal Health Records</h2>}
      <SlideUp>
        {view === '' && (
          <div className="mt-4 grid grid-cols-12">
            <StatusCard
              containerVariant="col-span-6 pb-4 lg:col-span-4 border-r border-neutral_stroke_2 border-b pr-4"
              variant="px-4"
              icon={<BloodDrop />}
              loading={loading}
              title="Blood Type"
              description="Enter your blood group to ensure your health and safety are prioritized."
              status={`${patientInfo?.medicalRecord?.bloodType?.bloodType || patientInfo?.medicalRecord?.bloodType?.genotype ? 1 : 0} type`}
              onClick={() => setView('Blood Type')}
            />
            <StatusCard
              containerVariant="col-span-6 lg:col-span-4 pb-4 lg:border-r border-neutral_stroke_2 px-4 border-b"
              loading={loading}
              variant="px-4"
              icon={<Contact />}
              title="Emergency Contact"
              description="Add your emergency contact(s)to ensure safety and swift communication during critical moments."
              status={patientInfo?.medicalRecord?.emergencyContact?.length}
              onClick={() => setView('Emergency Contact')}
            />
            <StatusCard
              variant="px-4"
              containerVariant="col-span-6 lg:col-span-4 lg:pl-4 pb-4 border-r lg:border-r-0 border-neutral_stroke_2 border-b"
              loading={loading}
              icon={<Hospital />}
              title="Family Hospital"
              description="Providing information about your family hospital will help us with quick access to your essential healthcare information."
              status={patientInfo?.medicalRecord?.familyHospital?.length}
              onClick={() => setView('Family Hospital')}
            />
            <StatusCard
              containerVariant="col-span-6 lg:col-span-4 pr-4 py-4 lg:border-r border-r-0 border-neutral_stroke_2 border-b"
              loading={loading}
              icon={<MedicalHistoryIcon />}
              variant="px-4"
              status={patientInfo?.medicalRecord?.medicalDiagnosis?.length}
              title="Medical History"
              description="Add details about past diagnoses, treatments, and medications for a more informed and personalized healthcare experience."
              onClick={() => setView('Medical History')}
            />
            <StatusCard
              containerVariant="col-span-6 lg:col-span-4 pl-0 lg:p-4 border-r border-neutral_stroke_2 border-b"
              variant="px-4"
              loading={loading}
              icon={<Insurance />}
              status={patientInfo?.medicalRecord?.insuranceInformation?.length}
              title="Insurance Information"
              description="Add your insurance information to ensure hassle-free transactions and coverage."
              onClick={() => setView('Insurance Information')}
            />
            <StatusCard
              variant="px-4"
              containerVariant="col-span-6 lg:col-span-4 pl-4 py-4 border-r-0 border-neutral_stroke_2 border-b"
              loading={loading}
              icon={<SurgeriesIcon />}
              title="Surgeries"
              description="Keep track of important health milestones for a comprehensive overview of your well-being."
              status={patientInfo?.medicalRecord?.surgeries?.length}
              onClick={() => setView('Surgeries')}
            />
            <StatusCard
              containerVariant="col-span-6 pr-4 py-4 border-r border-neutral_stroke_2 border-b"
              variant="px-4"
              loading={loading}
              icon={<AllergiesIcon />}
              status={patientInfo?.medicalRecord?.allergies?.length}
              title="Allergies/Sensitivities"
              description="Add your allergies or sensitivities to ensure a personalized and safe experience."
              onClick={() => setView('Allergies')}
            />
            <StatusCard
              loading={loading}
              variant="px-4"
              containerVariant="col-span-6 pl-4 py-4 border-r-0 border-neutral_stroke_2 border-b"
              icon={<Immunization />}
              title="Immunization History"
              description="keep track of your past vaccinations, ensuring a comprehensive health record at your fingertips."
              status={patientInfo?.medicalRecord?.immunizationHistory?.length}
              onClick={() => setView('Immunization')}
            />
            <StatusCard
              containerVariant="col-span-6 pr-4 py-4 border-r border-neutral_stroke_2"
              variant="px-4"
              loading={loading}
              icon={<History />}
              status={patientInfo?.medicalRecord?.familyHistory?.length}
              title="Family History"
              description="Add family history to document and track relevant health conditions within your family."
              onClick={() => setView('Family History')}
            />
            <StatusCard
              containerVariant="col-span-6 pl-4 py-4 border-r-0 border-neutral_stroke_2"
              variant="px-4"
              icon={<Lifestyle />}
              loading={loading}
              title="Lifestyle Habits"
              description="Keep record of your lifestyle habits so we can recommend the best healthcare options for you."
              date={dayjs(patientInfo?.updatedAt).format('MMM DD, YYYY')}
              onClick={() => setView('Lifestyle Habit')}
            />
          </div>
        )}
      </SlideUp>
      {view && <View selected={view} onClick={() => setView('')} />}
    </>
  );
};

export default PatientHealthRecord;
