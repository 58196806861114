import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import { motion } from 'framer-motion';

const DropdownOptions = ({
  onSelect, search, handleChange, setSearch, setShowDropdown,
  optionContainerVariant, type, options, optionVariant, handleSelect, placeholder, loading
}) => {
  return (
    <motion.div
      animate={{ opacity: 1, y: '0px' }}
      initial={{ opacity: 0, y: '-10px' }}
      className="absolute left-0 top-[100%] w-full z-20 rounded-b-[8px] border-x border-b
      border-brand_primary bg-neutral_white drop-shadow-md lg:drop-shadow-md"
      data-testid="dropdown-modal"
    >
      {['country', 'search'].includes(type) ?
        <div>
          <input
            id="searh"
            name="search"
            type="text"
            placeholder="Search"
            value={search}
            onChange={handleChange}
            className={`
                h-[40px] px-[16px] text-black text-14 w-[94%] outline-0 font-inter hide_tap
                rounded-[8px] font-campton_r
              `}
          />
        </div> : ''
      }
      <div
        className={`
          ${optionContainerVariant} py-[8px] px-[16px] max-h-[216px]
          overflow-auto border-t border-t-neutral_stroke_2
        `}
      >
        {loading && options?.length === 0 && <Spinner />}
        {!loading && !options?.length && <p className="text-center font-campton_r">No data found</p>}
        {options?.map((item) => (
          <div
            key={item?.label}
            className={`${optionVariant}
            ${item.styles} ${type && 'flex justify-between'}
            hover:bg-[#F2F3F3] cursor-pointer font-campton_r py-[11px] px-[20px] text-clip truncate 
            `}
            onClick={() => handleSelect(item)}
            data-testid={item?.label}
          >
            <p className="text-neutral_black text-14 font-campton_r capitalize truncate">
              {item?.label ?? <span className="text-grey">{placeholder}</span>}
            </p>
            {type === 'country' && (
              <img
                src={`https://flagcdn.com/16x12/${item?.code}.png`}
                srcSet={`https://flagcdn.com/48x36/${item?.code}.png 2x`}
                width="22"
                height="12"
                alt="South Africa"
              />
            )}
            {type === 'search' &&
              options?.map((option) => (
                <div
                  key={option?.id || option?.name}
                  data-testid={option?.name}
                  onClick={() => {
                    onSelect({
                      name: option?.name,
                      value: option?.value || option.code,
                      id: option.id,
                    });
                    setSearch('')
                    setShowDropdown(false);
                  }}
                  className={`${optionVariant} py-[11px] px-[20px] flex items-center justify-between
                    cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3]`}
                >
                  <div className="flex items-center">
                    <p className="text-16 font-campton_r">{option?.name}</p>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default DropdownOptions;
DropdownOptions.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  search: PropTypes.string,
  handleChange: PropTypes.func,
  setSearch: PropTypes.func,
  setShowDropdown: PropTypes.func,
  optionContainerVariant: PropTypes.string,
  optionVariant: PropTypes.string,
  handleSelect: PropTypes.any,
  type: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
};
