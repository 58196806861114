import Button from 'components/Buttons/Button';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import useAuth from 'hooks/fetch/useAuth';
import React, { useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import PropTypes from "prop-types"

const ResetPasswordForm = ({invitedUser}) => {
  const { reset_password, create_password } = useAuth()
  const { loading, recoverUser } = useAuthState()
  const [formData, setFormData] = useState({
    emailAddress: invitedUser || recoverUser?.emailAddress,
    password: '',
    confirm_password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    if(invitedUser) {
      create_password(formData)
    } else {
      reset_password(formData)
    }
  }
  return (
    <FormWrapper
      title="Reset Password"
      description={
        <>
          Just one last step
          <br /> <span className='text-primary_shade_400 font-campton_sb'>Create a new password account!</span>
        </>
      }
      caption="Password should be a minimum of 8 characters"
      className="sm:h-full md:mt-12 mt-8 w-full sm:w-[387px] mx-auto"
      onSubmit={handleSubmit}
    >
      <div className="flex justify-center items-center flex-col w-full max-w-md mx-auto md:mt-12 mt-8">
        <div className="mb-6 w-full">
          <Input
            label="New Password"
            type="password"
            id="password"
            name="password"
            readOnly={loading}
            value={formData?.password}
            onChange={handleChange}
          />
        </div>
       
        <div className="mb-6 w-full">
          <Input
            label="Confirm Password"
            type="password"
            id="confirm_password"
            name="confirm_password"
            readOnly={loading}
            value={formData?.confirm_password}
            onChange={handleChange}
          />
        </div>
        <Button
          name="Save"
          theme="blue"
          className="w-full"
          disabled={(formData?.password !== formData.confirm_password) || !formData.confirm_password || loading}
          loading={loading}
        />
      </div>
    </FormWrapper>
  );
};

export default ResetPasswordForm;
ResetPasswordForm.propTypes = {
  invitedUser: PropTypes.string
}
