import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowBack } from 'react-icons/io';

export default function FormWrapper({
  onSubmit,
  variant,
  children,
  className,
  autoComplete,
  title,
  step,
  onClick,
  showBackbutton = false,
  caption,
  description,
}) {
  const submitForm = (e) => {
    e.preventDefault();
    if (onSubmit) onSubmit();
  };

  return (
    <form autoComplete={autoComplete} data-testid="form" className={className} onSubmit={submitForm}>
      {title || description ?
        <div className={` ${variant || 'text-center'}`}>
          {showBackbutton && step === 1 && title && <div
            className={` md:mb-10 mb-6 ${showBackbutton && step === 1 ? 'flex items-center justify-between lg:w-3/4 w-2/3' : 'w-full'
              }`}
          >
            {showBackbutton && step === 1 && (
              <div onClick={onClick} className="block p-2 bg-neutral_disabled rounded-full">
                <IoIosArrowBack className="text-24" />
              </div>
            )}
            {title && <h5 className="text-18 font-campton_m text-primary_300 ">{title}</h5>}
          </div>
          }
          {description && (
            <p className="md:text-24 text-20 font-campton_r leading-7 md:mb-2 text-[#748181]">{description}</p>
          )}
          {caption && <p className="text-14 md:text-16 text-neutral_body font-campton_r">{caption}</p>}
        </div> : ''
      }
      {children}
    </form>
  );
}

FormWrapper.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  title: PropTypes.any,
  step: PropTypes.number,
  showBackbutton: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  caption: PropTypes.any,
  description: PropTypes.any,
};
