import React, { useState } from 'react';
import { Input } from 'components/Inputs';
import PropTypes from 'prop-types';
import RadioButtonSelect from 'components/RadioButtonSelect';

const StressManagement = ({ formData, setFormData, type }) => {
  //Local State
  const [relaxationTechniques, setRelaxationTechniques] = useState(formData[type].relaxationTechniques);
  const [specificStressors, setSpecificStressors] = useState(formData[type].specificStressors);

  //Handler function
  const handleRelaxationTechniques = (name) => {
    setRelaxationTechniques(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], relaxationTechniques: name}}));

  };
  const handleSpecificStressors = (name) => {
    setSpecificStressors(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], specificStressors: name}}));

  };
  return (
  <div className="my-4">
  <div className='mb-4'>
    <Input
      label="How do you manage stress?"
      type="text"
      id="method"
      name="method"
      value={formData[type]?.method}
      onChange={(e) => setFormData((formData) => ({ ...formData, [type]: { ...formData[type], method: e.target.value } }))}

      showError={false}
    />
    </div>
    <div className='mb-4'>
       <p className="font-campton_r text-14 text-neutral_black mb-2">Do you engage in any relaxation techniques?</p>
          <div className="grid grid-cols-12 gap-4">
            <RadioButtonSelect
              label="Yes"
              variant="col-span-6"
              isChecked={relaxationTechniques === 'yes'}
              onChange={() => handleRelaxationTechniques('yes')}
            />
            <RadioButtonSelect
              label="No"
              variant="col-span-6"
              isChecked={relaxationTechniques === 'no'}
              onChange={() => handleRelaxationTechniques('no')}
            />
          </div>
        </div>
        <div className='mb-4'>
       <p className="font-campton_r text-14 text-neutral_black mb-2">Are there any specific stressors in your life that you would like to address?</p>
          <div className="grid grid-cols-12 gap-4">
            <RadioButtonSelect
              label="Yes"
              variant="col-span-6"
              isChecked={specificStressors === 'yes'}
              onChange={() => handleSpecificStressors('yes')}
            />
            <RadioButtonSelect
              label="No"
              variant="col-span-6"
              isChecked={specificStressors === 'no'}
              onChange={() => handleSpecificStressors('no')}
            />
          </div>
        </div>
  </div>
  )
}

export default StressManagement
StressManagement.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};