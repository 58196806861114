import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'components/Slider';
import RedirectIfToken from 'HOC/RedirectIfToken';
import { ReactComponent as DarkLogo } from '../assets/svg/logo-dark.svg';
import { useDispatch } from 'react-redux';
import { getCountries } from 'store/modules/auth/actions';

export default function AuthLayout({ center, right }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  return (
    <RedirectIfToken>
      <div className={`${center ? 'block' : 'flex'} relative py-0 h-screen`}>
        <div className="hidden lg:block w-1/2 bg-brand_blue h-screen left-0 top-0 z-10 overflow-y-scroll">
          <Slider />
        </div>
        {center && <div className="md:px-32 px-4">{center}</div>}
        {right && (
          <div className="w-full lg:w-1/2 h-full overflow-x-hidden overflow-y-auto py-4 flex-col lg:flex justify-center items-center ">
            <div className="p-6 sm:p-12 mb-12 block lg:hidden self-baseline">
              <DarkLogo />
            </div>
            <div className="md:mx-auto">
              <div className="w-full sm:max-w-[450px] px-6 sm:mx-auto">{right}</div>
            </div>
          </div>
        )}
      </div>
    </RedirectIfToken>
  );
}

AuthLayout.propTypes = {
  left: PropTypes.bool,
  center: PropTypes.element,
  backButton: PropTypes.string,
  right: PropTypes.element,
  to: PropTypes.string,
};
