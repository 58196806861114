export const removeSpecificKeys = (obj, keys) => {
  Object.keys(obj).forEach(key => {
    if (keys && keys.includes(key)) delete obj[key]
    if (obj[key] === "") delete obj[key]
  });
  return obj;
}

export const removeEmptyStringKeys = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the value is an object, recursively call the function
      const nestedObj = removeEmptyStringKeys(obj[key]);

      // Add the nested object even if it's empty
      newObj[key] = nestedObj;
    } else {
      // If the value is not an object, check for empty strings
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
  });

  // Check if the object is not empty after removing keys with empty strings
  if (Object.keys(newObj).length === 0) {
    return {}; // Return an empty object if all keys were empty strings
  }

  return newObj;

}
