import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SlideUp } from "components/Animations";
import Avatar from "components/Avatar/Avatar";
import Tests from "./Tests";
import ChiefComplaint from "./ChiefCompliant";
import PhysicalExamination from "./PhysicalExamination";
import ClinicalAssessment from "./ClinicalAssessment";
import TreatmentPlan from "./TreatmentPlan";
import Button from "components/Buttons/Button";
import { VisitContext } from "context";
import { createPortal } from 'react-dom';

export default function SingleVist({ onClick, setSelectedResult }) {
    const { visit } = useContext(VisitContext)

    return (
        <SlideUp>
            <div>
                <div className="p-[16px] rounded-[16px] border border-neutral_stroke_2 mb-[16px]">
                    <p className="text-12 font-campton_r text-neutral_body mb-[8px]">Patient Information</p>
                    <div className='flex flex-col items-center sm:flex-row mb-[16px] sm:mb-0'>
                        <Avatar
                            url={visit?.patient?.profilePic || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTga82Zqlb6VqnCZcxG2ur7hI6Fw8aJTtJLvDhiunx0AxCd6j8lmMVt1cIA-Tw-S3xV754&usqp=CAU'}
                            width="48px"
                            height="48px"
                            className="w-[48px] h-[48px] shrink-0"
                        />
                        <div className='ml-[16px] mt-[12px] sm:mt-0 text-left sm:flex justify-between w-full'>
                            <div>
                                <p className='text-14 font-campton_m text-neutral_black capitalize'>{visit?.patient?.firstName} {visit?.patient?.lastName}</p>
                                <p className='text-14 font-campton_r text-neutral_body'>{visit?.patient?.emailAddress}</p>
                            </div>
                            <p className='text-14 font-campton_r'>{visit?.patient?.gNumber}</p>
                        </div>
                    </div>
                </div>
                <div className="border border-neutral_stroke_2 px-[16px] rounded-[16px] mb-[60px]">
                    <ChiefComplaint />
                    <PhysicalExamination />
                    <Tests setSelectedResult={setSelectedResult} />
                    <ClinicalAssessment />
                    <TreatmentPlan />
                </div>
                {createPortal(
                    <>
                        {visit?.status === 'completed' &&
                            <Button
                                name='View visit summary'
                                theme="blue"
                                className="h-[32px] fixed bottom-[20px] sm:bottom-[60px] right-[22px] sm:right-[60px] sm:block"
                                onClick={onClick}
                                type="button"
                            />
                        }
                    </>,
                    document.body
                )}
            </div>
        </SlideUp >
    )
}

SingleVist.propTypes = {
    onClick: PropTypes.func,
    setSelectedResult: PropTypes.func
}