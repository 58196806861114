import BloodPressure from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/BloodPressure';
import BMI from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/BMI';
import HeartRate from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/HeartRate';
import Height from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/Height';
import OxygenSaturation from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/OxygenSaturation';
import RespiratoryRate from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/RespiratoryRate';
import Temperature from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/Temperature';
import Weight from 'pages/Dashboard/More/Tabs/Vitals/Component/VitalSignsAnalytics/Weight';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import useMedicalRecord from './fetch/useMedicalRecord';

export default function useVitalsAnalytics({ className }) {
  const [currentTab, setCurrentTab] = useState(0);
  const {get_vitals, loading} = useMedicalRecord()
  const {loggedUser} = useAuthState()
  const [filteredVitalsHistory, setFilteredVitalsHistory] = useState([]);
  const [vitalsHistory, setVitalsHistory] = useState([]);

  const tabs = [
    {
      id: 0,
      name: 'Blood pressure',
      type: 'bloodPressure',
      component: (
        <BloodPressure
          className={className}
          analyticsData={filteredVitalsHistory}
          recentVitals={
            vitalsHistory?.[0]?.sysBloodPressure
              ? `${vitalsHistory?.[0]?.sysBloodPressure}/${vitalsHistory?.[0]?.diaBloodPressure}`
              : ''
          }
        />
      ),
    },
    {
      id: 1,
      name: 'Heart rate trend',
      type: 'heartRate',
      component: (
        <HeartRate
          className={className}
          analyticsData={filteredVitalsHistory}
          recentVitals={vitalsHistory?.[0]?.heartRate}
        />
      ),
    },
    {
      id: 2,
      name: 'Respiratory trend',
      type: 'respiratoryRate',
      component: (
        <RespiratoryRate
          className={className}
          analyticsData={filteredVitalsHistory}
          recentVitals={vitalsHistory?.[0]?.respiratoryRate}
        />
      ),
    },
    {
      id: 3,
      name: 'Temperature trend',
      type: 'temperature',
      component: (
        <Temperature
          className={className}
          analyticsData={filteredVitalsHistory}
          recentVitals={vitalsHistory?.[0]?.temperature}
        />
      ),
    },
    {
      id: 4,
      name: 'Oxygen saturation trend',
      type: 'oxygenSaturation',
      component: (
        <OxygenSaturation
          className={className}
          analyticsData={filteredVitalsHistory}
          recentVitals={vitalsHistory?.[0]?.oxygenSaturation}
        />
      ),
    },
    {
      id: 5,
      name: 'Weight trend',
      type: 'weight',
      component: (
        <Weight className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory?.[0]?.weight} />
      ),
    },
    {
      id: 6,
      name: 'Height trend',
      type: 'height',
      component: (
        <Height className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory?.[0]?.height} />
      ),
    },
    {
      id: 7,
      name: 'Body mass index trend',
      type: 'bmi',
      component: (
        <BMI
          className={className}
          analyticsData={filteredVitalsHistory}
          recentVitals={Number(vitalsHistory?.[0]?.bmi)?.toFixed(2)}
        />
      ),
    },
  ];

  const type = tabs[currentTab].type;

  const selectedTab = tabs[currentTab];
  const getVitals = () => {
    get_vitals( loggedUser?._id, type ).then((res) => {
      setFilteredVitalsHistory(res?.data?.filteredVitalsHistory.slice(0, 10));
      setVitalsHistory(res?.data?.vitalsHistory);
    });
  }
  const toggleTab = () => {
    if (currentTab + 1 === tabs.length) return setCurrentTab(0);
    setCurrentTab((prevState) => prevState + 1);
  };

  useEffect(() => {
    getVitals()
  }, [type]);

  return { loading, selectedTab, tabs, toggleTab, getVitals, filteredVitalsHistory, vitalsHistory };
}
