import React, { useMemo, useState } from "react";
import PropTypes from 'prop-types';
import Button from "components/Buttons/Button";
import Dropdown from "components/Dropdown/Index";
import FormWrapper from "components/FormWrapper";
import { Input } from "components/Inputs";
import Phone from "components/Inputs/PhoneInput";
import TextArea from "components/Inputs/TextArea";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import DefaultDatepicker from "components/Datepicker/Index";
import useAuth from "hooks/fetch/useAuth";

export default function EditProfile({ cancel }) {
    const { update_userInfo, loading } = useAuth();
    const { loggedUser, countryList } = useSelector(state => state.auth);
    const [phone, setPhone] = useState(loggedUser?.phoneNumber ? `${loggedUser?.countryCode + loggedUser?.phoneNumber}` : '');
    const [formData, setFormData] = useState({
        firstName: loggedUser?.firstName,
        lastName: loggedUser?.lastName,
        dateOfBirth: new Date(loggedUser?.dateOfBirth),
        country: loggedUser?.country,
        homeAddress: loggedUser?.homeAddress,
        countryCode: loggedUser?.countryCode,
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const editProfile = () => {
        update_userInfo(
            {
                firstName: formData?.firstName.trim(),
                lastName: formData?.lastName.trim(),
                country: formData?.country,
                homeAddress: formData?.homeAddress.trim(),
                dateOfBirth: dayjs(formData.dateOfBirth).format('YYYY-MM-DD')
            },
            cancel
        );
    }

    const countries = useMemo(() => {
        return countryList ? countryList?.map((item, index) => {
            return {
                id: index,
                value: item?.name,
                code: item?.iso2.toLowerCase(),
                label: item?.name,
            }
        }) : []
    }, [countryList])

    return (
        <FormWrapper className="relative" onSubmit={editProfile}>
            <div className="modal-right-body">
                <div className="mb-[24px] w-full sm:mr-[24px]">
                    <Input
                        label="First Name"
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData?.firstName}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[24px] w-full">
                    <Input
                        label="Last Name"
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData?.lastName}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[24px] w-full sm:mr-[24px]">
                    <Input
                        label="Email Address"
                        type="text"
                        id="emailAddress"
                        name="emailAddress"
                        disabled={true}
                        value={loggedUser?.emailAddress}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[24px] w-full sm:mr-[24px]">
                    <Phone value={phone} onChange={setPhone} disabled={true} />
                </div>
                <div className="mb-[24px] w-full sm:mr-[24px]">
                    <DefaultDatepicker
                        label="Date of Birth"
                        containerVariant="lg:col-span-4 md:col-span-6 col-span-12"
                        name="dateOfBirth"
                        startDate={formData.dateOfBirth}
                        showCalender={true}
                        onSelect={(data) => {
                            setFormData({ ...formData, dateOfBirth: new Date(data) });
                        }}
                    />
                </div>
                <div className="mb-[24px] w-full">
                    <Dropdown
                        label="Country"
                        id="country"
                        name="country"
                        type='country'
                        options={countries}
                        //position="static"
                        value={{ label: formData?.country }}
                        onChange={(data) => setFormData({ ...formData, country: data.value })}
                    //onSelect={selectLocation}
                    />
                </div>
                <div>
                    <TextArea
                        label="Address"
                        id="homeAddress"
                        name="homeAddress"
                        value={formData?.homeAddress}
                        onChange={handleChange}
                        rows={2}
                    />
                </div>
            </div>
            <div className="modal-right-button-container">
                <Button
                    name="Cancel"
                    theme="transparent"
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                    type="button"
                    onClick={cancel}
                    disabled={loading}
                />
                <Button
                    name="Save Changes"
                    theme="blue"
                    className="h-[40px] text-16 w-[131px]"
                    disabled={!formData.firstName || !formData.lastName || loading}
                    loading={loading}
                />
            </div>
        </FormWrapper>
    )
}

EditProfile.propTypes = {
    cancel: PropTypes.func,
};