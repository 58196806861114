import Navbar from 'components/Navbar';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Illustration } from 'assets/svg/appointment/illustration.svg';
import Tabs from 'components/Tabs/Tabs';
import { ReactComponent as EmptyAppointment } from 'assets/svg/appointment/empty-appointment.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import useTable from 'hooks/useTable';
import { setLiveAppointment, useAppointmentState } from 'store/modules/appointments';
import useAppointment from 'hooks/fetch/useAppointment';
import AppointmentList from './Components/AppointmentList';
import DefaultModal from 'components/Modals/DefaultModal';
import ViewAppointment from './ViewAppointment';
import Spinner from 'components/Spinner';
import { useAuthState } from 'store/modules/auth';
import { useDispatch } from 'react-redux';
import useCheckLiveCallAtIntervals from 'hooks/useCheckLiveCallAtIntervals';
import Table from 'components/Table';

const Appointments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('upcoming');
  const { loggedUser } = useAuthState();
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { appointments } = useAppointmentState();
  const { get_appointments, loading } = useAppointment();
  const { currentCall, liveCall } = useCheckLiveCallAtIntervals(appointments?.appointments);
  const { params, changePage, selectLimit } = useTable();

  let tabsHeader = [
    { id: 1, name: 'upcoming' },
    { id: 2, name: 'ongoing' },
    { id: 3, name: 'cancelled' },
    { id: 4, name: 'history' },
    { id: 5, name: 'rescheduled' },
  ];

  const handleSingleView = (item) => {
    setSelectedAppointment(item);
    setShowModal(true);
  };

  useEffect(() => {
    get_appointments({
      page: params.page,
      limit: params.limit,
      requestType: 'patient',
      patientId: loggedUser?._id,
      status: selectedTab,
    });
  }, [selectedTab, params.page, params.limit]);

  const joinCall = (roomId) => {
    if (!currentCall?.liveCallId) return;
    dispatch(setLiveAppointment(currentCall));
    const anchor = document.createElement('a');
    anchor.href = `/appointments/live/${roomId}`;
    anchor.target = '_blank'
    anchor.click();
  };

  return (
    <div>
      <Navbar title="Appointments" />
      <div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 px-6 pt-6">
          <div className="bg-brand_primary p-6 text-neutral_white rounded-2xl flex justify-between">
            <div>
              <p className="text-14 sm:text-16 font-campton_m">Book Appointment</p>
              <p className="text-12 sm:text-14 font-campton_r">Schedule a consultation with our providers today!</p>
              <button
                onClick={() => navigate('/appointments/book-appointment')}
                className="flex justify-center items-center h-8 px-4 text-12 sm:text-14 
              font-campton_m rounded-full bg-neutral_white w-fit mt-4 text-neutral_black cursor-pointer"
              >
                Book Appointment
              </button>
            </div>
            <div>
              <Illustration className="w-20 md:w-40" />
            </div>
          </div>
          {currentCall && liveCall ? (
            <div className="bg-light_blue p-6 text-brand_blue rounded-2xl flex justify-between flex-col">
              <p className="text-12 sm:text-14 font-campton_r md:w-3/4 w-full">
                You have an ongoing tele-health appointment with{' '}
                <span className="capitalize">
                  {currentCall?.providerInfo?.title}. {currentCall?.providerInfo?.lastName}{' '}
                  {currentCall?.providerInfo?.firstName}
                </span>
              </p>
              <button
                className="flex justify-center items-center h-8 text-12 sm:text-14 cursor-pointer
              font-campton_m capitalize rounded-[100px] hide_tap w-fit mt-3 text-success"
                onClick={() => joinCall(currentCall?.liveCallId)}
              >
                <span>Join call</span> <ArrowRight className="ml-2" fill="#23CE6B" />
              </button>
            </div>
          ) : (
            <div className="bg-light_blue p-6 text-brand_blue rounded-2xl flex justify-center items-center text-center">
              <p className="text-12 sm:text-14 font-campton_m w-full">
                You currently have no ongoing tele-health appointment.
              </p>
            </div>
          )}
        </div>
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabsHeader} />
        <div className='h-[54vh] overflow-y-auto'>
          <Table
            hasTable={false}
            hasFilter={false}
            data={appointments?.appointments}
            loading={loading}
            totalCount={appointments?.allCount}
            perPage={appointments?.limit}
            currentPage={appointments?.page}
            selectLimit={selectLimit}
            // tableContainer='pl-[10px]'
            changePage={changePage}
            emptyState={<div className="flex justify-center items-center flex-col h-[38vh]">
              <EmptyAppointment />
              <div className="mt-10">
                <p className="text-black_100 text-base md:text-18 font-campton_m text-center">
                  It’s Quiet Over Here.
                </p>
                <p className="text-neutral_body text-center text-14 md:text-16 w-3/4 mx-auto font-campton_r">
                  You currently have no {selectedTab} appointment. All upcoming appointment will be displayed here.
                </p>
              </div>
            </div>}
          >
            {loading ? <Spinner className='h-[30vh]' /> :
              <div>
                {appointments?.appointments?.length ? (
                  <div className="pl-3 pr-6 pt-5">
                    {appointments?.appointments?.map((item, i) => (
                      <AppointmentList
                        key={item?._id}
                        item={item}
                        lastItem={appointments?.appointments?.length === i + 1}
                        handleSingleView={handleSingleView}
                      />
                    ))}
                  </div>
                ) : ''}
              </div>
            }
          </Table>
        </div>
        {showModal && (
          <DefaultModal
            closeModal={() => setShowModal(false)}
            styles="w-full md:w-[457px]"
            position="modal-right"
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
            title="Appointment Details"
          >
            <ViewAppointment data={selectedAppointment} callback={() => setShowModal(false)} selectedTab={selectedTab} />
          </DefaultModal>
        )}
      </div>
    </div>
  );
};

export default Appointments;
