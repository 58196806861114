import DefaultDatepicker from 'components/Datepicker/Index';
import Dropdown from 'components/Dropdown/Index';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import { setUserInfo, useAuthState } from 'store/modules/auth';
import useAuth from 'hooks/fetch/useAuth';
import dayjs from 'dayjs';
import { useAppDispatch } from "hooks/useReduxHook";
import useValidateFormData from 'hooks/useValidateFormData';

const ProfileSetUpForm = () => {
  const { userInfo, loading, countryList, isLoadingCountry } = useAuthState();
  const { validateFormData } = useValidateFormData()
  const { create_account } = useAuth()
  const dispatch = useAppDispatch();
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState();
  const [formData, setFormData] = useState({
    emailAddress: '',
    homeAddress: '',
    phoneNumber: phone,
    country: '',
    dateOfBirth: '',
    password: '',
  });

  const signUp = () => {
    create_account({ ...formData, ...userInfo, dateOfBirth: dayjs(formData?.dateOfBirth).format('YYYY-MM-DD') }, () => dispatch(setUserInfo({})))
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const countries = useMemo(() => countryList?.map((item, index) => {
    return {
      id: index,
      value: item?.name,
      code: item?.iso2.toLowerCase(),
      label: item?.name,
    };
  }), [countryList])

  const formDisabled = useMemo(() => {
    return !validateFormData({ email: formData.emailAddress, password: formData.password }) ||
    !formData.homeAddress || !formData.dateOfBirth || !formData.country || !userInfo.phoneNumber
  }, [formData, userInfo])

  return (
    <div className=''>
      <div className='lg:overflow-scroll lg:h-[280px] xl:max-h-full  max-h-full pt-1'>
        <div className="mb-6">
          <Input
            label="Email"
            type="text"
            id="email"
            name="emailAddress"
            readOnly={loading}
            value={formData?.emailAddress}
            onChange={handleChange}
          />
        </div>
        <Phone value={phone} onChange={setPhone} />
        <DefaultDatepicker
          label="Date of Birth"
          startDate={formData?.dateOfBirth}
          onSelect={(e) => setFormData({ ...formData, dateOfBirth: e })}
          name="dateOfBirth"
          maxDate={new Date()}
          value={formData?.dateOfBirth}
        />
        <Dropdown
          label={isLoadingCountry ? 'Loading Countries' : 'Country'}
          variant="mb-6"
          name="country"
          type='country'
          id="country"
          value={country}
          disabled={isLoadingCountry}
          optionContainerVariant="w-full md:min-h-[150px] min-h-[250px]"
          options={countries}
          onChange={(data) => {
            setCountry(data);
            setFormData({ ...formData, country: data.value });
          }}
        />
        <div className="mb-6">
          <Input
            label="Address"
            type="text"
            id="homeAddress"
            name="homeAddress"
            readOnly={loading}
            value={formData?.homeAddress}
            onChange={handleChange}
            showError={false}
          />
        </div>
        <div className="mb-10">
          <Input
            label="Password"
            type="password"
            id="password"
            name="password"
            readOnly={loading}
            value={formData?.password}
            onChange={handleChange}
          />
        </div>
      </div>
      <Button
        name="Next"
        theme="blue"
        onClick={signUp}
        className="w-full"
        disabled={formDisabled}
        loading={loading}
      />
    </div>
  );
};

export default ProfileSetUpForm;
ProfileSetUpForm.propTypes = {
  data: PropTypes.object
}