import React from "react";
import PropTypes from "prop-types"
import dayjs from 'dayjs';
import { ReactComponent as Timer } from 'assets/icons/timer.svg';

export default function Thread({ createdAt, children }) {
    return (
        <div>
            <div className="flex items-center">
                <Timer />
                <p className="text-12 text-neutral_body ml-[16px] uppercase">{dayjs(createdAt).format('MMM DD, YYYY, hh:mm A')}</p>
            </div>
            <div className="border-dashed border-l border-l-neutral_stroke_2 ml-[15px]">
                <div className="border border-neutral_stroke_2 rounded-[8px] p-[16px] ml-[32px]">
                    {children}
                </div>
            </div>
        </div>
    )
}

Thread.propTypes = {
    createdAt: PropTypes.string,
    children: PropTypes.element
}