import Accordion from "components/Accordion";
import EmptyState from "components/EmptyState";
import { ReactComponent as Examination } from "assets/svg/examination.svg";
import { VisitContext } from "context";
import React, { useContext } from "react";

export default function PhysicalExamination() {
    const { visit, progressNote } = useContext(VisitContext)
    const examination = progressNote?.physicalExamination?.examination || visit?.physicalExamination?.examination
    
    return (
        <Accordion
            display={false}
            title={<p className="text-14 text-neutral_body font-campton_r">Physical Examination</p>}
            className="mb-0 border-b border-b-neutral_stroke_2"
        >
            {
                examination?.length ?
                    examination?.map((item, i) =>
                        <div key={item?._id} className={`${i === 0 ? 'pb-[16px]' : 'py-[16px]'} border-b border-neutral_stroke_2`}>
                            <p className='text-14 text-neutral_black font-campton_m mb-[8px]'>{item?.title}</p>
                            <p className='text-14 text-neutral_body font-campton_r leading-[21px]'>{item?.observation}</p>
                        </div>
                    ) :
                    <EmptyState
                        illustration={<Examination />}
                        title="No Examination."
                        description="Your examination results will be displayed here."
                    />
            }
        </Accordion>
    )
}