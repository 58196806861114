import { createAsyncThunk } from '@reduxjs/toolkit'
import instance from 'services/axios-instance'
import { auth } from 'services/endpoints'

const customAsyncThunk = (thunkName, method, url) =>
    createAsyncThunk(thunkName, async (payload) => {
    try {
        const body = { ...payload }
        const params = payload?.params ? payload?.params : ''
        const response = await instance[method || 'get'](`${url}/${params}`, body)
        if (response?.data) return response.data ? response.data : {}
        if (response?.response?.data) return response.response.data ? response.response.data : {}
    } catch (error) {
        return error
    }
})

const forgotPassword = customAsyncThunk('auth/forgotPassword', 'post', auth.forgot_password)
const login = customAsyncThunk('auth/login', 'post', auth.login)
const logout = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'logout' })
})
const verifyAccount = customAsyncThunk('auth/verifyAccount', 'post', auth.verify_account)
const validateResetPasswordCode = customAsyncThunk('auth/validateResetPasswordCode', 'post', auth.validate_reset_password_code)
const createAccount = customAsyncThunk('auth/createAccount', 'post', auth.create_account)
const refreshToken = customAsyncThunk('auth/refreshToken', 'post', auth.refresh_token);
const resetPassword = customAsyncThunk('auth/resetPassword', 'patch', auth.reset_password)
const createPassword = customAsyncThunk('auth/createPassword', 'post', auth.create_password)
const resendOtp = customAsyncThunk('auth/resendOtp', 'post', auth.resend_otp)
const updateUserInfo = customAsyncThunk('auth/updateUserInfo', 'patch', auth.update_user_info)
const getCountries = customAsyncThunk('auth/Countries', 'get', 'https://countriesnow.space/api/v0.1/countries/positions')

export {
    forgotPassword,
    createAccount,
    verifyAccount,
    resetPassword,
    resendOtp,
    refreshToken,
    login,
    createPassword,
    updateUserInfo,
    getCountries,
    validateResetPasswordCode,
    logout
}