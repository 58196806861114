const base = '/patient-profile'

export const patient = {
  visits: `${base}/visits`,
  visit: `${base}/visit`,
  lab_test: `${base}/lab-test`,
  progress_note: `/progress-note`,
  procedure: '/procedure',
  referral:  '/referral' ,
  therapy: '/therapy',
  medications: '/medications/patient'
};
