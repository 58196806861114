import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Download } from 'assets/icons/download.svg';

export default function Upload({ setFile, fileRef, loading, id }) {
    return (
        <div className="p-7 border border-dashed border-[#DFE2E2] rounded-lg w-full font-campton_r text-center mb-[24px]">
            <label htmlFor={id} data-testid="file" className="cursor-pointer">
                <div>
                    <Download className="mx-auto mb-5" />
                    <p className="text-14 font-campton_m">
                        <span className="underline text-[#36A1C5] text-14">Click to upload</span>
                    </p>
                    <p className="font-campton_m text-neutral_body text-12 mt-0.5">Supported format: JPEG, PDF, PNG</p>
                </div>
            </label>
            <input
                data-testid="test-file"
                type="file"
                id={id}
                name="file"
                accept="image/png, image/jpg, image/jpeg, pdf"
                className="hidden"
                ref={fileRef}
                readOnly={loading}
                onChange={(e) => setFile(e.target.files[0])}
            />
        </div>
    )
}

Upload.propTypes = {
    setFile: PropTypes.func,
    fileRef: PropTypes.any,
    loading: PropTypes.bool,
    id: PropTypes.string,
}