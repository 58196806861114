import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'
import { patient, medicalRecord } from 'services/endpoints'
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/axios-instance'

const getVisits = createCustomAsyncThunk('myRecords/getVisits', 'get', patient.visits)
const getOngoingVisit = createCustomAsyncThunk('myRecords/getOngoingVisit', 'get', patient.visits)
const getProgressNotes = createCustomAsyncThunk('myRecords/getProgressNotes', 'get', patient.progress_note)
const getSingleProgressNote = createCustomAsyncThunk('myRecords/getSingleProgressNote', 'get', patient.progress_note)
const getSingleVisit = createCustomAsyncThunk('myRecords/getSingleVisit', 'get', patient.visit)
const getLabTests = createCustomAsyncThunk('myRecords/getLabTests', 'get', patient.lab_test)
const addLabTest = createCustomAsyncThunk('myRecords/addLabTest', 'post', patient.lab_test)
const getPendingLabTest = createCustomAsyncThunk('myRecords/getPendingLabTest', 'get', patient.lab_test)
const uploadTestResult = createCustomAsyncThunk('myRecords/uploadTestResult', 'patch', patient.visit)
const getPatientProfile = customAsyncThunk('myRecords/getPatientProfile', 'get', medicalRecord.get_patient_profile)
const updateMedicalRecord = createCustomAsyncThunk('myRecords/updateMedicalRecord', 'post', medicalRecord.update_medical_record)
const getVitals = createCustomAsyncThunk('myRecords/getVitals', 'get', medicalRecord.get_vitals_history)
const editMedicalRecord = customAsyncThunk('myRecords/editMedicalRecord', 'patch', medicalRecord.update_medical_record)
const requestUploadUrl = customAsyncThunk('myRecords/requestUploadUrl', 'post', medicalRecord.request_upload_url)
const createVitals = customAsyncThunk('myRecords/createVitals', 'post', medicalRecord.create_vitals)
const updateLifestyle = customAsyncThunk('myRecords/updateLifestyle', 'patch', medicalRecord.update_liftstyle)
const deleteMedicalRecord = createAsyncThunk('myRecords/deleteMedicalRecord', async (payload) => {
    try {
        const response = await api.delete(`${medicalRecord.update_medical_record}?type=${payload.type}&id=${payload.id}`)
        return response.data ? response.data : {}
    } catch (error) {
        return Promise.reject(error)
    }
})
const getTherapies = createCustomAsyncThunk('myRecords/getTherapies', 'get', patient.therapy)
const getReferrals = createCustomAsyncThunk('myRecords/getReferrals', 'get', patient.referral)
const getProcedure = createCustomAsyncThunk('myRecords/getProcedure', 'get', patient.procedure)
const getMedications = createCustomAsyncThunk('myRecords/getMedications', 'get', patient.medications)
const createMedications = customAsyncThunk('myRecords/createMedication', 'post', patient.medications)
const editMedications = customAsyncThunk('myRecords/editMedications', 'patch', patient.medications)
const getVisitTreatmentPlan = createCustomAsyncThunk('myRecords/getVisitTreatmentPlan', 'get', medicalRecord.treatment_plan)
const getVisitLabTest = createCustomAsyncThunk('myRecords/getVisitLabTest', 'get', medicalRecord.lab_test)
const deleteMedication = customAsyncThunk('myRecords/deleteMedications', 'post', patient.medications)

export {
    requestUploadUrl,
    getPatientProfile,
    createVitals,
    updateLifestyle,
    getVitals,
    updateMedicalRecord,
    deleteMedication,
    getProgressNotes,
    deleteMedicalRecord,
    editMedications,
    getSingleProgressNote,
    editMedicalRecord,
    getVisits,
    getOngoingVisit,
    getPendingLabTest,
    uploadTestResult,
    getLabTests,
    getTherapies,
    getMedications,
    getReferrals,
    getProcedure,
    getSingleVisit,
    createMedications,
    getVisitTreatmentPlan,
    getVisitLabTest,
    addLabTest
}