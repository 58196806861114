import Accordion from 'components/Accordion';
import React, { useEffect, useContext } from 'react';
import { ReactComponent as Tablet } from 'assets/svg/home/paracetamol.svg';

import { useRecordState } from 'store/modules/myRecords';
import useMyRecords from 'hooks/fetch/useMyRecords';
import { ReactComponent as Contact } from 'assets/icons/medicalRecords/emergency-contact.svg';
import dayjs from 'dayjs';
import { ReactComponent as Procedure } from 'assets/icons/treatmentplan/procedure.svg';
import { ReactComponent as Referral } from 'assets/icons/treatmentplan/referrals-bg.svg';
import { VisitContext } from "context";
import EmptyState from "components/EmptyState";
import { ReactComponent as Examination } from "assets/svg/examination.svg";
import Spinner from 'components/Spinner';

export default function TreatmentPlan() {
  const { visitTreatmentPlan } = useRecordState();
  const { get_visit_treatment_plan, loading } = useMyRecords();
  const { visit, progressNote } = useContext(VisitContext);

  useEffect(() => {
    get_visit_treatment_plan(`${visit._id}/treatment-plan`)
  }, []);
  
  const medications = progressNote?.medications ?? visitTreatmentPlan?.medications
  const procedures = progressNote?.procedures ?? visitTreatmentPlan?.procedures
  const referral = progressNote?.referrals ?? visitTreatmentPlan?.referral
  const therapies = progressNote?.medications ?? visitTreatmentPlan?.therapies

  return (
    <Accordion
      display={false}
      title={<p className="text-14 text-neutral_body font-campton_r">Treatment Plan</p>}
      className="mb-0"
    >
      {loading ? <Spinner /> :
        (<div className="border border-neutral_stroke_2 px-4 py-2 rounded-2xl">
          {medications?.length ? medications?.map((item) => (
            <div key={item.id} className="flex space-x-2 border-b border-neutral_stroke_2 pb-4 mt-4">
              <Tablet />
              <div className="w-full">
                <p className="text-12 font-campton_m text-neutral_black uppercase">
                  {item.medicationName} ({item?.dosage})
                </p>
                <div className="bg-primary_tint_50 mt-2 w-full p-2 rounded font-campton_r text-12">
                  <p className="text-home-grey">{item?.createdBy?.patientId ? 'Added By' : 'Prescribed by'}</p>
                  <p className="text-neutral_black">
                    {item?.createdBy?.firstName} {item?.createdBy?.lastName}
                  </p>
                  <p className="text-neutral_body">
                    <span>
                      {item?.createdBy?.emailAddress} |{' '}
                      {item?.createdBy?.patientId ? item?.createdBy?.gNumber : item.createdBy?.GPINumber}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )) :
            <EmptyState
              illustration={<Examination />}
              title="No Medications Available"
              description="All medications will be displayed here when added"
            />}
          {procedures?.length ? procedures?.map((item) => (
            <div key={item.id} className="flex space-x-2 border-b border-neutral_stroke_2 pb-4 mt-4">
              <Procedure />
              <div className="w-full">
                <p className="text-12 text-neutral_black font-campton_m uppercase">
                  <span>
                    {item?.procedureName} | {dayjs(item?.procedureDate).format('D MMM, YYYY')}
                  </span>
                </p>
                <div className="bg-primary_tint_50 mt-2 w-full p-2 rounded font-campton_r text-12">
                  <p className="text-home-grey">Attending provider for this procedure</p>
                  <p className="text-neutral_black">
                    {item?.attendingProviderId?.firstName} {item?.attendingProviderId?.lastName}
                  </p>
                  <p className="text-neutral_body">
                    <span>
                      {item.attendingProviderId?.emailAddress} |{' '}
                      {item.attendingProviderId?.GPINumber}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )) :
            <EmptyState
              illustration={<Examination />}
              title="No Procedures Available"
              description="All Procedures will be displayed here when added"
            />}
          {therapies?.length ? therapies?.map((item) => (
            <div key={item.id} className="flex space-x-2 border-b border-neutral_stroke_2 pb-4 mt-4">
              <Contact />
              <div className="w-full">
                <p className="text-12 text-neutral_black font-campton_m uppercase">
                  <span>
                    {item?.therapyName} | {dayjs(item?.therapyDate).format('D MMM, YYYY')}
                  </span>
                </p>
                <div className="bg-primary_tint_50 mt-2 w-full p-2 rounded font-campton_r text-12">
                  <p className="text-home-grey">Attending provider for this therapy</p>
                  <p className="text-neutral_black">
                    {item?.createdBy?.firstName} {item?.createdBy?.lastName}
                  </p>
                  <p className="text-neutral_body">
                    <span>
                      {item?.createdBy?.emailAddress}
                      {item?.createdBy?.GPINumber ? `| ${item?.createdBy?.GPINumber}` : ''}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )) :
            <EmptyState
              illustration={<Examination />}
              title="No Therapies Available"
              description="All therapies will be displayed here when added"
            />}
          {referral?.length > 0 ? referral?.map((item) => (
            <div key={item.id} className="flex space-x-2 border-b border-neutral_stroke_2 pb-4 mt-4">
              <Referral />
              <div className="w-full">
                <p className="text-12 text-neutral_black font-campton_m uppercase">
                  <span>
                    Referral Date: {dayjs(item?.therapyDate).format('D MMM, YYYY')}
                  </span>
                </p>
                <div className="bg-primary_tint_50 mt-2 w-full p-2 rounded font-campton_r text-12">
                  <div>
                    <p className="text-neutral_black font-campton_m">Receiving Institution Information</p>
                    <p className="text-neutral_black">
                      {item?.receivingInstitutionId?.businessName}
                    </p>
                    <p className="text-neutral_body">
                      <span>
                        {item?.receivingInstitutionId?.businessEmailAddress}
                      </span>
                    </p>
                  </div>
                  <div className='mt-2.5'>
                    <p className="text-neutral_black font-campton_m">Receiving Provider Information</p>
                    <p className="text-neutral_black">
                      {item?.receivingProviderId?.firstName} {item?.receivingProviderId?.lastName}
                    </p>
                    <p className="text-neutral_body">
                      <span>
                        {item?.receivingProviderId?.emailAddress}
                        {`| ${item?.receivingProviderId?.GPINumber} `} {`| ${item?.receivingProviderId?.specialty}`}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )) :
            <EmptyState
              illustration={<Examination />}
              title="No Referrals Available"
              description="All Procedures will be displayed here when added"
            />}
        </div>)
      }

    </Accordion>
  );
}
