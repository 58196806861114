import { SlideUp } from 'components/Animations'
import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Disability from './Disability'
import HealthVitals from './HealthVitals'
import ProstateCancer from './ProstateCancer'
import SubNavbar from 'components/SubNavbar';
import Navbar from 'components/Navbar'
import { healthTips } from 'mocks/myRecords'
import TipBox from '../Components/TipBox'
import HealthIsWealth from './HealthIsWealth'
import Cholesterol from './Cholesterol'
import Walking from './Walking'
import Headache from './Headache'

export default function HealthTips() {
const {id} = useParams()
const navigate = useNavigate()

const views = {
  'Disability': <Disability/>,
  'Prostate Cancer': <ProstateCancer/>,
  'Health vitals': <HealthVitals/>,
  'Health is wealth': <HealthIsWealth/>,
  'Cholesterol': <Cholesterol/>,
  'Walking': <Walking/>,
  'Headache': <Headache/>
}
const View = views[id];

return (
    <>
        <Navbar title='Health Tips'/>
        <SubNavbar
            back="Home"
            current='Health tips'
            backClick='/dashboard'
        />
        <div className='w-full'>
            <SlideUp>
                <div className='grid grid-cols-12'>
                    <div className='lg:col-span-9 col-span-12 p-6 border-r border-neutral_stroke_2 '>
                        {View}
                    </div>
                    <div className='col-span-3 p-6 lg:block hidden'>
                        <p className='font-campton_m text-14'>More Health Tips</p>
                        <div className="flex flex-col space-y-4 mt-4 overflow-scroll h-[calc(100vh-250px)]">
                            {healthTips.filter(tip => tip.id !== id).map((item, i) => (
                            <TipBox
                                key={item?.img}
                                img={item?.img}
                                time="5"
                                onClick={() => navigate(`/dashboard/health-tips/${item.id}`)}
                                lastItem={(i + 1) !== healthTips?.length}
                                title={item.title}
                                description={item.description}
                            />
                            ))}
                        </div>
                    </div>
                </div>
            </SlideUp>
        </div>
    </>
  )
}
