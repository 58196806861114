import { createSlice } from '@reduxjs/toolkit';
import {
    getConversations,
} from './actions';

const initialState = {
    conversations: [],
};

export const messaging = createSlice({
    name: 'messaging',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getConversations.fulfilled, (state, action) => {
                state.conversations = action?.payload?.data || []
            })
    },
});

export default messaging.reducer;
