import FormWrapper from 'components/FormWrapper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from 'components/Buttons/Button';
import PhysicalExercise from './PhysicalExercise';
import { motion, AnimatePresence } from 'framer-motion';
import SleepPatterns from './SleepPatterns';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import DietAndNutrition from './DietAndNutrition';
import StressManagement from './StressManagement';
import Tobacco from './Tobacco';
import MentalAndEmotional from './MentalAndEmotional';
import EnviromentalFactors from './EnviromentalFactors';
import SocialConnections from './SocialConnections';
import SafeSexPractices from './SafeSexPractices';
import BirthControl from './BirthControl';
import SexualDysfunction from './SexualDysfunction';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { useRecordState } from 'store/modules/myRecords';
import { removeEmptyStringKeys } from 'utils/removeSpecificKeys';

//Variables
const questions = [
  {
    title: 'Physical Exercise/Activity',
    key: 'physicalActivity',
    component: PhysicalExercise,
  },
  {
    title: 'Sleep Patterns',
    component: SleepPatterns,
    key: 'sleepPattern',
  },
  {
    title: 'Diet and Nutrition',
    component: DietAndNutrition,
    key: 'dietAndNutrition',
  },
  {
    title: 'Stress Management',
    component: StressManagement,
    key: 'stressManagement',
  },
  {
    title: 'Tobacco, Alcohol and Substance Use',
    component: Tobacco,
    key: 'substanceUse',
  },
  {
    title: 'Mental and Emotional Well-being',
    component: MentalAndEmotional,
    key: 'mentalHealth',
  },
  {
    title: 'Environmental Factors',
    component: EnviromentalFactors,
    key: 'environmentalFactors',
  },
  {
    title: 'Social Connections',
    component: SocialConnections,
    key: 'socialConnections',
  },
  {
    title: 'Safe Sex Practices',
    component: SafeSexPractices,
    key: 'safeSexPractises',
  },
  {
    title: 'Birth Control',
    component: BirthControl,
    key: 'birthControl',
  },
  {
    title: 'Sexual Dysfunction',
    component: SexualDysfunction,
    key: 'sexualDysfunction',
  },
];
const initialQuestionShowState = Array.from({ length: questions.length }, () => false);

const AddLifestyleHabit = ({ type, callback, selectedRecord }) => {
  //Local State
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [questionsShowState, setQuestionsShowState] = useState([...initialQuestionShowState]);
  const [formData, setFormData] = useState({
    physicalActivity: {},
    sleepPattern: {},
    dietAndNutrition: {},
    stressManagement: {},
    substanceUse: {},
    mentalHealth: {},
    environmentalFactors: {},
    socialConnections: {},
    safeSexPractises: {},
    birthControl: {},
    sexualDysfunction: {},
  });
  //Hooks
  const { update_liftstyle } = useMedicalRecord();
  const { loading } = useRecordState();
  useEffect(() => {
    if (selectedRecord && type === 'Edit') {
      setFormData(Object.fromEntries(
        Object.entries(selectedRecord).filter(([key]) => key !== 'lifestyleLastModified')
      ))
    }
  }, [selectedRecord, type])

  //handler functions
  const handleDropdown = (item, index) => {
    const newDropdownStates = [...initialQuestionShowState].map((val, id) => (id === index ? !val : val));
    setQuestionsShowState(newDropdownStates);
    setSelectedQuestion(item);
  };
  const updateLifestyle = () => {
    const payload = type === 'Edit' ? removeEmptyStringKeys(formData) : formData
    update_liftstyle(payload, callback)
  }
  return (
    <div className="w-full lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={updateLifestyle}>
        <div className="text-14 font-campton_r text-neutral_black pb-5">
          {questions.map((item, index) => (
            <div className="border-b border-b-neutral_stroke_2" key={item.title}>
              <div
                data-testid={item.key}
                className={`${
                  selectedQuestion?.title === item.title ? 'bg-[#F7FBFD]' : 'bg-[#FAFAFA]'
                } flex items-center justify-between bg-[#FAFAFA] py-[14px] px-[16px] cursor-pointer hide_tap`}
                onClick={() => handleDropdown(item, index)}
              >
                <div className="flex items-center">
                  <p
                    className={`${
                      selectedQuestion?.title === item.title
                        ? 'text-primary_shade_200 font-campton_m'
                        : 'text-neutral_black font-campton_r'
                    } `}
                  >
                    {item.title}
                  </p>
                </div>
                {questionsShowState[index] && selectedQuestion?.title === item.title ? (
                  <IoIosArrowUp />
                ) : (
                  <IoIosArrowDown />
                )}
              </div>
              <AnimatePresence>
                {questionsShowState[index] && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto', transition: { type: 'spring', duration: 0.3 } }}
                    exit={{ opacity: 0, height: 0, transition: { duration: 0.2 } }}
                  >
                    <item.component formData={formData} setFormData={setFormData} type={item.key} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}

          <div className="flex justify-end col-span-12 mt-5">
            <Button name="Save" theme="blue" className="w-28 h-10" loading={loading}/>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddLifestyleHabit;
AddLifestyleHabit.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};
