import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChart({chartData, chartOptions, datasets}) {
  const defaultChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animation: { duration: 1000 },
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
          grid: {
            display: true,
          },
          ticks: {
            display: true,
            beginAtZero: true,
          },
        },
        y: {
          beginAtZero: true,
          // grid: {
          //   display: true,
          // },
          ticks: {
            display: true,
            beginAtZero: true,
          },
        },
    },

  };
  const defaultDatasets = [{
    data: chartData?.data,
    backgroundColor: '#23CE6B',
    barThickness: 108,
  }]

  const data = {
    labels: chartData?.labels,
    datasets: datasets || defaultDatasets
  }
  return (
    <div>
        <Bar
          data-testid={`test-chart-js`}
          data={data}
          options={defaultChartOptions || chartOptions}
          height="250px"
        />
    </div>
  );
}

BarChart.propTypes = {
  chartData: PropTypes.object,
  chartOptions: PropTypes.object,
  datasets: PropTypes.array
};
