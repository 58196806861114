import { createSlice } from '@reduxjs/toolkit'
import { getOutstandingBills } from './actions'

const initialState = {
  outstandingBills: {},
}

export const billsAndPayments = createSlice({
  name: 'billsAndPayments',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOutstandingBills.fulfilled, (state, action) => {
        state.outstandingBills = action?.payload?.data || {}
      })
  },
});

// Action creators are generated for each case reducer function
export default billsAndPayments.reducer;