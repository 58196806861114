/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import healthIsWealth from 'assets/svg/home/healthIsWealth.svg';
import { SlideUp } from 'components/Animations';

const HealthIsWealth = () => {

  return (
    <SlideUp className='h-full overflow-y-scroll'>
      <p className='font-campton_m text-[32px] text-neutral_black leading-[38.4px]'>Health Is Wealth</p>
      <p className='text-14 text-neutral_body font-campton_m mt-3'> Living with high blood pressure, diabetes, and high cholesterol can feel daunting, but embracing a healthier lifestyle can be a powerful game-changer. Here are some key areas to focus on:</p>
      <p className='flex space-x-4 text-neutral_black text-12 my-6 font-campton_r'>
        <span className='border border-neutral_stroke_2 px-2 py-1 rounded-full'> 5 min read</span>
        <span className='border border-neutral_stroke_2 py-1 px-2 rounded-full'> 21 May, 2023</span>
      </p>
      <div className=''>
        <img src={healthIsWealth} alt="healthy tips" className="w-full rounded-3xl object-cover lg:h-[430px] h-40" />
      </div>
      <div className='md:mb-0 mt-6 lg:h-[calc(100%-200px)] h-full'>
      <p className='text-14 font-campton_r text-neutral_body mt-6'>
        <span className='text-neutral_black font-campton_m mr-1'>Fueling Your Body Wisely:</span>Ditch the processed, sugary, and high-fat choices. Instead, embrace a colorful plate rich in fruits, vegetables, and whole grains. Opt for lean protein sources like fish and legumes, and choose healthy fats like nuts and olive oil. Don't forget the power of fiber! It helps regulate blood sugar and cholesterol, so load up on whole grains, beans, and leafy greens. Watch your sodium intake and limit sugary drinks – water is your best friend!
        </p>  
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        <span className='text-neutral_black font-campton_m mr-1'>Moving Your Body:</span>Exercise isn't just about sweating it out at the gym. Find activities you enjoy, be it brisk walking, dancing, cycling, or swimming. Aim for at least 150 minutes of moderate-intensity exercise or 75 minutes of vigorous activity per week. Break it down into manageable chunks, starting with 30 minutes most days. Remember, even small bursts of movement throughout the day add up
        </p>  
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        <span className='text-neutral_black font-campton_m mr-1'>Mind Your Mental and Emotional Well-being:</span>Stress can negatively impact all three conditions. Practice relaxation techniques like meditation, yoga, or deep breathing. Ensure adequate sleep, at least 7-8 hours for adults. Don't be afraid to seek help for stress management or mental health concerns. Remember, prioritizing your well-being is not a luxury, it's an essential part of the health equation
        </p>  
        <p className='text-14 font-campton_r text-neutral_body mt-6'>
        These are just the first steps on your journey to better health. Remember, consistency is key, and even small changes can have a significant impact. Consult your doctor for personalized guidance and celebrate your progress every step of the way. You've got this!
        </p>
      </div>
    </SlideUp>
  )
}

export default HealthIsWealth