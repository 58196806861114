import React, { useState } from 'react';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Illustration } from 'assets/svg/family-hospital-illustration.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import Spinner from 'components/Spinner';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddFamilyHospital';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as FamilyHospitalSolid } from 'assets/icons/medicalRecords/family-hospital-solid.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import Header from '../Header';
import { useRecordState } from 'store/modules/myRecords';

const FamilyHospital = ({ selected, onClick }) => {
  //Hools
  const { familyHospital, loading } = useRecordState();
  const { delete_medical_record } = useMedicalRecord();

  //Local State
  const [type, setType] = useState();
  const [selectedDetails, setSelectedDetails] = useState();
  const [showModal, setShowModal] = useState();
  //Handler function
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={() => handleShowModal('Add')} condition={familyHospital?.length > 0} />
        {loading && (
          <div className="mt-10">
            <Spinner />
          </div>
        )}
        {!loading &&
          (familyHospital?.length > 0 ? (
            <>
              <div className="mt-6">
                {familyHospital.map((item) => (
                  <div
                    className="flex justify-between 
                    items-start border-b last-of-type:border-b-0 border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0"
                    key={item?._id}
                  >
                    <div className="flex space-x-4">
                      <FamilyHospitalSolid />
                      <div>
                        <p className="capitalize font-campton_m text-neutral_black text-16">{item.name}</p>
                        <p className="capitalize text-14 text-neutral_body font-campton_r mt-1">{item.address}</p>
                        <div className="text-14 font-campton_r text-neutral_body flex items-center space-x-2 mt-1">
                          <p className="">{item.countryCode + item.phoneNumber} </p> <Dot /> <p>{item.emailAddress}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <EditIcon
                        onClick={() => handleShowModal('Edit', () => setSelectedDetails(item))}
                        className="cursor-pointer"
                        data-testid="test-edit"
                      />
                      <DeleteIcon
                        data-testid="test-delete"
                        onClick={() => delete_medical_record('familyHospital', item._id)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyState
              illustration={<Illustration />}
              title="No Family Hospital"
              description="Family hospital will be displayed here when added."
              btnText="Add Family Hospital"
              onClick={() => handleShowModal('Add')}
            />
          ))}
        {showModal && (
          <DefaultModal
            closeModal={() => setShowModal(false)}
            styles="min-w-[90%] md:min-w-[730px]"
            title={`${type} Family Hospital`}
            subTitle="Input the details of family hospital below."
            containerVariant="bg-neutral_white"
          >
            <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
          </DefaultModal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default FamilyHospital;
FamilyHospital.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
