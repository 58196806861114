import PropTypes from 'prop-types';
import React from 'react';

const ModalContent = ({ title, description, btnText, isDisabled, index, onBtnClick }) => {
  return (
    <div className={`${isDisabled ? 'cursor-not-allowed' : 'cursor-default'}`}>
      <div className="flex gap-2">
        <div>
          <div
            className={`w-[20px] h-[20px] ${
              isDisabled ? 'bg-[#B5B5B5]' : 'bg-success'
            } rounded-full text-neutral_white items-center justify-center flex`}
          >
            <span className="text-[12px]">{index + 1}</span>
          </div>
          {index === 1 ? null : (
            <div className="flex items-center w-[1px] mx-auto h-[100%] pb-6 pt-2">
              <div className="w-full mx-auto h-full bg-success"></div>
            </div>
          )}
        </div>
        <div className="w-full">
          <h3 className={`text-[14px] font-campton_m mb-2 ${isDisabled ? 'text-[#B5B5B5]' : ''}`}>{title}</h3>
          <p className={`text-[12px] mb-4 ${isDisabled ? 'text-[#B5B5B5]' : ''}`}>{description}</p>
          <button
            className={`bg-brand_primary rounded-[1000px] px-2 py-1 text-[12px] font-campton_m  ${
              isDisabled
                ? 'bg-neutral_white text-[#B5B5B5] border border-1 cursor-not-allowed'
                : 'bg-brand_primary text-neutral_white cursor-pointer'
            }`}
            type="button"
            onClick={onBtnClick}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;

ModalContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  isDisabled: PropTypes.bool,
  index: PropTypes.number,
  onBtnClick: PropTypes.func,
};
