/* eslint-disable max-len */
import LoginForm from 'components/OnboardingForms/LoginForm';
import React, { useState } from 'react';
import AuthLayout from 'layouts/AuthLayout';

const Login = () => {
  const [selectedTab, setSelectedTab] = useState({ name: 'Email Address', value: 'emailAddress' });

  let TabsHeader = [
    { name: 'Email Address', value: 'emailAddress' },
    { name: 'Phone Number', value: 'phoneNumber' },
    { name: 'G-Number', value: 'gNumber' },
  ];
  
  return (
    <AuthLayout
      right={
        <LoginForm type={selectedTab} TabsHeader={TabsHeader} setSelectedTab={setSelectedTab} />
      }
    />
  );
};

export default Login;
