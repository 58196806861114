import React, { useMemo, useRef } from "react";
import PropTypes from 'prop-types';
import FormWrapper from "components/FormWrapper";
import Button from "components/Buttons/Button";
import Pin from "components/Inputs/Pin";
import usePIN from "hooks/usePIN";
import useResendOtp from "hooks/useResendOtp";
import useAuth from "hooks/fetch/useAuth";
import useExtractPIN from "hooks/useExtractPIN";

export default function VerifyOTP({ submit, emailAddress, callback }) {
    const inputRef = useRef([]);
    const { otp, onChange, onPress, onPaste } = usePIN(6, inputRef);
    const { timeLeft, resetTimer } = useResendOtp();
    const { forgot_password, loading, validate_reset_password_code } = useAuth()
    const { extractPIN } = useExtractPIN();
    const splitEmail = emailAddress?.split('@') || []

    const sendOTP = () => {
        forgot_password({ payload: { emailAddress } })
            .then(res => {
                if (res?.code === 200) resetTimer()
            })
    }

    const disabled = useMemo(() => {
        return extractPIN(otp)?.length < 6
    }, [otp]);

    const verifyAccount = () => {
        validate_reset_password_code({ code: otp, emailAddress })
            .then(res => {
                if (res?.code === 200) callback()
            })
    };

    return (
        <FormWrapper className="relative" onSubmit={verifyAccount}>
            <div className="modal-right-body">
                <p className="text-14 text-neutral_body font-campton_r leading-[21px] mb-[24px]">
                    Enter the 6-digit otp sent to <span className="text-brand_secondary font-campton_m">{splitEmail[0]?.slice(0, 3)}***@{splitEmail[1]}</span>
                </p>
                <div className="grid grid-cols-6 font-campton_r mb-[24px]">
                    <Pin
                        onChange={onChange}
                        onPaste={onPaste}
                        onPress={onPress}
                        pin={otp}
                        inputRef={inputRef}
                    />
                </div>
                <p className="text-16 text-neutral_black font-campton_r leading-[21px]" onClick={timeLeft === 0 ? sendOTP : null}>
                    Didn’t receive code? {timeLeft === 0 ? <span className="text-14 text-brand_secondary cursor-pointer">Resend</span> : <>Resend in <span className="text-neutral_black font-campton_m">{timeLeft}s</span></>}
                </p>
            </div>
            <div className="modal-right-button-container">
                <Button
                    name="Submit"
                    theme="blue"
                    className="h-[40px] text-16 w-[80px]"
                    onClick={submit}
                    disabled={disabled || loading}
                    loading={loading}
                />
            </div>
        </FormWrapper>
    )
}

VerifyOTP.propTypes = {
    cancel: PropTypes.func,
    submit: PropTypes.func,
    emailAddress: PropTypes.string,
    callback: PropTypes.func
};