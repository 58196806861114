import React, { useRef, useState } from "react";
import PropTypes from "prop-types"
import { ReactComponent as Microscope } from 'assets/svg/microscope.svg';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { SlideUp } from "components/Animations";
import Avatar from "components/Avatar/Avatar";
import Button from "components/Buttons/Button";
import { Tracker } from "components/Cards";
import useUpload from "hooks/useUpload";
import useMyRecords from "hooks/fetch/useMyRecords";
import dayjs from "dayjs";
import Upload from "components/Upload";

export default function UploadTest({ selectedTest, callback }) {
    const fileRef = useRef()
    const [file, setFile] = useState()
    const { upload, uploading } = useUpload()
    const { upload_test_result, loading } = useMyRecords()

    const uploadTestFile = () => {
        const timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
        const fileName = `${timestamp}${file?.name}`
        const payload = { testResult: fileName }
        upload(file, payload, timestamp, false)
            .then(res => {
                if (res?.response?.status === 200) {
                    const pathname = `lab-test/${selectedTest?._id}`
                    upload_test_result({ testResult: [res.imageUrl] }, pathname)
                        .then(res => {
                            if (res?.code === 200) callback()
                        })
                }
            })
    }

    return (
        <SlideUp>
            <Tracker
                title={selectedTest?.institution?.businessName}
                purpose="Pending Test"
                date={selectedTest?.dateRequested}
                id={selectedTest?.labReadId}
                icon={<Microscope />}
            />
            {selectedTest?.requestedBy?.firstName && <div className="p-[16px] rounded-[16px] border border-neutral_stroke_2 mb-[24px]">
                <p className="text-12 font-campton_r text-neutral_body mb-[8px]">Requested by</p>
                <div className='flex flex-col items-center sm:flex-row mb-[16px] sm:mb-0'>
                    <Avatar
                        url={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTga82Zqlb6VqnCZcxG2ur7hI6Fw8aJTtJLvDhiunx0AxCd6j8lmMVt1cIA-Tw-S3xV754&usqp=CAU'}
                        width="48px"
                        height="48px"
                        //alt={loggedUser?.firstName?.split('')[0]}
                        className="w-[48px] h-[48px] shrink-0"
                    />
                    <div className='ml-[16px] text-left sm:flex justify-between w-full'>
                        <div>
                            <p className='text-14 font-campton_m text-neutral_black capitalize'>
                                {selectedTest?.requestedBy?.firstName} {selectedTest?.requestedBy?.lastName}
                            </p>
                            <p className='text-14 font-campton_r text-neutral_body'>{selectedTest?.requestedBy?.emailAddress}</p>
                        </div>
                        <p className='text-14 font-campton_r'>{selectedTest?.requestedBy?.gpiNumber}</p>
                    </div>
                </div>
            </div>
            }
            {!file && !selectedTest?.testResult.length ?
                <Upload
                    id="test"
                    setFile={setFile}
                    loading={uploading}
                    fileRef={fileRef}
                /> : ''
            }
            {selectedTest?.testResult.length || file ?
                <div className="flex justify-between items-center px-[16px] py-[8px] border border-neutral_stroke_1 rounded-[8px]">
                    <div className="flex">
                        <File className="mr-[8px]" />
                        <div>
                            <p className="text-14 font-campton_r text-neutral_black">{file?.name ?? selectedTest?.testResult[0]?.testType}</p>
                            {file && <p className="text-14 font-campton_r text-neutral_black">{(file?.size / 1024).toFixed(2)} KB</p>}
                        </div>
                    </div>
                    {!selectedTest?.testResult.length ? <Close data-testid='delete-file' className="cursor-pointer" onClick={() => setFile()} /> : ''}
                </div> : ''
            }
            <div className="flex justify-end mt-6">
                {!selectedTest?.testResult.length ?
                    <Button
                        className="w-28 rounded-full h-[40px] font-campton_m"
                        theme="blue"
                        disabled={!file || uploading || loading}
                        name="Save"
                        onClick={uploadTestFile}
                        loading={uploading || loading}
                    /> : ''
                }
            </div>
        </SlideUp>
    )
}

UploadTest.propTypes = {
    selectedTest: PropTypes.object,
    callback: PropTypes.func
}