import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useInputValidate from '../../hooks/useInputValidate';

export default function TextArea({
  id,
  name,
  placeholder,
  value,
  label,
  onChange,
  readOnly,
  variant,
  rows,
  helperText,
  disabled,
  max,
  pattern,
  inputMode,
  showError,
  className
}) {
  const { error, validate, setError } = useInputValidate(showError);

  const inputError = useMemo(() => {
    return !(showError === false || !error)
  }, [error])

  return (
    <>
      <div className={`relative mb-[3.5px] textarea-container ${variant || ''}`}>
        <textarea
          id={id}
          name={name}
          label={label}
          placeholder={placeholder || ''}
          inputMode={inputMode}
          max={max}
          value={value}
          rows={rows}
          pattern={pattern}
          data-testid={`test-${id}`}
          aria-labelledby={id}
          onChange={onChange}
          readOnly={readOnly}
          autoComplete="off"
          onBlur={() => validate({ name, value })}
          onKeyDown={() => setError('')}
          className={`${inputError ? 'border-error' : 'border-neutral_stroke'} p-4 ${className}
                        text-14 w-full text-neutral_black outline-0 border font-campton_r font-normal hide_tap
                        rounded-lg focus:border-brand_primary focus:border bg-neutral_white`}
        />
        {label &&
          <label
            htmlFor={id}
            className={`${disabled ? 'bg-none' : 'bg-neutral_white'}
                    text-neutral_body mb-2 font-campton_r bg-neutral_white px-1 pt-2 cursor-text`}
          >
            {label}
          </label>}
      </div>
      <p className={`font-dmsans_r ${inputError ? 'text-error' : 'text-neutral_body'} text-12`}>
        {(inputError && error) || helperText}
      </p>
    </>
  );
}

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  rows: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.string,
  pattern: PropTypes.string,
  variant: PropTypes.string,
  inputMode: PropTypes.string,
  showError: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string
};
