import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import { useState } from 'react';

const useHome = () => {
  const { makeAnonymousRequest } = useMakeRequest();
  const { tryCatch } = useTryCatch();
  const [loading, setLoading] = useState(false);

  const get_medications = async (id, email) => {
    const url = `medications/patient/${id}?search=${email}`;
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, method: 'get', alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  return { get_medications, loading };

};

export default useHome;
