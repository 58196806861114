import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { motion } from 'framer-motion';
import { ReactComponent as Calendar } from 'assets/svg/appointment/calendar.svg';

export default function ProgressNoteHeader({ filters, selected, select }) {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOnClickOutside([ref], () => setShow(false));
  return (
    <div className="pb-4">
      <div className="flex overflow-x-auto mt-[10px] lg:mt-0">
        {filters?.slice(0, 2).map((f) => (
          <button
            data-testid={f?._id}
            key={f?._id}
            onClick={() => select(f)}
            className={`text-14 font-campton_r px-[8px] py-[4px] rounded-[24px] cursor-pointer
            border ${
              selected?._id === f?._id ? 'border-brand_primary' : 'border-neutral_stroke_2'
            } mr-[16px] whitespace-nowrap h-fit`}
          >
            {f?.createdAt}
          </button>
        ))}
        {filters?.length > 2 && (
          <div ref={ref}>
            <button
              className="text-14 font-campton_r px-[8px] py-[4px] rounded-[24px] border border-neutral_stroke_2 mr-[16px whitespace-nowrap h-fit"
              onClick={() => setShow(!show)}
              data-testid='more-btn'
            >
              more <ArrowDown fill="#505959" className="inline" />
            </button>
            <div className={`absolute z-40 right-28 w-[230px] mt-[14px]`}>
              <motion.ul
                initial={{ opacity: 0, y: '20px' }}
                animate={{ opacity: show ? 1 : 0, y: !show ? '20px' : 0 }}
                exit={{ opacity: 0, y: '20px' }}
                onClick={() => setShow(false)}
                className={`${
                  show ? '' : 'hidden'
                } bg-neutral_white p-[16px] rounded-[16px] text-14 font-campton_r options_shadow border border-neutral_stroke_1`}
              >
              <p className='font-campton_r uppercase text-10 text-neutral_body'>Progress notes records</p>
                {filters?.slice(2)?.map((option) => (
                  <li
                    key={option?._id}
                    data-testid={option?._id}
                    className={`
                        cursor-pointer w-full flex items-center py-[9.5px] hover:bg-[#dfe2e23b]
                         hover:text-neutral_black hover:pl-[8px] rounded-[50px]
                        ${selected?._id === option?._id ? 'text-neutral_black px-1.5 border border-brand_primary' : 'text-neutral_body'}
                    `}
                    onClick={() => select(option)}
                  >
                    <Calendar className="cursor-pointer mr-1" />
                    <span className="inline-block">{option?.createdAt}</span>
                  </li>
                ))}
              </motion.ul>
              <div className={`${show ? '' : 'hidden'} absolute z-10 top-[-30px] right-[22px] triangle-up`}></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ProgressNoteHeader.propTypes = {
  filters: PropTypes.array,
  selected: PropTypes.object,
  select: PropTypes.func,
};
