import React, { useMemo, useState } from "react";
import PropTypes from 'prop-types';
import FormWrapper from "components/FormWrapper";
import Button from "components/Buttons/Button";
import { Input } from "components/Inputs";
import useAuth from "hooks/fetch/useAuth";
import useInputValidate from "hooks/useInputValidate";
import { useDispatch } from "react-redux";
import { logout } from "store/modules/auth/actions";

export default function CreateNewPassword({ cancel }) {
    const { change_password, loading } = useAuth()
    const { validate } = useInputValidate()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: ''
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const submit = () => {
        change_password({
            payload: {
                oldPassword: formData.currentPassword,
                newPassword: formData.newPassword
            }
        })
            .then(res => {
                if (res?.code === 200) dispatch(logout())
            })
    }

    const disabled = useMemo(() => {
        return !validate({ name: 'password', value: formData.newPassword }) ||
            (formData?.newPassword === formData.currentPassword) || !formData.currentPassword || loading
    }, [formData, loading])

    return (
        <FormWrapper className="relative" onSubmit={submit}>
            <div className="modal-right-body">
                <p className="text-14 text-neutral_body font-campton_r leading-[21px] mb-[24px]">
                    Your new password must be different from previously used passwords
                </p>
                <div className="mb-[24px] w-full sm:mr-[24px]">
                    <Input
                        label="Current Password"
                        type="password"
                        id="currentPassword"
                        name="currentPassword"
                        //readOnly={loading}
                        value={formData?.currentPassword.trim()}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[24px] w-full">
                    <Input
                        label="New Password"
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        //readOnly={loading}
                        value={formData?.newPassword.trim()}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="modal-right-button-container">
                <Button
                    name="Cancel"
                    theme="transparent"
                    className="h-[40px] text-16 w-[80px] mr-[16px]"
                    onClick={cancel}
                    type="button"
                    disabled={loading}
                />
                <Button
                    name="Save Changes"
                    theme="blue"
                    className="h-[40px] text-16 w-[131px]"
                    disabled={disabled}
                    loading={loading}
                />
            </div>
        </FormWrapper>
    )
}

CreateNewPassword.propTypes = {
    cancel: PropTypes.func,
};