import React, { useEffect, useMemo, useRef } from 'react';
import Navbar from 'components/Navbar';
import Avatar from 'components/Avatar/Avatar';
import Menu from './Components/Menu';
import DefaultModal from 'components/Modals/DefaultModal';
import EditProfile from './Components/Forms/EditProfile';
import ChangePassword from './Components/ChangePassword';
import Termination from './Components/Termination';
import useTabOptions from './hooks/useTabOptions';
import { SlideUp } from 'components/Animations';
import { ReactComponent as UploadImg } from 'assets/svg/upload-profile.svg';
import dayjs from 'dayjs';
import useUpload from 'hooks/useUpload';
import { useAuthState } from 'store/modules/auth';
import { useRecordState } from 'store/modules/myRecords';
import useAuth from 'hooks/fetch/useAuth';
import { generateImageUrl } from 'utils/generateImageUrl';
import { useNavigate } from 'react-router-dom';
import useWindowWidth from 'hooks/useWindowWidth';
import { MEDIUM_WIDTH } from 'constants';

export default function MyRecords() {
  const fileInputRef = useRef();
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth < MEDIUM_WIDTH
  const { loggedUser } = useAuthState();
  const { upload, imageUrl } = useUpload();
  const { patientInfo } = useRecordState();
  const { update_userInfo } = useAuth();
  const {
    myRecordsTabs,
    settingsTabs,
    terminationTabs,
    termination,
    record,
    setTermination,
    editProfile,
    setEditProfile,
    changePassword,
    setChangePassword,
  } = useTabOptions();

  const componentToDisplay = useMemo(() => {
    return myRecordsTabs.find((option) => option.title === record)?.component;
  }, [record]);

  const uploadFile = (e) => {
    const timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
    const payload = { profilePic: `${timestamp}${e.target.files[0]?.name}` };
    upload(e.target.files[0], payload, timestamp, false)
      .then((res) => {
        if (res?.response?.status === 200) {
          const payload = { profilePic: res?.imageUrl }
          update_userInfo(payload)
        }
      });
  };

  useEffect(() => {
    if (windowWidth > (MEDIUM_WIDTH || 800) && !record) {
      navigate('?record=personal health records')
    }
  }, [windowWidth])

  const tabContainerStyle = useMemo(() => {
    if (!isMobile) return "block h-[84.5vh]"
    return "translate-x-[-.5%] h-full"
  }, [isMobile])

  return (
    <div>
      <Navbar title="My Records" />
      <SlideUp>
        <div className="flex overflow-y-auto md:overflow-y-hidden h-screen md:h-auto">
          <div className={`${record && isMobile ? 'hidden' : 'md:block'} w-full md:w-[377px] shrink-0 border-r border-neutral_stroke_2`}>
            <section className='px-[24px]'>
              <div
                className="
              flex flex-col items-center bg-[url('/src/assets/svg/profile-bg.svg')]
              bg-[#36A1C5] p-[24px] rounded-[16px] mb-[24px] mt-[48px]
              "
              >
                <div>
                  <Avatar
                    url={generateImageUrl(imageUrl, loggedUser)}
                    width="72px"
                    height="72px"
                    alt={patientInfo?.firstName?.split('')[0]}
                    canViewProfile={true}
                    className="w-[72px] h-[72px] mt-[-60px] mb-[16px] cursor-pointer"
                  />
                  <div className="absolute translate-x-[50px] translate-y-[-40px] cursor-pointer">
                    <label htmlFor="file" className="cursor-pointer flex space-x-4 items-center">
                      <UploadImg />
                      <input
                        id="file"
                        type="file"
                        data-testid='file'
                        ref={fileInputRef}
                        accept="image/*" // Only accept image files
                        className="hidden"
                        onChange={(e) => uploadFile(e)}
                      />
                    </label>
                  </div>
                </div>
                <p className="text-16 text-neutral_white font-campton_m capitalize">
                  {loggedUser?.firstName} {loggedUser?.lastName}
                </p>
                <p className="text-12 text-neutral_white font-campton_r flex">
                  <span className="inline-block w-[50%] truncate mr-[4px]">
                    {loggedUser?.emailAddress}</span>|<span>{loggedUser?.countryCode}{loggedUser?.phoneNumber}
                  </span>
                </p>
                <p className="text-12 text-neutral_white font-campton_r mb-[16px]">{loggedUser?.gNumber}</p>
              </div>
            </section>
            <section className="h-[67vh] lg:h-[57vh] overflow-auto px-[24px]">
              <Menu
                title="MY RECORDS"
                options={myRecordsTabs}
                className="mb-[24px]"
                selected={record}
              />
              <Menu
                title="SETTINGS"
                options={settingsTabs}
                className="mb-[24px]"
              />
              <Menu
                title="TERMINATION"
                options={terminationTabs}
                className="mb-[24px]"
              />
            </section>
          </div>
          {record &&
            <div className={`px-[24px] pb-[40px] bg-neutral_white md:block w-full overflow-auto ${tabContainerStyle}`}>
              {componentToDisplay}
            </div>
          }
        </div>
      </SlideUp>
      {editProfile ?
        (<DefaultModal
          closeModal={() => setEditProfile(false)}
          styles="w-full md:w-[457px]"
          position="modal-right"
          title="Edit Profile"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
          <EditProfile cancel={() => setEditProfile(false)} />
        </DefaultModal>) : ''
      }
      {changePassword ?
        (<DefaultModal
          closeModal={() => setChangePassword(false)}
          styles="w-full md:w-[457px]"
          position="modal-right"
          title="Change Password"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
          <ChangePassword />
        </DefaultModal>) : ''
      }
      <Termination reason={termination.reason} status={termination.status} setTermination={setTermination} />
    </div>
  );
}
