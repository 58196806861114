import React, { lazy, Suspense } from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import AppLayout from "layouts/AppLayout";
import CreateAccount from "pages/Auth/CreateAccount";
import ForgotPassword from "pages/Auth/ForgotPassword";
import Login from "pages/Auth/Login";
import ResetPassword from "pages/Auth/ResetPassword";
import TermsAndConditions from "pages/Auth/TermsAndCondition";
import Spinner from "components/Spinner";
import PageNotFound from "pages/404";
import More from "pages/Dashboard/More";
import ViewAppointment from "pages/Appointments/ViewAppointment";
import HealthTips from "pages/Dashboard/Home/HealthTips/HealthTips";
import BookAppointment from "pages/Appointments/BookAppointment";
import Home from "pages/Dashboard/Home/Home"
import Appointments from "pages/Appointments/Appointments"
import Messaging from "pages/Messaging"
import BillsAndPayments from "pages/Dashboard/BillsAndPayments"
const LiveSession = lazy(() => import('pages/Appointments/LiveSession'))

const router = createBrowserRouter([
    {
        path: "",
        element: <Login />,
    },
    {
        path: "create-account",
        element: <CreateAccount />
    },
    {
        path: "verify-account",
        element: <CreateAccount />
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "reset-password",
        element: <ResetPassword />
    },
    {
        path: "terms-and-condition",
        element: <TermsAndConditions />,
    },
    {
        path: "/",
        errorElement: <div>Something went wrong</div>,
        element: (
            <AppLayout>
                <Outlet />
            </AppLayout>
        ),
        children: [
            {
                path: 'dashboard',
                element: <Home />,
            },
            {
                path: 'profile',
                element: <Home />,
            },
            {
                path: 'appointments',
                element: <Appointments />,
            },
            {
                path: 'appointments/book-appointment',
                element: <BookAppointment />,
            },
            {
                path: "appointments/:id",
                element: <ViewAppointment />,
            },
            {
                path: 'messaging',
                element: <Messaging />,
            },
            {
                path: 'bills-and-payment',
                element: <BillsAndPayments />,
            },
            {
                path: 'more',
                element: <More />,
            },
            {
                path: 'dashboard/health-tips/:id',
                element: <HealthTips />,
            },
            {
                path: 'settings',
                element: <div>Settings</div>,
            },
        ]
    },
    {
        path: 'appointments/live/:id',
        element: (
            <Suspense fallback={<Spinner />}>
                <LiveSession />
            </Suspense>
        )
    },
    {
        path: "*",
        element: <PageNotFound />,
    }
]);

export { router }