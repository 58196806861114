import React from 'react';
import PropTypes from 'prop-types';

export default function Switch({ status, onToggle }) {
    return (
        <div
            className={`switch cursor-pointer ${status ? 'switch_active' : 'switch_inactive'}`}
            onClick={onToggle}
        >
            <span
                className={`
                    slider switchball round
                    ${status ? 'active_slider active_switchball' : 'inactive_slider inactive_switchball'}
                    `}
            ></span>
        </div>
    );
}

Switch.propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onToggle: PropTypes.func
}