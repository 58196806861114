/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"

const Tabs = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <>
          <div className="flex px-[24px] border-b border-b-neutral_stroke_2 overflow-x-auto no-scrollbar">
            {tabs?.map((tab) =>
                <div key={tab?.id} className="mr-[16px] relative">
                    <p
                        data-testid={tab?.name}
                        onClick={() => tab?.method ? tab?.method() : setSelectedTab(tab?.name)}
                        className={`
                                ${selectedTab === tab?.name ? 'text-brand_secondary font-campton_m' : 'text-neutral_body font-campton_r'}
                                text-14 cursor-pointer hide_tap whitespace-nowrap pt-[16px] pb-[11px]
                                capitalize`}
                    >
                        {tab?.name}
                    </p>
                    {selectedTab === tab?.name ?
                        <motion.div
                            initial={{ opacity: 0, y: "10px" }}
                            animate={{ opacity: 1, y: 0, transition: { type: 'spring', duration: 1 } }}
                            exit={{ opacity: 0 }}
                            className="h-[4px] rounded-t-[8px] bg-brand_secondary top-0">
                        </motion.div> : ''}
                </div>
            )}
        </div>
    </>
  );
};

export default Tabs;

Tabs.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.any,
  setSelectedTab: PropTypes.func,
};
