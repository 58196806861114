import React, { useState } from 'react';
import { Input } from 'components/Inputs';
import Button from 'components/Buttons/Button';
import ProfileSetUpForm from './ProfileSetUpForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useReduxHook';
import { setUserInfo, useAuthState } from 'store/modules/auth';

const CreateAccountForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
  });
  const { loading } = useAuthState();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const step = Number(searchParams.get('step'));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const next = () => {
    setSearchParams({ step: 1 });
    dispatch(setUserInfo(formData));
  };

  return (
    <div className="flex flex-col h-full md:mt-10 mt-8">
      {step === 1 ? (
        <ProfileSetUpForm data={formData} />
      ) : (
        <div>
          <div className="mb-6">
            <Input
              label="First Name"
              type="text"
              id="first_name"
              name="firstName"
              readOnly={loading}
              value={formData?.firstName}
              onChange={handleChange}
              showError={false}
            />
          </div>
          <div className="mb-10">
            <Input
              label="Last Name"
              type="text"
              id="last_name"
              name="lastName"
              readOnly={loading}
              value={formData?.lastName}
              onChange={handleChange}
              showError={false}
            />
          </div>
          <Button
            name="Next"
            theme="blue"
            onClick={next}
            className="w-full"
            disabled={!formData.firstName || !formData.lastName}
          />
        </div>
      )}
      <p className="text-14 font-campton_m text-center mt-6">
        By creating your account you agree to GabbiHealth <br />
        <a href='/terms-and-condition?type=terms of use' target='_blank' className="text-brand_secondary cursor-pointer">Terms of Use</a> and{' '}
        <a href='/terms-and-condition?type=privacy policy' target='_blank' className="text-brand_secondary cursor-pointer">Privacy Policy</a>
      </p>
      <Button
        className="text-[16px] py-2 w-full border border-neutral_stroke_2 flex justify-center items-center rounded-full mt-6 h-12 cursor-pointer hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary text-neutral_body"
        onClick={() => navigate('/')}
      >
        <p className="font-campton_r">
          Have an account? <span className="text-brand_secondary font-campton_m">Login</span>
        </p>
      </Button>
    </div>
  );
};

export default CreateAccountForm;
