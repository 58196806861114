import { customAsyncThunk, createCustomAsyncThunk } from 'utils/createThunk';
import { appointment } from 'services/endpoints/appointment';
import { payment } from 'services/endpoints';

const bookAppointment = customAsyncThunk('appointment/bookAppointment', 'post', appointment.appointment);
const getAppointments = createCustomAsyncThunk('appointment/getAppointments', 'get', appointment.appointment)
const getAvailableTimeSlot = createCustomAsyncThunk('appointment/getAvailableTimeSlot', 'get', '/calendar')
const intializeTransaction = customAsyncThunk('appointment/intializeTransaction', 'post', payment.intializeTransaction);
const getServices = createCustomAsyncThunk('appointment/getServices', "get", payment.getServices)
const getCurrentPolicy = createCustomAsyncThunk('appointment/getCurrentPolicy', "get", payment.getCurrentPolicy)
const cancelAppointment = createCustomAsyncThunk('appointment/cancelAppointment', "patch", appointment.editAppointment)
const rescheduelAppointment = createCustomAsyncThunk('appointment/rescheduelAppointment', "patch", appointment.editAppointment)

export {
  getAppointments,
  bookAppointment,
  intializeTransaction,
  getAvailableTimeSlot,
  getServices,
  getCurrentPolicy,
  cancelAppointment,
  rescheduelAppointment
};
