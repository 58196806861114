import EmptyState from "components/EmptyState";
import Spinner from "components/Spinner";
import React from "react"
import PropTypes from "prop-types"
import { formatCurrency } from "utils/formatCurrency";
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import { ReactComponent as Pdf } from "assets/icons/pdf.svg"

export default function OutstandingBills({ loading, outstandingBills }) {
    return (
        <section className="rounded-[16px] pt-[24px]  pb-4 w-full border border-neutral_stroke_2">
            <p className="text-16 text-neutral_black font-campton_m px-6">Outstanding Bills</p>
            <div className="pt-[24px]">
                {loading && <Spinner />}
                {!loading &&
                    <div className="space-y-4 max-h-52 overflow-auto px-6">
                        {outstandingBills?.length ?
                            outstandingBills?.map((bill, index, array) => {
                                const isLastItem = index === array?.length - 1;
                                return (
                                    <div key={bill?._id}>
                                        <div className="flex items-center">
                                            <Pdf className="w-[28px] mr-[10px]" />
                                            <div>
                                                <p className="text-neutral_black text-[14px] font-campton_m">
                                                    {formatCurrency(bill?.amountExpected ?? 0)}
                                                </p>
                                                <p className="text-[12px] text-[#505959] font-campton_r">
                                                    {`${bill?.billId} | ${formatDate(bill?.dueDate ?? bill?.createdAt)}, 
                                                    ${formatTwelveHour(bill?.dueDate ?? bill?.createdAt)} | ${bill?.paymentStatus}`}
                                                </p>
                                            </div>
                                        </div>
                                        {!isLastItem && <hr className="border-[#ECEEEE] mt-4" />}
                                    </div>
                                )
                            }) :
                            <EmptyState
                                title="No outstanding bills"
                                description="All unpaid/partially paid bills will be displayed here"
                            />
                        }
                    </div>
                }
            </div>
        </section>
    )
}

OutstandingBills.propTypes = {
    outstandingBills: PropTypes.array,
    loading: PropTypes.bool
}