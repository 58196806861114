import React, { useEffect, useState } from 'react';
import { Input } from 'components/Inputs';
import DefaultDatepicker from 'components/Datepicker/Index';
import Dropdown from 'components/Dropdown/Index';
import { ethnicityOptions, genderOptions, maritalStatusOptions, raceOptions } from 'mocks/medicalRecords';
import Phone from 'components/Inputs/PhoneInput';
import TextArea from 'components/Inputs/TextArea';
import Button from 'components/Buttons/Button';
import { useAuthState } from 'store/modules/auth';
import useAuth from 'hooks/fetch/useAuth';
import dayjs from 'dayjs';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import SubNavbar from 'components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import { useRecordState } from 'store/modules/myRecords';
import { SlideUp } from 'components/Animations';

const UserProfile = () => {
  //Redux Store
  const { loggedUser, loading } = useAuthState();
  const { patientInfo } = useRecordState();
  const navigate = useNavigate()
  //Hooks
  const { update_userInfo } = useAuth();
  const { get_patient_profile } = useMedicalRecord();
  //Local State
  const [gender, setGender] = useState();
  const [race, setRace] = useState();
  const [ethnicity, setEthnicity] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [phone, setPhone] = useState(`${loggedUser?.countryCode} ${loggedUser?.phoneNumber}`);
  const [formData, setFormData] = useState({
    firstName: patientInfo?.firstName || '',
    lastName: patientInfo?.lastName || '',
    phoneNumber: patientInfo?.phoneNumber || '',
    emailAddress: patientInfo?.emailAddress || '',
    dateOfBirth: dayjs(startDate).format('YYYY-MM-DD'),
    homeAddress: patientInfo?.homeAddress || '',
    race: '',
    ethnicity: '',
    gender: '',
    maritalStatus: '',
    officeAddress: patientInfo?.officeAddress || '',
  });

  const handleCallback = () => {
    get_patient_profile()
    navigate('/dashboard')
  }
  //Handler Function
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const updateUserInfo = (e) => {
    e.preventDefault();
    update_userInfo(
      {
        ...formData,
        dateOfBirth: dayjs(startDate).format('YYYY-MM-DD')
      },
      handleCallback
    );
  };

  useEffect(() => {
    if (patientInfo) {
      setStartDate(new Date(patientInfo.dateOfBirth))
      setMaritalStatus({
        id: patientInfo?.maritalStatus,
        name: patientInfo?.maritalStatus,
        label: patientInfo?.maritalStatus,
      });
      setEthnicity({
        id: patientInfo?.ethnicity,
        name: patientInfo?.ethnicity,
        label: patientInfo?.ethnicity,
      });
      setGender({
        id: patientInfo?.gender,
        name: patientInfo?.gender,
        label: patientInfo?.gender,
      });
      setRace({
        id: patientInfo?.race,
        name: patientInfo?.race,
        label: patientInfo?.race,
      });
    }
  }, [patientInfo]);

  return (
    <SlideUp>
    <div>
      <SubNavbar back='Home' next='Basic Informtion' backClick='/dashboard' />
      <form className="p-6 overflow-y-auto max-h-[80vh]">
        <div>
          <p className="font-campton_m text-18">Basic Information</p>
          <p className="mt-2 font-campton_r text-neutral_body text-14">
          Update your personal details for a better healthcare experience. 
          </p>
          <div className="grid gap-6 grid-cols-12 mt-6">
            <div className="md:col-span-6 col-span-12">
              <Input
                label="First Name"
                type="text"
                id="first_name"
                name="firstName"
                readOnly={loading}
                value={formData?.firstName}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Last Name"
                type="text"
                id="last_name"
                name="lastName"
                readOnly={loading}
                value={formData?.lastName}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <DefaultDatepicker
              label="Date of Birth"
              containerVariant="lg:col-span-4 md:col-span-6 col-span-12"
              startDate={startDate}
              maxDate={new Date()}
              onSelect={(date) => setStartDate(date)}
              name="dateOfBirth"
              value={formData?.dateOfBirth}
            />
            <Dropdown
              label="Gender"
              variant="lg:col-span-4 md:col-span-6 col-span-12"
              name="gender"
              id="gender"
              value={gender}
              disabled={loading}
              optionContainerVariant="w-full md:min-h-[100px] min-h-[150px]"
              options={genderOptions}
              onChange={(data) => {
                setGender(data);
                setFormData({ ...formData, gender: data.value });
              }}
            />
            <Dropdown
              label="Marital Status"
              variant="lg:col-span-4 md:col-span-6 col-span-12"
              name="maritalStatus"
              id="maritalStatus"
              value={maritalStatus}
              disabled={loading}
              optionContainerVariant="w-full min-h-[80px]"
              options={maritalStatusOptions}
              onChange={(data) => {
                setMaritalStatus(data);
                setFormData({ ...formData, maritalStatus: data.value });
              }}
            />
            <Dropdown
              label="Race"
              variant="md:col-span-6 col-span-12"
              name="race"
              id="race"
              value={race}
              disabled={loading}
              optionContainerVariant="w-full min-h-[80px]"
              options={raceOptions}
              onChange={(data) => {
                setRace(data);
                setFormData({ ...formData, race: data.value });
              }}
            />
            <Dropdown
              label="Ethnicity"
              variant="md:col-span-6 col-span-12"
              name="ethnicity"
              id="ethnicity"
              value={ethnicity}
              disabled={loading}
              optionContainerVariant="w-full min-h-[80px]"
              options={ethnicityOptions}
              onChange={(data) => {
                setEthnicity(data);
                setFormData({ ...formData, ethnicity: data.value });
              }}
            />
          </div>
        </div>
        <hr className="border-y border-dashed border-neutral_stroke my-6" />
        <div>
          <p className="text-primary_shade_100 font-campton_m text-14 mb-6">My Contact Information</p>
          <div className="grid gap-6 grid-cols-12 mt-6">
            <Phone value={phone} onChange={setPhone} variant="md:col-span-6 col-span-12" disabled />
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Email"
                type="text"
                id="email"
                name="emailAddress"
                readOnly={true}
                value={formData?.emailAddress}
                onChange={handleChange}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <TextArea
                label="Home Address"
                id="homeAddress"
                name="homeAddress"
                readOnly={loading}
                rows={3}
                value={formData?.homeAddress}
                onChange={handleChange}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <TextArea
                label="Office Address"
                id="officeAddress"
                name="officeAddress"
                readOnly={loading}
                rows={3}
                value={formData?.officeAddress}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center space-x-6 mt-10">
          <Button
            name="Cancel"
            theme='transparent'
            onClick={() => navigate('/dashboard')}
            className="w-fit py-2 px-3 bg-neutral_white border border-neutral_smoke_2 text-neutral_body"
          />
          <Button
            name="Save"
            loading={loading}
            onClick={(e) => updateUserInfo(e)}
            theme="blue"
            className="w-28 py-2 px-3"
          />
        </div>
      </form>
    </div>
    </SlideUp>
  );
};

export default UserProfile;
