/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notify } from "../../store/modules/global/index"
import { motion } from "framer-motion";

export default function Notification() {
    let dispatch = useDispatch()
    const { display, message, status } = useSelector(state => state.global.notification)
    const style = {
        open: { y: 0, transition: { type: 'spring', duration: .1 } },
        close: { y: -100 }
    }

    const close = () => {
        dispatch(notify({ display: false }))
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            close()
        }, 5000)

        return () => clearTimeout(timer)
    }, [display, message])

    return (
        <motion.div
            animate={display ? style.open : style.close}
            initial={style.close}
            data-testid="notification"
            id="notification"
            className={
                `transition ease-in-out duration-1000 fixed top-4
                ${status === 'success' ? 'bg-green' : 'bg-error'} right-[1rem] z-[5000]
                max-w-[91.5%] sm:max-w-[560px] rounded-[8px] flex items-center p-[16px]`
            }
        >
            <div className="border-r border-r-neutral_white pr-[16px]">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[30px] h-[30px]">
                    <rect width="40" height="40" rx="20" fill="white" />
                    <path d="M24.2806 17.2194C24.3504 17.289 24.4057 17.3717 24.4434 17.4628C24.4812 17.5538 24.5006 17.6514 24.5006 17.75C24.5006 17.8486 24.4812 17.9462 24.4434 18.0372C24.4057 18.1283 24.3504 18.211 24.2806 18.2806L19.0306 23.5306C18.961 23.6004 18.8783 23.6557 18.7872 23.6934C18.6962 23.7312 18.5986 23.7506 18.5 23.7506C18.4014 23.7506 18.3038 23.7312 18.2128 23.6934C18.1218 23.6557 18.039 23.6004 17.9694 23.5306L15.7194 21.2806C15.5786 21.1399 15.4996 20.949 15.4996 20.75C15.4996 20.551 15.5786 20.3601 15.7194 20.2194C15.8601 20.0786 16.051 19.9996 16.25 19.9996C16.449 19.9996 16.6399 20.0786 16.7806 20.2194L18.5 21.9397L23.2194 17.2194C23.289 17.1496 23.3718 17.0943 23.4628 17.0566C23.5538 17.0188 23.6514 16.9994 23.75 16.9994C23.8486 16.9994 23.9462 17.0188 24.0372 17.0566C24.1283 17.0943 24.211 17.1496 24.2806 17.2194ZM29.75 20C29.75 21.9284 29.1782 23.8134 28.1068 25.4168C27.0355 27.0202 25.5127 28.2699 23.7312 29.0078C21.9496 29.7458 19.9892 29.9389 18.0979 29.5627C16.2066 29.1865 14.4693 28.2579 13.1057 26.8943C11.7422 25.5307 10.8136 23.7934 10.4373 21.9021C10.0611 20.0108 10.2542 18.0504 10.9922 16.2688C11.7301 14.4873 12.9798 12.9645 14.5832 11.8932C16.1866 10.8218 18.0716 10.25 20 10.25C22.585 10.2527 25.0634 11.2808 26.8913 13.1087C28.7192 14.9366 29.7473 17.415 29.75 20ZM28.25 20C28.25 18.3683 27.7662 16.7733 26.8596 15.4165C25.9531 14.0598 24.6646 13.0024 23.1571 12.378C21.6497 11.7536 19.9909 11.5902 18.3905 11.9085C16.7902 12.2268 15.3202 13.0126 14.1664 14.1664C13.0126 15.3202 12.2269 16.7902 11.9085 18.3905C11.5902 19.9908 11.7536 21.6496 12.378 23.1571C13.0024 24.6646 14.0598 25.9531 15.4165 26.8596C16.7733 27.7661 18.3683 28.25 20 28.25C22.1873 28.2475 24.2843 27.3775 25.8309 25.8309C27.3775 24.2843 28.2475 22.1873 28.25 20Z" fill="#153E4C" />
                </svg>
            </div>
            <p
                className={
                    `font-campton_m text-14 leading-[21px] ml-[16px] mr-[2rem] text-neutral_white`
                }
            >
                {message}
            </p>
            <div
                data-testid="close"
                className="cursor-pointer hide_tap"
                onClick={() => close()}
            >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[30px] h-[30px]">
                    <rect width="40" height="40" rx="8" fill="black" fillOpacity="0.08" />
                    <path d="M27.281 26.2194C27.3507 26.2891 27.406 26.3718 27.4437 26.4629C27.4814 26.5539 27.5008 26.6515 27.5008 26.7501C27.5008 26.8486 27.4814 26.9462 27.4437 27.0372C27.406 27.1283 27.3507 27.211 27.281 27.2807C27.2114 27.3504 27.1286 27.4056 27.0376 27.4433C26.9465 27.4811 26.849 27.5005 26.7504 27.5005C26.6519 27.5005 26.5543 27.4811 26.4632 27.4433C26.3722 27.4056 26.2895 27.3504 26.2198 27.2807L20.0004 21.0604L13.781 27.2807C13.6403 27.4214 13.4494 27.5005 13.2504 27.5005C13.0514 27.5005 12.8605 27.4214 12.7198 27.2807C12.5791 27.1399 12.5 26.9491 12.5 26.7501C12.5 26.551 12.5791 26.3602 12.7198 26.2194L18.9401 20.0001L12.7198 13.7807C12.5791 13.6399 12.5 13.4491 12.5 13.2501C12.5 13.051 12.5791 12.8602 12.7198 12.7194C12.8605 12.5787 13.0514 12.4996 13.2504 12.4996C13.4494 12.4996 13.6403 12.5787 13.781 12.7194L20.0004 18.9397L26.2198 12.7194C26.3605 12.5787 26.5514 12.4996 26.7504 12.4996C26.9494 12.4996 27.1403 12.5787 27.281 12.7194C27.4218 12.8602 27.5008 13.051 27.5008 13.2501C27.5008 13.4491 27.4218 13.6399 27.281 13.7807L21.0607 20.0001L27.281 26.2194Z" fill="white" />
                </svg>
            </div>
        </motion.div>
    )
}