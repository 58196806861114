import { useState } from 'react'
import useMakeRequest from 'hooks/useMakeRequest'
import useTryCatch from 'hooks/useTryCatch'
import { getProviders, getStaffWithAvailableTime } from 'store/modules/providers/actions'

export default function useProviders() {
    const { makeRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_providers = async (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getProviders({ params }), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }
    const get_staff_with_available_time = async (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getStaffWithAvailableTime({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        get_providers,
        get_staff_with_available_time,
        loading
    }
}
