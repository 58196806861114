import React, { useEffect, useState } from 'react';
import { Title } from '../Components';
import StatusCard from './Components/StatusCard';
import Medications from './Medications';
import Procedures from './Procedures';
import Therapies from './Therapies';
import Referrals from './Referrals';
import { SlideUp } from 'components/Animations';
import { treatmentPlan } from 'mocks/myRecords';
import Lifestyle from './LifeStyle';
import useMyRecords from 'hooks/fetch/useMyRecords';
import { useAuthState } from 'store/modules/auth';
import { useRecordState } from 'store/modules/myRecords';

const TreatmentPlan = () => {
  const [view, setView] = useState('');
  const {referrals, therapies, procedures, medications} = useRecordState()

  const views = {
    Medications,
    Procedures,
    Therapies,
    Referrals,
    Lifestyle
  };

  const status = {
    Procedures : procedures?.total ?? 0,
    Referrals : referrals?.total ?? 0,
    Therapies : therapies?.total ?? 0,
    Medications: medications?.total ?? 0,
    Lifestyle: 0
  }

  const View = views[view];

  const { get_procedures, get_referrals, get_therapies, loading, get_medications } = useMyRecords();
  const {loggedUser} = useAuthState()
  useEffect(() => {
    const params = {
      type: 'patient',
      patientId: loggedUser._id
    }
    get_procedures(params);
    get_referrals(params);
    get_therapies(params);
    get_medications(params?.patientId)
  }, [])
  
  return (
    <SlideUp>
      <section>
        {(view === 'Medications' || !view) && (
          <>
            <Title name="Treatment Plan" />
            <div className="grid grid-cols-12 gap-4">
            {treatmentPlan.map((item) =>
              <StatusCard
              key={item.title}
                containerVariant={item.style}
                variant="px-4"
                loading={loading}
                icon={<item.icon/>}
                title={item.title === 'Lifestyle' ? 'Lifestyle Recommendations' : item.title}
                description={item.description}
                status={status[item.title]}
                onClick={() => setView(item.title)}
              />
        )}
            </div>
          </>
        )}
        {view && <View callback={() => setView('')}/>}
      </section>
    </SlideUp>
  );
};

export default TreatmentPlan;
