import React from 'react'
import EmptyState from 'components/EmptyState';
import { ReactComponent as Examination } from 'assets/svg/examination.svg';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import PropTypes from 'prop-types';

const PhysicalExamination = ({physicalExamination}) => {
  return (
    <>
        {physicalExamination && (
        <div className="bg-[#FAFAFA] mt-2">
            <div className="flex w-full border-b-[0.5px] border-neutral_stroke_2 py-2.5 px-4">
            <Document className="mr-3" />
            <p className="font-campton_r text-brand_secondary text-12 ">Physical Examination</p>
            </div>
            <div className="mt-1 grid px-3 pb-2.5">
            {physicalExamination?.length ? (
                physicalExamination?.map((item) => (
                <div key={item?._id} className="col-span-6">
                    <p className="text-10 text-neutral_black font-campton_m mb-[8px]">{item?.title}</p>
                    <p className="text-[8px] text-neutral_body font-campton_r leading-3 mt-0.5">
                    {item?.observation}
                    </p>
                </div>
                ))
            ) : (
                <EmptyState
                illustration={<Examination />}
                title="No Examination"
                description="All physical examinations will be displayed here when added"
                />
            )}
            </div>
        </div>
        )}
    </>
  )
}

export default PhysicalExamination;
PhysicalExamination.propTypes = {
    physicalExamination: PropTypes.array,
  };