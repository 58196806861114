import React, { useContext } from "react"
import PropTypes from "prop-types"
import Button from "components/Buttons/Button"
import useMessaging from "hooks/fetch/useMessaging"
import { MessagingContext } from "context"

export default function DeleteMessage({ cancel, reset }) {
    const { delete_conversation, get_conversations, loading } = useMessaging()
    const { currentTab, selectedMessage, setSelectedMessage } = useContext(MessagingContext)

    const deleteMsg = () => {
        delete_conversation(selectedMessage?.conversationId)
        .then(res => {
            if (res?.code === 200) {
                cancel()
                reset()
                setSelectedMessage()
                get_conversations({ type: currentTab === 'Trash' ? 'trash' : 'messages' })
            }
        })
    }

    return (
        <div className="flex flex-col items-center text-center">
            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="40" height="40" rx="20" fill="#EBF6FA" />
                <path d="M28.75 12.5H25V11.75C25 11.1533 24.7629 10.581 24.341 10.159C23.919 9.73705 23.3467 9.5 22.75 9.5H18.25C17.6533 9.5 17.081 9.73705 16.659 10.159C16.2371 10.581 16 11.1533 16 11.75V12.5H12.25C12.0511 12.5 11.8603 12.579 11.7197 12.7197C11.579 12.8603 11.5 13.0511 11.5 13.25C11.5 13.4489 11.579 13.6397 11.7197 13.7803C11.8603 13.921 12.0511 14 12.25 14H13V27.5C13 27.8978 13.158 28.2794 13.4393 28.5607C13.7206 28.842 14.1022 29 14.5 29H26.5C26.8978 29 27.2794 28.842 27.5607 28.5607C27.842 28.2794 28 27.8978 28 27.5V14H28.75C28.9489 14 29.1397 13.921 29.2803 13.7803C29.421 13.6397 29.5 13.4489 29.5 13.25C29.5 13.0511 29.421 12.8603 29.2803 12.7197C29.1397 12.579 28.9489 12.5 28.75 12.5ZM17.5 11.75C17.5 11.5511 17.579 11.3603 17.7197 11.2197C17.8603 11.079 18.0511 11 18.25 11H22.75C22.9489 11 23.1397 11.079 23.2803 11.2197C23.421 11.3603 23.5 11.5511 23.5 11.75V12.5H17.5V11.75ZM26.5 27.5H14.5V14H26.5V27.5ZM19 17.75V23.75C19 23.9489 18.921 24.1397 18.7803 24.2803C18.6397 24.421 18.4489 24.5 18.25 24.5C18.0511 24.5 17.8603 24.421 17.7197 24.2803C17.579 24.1397 17.5 23.9489 17.5 23.75V17.75C17.5 17.5511 17.579 17.3603 17.7197 17.2197C17.8603 17.079 18.0511 17 18.25 17C18.4489 17 18.6397 17.079 18.7803 17.2197C18.921 17.3603 19 17.5511 19 17.75ZM23.5 17.75V23.75C23.5 23.9489 23.421 24.1397 23.2803 24.2803C23.1397 24.421 22.9489 24.5 22.75 24.5C22.5511 24.5 22.3603 24.421 22.2197 24.2803C22.079 24.1397 22 23.9489 22 23.75V17.75C22 17.5511 22.079 17.3603 22.2197 17.2197C22.3603 17.079 22.5511 17 22.75 17C22.9489 17 23.1397 17.079 23.2803 17.2197C23.421 17.3603 23.5 17.5511 23.5 17.75Z" fill="#36A1C5" />
            </svg>
            <h3 className="text-16 text-neutral_black font-campton_m mb-[8px] mt-[24px]">Delete</h3>
            <p className="text-14 text-neutral_black leading-[21px] mb-[24px]">Would you like to delete this conversation?</p>
            <div className="flex">
                <Button
                    name="delete"
                    theme="transparent"
                    className="w-28 h-[40px] mr-[16px]"
                    disabled={loading}
                    loading={loading}
                    onClick={deleteMsg}
                />
                <Button
                    name="Cancel"
                    theme="blue"
                    className="w-28 h-[40px]"
                    disabled={loading}
                    onClick={cancel}
                />
            </div>
        </div>
    )
}

DeleteMessage.propTypes = {
    reset: PropTypes.func,
    cancel: PropTypes.func,
}