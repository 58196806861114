import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { ReactComponent as BloodTypeSolid } from 'assets/icons/medicalRecords/blood-type-solid.svg';
import { ReactComponent as Saturation } from 'assets/icons/vitals/saturation.svg';
import { ReactComponent as Temperature } from 'assets/icons/vitals/temperature.svg';
import { ReactComponent as HeartRate } from 'assets/icons/vitals/heartbeat.svg';
import { ReactComponent as Height } from 'assets/icons/vitals/height.svg';
import { ReactComponent as ResRate } from 'assets/icons/vitals/respiration.svg';
import { ReactComponent as Weight } from 'assets/icons/medicalRecords/lifestyle.svg';
import { ReactComponent as Bmi } from 'assets/icons/vitals/bmi.svg';
import VitalsCard from './VitalsCard';
import { extractPatientVitals } from 'utils/extractPatientVitals';

const ViewSingleVitals = ({ vitalSign }) => {
  return (
    <div className='modal-right-body'>
      <div className="bg-primary_tint_50 p-4 rounded-2xl mt-4">
        <div className="text-12 flex justify-between">
          <p className="font-campton_m text-neutral_black">{dayjs(vitalSign?.updatedAt).format('MMM DD, YYYY')}</p>
          <p className="font-campton_r text-neutral_body">{dayjs(vitalSign?.updatedAt).format('hh:mm A')}</p>
        </div>
        <p className="font-campton_r text-12 text-neutral_body mt-1 mb-2 capitalize-first">{vitalSign?.additionalNote}</p>
        <p className="text-12 text-neutral_body mb-[8px] font-campton_r">{extractPatientVitals(vitalSign)?.join(", ")}</p>
        <p className="border border-neutral_stroke_2 py-1 px-2 w-fit rounded-full text-12 font-campton_r capitalize">
          {vitalSign.recordedBy.businessName || `${vitalSign.recordedBy?.firstName + ' ' + vitalSign.recordedBy?.lastName}`}
        </p>
      </div>
      <div className="border rounded-2xl border-neutral_stroke_2 px-4 mt-6">
        <div className="">
          <div className="border-b border-neutral_stroke_2 py-4 grid grid-cols-2 gap-4">
            <VitalsCard
              title="BP"
              value={vitalSign?.sysBloodPressure && `${vitalSign?.sysBloodPressure}/${vitalSign?.diaBloodPressure}mmHg`}
              icon={<BloodTypeSolid />}
            />
            <VitalsCard
              title="Oxy. Saturation"
              value={vitalSign?.oxygenSaturation && `${vitalSign?.oxygenSaturation}%`}
              icon={<Saturation className="w-7 h-7" />}
              label="%"
            />
          </div>
          <div className="border-b border-neutral_stroke_2 py-4 grid grid-cols-2 gap-4">
            <VitalsCard
              title="Temperature"
              value={vitalSign?.temperature && `${vitalSign?.temperature}°C`}
              icon={<Temperature className="w-7 h-7" />}
            />
            <VitalsCard
              title="Heart Rate"
              value={vitalSign?.heartRate && `${vitalSign?.heartRate}bpm`}
              icon={<HeartRate className="w-7 h-7" />}
            />
          </div>
          <div className="border-b border-neutral_stroke_2 py-4 grid grid-cols-2 gap-4">
            <VitalsCard
              title="Res Rate"
              value={vitalSign?.respiratoryRate && `${vitalSign?.respiratoryRate}bpm`}
              icon={<ResRate className="w-7 h-7" />}
            />
            <VitalsCard
              title="Height"
              value={vitalSign?.height && `${vitalSign?.height}meters`}
              icon={<Height className="w-7 h-7" />}
            />
          </div>
          <div className="py-4 grid grid-cols-2 gap-4">
            <VitalsCard
              title="Weight"
              value={vitalSign?.weight && `${vitalSign?.weight}kg`}
              icon={<Weight className="w-7 h-7" />}
            />
            <VitalsCard
              title="BMI"
              value={vitalSign?.bmi && Number(vitalSign?.bmi).toFixed(2)}
              icon={<Bmi className="w-6 h-6" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSingleVitals;
ViewSingleVitals.propTypes = {
  vitalSign: PropTypes.object,
};
