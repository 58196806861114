import React, { useEffect, useState } from 'react';
import Button from 'components/Buttons/Button';
import PropTypes from 'prop-types';
import AppointmentInfo from './Components/AppointmentInfo';
import { useDispatch } from 'react-redux';
import { setLiveAppointment } from 'store/modules/appointments';
import DefaultModal from 'components/Modals/DefaultModal';
import CancelAppointment from './CancelAppointment';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import RescheduleAppointment from './RescheduleAppointment';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import useAppointment from 'hooks/fetch/useAppointment';
import useTable from 'hooks/useTable';
import dayjs from 'dayjs';
import { isTimeInRange } from 'utils/formatDate';
import { useAuthState } from 'store/modules/auth';

const ViewAppointment = ({ data, callback, selectedTab }) => {
  const dispatch = useDispatch()
  const [confirm, setConfirm] = useState(false);
  const [type, setType] = useState('');
  const { loggedUser } = useAuthState()
  const { params } = useTable();
  const { cancel_appointment, loading, get_appointments, reschedule_appointment } = useAppointment();
  const [liveCall, setLiveCall] = useState(false)
  const [formData, setFormData] = useState({
    reason: '',
    other_reason: '',
  });
  const [rescheduleData, setRescheduleData] = useState({
    proposedDate: data.appointmentDate ? new Date(data.appointmentDate) : '',
    proposedStartTime: dayjs(data.startTime).format('hh:mm A') ?? '',
  });
  const isTimeInRangeProxy = isTimeInRange(data?.appointmentDate, data?.startTime, data?.endTime)

  const checkLiveCall = () => {
    if (['ongoing', 'upcoming', 'rescheduled'].includes(data?.status?.toLowerCase()) && data?.type === 'inApp' && isTimeInRangeProxy)
      setLiveCall(true)
    else
      setLiveCall(false)
  }

  const handleCallback = () => {
    setType('');
    callback();
    get_appointments({
      ...params, status: 'upcoming',
      requestType: 'patient',
      patientId: loggedUser?._id
    });
  };

  useEffect(() => {
    let interval = 1000 * 30 // 30 seconds
    let timer = setInterval(() => {
      checkLiveCall()
    }, interval)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    checkLiveCall()
  }, [])

  const cancelAppointment = () => {
    cancel_appointment(
      `${data._id}/cancel`,
      {
        reason: formData.reason === 'others' ? formData.other_reason : formData.reason,
      },
      handleCallback,
    );
  };

  const rescheduleAppointment = () => {
    reschedule_appointment(
      `${data._id}/reschedule`,
      {
        ...rescheduleData,
        proposedDate: dayjs(rescheduleData.proposedDate).format('YYYY-MM-DD'),
        reason: formData.reason === 'others' ? formData.other_reason : formData.reason,
      },
      handleCallback,
    );
  };

  const joinCall = (roomId) => {
    if (!data?.liveCallId) return
    dispatch(setLiveAppointment(data))
    const anchor = document.createElement('a')
    anchor.href = `/appointments/live/${roomId}`
    anchor.target = '_blank'
    anchor.click()
  }

  return (
    <div className="relative">
      <div className='modal-right-body'>
        <AppointmentInfo data={data} />
      </div>
      <div className="modal-right-button-container">
        {liveCall ? (
          <Button
            name="Join call"
            theme="blue"
            onClick={() => joinCall(data?.liveCallId)}
            className="w-32 h-[40px] bg-success text-neutral_white"
          />
        ) : (
          <>
            {selectedTab !== 'cancelled' && (
              <>
                <Button
                  name="Cancel appointment"
                  theme="transparent"
                  className="w-fit h-[40px] mr-4"
                  onClick={() => setType('Cancel')}
                />
                <Button name="Reschedule" theme="blue" className="w-28 h-[40px]" onClick={() => setType('Reschedule')} />
              </>
            )}
          </>
        )}
      </div>
      {type && (
        <DefaultModal
          closeModal={() => setType('')}
          icon={type && <ArrowBack fill="#748181" className="w-2" />}
          styles="w-full md:w-[457px]"
          containerVariant='overflow-y-scroll'
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={`${type} Appointment `}
          footer={
            <div className="bg-neutral_white h-[0px]">
              <div className="mt-1 space-x-4  fixed bottom-5 hidden sm:flex flex-col px-6 py-4 border-t border-neutral_stroke_2 bg-neutral_white">
                <div className="flex">
                  <div data-testid="test-check" className="cursor-pointer" onClick={() => setConfirm((prev) => !prev)}>
                    {confirm ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-neutral_body font-campton_r text-14 ml-3">
                    I have read and agree with reddington hospital’s {' '}
                    <span className="underline text-brand_primary cursor-pointer">Appointment Policy</span>
                  </p>
                </div>
                <Button
                  name={`${type} appointment`}
                  theme="blue"
                  loading={loading}
                  onClick={() => {
                    type === 'Cancel' ? cancelAppointment() : rescheduleAppointment();
                  }}
                  className="w-full h-[60px] py-3 mt-4"
                  disabled={!confirm}
                />
              </div>
            </div>
          }
        >
          {type === 'Cancel' ? (
            <CancelAppointment data={data} formData={formData} setFormData={setFormData} />
          ) : (
            <RescheduleAppointment
              data={data}
              formData={rescheduleData}
              setFormData={setRescheduleData}
              reasonData={formData}
              setReasonData={setFormData}
            />
          )}
        </DefaultModal>
      )}
    </div>
  );
};

export default ViewAppointment;
ViewAppointment.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
  selectedTab: PropTypes.string
};
