import Button from 'components/Buttons/Button';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import TextArea from 'components/Inputs/TextArea';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setUserInfo, useAuthState } from 'store/modules/auth';
import { useAppDispatch } from 'hooks/useReduxHook';
import { useRecordState } from 'store/modules/myRecords';
import { REGEX_NUMBERS } from 'constants';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';

const AddEmergencyContact = ({ type, callback, selectedRecord }) => {
  const { userInfo } = useAuthState();
  const { loading, patientId } = useRecordState();
  const dispatch = useAppDispatch();
  const { update_medical_record, edit_medical_record } = useMedicalRecord();
  const [phone, setPhone] = useState(type === 'Edit' ? selectedRecord?.countryCode + selectedRecord?.phoneNumber : '');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    relationship: '',
    address: '',
    // phoneNumber: '',
  });

  useEffect(() => {
    if (selectedRecord && type === 'Edit') {
      dispatch(
        setUserInfo({
          phoneNumber: selectedRecord?.phoneNumber,
          countryCode: selectedRecord?.countryCode,
        }),
      );
      setFormData({
        firstName: selectedRecord?.firstName,
        lastName: selectedRecord?.lastName,
        emailAddress: selectedRecord?.emailAddress,
        phoneNumber: selectedRecord?.phoneNumber,
        address: selectedRecord?.address,
        relationship: selectedRecord?.relationship,
      });
    }
  }, [selectedRecord, type]);

  const handleChange = (e) => {
    if (['firstName', 'lastName', 'relationship'].includes(e.target.name) && REGEX_NUMBERS?.test(e.target.value)) return
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateMedicalRecord = () => {
    const payload = { ...formData, ...userInfo };
    type === 'Edit'
      ? edit_medical_record('emergencyContact', selectedRecord._id, payload, callback)
      : update_medical_record('emergencyContact', patientId, payload, callback);
  };

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={updateMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6 mb-[30px]">
            <div className="md:col-span-6 col-span-12">
              <Input
                label="First Name"
                type="text"
                id="firstName"
                name="firstName"
                readOnly={loading}
                value={formData?.firstName}
                onChange={handleChange}
                showError={true}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Last Name"
                type="text"
                id="lastName"
                name="lastName"
                readOnly={loading}
                value={formData?.lastName}
                onChange={handleChange}
                showError={true}
              />
            </div>
            <Phone value={phone} onChange={setPhone} variant="lg:col-span-4 md:col-col-6 col-span-12" />
            <div className="lg:col-span-4 md:col-col-6 col-span-12">
              <Input
                label="Email"
                type="text"
                id="emailAddress"
                name="emailAddress"
                readOnly={loading}
                value={formData?.emailAddress}
                onChange={handleChange}
                showError={true}
              />
            </div>
            <div className="lg:col-span-4 md:col-col-6 col-span-12">
              <Input
                label="Relationship"
                type="text"
                id="relationship"
                name="relationship"
                //   readOnly={loading}
                value={formData?.relationship}
                onChange={handleChange}
                showError={true}
              />
            </div>
            <div className="col-span-12">
              <TextArea
                label="Address"
                id="address"
                name="address"
                readOnly={loading}
                rows={2}
                value={formData?.address}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button name="Save" theme="blue" className="w-28 h-[40px]" disabled={checkEmptyProperties(formData) || !userInfo?.phoneNumber} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddEmergencyContact;
AddEmergencyContact.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};
