import React from "react";
import PropTypes from "prop-types"
import EmptyState from "components/EmptyState";
import Thread from "components/Thread";
import Avatar from "components/Avatar/Avatar";
import { userInitials } from "utils/ImagePlaceholder";
import Billing from "../Overview/Billing";
import Spinner from "components/Spinner";
import { formatCurrency } from "utils/formatCurrency";

export default function PaymentHistory({ paymentHistory, selectedPayment, loading }) {
    return (
        <div className="relative">
            <div className="modal-right-body">
                <Billing selectedPayment={selectedPayment} />
                {loading && <Spinner />}
                {!loading &&
                    <>
                        {paymentHistory?.length ? paymentHistory?.map((history) => (
                            <Thread key={history?._id} createdAt={history?.createdAt}>
                                <>
                                    <p className="text-12 text-neutral_body font-campton_r mb-[8px]">Payer</p>
                                    <div className="flex mb-[8px]">
                                        <Avatar
                                            url={history?.patientInfo?.profilePic}
                                            width="32px"
                                            height="32px"
                                            alt="profile"
                                            initials={userInitials(`${history?.patientInfo?.firstName}`, `${history?.patientInfo?.lastName}`)}
                                            className="bg-brand_secondary w-[32px] h-[32px] shrink-0 mr-[8px]"
                                        />
                                        <div className="w-full">
                                            <p className="text-14 text-neutral_black font-campton_m capitalize">
                                                {history?.patientInfo?.firstName} {history?.patientInfo?.lastName}
                                            </p>
                                            <p className="text-12 text-neutral_body font-campton_r flex justify-between">
                                                <span className="block w-[60%] truncate">{history?.patientInfo?.emailAddress}</span>
                                                <span className="block text-right">{history?.patientInfo?.gNumber}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between mb-[8px]">
                                        <p className="text-12 text-neutral_body font-campton_r">Transaction ID</p>
                                        <p className="text-12 text-neutral_black font-campton_r">
                                            {`${history?.reference}`}
                                        </p>
                                    </div>
                                    <div className="flex justify-between mb-[8px]">
                                        <p className="text-12 text-neutral_body font-campton_r">Amount paid</p>
                                        <p className="text-12 text-neutral_black font-campton_r">
                                            {formatCurrency(history?.amount)}
                                        </p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className="text-12 text-neutral_body font-campton_r">Outstanding amount</p>
                                        <p className="text-12 text-neutral_black font-campton_r">
                                            {formatCurrency(history?.outstandingAmount)}
                                        </p>
                                    </div>
                                </>
                            </Thread>
                        )) :
                            <section className="bg-[#FAFAFA] rounded-[16px] p-[8px] h-full">
                                <EmptyState
                                    title={<span className="text-14">There’s currently no payment history</span>}
                                    description={<span className="text-12">All your transactions for this bill will be displayed here.</span>}
                                />
                            </section>
                        }
                    </>
                }
            </div>
        </div>
    )
}

PaymentHistory.propTypes = {
    paymentHistory: PropTypes.array,
    loading: PropTypes.bool,
    selectedPayment: PropTypes.object
}