import Button from 'components/Buttons/Button';
import React, { useMemo, useState } from 'react';
import { ReactComponent as BloodTypeSolid } from 'assets/icons/medicalRecords/blood-type-solid.svg';
import { ReactComponent as Saturation } from 'assets/icons/vitals/saturation.svg';
import { ReactComponent as Temperature } from 'assets/icons/vitals/temperature.svg';
import { ReactComponent as HeartRate } from 'assets/icons/vitals/heartbeat.svg';
import { ReactComponent as Height } from 'assets/icons/vitals/height.svg';
import { ReactComponent as ResRate } from 'assets/icons/vitals/respiration.svg';
import { ReactComponent as Weight } from 'assets/icons/medicalRecords/lifestyle.svg';
import { ReactComponent as Bmi } from 'assets/icons/vitals/bmi.svg';
import VitalsHistory from './Component/VitalsHistory';
import AddVitals from './Component/AddVitals';
import DefaultModal from 'components/Modals/DefaultModal';
import VitalsCard from './Component/VitalsCard';
import VitalSignsAnalytics from './Component/VitalSignsAnalytics';
import useVitalsAnalytics from 'hooks/useVitalsAnalytics';

export default function VitalSigns() {
  const [showModal, setShowModal] = useState(false);
  const {
    loading: loadingVitals,
    selectedTab,
    toggleTab,
    vitalsHistory,
    getVitals
  } = useVitalsAnalytics({ className: 'h-[200px]' });

  const vitalSign = useMemo(() => {
    return vitalsHistory?.[0]
  }, [vitalsHistory])

  const vitals = [
    {
      icon: <BloodTypeSolid className="w-10 h-10" />,
      title: 'BP',
      value: vitalSign?.sysBloodPressure && `${vitalSign?.sysBloodPressure}/${vitalSign?.diaBloodPressure}mmHg`
    },
    {
      icon: <Saturation />,
      title: 'Oxy. Saturation',
      value: vitalSign?.oxygenSaturation && `${vitalSign?.oxygenSaturation}%`,
      variant: 'text-[#935F06]'
    },
    {
      icon: <Temperature />,
      title: 'Temperature',
      value: vitalSign?.temperature && `${vitalSign?.temperature}°C`,
      variant: 'text-[#881616]'
    },
    {
      icon: <HeartRate />,
      title: 'Heart Rate',
      value: vitalSign?.heartRate && `${vitalSign?.heartRate}bpm`
    },
    {
      icon: <ResRate />,
      title: 'Res Rate',
      value: vitalSign?.respiratoryRate && `${vitalSign?.respiratoryRate}bpm`,
      variant: 'text-[#106032]'
    },
    {
      icon: <Height />,
      title: 'Height',
      value: vitalSign?.height && `${vitalSign?.height}meters`,
      variant: 'text-[#935F06]'
    },
    {
      icon: <Weight />,
      title: 'Weight',
      value: vitalSign?.weight && `${vitalSign?.weight}kg`,
      variant: 'text-[#881616]'
    },
    {
      icon: <Bmi />,
      title: 'BMI',
      value: vitalSign?.bmi && `${Number(vitalSign?.bmi).toFixed(2)}`,
      variant: 'text-[#935F06]',
    },
  ];

  const callback = () => {
    setShowModal(false)
    getVitals()
  }

  return (
    <div>
      <div className="flex justify-between items-center mt-6 mb-3">
        <p className="font-campton_sb text-18 text-black_100">Vitals</p>
        <Button name="New Vitals" theme="blue" className="w-fit h-8" onClick={() => setShowModal(true)} />
      </div>
      <div className="border border-neutral_stroke_2 rounded-2xl flex overflow-x-scroll">
        {vitals?.map((item) => (
          <div key={item?.title} className="first-of-type:border-l-0 border-l border-neutral_stroke_2">
            <VitalsCard
              title={item?.title}
              value={item?.value}
              variant={item?.variant}
              icon={item?.icon}
              loading={loadingVitals}
              className="border-l p-4 w-[170px]"
            />
          </div>
        ))}
      </div>
      <div className="mb-[24px] bg-primary_tint_50 p-4 rounded-2xl mt-6 h-[325px]">
        <VitalSignsAnalytics loading={loadingVitals} selectedTab={selectedTab} toggleTab={toggleTab} />
      </div>
      <VitalsHistory history={vitalsHistory} loading={loadingVitals} />
      {showModal && (
        <DefaultModal
          closeModal={() => setShowModal(false)}
          title="New Vital Signs"
          styles="min-w-[90%] md:min-w-[730px]"
          subTitle="Enter your latest vitals for a comprehensive overview of your well-being."
          containerVariant="bg-neutral_white"
        >
          <AddVitals callback={callback} />
        </DefaultModal>
      )}
    </div>
  );
}
