import React from 'react'
import { ReactComponent as Procedure } from 'assets/icons/treatmentplan/procedure.svg';
import { ReactComponent as Referral } from 'assets/icons/treatmentplan/referrals-bg.svg';
import { ReactComponent as Contact } from 'assets/icons/medicalRecords/emergency-contact.svg';
import { ReactComponent as Tablet } from 'assets/svg/home/paracetamol.svg';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import { ReactComponent as Examination } from 'assets/svg/examination.svg';
import EmptyState from 'components/EmptyState';

const TreatmentPlan = ({visitTreatmentPlan, progressNote }) => {
    const medications = progressNote?.medications ?? visitTreatmentPlan?.medications
    const procedures = progressNote?.procedures ?? visitTreatmentPlan?.procedures
    const referral = progressNote?.referrals ?? visitTreatmentPlan?.referral
    const therapies = progressNote?.medications ?? visitTreatmentPlan?.therapies

  return (
    <>
    {(medications?.length ||
        procedures?.length ||
        therapies?.length ||
        referral?.length) ? (
          <div className="bg-[#FAFAFA] mt-2">
            <div className="flex w-full border-b-[0.5px] border-neutral_stroke_2 py-2.5 px-4">
              <Document className="mr-3" />
              <p className="font-campton_r text-brand_secondary text-12 ">Treatment Plan</p>
            </div>
            <div className="py-2 text-[9px] font-campton_r">
              {medications?.length ? (
                <div className="border-b-[0.5px] border-neutral_stroke_2 px-4 pb-4">
                  <p className="flex items-center">
                    <Tablet className="w-6 h-6" /> <span className="ml-2 text-12">Medications</span>
                  </p>
                  {medications?.length ? (
                    medications?.map((item) => (
                      <li className="col-span-6 text-10 font-campton_r text-neutral_body mt-3" key={item.id}>
                        {item.medicationName} {item?.dosage} | {item.frequency} |{' '}
                        {dayjs(item.startDate).format('DD MMM, YYYY')} -{' '}
                        {item.endDate ? dayjs(item.endDate).format('DD MMM, YYYY') : 'N/A'}
                      </li>
                    ))
                  ) : (
                    <EmptyState
                      illustration={<Examination />}
                      title="No Medications Available"
                      description="All medications will be displayed here when added"
                    />
                  )}
                </div>
              ) : ''}
              {procedures?.length ? (
                <div className="border-b-[0.5px] border-neutral_stroke_2 px-4 py-4">
                  <p className="flex items-center">
                    <Procedure className="w-6 h-6" /> <span className="ml-2 text-12">Procedures</span>
                  </p>
                  {procedures?.length ? (
                    procedures?.map((item) => (
                      <li className="col-span-6 text-10 font-campton_r text-neutral_body mt-3" key={item.id}>
                        {item?.procedureName} | {dayjs(item?.procedureDate).format('DD MMM, YYYY')}
                      </li>
                    ))
                  ) : (
                    <EmptyState
                      illustration={<Examination />}
                      title="No Procedures Available"
                      description="All Procedures will be displayed here when added"
                    />
                  )}
                </div>
              ) : ''}
              {therapies?.length ? (
                <div className="border-b-[0.5px] border-neutral_stroke_2 px-4 py-4">
                  <p className="flex items-center">
                    <Contact className="w-6 h-6" /> <span className="ml-2 text-12">Recommended Therapies</span>
                  </p>
                  {therapies?.length ? (
                    therapies?.map((item) => (
                      <div key={item.id}>
                        <li className="col-span-6 text-10 font-campton_r text-neutral_body mt-3">
                          {item?.therapyName} | {dayjs(item?.therapyDate).format('DD MMM, YYYY')}, {item.startTime}{' '}
                          - {item.endTime}
                        </li>
                        <p className="text-neutral_body text-10 ml-3">{item.note}</p>
                        <div className="border-t-[0.5px] border-dashed border-t-neutral_stroke_2 mt-2 w-full p-2 font-campton_r text-10">
                          <div className="ml-4">
                            <p className="text-home-grey">Attending provider for this therapy</p>
                            <p className="text-neutral_black">
                              {item?.createdBy?.firstName} {item?.createdBy?.lastName}
                            </p>
                            <p className="text-neutral_body">
                              <span>
                                {item?.createdBy?.emailAddress}
                                {item?.createdBy?.GPINumber ? `| ${item?.createdBy?.GPINumber}` : ''}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <EmptyState
                      illustration={<Examination />}
                      title="No Therapies Available"
                      description="All Procedures will be displayed here when added"
                    />
                  )}
                </div>
              ) : ''}
              {referral?.length ? (
                <div className="px-4 py-4">
                  <p className="flex items-center">
                    <Referral className="w-6 h-6" /> <span className="ml-2 text-12">e-Referral</span>
                  </p>
                  {referral?.length ? (
                    referral?.map((item) => (
                      <div key={item?._id} className="mt-3">
                        <p className="text-neutral_body text-10">{item.reason}</p>
                        <p className="text-10 text-neutral_black font-campton_m">
                          Referral Date: {dayjs(item?.therapyDate).format('DD MMM, YYYY')}
                        </p>
                        <div className="border-t-[0.5px] border-dashed border-t-neutral_stroke_2 mt-2 ml-2 py-2">
                          <div className="ml-4">
                            <p className="text-neutral_black font-campton_m">Receiving Provider Information</p>
                            <p className="text-neutral_black">
                              {item?.receivingProviderId?.firstName} {item?.receivingProviderId?.lastName}
                            </p>
                            <p className="text-neutral_body">
                              {item?.receivingProviderId?.emailAddress} | {item?.receivingProviderId?.GPINumber}
                            </p>
                            <p className="text-neutral_body">{item?.receivingProviderId?.specialty}</p>
                          </div>
                        </div>
                        <div className="border-t-[0.5px] border-dashed border-t-neutral_stroke_2 py-2 ml-2">
                          <div className="ml-4">
                            <p className="text-neutral_black font-campton_m">Receiving Institution Information</p>
                            <p className="text-neutral_black">{item?.receivingInstitutionId?.businessName}</p>
                            <p className="text-neutral_body">
                              <span>{item?.receivingInstitutionId?.businessEmailAddress}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <EmptyState
                      illustration={<Examination />}
                      title="No Referrals Available"
                      description="Referrals will be displayed here when added"
                    />
                  )}
                </div>
              ) : ''}
            </div>
          </div>
        ) : ''}
    </>
  )
}

export default TreatmentPlan

TreatmentPlan.propTypes = {
    progressNote: PropTypes.object,
    visitTreatmentPlan: PropTypes.object
}