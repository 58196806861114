import Spinner from 'components/Spinner';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import React, { useState } from 'react';
import Header from '../Header';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Illustration } from 'assets/svg/surgeries-illustration.svg';
import { AnimatePresence, motion } from 'framer-motion';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddSurgeries';
import { ReactComponent as SurgeriesSolid } from 'assets/icons/medicalRecords/surgeries-solid.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useRecordState } from 'store/modules/myRecords';

const Surgeries = ({ selected, onClick }) => {
  const { surgeries, loading } = useRecordState();
  const [type, setType] = useState();
  const [showModal, setShowModal] = useState();
  const [selectedDetails, setSelectedDetails] = useState();
  const { delete_medical_record } = useMedicalRecord();
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={() => handleShowModal('Add')} condition={surgeries?.length > 0} />
        {!surgeries?.length ?
          <>
            {loading ? <div className="mt-10"><Spinner /></div> :
              <EmptyState
                illustration={<Illustration />}
                title="No Previous Surgeries"
                description="All previous surgeries will be displayed here when added."
                btnText="Add Surgeries"
                onClick={() => handleShowModal('Add')}
              />
            }
          </> : null
        }
        {surgeries?.length > 0 ? (
          <>
            <div className="mt-6">
              {surgeries.map((item) => (
                <div
                  className="flex justify-between items-center border-b last-of-type:border-b-0 border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0"
                  key={item?._id}
                >
                  <div className="flex space-x-4">
                    <SurgeriesSolid />
                    <div className="">
                      <p className="capitalize font-campton_m text-neutral_black text-14">{item?.procedure}</p>
                      <p className="capitalize text-12 text-neutral_body">
                        Date:
                        <span className="font-campton_r text-neutral_black mf">
                          {dayjs(item.date).format('MM-DD-YYYY')}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <EditIcon
                      data-testid="test-edit"
                      onClick={() => handleShowModal('Edit', () => setSelectedDetails(item))}
                      className="cursor-pointer"
                    />
                    <DeleteIcon
                      className="cursor-pointer"
                      data-testid="test-delete"
                      onClick={() => delete_medical_record('surgeries', item._id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
        {showModal && (
          <DefaultModal
            closeModal={() => setShowModal(false)}
            styles="min-w-[90%] md:min-w-[730px]"
            title={`${type} Surgeries`}
            subTitle="Record any previous surgeries to keep your health records comprehensive and up-to-date."
            containerVariant="bg-neutral_white"
          >
            <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
          </DefaultModal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default Surgeries;
Surgeries.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
