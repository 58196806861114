/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import cholesterol from 'assets/svg/home/cholesterol.svg';
import { SlideUp } from 'components/Animations';

const Cholesterol = () => {
  return (
    <SlideUp className="h-full overflow-y-scroll">
      <p className="font-campton_m text-[32px] text-neutral_black leading-[38.4px]">
        The key to healthy heart is health lifestyle
      </p>
      <p className="text-14 text-neutral_body font-campton_m mt-3">
        Did you know that there is good cholesterol and bad cholesterol:
      </p>
      <p className="flex space-x-4 text-neutral_black text-12 my-6 font-campton_r">
        <span className="border border-neutral_stroke_2 px-2 py-1 rounded-full"> 5 min read</span>
        <span className="border border-neutral_stroke_2 py-1 px-2 rounded-full"> 21 May, 2023</span>
      </p>
      <div className="">
        <img src={cholesterol} alt="healthy tips" className="w-full rounded-3xl object-cover lg:h-[400px] h-72" />
      </div>
      <div className="md:mb-0 mt-6 lg:h-[calc(100%-200px)] h-full">
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          Did you know that cholesterol isn't just one villain in the health story? It actually comes in two forms, each
          playing a very different role: good cholesterol (HDL) and bad cholesterol (LDL)
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">The Good one: HDL (High-Density Lipoprotein)</span>Imagine HDL as the helpful recycling truck of the cholesterol world. It scoops up excess cholesterol from your
          bloodstream, preventing it from building up and clogging your arteries. Think of it like keeping the plumbing
          pipes clean! Higher HDL levels are associated with a lower risk of heart disease, so give HDL a high five next
          time you hear its name.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">The Bad one: LDL (Low-Density Lipoprotein)</span>LDL, on the other hand, is the troublemaker. It's like the greasy drain clog that restricts blood flow and
          increases the risk of heart disease. When LDL levels are high, it can lead to plaque buildup in your arteries,
          eventually causing blockages and even heart attacks. So, keeping LDL in check is crucial for a healthy heart.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">The Takeaway:</span>Remember, cholesterol isn't just a single number on your blood test. It's a team of two, and knowing the good
          from the bad is key to understanding your heart health. Aim for high HDL and low LDL levels through a healthy
          diet, regular exercise, and lifestyle choices. Your heart will thank you for it!
        </p>
      </div>
    </SlideUp>
  );
};

export default Cholesterol;
