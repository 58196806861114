import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';

const EnviromentalFactors = ({ formData, setFormData, type }) => {
  const [pollution, setPollution] = useState(formData[type].pollution);
  const [hazard, setHazard] = useState(formData[type].occupationalHazards);

  const handlePollution = (name) => {
    setPollution(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], pollution: name}}));

  };
  const handleHazard = (name) => {
    setHazard(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], occupationalHazards: name}}));

  };
  return (
    <div className="my-4">
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          Do you live or work in environments with high levels of pollution?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={pollution === 'yes'}
            onChange={() => handlePollution('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={pollution === 'no'}
            onChange={() => handlePollution('no')}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          Are there any occupational hazards that may impact your health?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={hazard === 'yes'}
            onChange={() => handleHazard('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={hazard === 'no'}
            onChange={() => handleHazard('no')}
          />
        </div>
      </div>
    </div>
  );
};

export default EnviromentalFactors;
EnviromentalFactors.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
