import Status from 'components/Status';
import dayjs from 'dayjs';
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar/Avatar';
import { userInitials } from 'utils/ImagePlaceholder';
import Button from 'components/Buttons/Button';
import useMyRecords from 'hooks/fetch/useMyRecords';

const ViewMedications = ({ data, setShowModal, callback }) => {
  // const [showModal, setShowModal] = useState('')
  const { delete_medication, loading } = useMyRecords();
  const deleteMedication = () => {
    delete_medication({
      medicationId: data?._id,
      createdBy: 'patient',
    }).then((res) => {
      if (res?.code === 200) {
        callback()
      }
    });
  };

  return (
    <div className='relative'>
      <div className="modal-right-body">
        <p className="font-campton_m text-14 text-neutral_black mb-3">Medication Information</p>
        <div className="border border-neutral_stroke_2 rounded-lg py-4 px-6 text-14 font-campton_r bg-[#F7FBFD]">
          <div className="pb-4 border-b border-neutral_stroke_2">
            <p className="text-neutral_body">Medication Name</p>
            <p className="text-neutral_black">{data?.medicationName}</p>
          </div>
          <div className="py-4 border-b border-neutral_stroke_2 ">
            <p className="text-neutral_body">Dosage</p>
            <p className="text-neutral_black">{data?.dosage}</p>
          </div>
          <div className="flex justify-between py-4 border-b border-neutral_stroke_2">
            <div>
              <p className="text-neutral_body">Frequency</p>
              <p className="text-neutral_black">{data?.frequency}</p>
            </div>
            <div>
              <p className="text-neutral_body">Route of Administration</p>
              <p className="text-neutral_black">{data?.routeOfAdministration}</p>
            </div>
          </div>
          <div className="flex justify-between py-4 border-b border-neutral_stroke_2 items-start">
            <div>
              <p className="text-neutral_black">Period</p>
              <p className="text-neutral_body">
                {`${dayjs(data?.startDate).format('MMM DD, YYYY')} ` +
                  '-' +
                  ` ${data?.endDate ? dayjs(data?.endDate).format('MMM DD, YYYY') : 'No End date'}`}
              </p>
            </div>
            <Status status={data?.status} />
          </div>
          <div className="pt-4 border-neutral_stroke_2">
            <p className="text-neutral_black">Note</p>
            <p className="text-neutral_body">{data?.note}</p>
          </div>
        </div>
        <p className="font-campton_m text-14 text-neutral_black mt-6 mb-3">
          {data?.createdBy.patientId ? 'Added' : 'Prescribed'} By
        </p>
        <div className="border border-neutral_stroke_2 rounded-lg py-4 px-6 text-14 font-campton_r ">
          <div className="flex pb-4 border-b border-neutral_stroke_2">
            <Avatar
              url={data?.createdBy?.profilePic}
              width="32px"
              height="32px"
              textVariant="!text-12"
              alt={data?.createdBy?.firstName}
              initials={userInitials(data?.createdBy?.firstName.split('')[0], data?.createdBy?.lastName.split('')[0])}
              className="bg-brand_secondary mr-1.5 w-8 h-8"
            />
            <div className="font-campton_r">
              <p className="text-neutral_black text-14">
                {`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
              </p>
              <p className="text-12 text-neutral_body">{data?.createdBy?.emailAddress ?? 'N/A'}</p>
            </div>
          </div>
          {!data.createdBy.patientId && (
            <div className="py-4 border-b border-neutral_stroke_2 ">
              <p className="text-neutral_body">Hospital</p>
              <p className="text-neutral_black">{data?.institutionInfo?.businessName ?? 'N/A'}</p>
              <p className="text-12 text-neutral_body">{data?.institutionInfo?.businessAddress ?? 'N/A'}</p>
            </div>
          )}
          <div className="py-4">
            <p className="text-neutral_body">Date Created</p>
            <p className="text-neutral_black">
              {dayjs(data?.createdAt).format('MMM DD, YYYY')}, {dayjs(data.createdAt).format('hh:mm A')}
            </p>
          </div>
        </div>
      </div>
      {data.createdBy.patientId &&
        <div className="modal-right-button-container">
          <Button
            name="Edit medication"
            theme="blue"
            className="w-[148px] h-8 px-2"
            onClick={() => setShowModal('edit')}
          />
          <Button
            name="Delete medication"
            theme="transparent"
            loading={loading}
            className="w-[158px] h-8 px-2"
            onClick={deleteMedication}
          />
        </div>
      }
    </div>
  );
};

export default ViewMedications;
ViewMedications.propTypes = {
  data: PropTypes.object,
  setShowModal: PropTypes.func,
  callback: PropTypes.func,
};
