import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import Switch from "components/Switch";

export default function Menu({ title, options, className, selected }) {
    return (
        <section className={className}>
            <p className='text-12 text-neutral_body font-campton_r mb-[12px] sticky -top-[1px] md:top-0 bg-neutral_white'>{title}</p>
            <div className='border border-neutral_stroke_2 rounded-[16px] px-[16px]'>
                {options?.map((tab, i) =>
                    <div
                        key={tab.id}
                        className={`${(i + 1) === options?.length ? '' : 'border-b border-b-neutral_stroke_2'} py-[16px]`}
                    >
                        <div
                            data-testid={tab?.title}
                            onClick={() => tab?.method()}
                            className={`
                            ${selected === tab.title ? 'bg-[#EFF8FB]' : ''}
                            cursor-pointer hide_tap transition hover:bg-[#EFF8FB] p-[10px] rounded-[100px]
                            `}
                        >
                            <div className='flex'>
                                {tab?.icon}
                                <div className='ml-[16px] w-[90%]'>
                                    <div className='flex items-center justify-between'>
                                        <p className='text-14 font-campton_r text-neutral_black capitalize'>{tab?.title}</p>
                                        {tab?.arrow ? <ArrowRight fill="#A9B2B2" className='w-[40px] shrink-0' /> : ''}
                                        {tab?.showSwitch ? <Switch onToggle={tab?.onToggle} status={tab?.status} /> : ''}
                                    </div>
                                    <div className='w-[80%]'>
                                        <p className='text-12 font-campton_r text-neutral_body truncate'>{tab?.caption}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

Menu.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.string,
    selected: PropTypes.string,
    onToggle: PropTypes.func
}