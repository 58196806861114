import Dropdown from 'components/Dropdown/Index';
import FormWrapper from 'components/FormWrapper';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { bloodTypes, genotypes } from 'mocks/medicalRecords';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import { useRecordState } from 'store/modules/myRecords';

const AddBloodType = ({ type, callback }) => {
  //Local State
  const [formData, setFormData] = useState({
    bloodType: '',
    genotype: '',
  });
  const [bloodType, setBloodType] = useState();
  const [genotype, setGenotype] = useState();
  //Hooks
  const { update_medical_record, edit_medical_record, loading } = useMedicalRecord();
  //Redux
  const { patientInfo, patientId } = useRecordState();

  //Handler functions
  const updateMedicalRecord = () => {
    type === 'Edit'
      ? edit_medical_record('bloodType',patientId, formData, callback)
      : update_medical_record('bloodType',patientId, formData , callback);
  };

  //Effect
  useEffect(() => {
    if (patientInfo?.medicalRecord?.bloodType.bloodType) {
      setBloodType({
        id: patientInfo?.medicalRecord?.bloodType?.bloodType,
        name: patientInfo.medicalRecord?.bloodType?.bloodType,
        label: patientInfo?.medicalRecord?.bloodType?.bloodType,
      });
      setGenotype({
        id: patientInfo?.medicalRecord?.bloodType?.genotype,
        name: patientInfo?.medicalRecord?.bloodType?.genotype,
        label: patientInfo?.medicalRecord?.bloodType?.genotype,
      });
    }
  }, [patientInfo?.medicalRecord?.bloodType]);

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={updateMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-6 grid-cols-12 mt-6">
            <Dropdown
              label="Blood Group"
              variant="lg:col-span-6 col-span-12"
              name="bloodGroup"
              id="bloodGroup"
              value={bloodType}
                    disabled={loading}
              optionContainerVariant="w-full md:min-h-[100px] min-h-[150px]"
              options={bloodTypes}
              onChange={(data) => {
                setBloodType(data);
                setFormData({ ...formData, bloodType: data.value });
              }}
            />
            <Dropdown
              label="Genotype"
              variant="lg:col-span-6 col-span-12"
              name="Genotype"
              id="genotype"
              value={genotype}
                    disabled={loading}
              optionContainerVariant="w-full md:min-h-[100px] min-h-[150px]"
              options={genotypes}
              onChange={(data) => {
                setGenotype(data);
                setFormData({ ...formData, genotype: data.value });
              }}
            />
          </div>
          <div className="flex justify-end">
            <Button name="Save" theme="blue" className="w-28 h-10" disabled={!genotype || !bloodType} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddBloodType;
AddBloodType.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
};
