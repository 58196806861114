/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import headache from 'assets/svg/home/headache.svg';
import { SlideUp } from 'components/Animations';

const Headache = () => {
  return (
    <SlideUp className="h-full overflow-y-scroll">
      <p className="font-campton_m text-[32px] text-neutral_black leading-[38.4px]">
        How i wish i could just switch off this headache!
      </p>
      <p className="text-14 text-neutral_body font-campton_m mt-3">
        When a headache lingers and doesn't seem to go away, it's important to take steps to address it. Here's what you
        can do:
      </p>
      <p className="flex space-x-4 text-neutral_black text-12 my-6 font-campton_r">
        <span className="border border-neutral_stroke_2 px-2 py-1 rounded-full"> 5 min read</span>
        <span className="border border-neutral_stroke_2 py-1 px-2 rounded-full"> 21 May, 2023</span>
      </p>
      <div className="">
        <img src={headache} alt="healthy tips" className="w-full rounded-3xl object-cover lg:h-[500px] h-72" />
      </div>
      <div className="md:mb-0 mt-6 lg:h-[calc(100%-100px)] h-full">
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">Identify Triggers:</span>Keep a headache diary to
          identify potential triggers, such as stress, dehydration, or certain foods.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">Stay Hydrated:</span>Dehydration can be a common
          headache trigger, so ensure you drink enough water throughout the day.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">Manage Stress:</span>Practice stress-reduction
          techniques like deep breathing, meditation, or progressive muscle relaxation.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          <span className="text-neutral_black font-campton_m mr-1">Limit Caffeine and Alcohol:</span>Excessive caffeine
          and alcohol consumption can trigger headaches in some individuals. Get Regular Sleep: Establish a consistent
          sleep schedule to prevent sleep-related headaches.
        </p>
        <p className="text-14 font-campton_r text-neutral_body mt-6">
          Remember that if your headache is unusually severe, accompanied by neurological symptoms, or persists for an
          extended period, it's essential to consult a healthcare professional for a proper evaluation and this can
          swiftly be done through GabbiHealth's patient's app.
        </p>
      </div>
    </SlideUp>
  );
};

export default Headache;
