import useMultiStepForm from "hooks/useMultiStepForm";
import React, { useState } from "react";
import ChooseStep from "./Forms/ChooseStep";
import VerifyOTP from "./Forms/VerifyOTP";
import CreateNewPassword from "./Forms/CreateNewPassword";
import { useSelector } from "react-redux";

export default function ChangePassword() {
    const [means, setMeans] = useState('Email address')
    const { loggedUser } = useSelector(state => state.auth)

    const { step, next } = useMultiStepForm([
        <ChooseStep
            key={1}
            means={means}
            emailAddress={loggedUser?.emailAddress}
            phoneNumber={loggedUser?.phoneNumber}
            setMeans={setMeans}
            callback={() => next()}
        />,
        <VerifyOTP
            key={2}
            emailAddress={loggedUser?.emailAddress}
            callback={() => next()}
        />,
        <CreateNewPassword
            key={3}
        />
    ])

    return step
}