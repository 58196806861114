import React, { useEffect, useMemo, useState } from 'react';
import { groupDataByDate } from 'utils/groupDataByDate';
import LabTestHistory from './LabTestHistory';
import SingleTest from './SingleTest';
import UploadTest from './UploadTest';
import { Title } from '../Components';
import useMyRecords from 'hooks/fetch/useMyRecords';
import { useSelector } from 'react-redux';
import useTable from 'hooks/useTable';
import useDebounce from 'hooks/useDebounce';
import Button from 'components/Buttons/Button';
import AddTestResult from './Components/AddTestResult';

export default function LabTests() {
    const { labTest, pendingLabTest } = useSelector(state => state.myRecords)
    const { get_pending_lab_tests, loading: isFetchingPendingLabTest } = useMyRecords()
    const { get_lab_tests, loading: isFetchingLabTest } = useMyRecords()
    const [selectedTest, setSelectedTest] = useState()
    const [tab, setTab] = useState('history')
    const { params, handleSearch } = useTable()
    const pendingLabTestUrl = { status: 'requested' }
    const [uploadTest, setUploadTest] = useState(false)
    const debounceSearchValue = useDebounce(params.search)
    const groupedTests = useMemo(() => groupDataByDate(labTest?.labTest, 'dateRequested'), [labTest?.labTest])

    const fetchUpdatedLabTest = () => {
        setTab('history')
        setSelectedTest()
        setUploadTest(false)
        Promise.all([
            get_pending_lab_tests({ params: pendingLabTestUrl }),
            get_lab_tests({ params })
        ])
    }

    useEffect(() => {
        get_pending_lab_tests({ params: pendingLabTestUrl })
    }, [])

    useEffect(() => {
        const newParams = { ...params, status: 'completed'}
        get_lab_tests({ params: newParams })
    }, [debounceSearchValue])

    const TabTitle = useMemo(() => {
        if (uploadTest) return "Upload Test Result"
        if (selectedTest) return selectedTest?.testType
        return "Lab Tests"
    }, [uploadTest, selectedTest])

    return (
        <section>
            <Title
                name={TabTitle}
                showButton={tab !== 'history' || !!selectedTest || uploadTest}
                onClick={() => {
                    setTab('history')
                    setSelectedTest()
                    setUploadTest(false)
                }}
            />
            {!uploadTest ?
                <div className='relative min-h-[74vh]'>
                    {!selectedTest && tab === 'history' ?
                        <LabTestHistory
                            isFetchingPendingLabTest={isFetchingPendingLabTest}
                            isFetchingLabTest={isFetchingLabTest}
                            tests={groupedTests}
                            pendingLabTest={pendingLabTest}
                            setSelectedTest={setSelectedTest}
                            setTab={setTab}
                            handleSearch={handleSearch}
                        /> : ''}
                    {selectedTest && tab === 'upload' ? <UploadTest selectedTest={selectedTest} callback={fetchUpdatedLabTest} /> : ''}
                    {selectedTest && tab !== 'upload' ? <SingleTest test={selectedTest} /> : ''}
                    {!uploadTest && !selectedTest && <Button
                        className="fixed z-10 bottom-[40px] right-[50px] w-[150px] rounded-full h-[40px] font-campton_m"
                        theme="blue"
                        name="Add test result"
                        onClick={() => setUploadTest(true)}
                    />}
                </div> : <AddTestResult callback={fetchUpdatedLabTest}/>
            }
        </section >
    )
}