import DefaultModal from 'components/Modals/DefaultModal';
import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { VisitContext } from 'context';
import Thread from 'components/Thread';
import EmptyState from 'components/EmptyState';
import Avatar from 'components/Avatar/Avatar';
import { userInitials } from 'utils/ImagePlaceholder';
import VisitSummaryPdf from './VisitSummaryPdf';
import Button from 'components/Buttons/Button';
import { useReactToPrint } from 'react-to-print';
import useMyRecords from 'hooks/fetch/useMyRecords';
import { useRecordState } from 'store/modules/myRecords';
import { useAppDispatch } from 'hooks/useReduxHook';
import useHandleError from 'hooks/useHandleError';
import { notify } from 'store/modules/global';

const VisitSummary = ({ closeModal }) => {
  const [labTests, setLabTests] = useState();
  const { visit, progressNote } = useContext(VisitContext);
  const { handleError } = useHandleError()
  const dispatch = useAppDispatch()
  const templateRef = useRef(null);
  const { get_single_visit, get_visit_lab_test, get_visit_treatment_plan } = useMyRecords();
  const { visitDetails, visitTreatmentPlan } = useRecordState();
  const visitSummary = progressNote?.visitSummary?.summary ?? visit?.visitSummary?.summary
  const instructions = progressNote?.visitSummary?.instructions ?? visit?.visitSummary?.instructions

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
  });
  
  const exportPdf = async () => {
    try {
      const visitData = await Promise.all([
        get_single_visit(visit._id),
        get_visit_lab_test(`${visit._id}/lab-test`),
        get_visit_treatment_plan(`${visit._id}/treatment-plan`)
      ]);

      const labTestResult = visitData[1] //Select lab test result from array of response

      if (labTestResult && labTestResult.code === 200) {
        setLabTests(labTestResult.data);
      }

      handlePrint();
    } catch (error) {
      // Handle errors if any of the promises fail
      dispatch(notify({ display: true, status: 'error', message: handleError({ message: 'Unable to export pdf at the moment, please try again' }) }))
    }
  };

  return (
    <DefaultModal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
      title="Visit Summary"
    >
      <div className='modal-right-body'>
        <div className="flex justify-between items-center">
          <p className="text-14 font-campton_m text-neutral_black">Summary</p>
          <Button
            name='Export Summary'
            theme="transparent"
            className="w-fit rounded-full h-8 font-campton_m text-14"
            onClick={exportPdf}
          />
        </div>
        <p className="text-14 text-neutral_body font-campton_r mt-2">{visitSummary ?? 'N/A'}</p>
        <div className="mt-6">
          <p className="font-campton_m text-brand_secondary text-14">Follow-up instructions</p>
          {instructions?.length ? (
            instructions?.map((item) => (
              <Thread key={item?._id} createdAt={item?.createdAt}>
                <>
                  <div className="flex mb-[8px]">
                    <Avatar
                      url={item?.provider?.profilePic}
                      width="32px"
                      height="32px"
                      alt="profile"
                      initials={userInitials(
                        item?.provider?.firstName === ''
                          ? `${visit?.institution?.businessName.split(' ')[0]}`
                          : item.provider.firstName,
                        item?.provider?.firstName === ''
                          ? `${visit?.institution?.businessName.split(' ')[1]}`
                          : item.provider.lastName,
                      )}
                      className="bg-brand_secondary w-[32px] h-[32px] shrink-0 mr-[8px]"
                    />
                    <div className="w-full">
                      <p className="text-14 text-neutral_black font-campton_m">
                        {item?.provider?.firstName === ''
                          ? `${visit?.institution.businessName}`
                          : `${item?.provider?.firstName} ${item?.provider?.lastName}`}
                      </p>
                      <p className="text-12 text-neutral_body font-campton_r flex justify-between">
                        {item?.provider?.emailAddress === ''
                          ? `${visit?.institution.businessEmailAddress}`
                          : `${item.provider?.emailAddress}`}
                      </p>
                    </div>
                  </div>
                  <p className="font-campton_r text-14 text-neutral_body">{item.note}</p>
                </>
              </Thread>
            ))
          ) : (
            <section className="bg-[#FAFAFA] rounded-[16px] p-[8px] h-full">
              <EmptyState
                title={<span className="text-14">There’s currently no instructions</span>}
                description={<span className="text-12">follow up instructions will be displayed here</span>}
              />
            </section>
          )}
        </div>
        <div className="hidden">
          <VisitSummaryPdf
            visit={visit}
            templateRef={templateRef}
            progressNote={progressNote}
            labTests={labTests}
            visitTreatmentPlan={visitTreatmentPlan}
            visitDetails={visitDetails}
          />
        </div>
      </div>
    </DefaultModal>
  );
};

export default VisitSummary;
VisitSummary.propTypes = {
  closeModal: PropTypes.func,
};
