import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Hospital } from 'assets/svg/hospital.svg';
import { groupDataByDate } from 'utils/groupDataByDate';
import VisitHistory from './VisitHistory';
import SingleVist from './SingleVisit';
import { Title } from '../Components';
import { useSelector } from 'react-redux';
import useMyRecords from 'hooks/fetch/useMyRecords';
import { VisitContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import CustomSkeleton from 'components/Skeleton';
import { Tracker } from 'components/Cards';
import useTable from 'hooks/useTable';
import useDebounce from 'hooks/useDebounce';
import VisitSummary from './SingleVisit/VisitSummary';
import ViewTestResult from './SingleVisit/ViewTestResult';
import ProgressNoteHeader from './Components/ProgressNoteHeader';
import { formatDate } from 'utils/formatDate';
import { formatTwelveHour } from 'utils/formatTime';

export default function Visits() {
    const { get_ongoing_visit, loading, get_progress_notes, get_single_progress_note } = useMyRecords()
    const { params, handleSearch } = useTable()
    const { get_visits, loading: isFetchingVisits, get_single_visit } = useMyRecords()
    const { visits, ongoingVisit } = useSelector(state => state.myRecords)
    const [selectedVisit, setSelectedVisit] = useState()
    const debounceSearchValue = useDebounce(params.search)
    const groupedVisit = useMemo(() => groupDataByDate(visits?.visits), [visits?.visits])
    const [allProgressNotes, setAllProgressNotes] = useState()
    const [showModal, setShowModal] = useState(false)
    const [progressNoteFilter, setProgressNoteFilter] = useState()
    const [selectedProgressNoteFilter, setSelectedProgressNoteFilter] = useState()
    const [progressNote, setProgressNote] = useState()
    const [selectedResult, setSelectedResult] = useState(null)
    const patientInfo = selectedVisit?.patient || allProgressNotes?.progressNotes?.[0]?.patientInfo

    const visit = useMemo(() => {
        return selectedVisit
    }, [selectedVisit])

    useEffect(() => {
        const urlParams = { status: 'onGoing' }
        get_ongoing_visit({ params: urlParams })
    }, [])

    useEffect(() => {
        const newParams = { ...params, status: 'completed' }
        get_visits({ params: newParams })
    }, [debounceSearchValue])

    const getProgressNotes = () => {
        get_progress_notes({ visitId: selectedVisit?._id })
            .then((res) => {
                setAllProgressNotes(res?.data)
                const filter = res?.data?.progressNotes?.map(({ _id, createdAt }) => ({
                    _id,
                    createdAt: `${formatDate(createdAt)} ${formatTwelveHour(createdAt)}`
                }));
                if (filter?.length) {
                    setProgressNoteFilter(filter)
                    setSelectedProgressNoteFilter(filter[0])
                }
            })
    }

    const getSingleVisit = (id) => {
        get_single_visit(id).then(res => {
            if (res?.code === 200) {
                setSelectedVisit(res.data)
            }
        })
    }

    useEffect(() => {
        if (selectedVisit) getProgressNotes()
    }, [selectedVisit])

    useEffect(() => {
        if (selectedProgressNoteFilter?._id)
            get_single_progress_note(selectedProgressNoteFilter?._id)
                .then((res) => {
                    if (res?.code === 200) {
                        setProgressNote(res?.data[0])
                    }
                })
    }, [selectedProgressNoteFilter])

    const contextValues = useMemo(() => {
        return { visit, params, progressNote, patientInfo }
    }, [visit, params, progressNote, patientInfo])

    return (
        <div>
            <section className='relative'>
                <Title
                    name="Visits"
                    showButton={!!selectedVisit}
                    onClick={() => setSelectedVisit()}
                />
                <AnimatePresence>
                    <>
                        {selectedVisit &&
                            <ProgressNoteHeader
                                filters={progressNoteFilter}
                                selected={selectedProgressNoteFilter}
                                select={setSelectedProgressNoteFilter}
                            />
                        }
                        {ongoingVisit?.length &&
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 1 } }}
                            >
                                {ongoingVisit?.map((item) =>
                                    <Tracker
                                        key={item?._id}
                                        title={item?.institution?.businessName}
                                        location={item?.institution?.businessAddress}
                                        purpose="You have an ongoing visit"
                                        date={item?.createdAt}
                                        status={item?.status}
                                        id={item?.visitReadId}
                                        icon={<Hospital />}
                                    />
                                )}
                            </motion.div>
                        }
                        {loading && !ongoingVisit?.length ?
                            <div className='mb-[16px]'>
                                <CustomSkeleton className="mb-[8px] py-[40px]" />
                            </div> : ''
                        }
                    </>
                </AnimatePresence>
                <div>
                    {!selectedVisit ?
                        <VisitHistory
                            loading={isFetchingVisits}
                            visits={groupedVisit}
                            setSelectedVisit={getSingleVisit}
                            handleSearch={handleSearch}
                        /> :
                        <VisitContext.Provider value={contextValues}>
                            <SingleVist onClick={() => setShowModal(true)} setSelectedResult={setSelectedResult} />
                        </VisitContext.Provider>
                    }
                </div>
            </section >
            {showModal &&
                <VisitContext.Provider value={contextValues}>
                    <VisitSummary closeModal={() => setShowModal(false)} />
                </VisitContext.Provider>
            }
            {selectedResult &&
                <ViewTestResult
                    test={selectedResult}
                    closeModal={() => setSelectedResult(null)}
                />
            }
        </div>
    )
}