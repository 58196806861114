import React from 'react'
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const DisabledDropdown = ({ variant, labelStyles, name, value, label, id, styles }) => {
  return (
    <div className={`${variant} relative`}>
      <motion.label
        htmlFor={id}
        animate={{
          scale: 0.8,
          top: !labelStyles && !value ? '8px' : '-16px',
          fontSize: !labelStyles && !value ? '16px' : '14px',
        }}
        className="
                    absolute text-neutral_body mb-2 block text-14
                    font-campton_r bg-neutral_white px-1 pt-2 cursor-text"
      >
        {label}
      </motion.label>

      <div
        data-testid={`test-${id}`}
        name={name}
        className={`${styles} 
            h-[56px] px-4 py-[18px] border border-neutral_stroke font-campton_r rounded-lg text-14 text-neutral_black w-full cursor-pointer`}
      >
        {value?.label}
      </div>
    </div>
  )
}

export default DisabledDropdown
DisabledDropdown.propTypes = {
  variant: PropTypes.string,
  labelStyles: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  styles: PropTypes.string,
  value: PropTypes.object,
};