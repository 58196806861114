import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';

const SafeSexPractices = ({ formData, setFormData, type }) => {
  const [sexuallyActive, setSexuallyActive] = useState(formData[type].sexuallyActive);
  const [contraceptives, setContraceptives] = useState(formData[type].contraceptives);
  const [stiTests, setStiTests] = useState(formData[type].stiTests);
const [partner, setPartner] = useState(formData[type].partner)

  const handleSexualActivity = (name) => {
    setSexuallyActive(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], sexuallyActive: name}}));

  };
  const handleContraceptives = (name) => {
    setContraceptives(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], contraceptives: name}}));

  };
  const handleStiTest = (name) => {
    setStiTests(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], stiTests: name}}));

  };
  const handlePartners = (name) => {
    setPartner(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], partner: name}}));

  };
  return (
    <div className="my-4">
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">Sexual active?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={sexuallyActive === 'yes'}
            onChange={() => handleSexualActivity('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={sexuallyActive === 'no'}
            onChange={() => handleSexualActivity('no')}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">Partner?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Single Partner"
            variant="col-span-6"
            isChecked={partner === 'single partner'}
            onChange={() => handlePartners('single partner')}
          />
          <RadioButtonSelect
            label="Multiple Partner"
            variant="col-span-6"
            isChecked={partner === 'multiple partner'}
            onChange={() => handlePartners('multiple partner')}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">Do you use barrier methods of contraception?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={contraceptives === 'yes'}
            onChange={() => handleContraceptives('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={contraceptives === 'no'}
            onChange={() => handleContraceptives('no')}
          />
        </div>
      </div>
      <div>
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          Have you been tested for sexually transmitted infections (STIs)?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={stiTests === 'yes'}
            onChange={() => handleStiTest('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={stiTests === 'no'}
            onChange={() => handleStiTest('no')}
          />
        </div>
      </div>
    </div>
  );
};

export default SafeSexPractices;
SafeSexPractices.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
