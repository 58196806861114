import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmptyImage } from 'assets/svg/medications-empty.svg';

export default function EmptyState({ illustration, title, description, btnText, onClick, className }) {
    return (
        <div className={`flex w-full p-4 h-full flex-col justify-center items-center ${className}`}>
            <div className='mb-[24px]'>{illustration || <EmptyImage/>}</div>
            <p className="font-campton_m md:text-18 text-neutral_black text-16 text-center">{title}</p>
            <p className="font-campton_r text-14 mt-1 text-neutral_body max-w-[285px] mx-auto text-center">{description}</p>
            {btnText ?
                <button
                    onClick={onClick}
                    className="w-fit text-14 h-8 px-3 rounded-[100px] font-campton_m text-neutral_white bg-brand_primary mt-6"
                >
                    {btnText}
                </button> : ''
            }
        </div>
    );
}

EmptyState.propTypes = {
    illustration: PropTypes.any,
    description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    btnText: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
};
