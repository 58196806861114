import Avatar from 'components/Avatar/Avatar';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userInitials } from 'utils/ImagePlaceholder';

const AppointmentCard = ({ item }) => {
  const navigate = useNavigate()

  return (
    <div className="font-campton_r p-4 border-[0.5px] border-neutral_stroke rounded-[8px] w-full cursor-pointer" onClick={() => navigate('/appointments')}>
      <p className="mb-3 text-[10px] text-neutral_body">
        {dayjs(item?.appointmentDate).format('DD MMM YYYY')} <span>| {item.startTime}</span>
      </p>
      <div className="flex gap-2">
        <Avatar
          url={item?.profilePic}
          width="36px"
          height="36px"
          alt={item?.providerInfo?.firstName?.split('')[0]}
          initials={userInitials(item?.providerInfo?.firstName || '', item?.providerInfo?.lastName || '')}
          className="bg-brand_secondary w-[36px] h-[36px]"
        />
        <div className="max-w-[179px]">
          <p className="text-[14px] text-neutral_black capitalize">
            Dr. {`${item?.providerInfo.firstName} ${item?.providerInfo?.lastName}`}
          </p>
          <p className="text-neutral_body text-[12px]">{`${
            item?.institutionInfo?.businessName ?? ''
          } | Cardiologist | ${item?.providerInfo?.qualification ?? ''}`}</p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;

AppointmentCard.propTypes = {
  item: PropTypes.object,
};
