import { ReactComponent as Medication } from 'assets/icons/treatmentplan/medications-bg.svg';
import { ReactComponent as Procedure } from 'assets/icons/treatmentplan/procedures-bg.svg';
import { ReactComponent as Referral } from 'assets/icons/treatmentplan/referrals-bg.svg';
import { ReactComponent as Therapy } from 'assets/icons/treatmentplan/therapies-bg.svg';
// import { ReactComponent as Lifestyle } from 'assets/icons/treatmentplan/lifestyles-bg.svg';
import disability from 'assets/svg/home/disability.svg';
import cancer from 'assets/svg/home/cancer.svg';
import healthVitals from 'assets/svg/home/vitals.svg';
import cholesterol from 'assets/svg/home/cholesterol.svg';
import headache from 'assets/svg/home/headache.svg';
import healthIsWealth from 'assets/svg/home/healthIsWealth.svg';
import walking from 'assets/svg/home/walking.svg';

export const reasonsForTermination = [
  {
    label: "No Longer Using the App",
    value: 'No Longer Using the App'
  },
  {
    label: "Account Security Concerns",
    value: 'Account Security Concerns'
  },
  {
    label: "Unsatisfactory Experience",
    value: 'Unsatisfactory Experience'
  },
  {
    label: "Others",
    value: ''
  }
]
export const reasonsForDeactivation = [
  {
    label: "Account Duplication",
    value: 'Account Duplication'
  },
  {
    label: "Personal Reasons",
    value: 'Personal Reasons'
  },
  {
    label: "Others",
    value: ''
  }
]
export const visit_history = [
  {
    id: '1',
    hospital: 'Reddington Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-29T20:41:41.142Z',
  },
  {
    id: '2',
    hospital: 'EVERCARE Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-29T20:41:41.142Z',
  },
  {
    id: '3',
    hospital: 'Reddington Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-28T20:41:41.142Z',
  },
  {
    id: '4',
    hospital: 'EVERCARE Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-28T20:41:41.142Z',
  },
];

export const test_history = [
  {
    id: '1',
    hospital: 'Reddington Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-29T20:41:41.142Z',
  },
  {
    id: '2',
    hospital: 'EVERCARE Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-29T20:41:41.142Z',
  },
  {
    id: '3',
    hospital: 'Reddington Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-28T20:41:41.142Z',
  },
  {
    id: '4',
    hospital: 'EVERCARE Hospital',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    createdAt: '2023-05-28T20:41:41.142Z',
  },
];

export const treatmentPlan = [
  {
    title: 'Medications',
    description: 'Prescription details, including medication name, dosage, frequency, and route of administration.',
    style: 'col-span-6 lg:col-span-4 bg-[#EFF8FB]',
    icon: Medication,
  },
  {
    title: 'Therapies',
    description: 'Recommendations for physical therapy, occupational therapy, or other non-pharmacological treatments.',
    style: 'col-span-6 lg:col-span-4 bg-[#E0FAEB]',
    icon: Therapy,
  },
  {
    title: 'Procedures',
    description: 'Instructions for specific medical procedures or interventions.',
    style: 'col-span-6 lg:col-span-4 bg-[#F8EBFF]',
    icon: Procedure,
  },
  // {
  //     title: 'Lifestyle',
  //     icon: Lifestyle,
  //     description: 'Guidance regarding dietary changes, exercise, or other lifestyle modifications.',
  //     style: 'col-span-6 lg:col-span-4 bg-[#FEF1DD]',
  // },
  {
    title: 'Referrals',
    icon: Referral,
    description: 'Recommendations for specialized consultations or referrals to other healthcare professionals.',
    style: 'col-span-6 lg:col-span-4 bg-[#FCEDED]',
  },
];
export const procedure_list_header = ['Procedure Name', 'Procedure Date/Time', 'Provider Info', 'Date Created', ''];
export const referral_list_header = ['Institution Name', 'Referral Date', 'Recieving Provider', ''];
export const therapies_list_header = ['Therapy Name', 'Therapy Date/Time', 'Date Created', ''];
export const healthTips = [
  {
    id: 'Prostate Cancer',
    title: 'Prostate Cancer',
    description: `Men, don't let prostate cancer sneak up on you; early detection saves lives.`,
    img: cancer,
  },
  {
    id: 'Cholesterol',
    title: 'The key to healthy heart is health lifestyle ',
    description: `Did you know that there is good cholesterol and bad cholesterol...`,
    img: cholesterol,
  },
  {
    id: 'Health vitals',
    title: 'What vital signs tracking is all about and how it works.',
    description: `Tracking your health vitals, such as blood pressure, heart rate, and oxygen levels, is important for several reasons:
    `,
    img: healthVitals,
  },
  {
    id: 'Headache',
    title: 'How i wish i could just switch off this headache!',
    description: `When a headache lingers and doesn't seem to go away, it's important to take steps to address it. Here's what you can do`,
    img: headache,
  },
  {
    id: 'Health is wealth',
    title: 'Health Is Wealth',
    img: healthIsWealth,
    description: 'Living with high blood pressure, diabetes, and high cholesterol can feel daunting, but embracing a healthier lifestyle can be a powerful game-changer. Here are some key areas to focus on:',
  },
  {
    id: 'Walking',
    title: 'Getting even a little bit of exercise can have a big impact on your health.',
    description: 'walking kust 30 minutes a day can reduce your risk of health disease, stoke, daibetes and cancer.',
    img: walking,
  },
  {
    id: 'Disability',
    description: 'Disability is not a limit; it’s a part of the unique mosaic abilities',
    img: disability,
    title: 'Disability is not a limit'
  },
];
export const medication_list_header = ['Medication Name', 'Start Date', 'End Date', 'Status', ''];
export const dosageTypes = [
  {
    id: 1,
    label: 'Milligrams(mg)',
    value: 'Milligrams(mg)',
  },
  {
    id: 2,
    label: 'Micrograms(mcg)',
    value: 'Micrograms(mcg)',
  },
  {
    id: 3,
    label: 'grams(g)',
    value: 'grams(g)',
  },
  {
    id: 4,
    label: 'Drops',
    value: 'Drops',
  },
  {
    id: 5,
    label: 'Teaspoons(tsp)',
    value: 'Teaspoons(tsp)',
  },
  {
    id: 6,
    label: 'tablespoons(tbsp)',
    value: 'tablespoons(tbsp)',
  },
  {
    id: 7,
    label: 'Patches',
    value: 'Patches',
  },
];
export const frequencyTypes = [
  {
    id: 1,
    label: 'Daily',
    value: 'daily',
  },
  {
    id: 2,
    label: 'Twice a day',
    value: 'twice a day',
  },
  {
    id: 3,
    label: 'Three times a day',
    value: 'three times a day',
  },
  {
    id: 4,
    label: 'Four times a day',
    value: 'four times a day',
  },
  {
    id: 5,
    label: 'Every other day',
    value: 'every other day',
  },
  {
    id: 6,
    label: 'Every bedtime',
    value: 'every bedtime',
  },
  {
    id: 7,
    label: 'Every 4 hours',
    value: 'every 4 hours',
  },
  {
    id: 8,
    label: 'Every 4 to 6 hours',
    value: 'every 4 to 6 hours',
  },
  {
    id: 9,
    label: 'Every week',
    value: 'every week',
  },
  {
    id: 10,
    label: 'PRN/ as needed',
    value: 'PRN/ as needed',
  },
  {
    id: 11,
    name: 'Stat',
    value: 'Stat'
  }
];
export const routeOfAdministrationList = [
  {
    id: 1,
    label: 'Intramuscular',
    value: 'intramuscular',
  },
  {
    id: 2,
    label: 'Intraperitoneal',
    value: 'intraperitoneal',
  },
  {
    id: 3,
    label: 'Rectum',
    value: 'rectum',
  },
  {
    id: 4,
    label: 'Topical',
    value: 'topical',
  },
  {
    id: 5,
    label: 'Intranasal',
    value: 'intranasal',
  },
  {
    id: 6,
    label: 'Oral',
    value: 'oral',
  },
  {
    id: 7,
    label: 'Intravenous',
    value: 'intravenous',
  },
  {
    id: 8,
    label: 'Intradermal',
    value: 'intradermal',
  },
  {
    id: 9,
    label: 'Sublingual',
    value: 'sublingual',
  },
  {
    id: 10,
    label: 'Buccal',
    value: 'buccal',
  },
];
export const requiredFields = [
  'firstName',
  'lastName',
  'emailAddress',
  'officeAddress',
  'homeAddress',
  'gender',
  'race',
  'ethnicity',
  'maritalStatus',
  'phoneNumber',
  'dateOfBirth',
];
export const medicalRecordsFields = [
  'medicalRecord.allergies.length',
  'medicalRecord.emergencyContact.length',
  'medicalRecord.insuranceInformation.length',
  'medicalRecord.medicalDiagnosis.length',
  'medicalRecord.familyHospital.length',
  'medicalRecord.surgeries.length',
  'medicalRecord.immunizationHistory.length',
  'medicalRecord.familyHistory.length',
];
export const availableTests = [
  {
    name: 'Comp. Metabolic Panel',
    categories: [
      {
        name: 'Glucose',
        unit: 'mg/dL',
        value: '',
      },
      {
        name: 'BUN',
        unit: 'mg/dL',
        value: '',
      },
      {
        name: 'Creatinine',
        unit: 'mg/dL',
        value: '',
      },
      {
        name: 'eGFR',
        unit: 'mL/min',
        value: '',
      },
      {
        name: 'BUN/Creatinine Ratio',
        unit: '',
        value: '',
      },
      {
        name: 'Sodium',
        unit: 'mmol/L',
        value: '',
      },
      {
        name: 'Potassium',
        unit: 'mmol/L',
        value: '',
      },
      {
        name: 'Chloride',
        unit: 'mmol/L',
        value: '',
      },
      {
        name: 'Carbon Dioxide, Total',
        unit: 'mmol/L',
        value: '',
      },
      {
        name: 'Calcium',
        unit: 'mg/dL',
        value: '',
      },
      {
        name: 'Protein, Total',
        unit: 'g/dL',
        value: '',
      },
      {
        name: 'Albumin',
        unit: 'g/dL',
        value: '',
      },
      {
        name: 'Globulin, Total',
        unit: 'g/dL',
        value: '',
      },
      {
        name: 'A/G Ratio',
        unit: '',
        value: '',
      },
      {
        name: 'Bilirubin, Total',
        unit: 'mg/dL',
        value: '',
      },
      {
        name: 'Alkaline Phosphatase',
        unit: 'IU/L',
        value: '',
      },
      {
        name: 'AST (SGOT)',
        unit: 'IU/L',
        value: '',
      },
      {
        name: 'ALT (SGPT)',
        unit: 'IU/L',
        value: '',
      },
    ],
  },
  {
    name: 'Complete Blood Count (CBC)',
    categories: [
      {
        name: 'WBC',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'RBC',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'Hemoglobin',
        unit: 'g/dL',
        value: '',
      },
      {
        name: 'Hematocrit',
        unit: '%',
        value: '',
      },
      {
        name: 'MCV',
        unit: 'fL',
        value: '',
      },
      {
        name: 'MCH',
        unit: 'pg',
        value: '',
      },
      {
        name: 'MCHC',
        unit: 'g/dL',
        value: '',
      },
      {
        name: 'RDW',
        unit: '%',
        value: '',
      },
      {
        name: 'Platelets',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'Neutrophils',
        unit: '%',
        value: '',
      },
      {
        name: 'Lymphs',
        unit: '%',
        value: '',
      },
      {
        name: 'Monocytes',
        unit: '%',
        value: '',
      },
      {
        name: 'Eos',
        unit: '%',
        value: '',
      },
      {
        name: 'Basos',
        unit: '%',
        value: '',
      },
      {
        name: 'Neutrophils (Absolute)',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'Lymphs (Absolute)',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'Monocytes (Absolute)',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'Eos (Absolute)',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'Baso (Absolute)',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'Immature Granulocytes',
        unit: '%',
        value: '',
      },
      {
        name: 'Immature Grans (Abs)',
        unit: 'x10E3/uL',
        value: '',
      },
      {
        name: 'NRBC',
        unit: '%',
        value: '',
      },
    ],
  },
  {
    name: 'Urinalysis Panel',
    categories: [
      {
        name: 'Specific Gravity',
        unit: '',
        value: '',
      },
      {
        name: 'pH',
        unit: '',
        value: '',
      },
      {
        name: 'Urine−Color',
        unit: '',
        value: '',
      },
      {
        name: 'Appearance',
        unit: '',
        value: '',
      },
      {
        name: 'WBC Esterase',
        unit: '',
        value: '',
      },
      {
        name: 'Protein',
        unit: '',
        value: '',
      },
      {
        name: 'Glucose',
        unit: '',
        value: '',
      },
      {
        name: 'Ketones',
        unit: '',
        value: '',
      },
      {
        name: 'Occult Blood',
        unit: '',
        value: '',
      },
      {
        name: 'Bilirubin',
        unit: '',
        value: '',
      },
      {
        name: 'Urobilinogen, Semi−Qn',
        unit: 'mg/dL',
        value: '',
      },
      {
        name: 'Nitrite, Urine',
        unit: '',
        value: '',
      },
    ],
  },
  {
    name: 'Microscopic Examination',
    categories: [
      {
        name: 'WBC',
        unit: '/hpf',
        value: '',
      },
      {
        name: 'RBC',
        unit: '/hpf',
        value: '',
      },
      {
        name: 'Epithelial Cells (non renal)',
        unit: '/hpf',
        value: '',
      },
      {
        name: 'Casts',
        unit: '/hpf',
        value: '',
      },
      {
        name: 'Protein',
        unit: '',
        value: '',
      },
    ],
  },
];
export const visitTreatmentPlan = {
  therapies: [
    {
      createdBy: {
        providerId: '655f422129472c09342ed241',
        firstName: 'trust',
        lastName: 'Hospital',
        emailAddress: 'fistemesta@gufum.com',
        profilePic:
          'https://photow-profile-images.s3.us-west-2.amazonaws.com/20231203-064641360_F_514364850_xLOQX6SOY2qcjAIcTowsi3xYvHmhmvs0.jpg',
      },
      _id: '6571b7d21164b587628be987',
      therapyName: 'gabbi therapy ',
      therapyDate: '2023-12-07T00:00:00.000Z',
      startTime: '02:00 PM',
      endTime: '03:00 PM',
      note: 'note',
      patientId: '6564c190640b0765aee958c7',
      attendingProviderId: '655f422129472c09342ed241',
      visitId: '6571af70e67c7b7bba7e633a',
      institutionId: '655f422129472c09342ed23f',
      totalFee: 200,
      serviceDetails: [
        {
          category: 'Therapy',
          subCategory: 'Cognitive behaviour  therapy',
          fee: 100,
          vat: 100,
          _id: '6571b7d21164b587628be988',
        },
      ],
      createdAt: '2023-12-07T12:17:22.916Z',
      updatedAt: '2023-12-07T12:17:22.916Z',
      __v: 0,
    },
  ],
  medications: [
    {
        "createdBy": {
            "providerId": "65658fc8640b0765aee95c47",
            "firstName": "Steph Hospital",
            "lastName": " ",
            "emailAddress": "steph@yopmail.com",
            "profilePic": "",
            "GPINumber": "GPI-00520727",
            "gNumber": ""
        },
        "_id": "6567583dcfe6b276a1c9e5bb",
        "medicationName": "Medics",
        "dosage": "500 Milligrams(mg)",
        "startDate": "2023-11-29T00:00:00.000Z",
        "endDate": "2023-12-22T00:00:00.000Z",
        "frequency": "twice a day",
        "routeOfAdministration": "intraperitoneal",
        "note": "note",
        "patientId": "654c734d86d66f4a9280f08c",
        "visitId": "65675748cfe6b276a1c9e553",
        "institutionId": "65658fc8640b0765aee95c45",
        "status": "ongoing",
        "createdAt": "2023-11-29T15:26:53.071Z",
        "updatedAt": "2023-11-30T00:00:00.059Z",
        "__v": 0
    }
],
  referral: [
    {
        "_id": "655cb2ea29636f8e113149de",
        "patientId": "654b9d3386d66f4a9280e018",
        "referringProviderId": "655ca0ed29636f8e113140a2",
        "receivingProviderId": {
            "_id": "655649cf55ea5ed2627c7669",
            "firstName": "Idris",
            "lastName": "Green",
            "emailAddress": "green@gmail.com",
            "GPINumber": "GPI-00279549",
            "profilePic": "",
            "specialty": "Psychiatrist"
        },
        "receivingInstitutionId": {
            "_id": "654b9dd086d66f4a9280e03e",
            "businessName": "Creek hospital",
            "businessEmailAddress": "creekhospital@yopmail.com",
            "GPINumber": "GPI-00513117",
            "logoUrl": "https://photow-profile-images.s3.us-west-2.amazonaws.com/https://photow-profile-images.s3.us-west-2.amazonaws.com/20231110-06385620230417-125758pouuch (1).png"
        },
        "visitId": "655ca43929636f8e113142c1",
        "reason": "reason",
        "createdAt": "2023-11-21T13:38:50.496Z",
        "updatedAt": "2023-11-21T13:38:50.496Z",
        "__v": 0
    }
],
  procedures: [
    {
      _id: '6571b7ef1164b587628be99b',
      procedureName: 'Tooth Extraction',
      procedureDate: '2023-12-07T00:00:00.000Z',
      procedureTime: '04:00 PM',
      preProcedureAssessment: 'dfgh',
      preProcedureInstructions: 'bn',
      attendingProviderId: {
        _id: '655f422129472c09342ed241',
        firstName: 'trust',
        lastName: 'Hospital',
        emailAddress: 'fistemesta@gufum.com',
        GPINumber: 'GPI-00807668',
        profilePic: '',
      },
      patientId: '6564c190640b0765aee958c7',
      institutionId: {
        _id: '655f422129472c09342ed23f',
        businessName: 'trust Hospital',
        businessEmailAddress: 'fistemesta@gufum.com',
        GPINumber: 'GPI-00807668',
      },
      visitId: '6571af70e67c7b7bba7e633a',
      totalFee: 34500,
      serviceDetails: [
        {
          category: 'Procedure',
          subCategory: 'Dental Procedures',
          fee: 34000,
          vat: 500,
          _id: '6571b7ef1164b587628be99c',
        },
      ],
      createdAt: '2023-12-07T12:17:51.866Z',
      updatedAt: '2023-12-07T12:17:51.866Z',
      __v: 0,
    },
  ],
};
export const visitDetails = {
  "_id": "65672e29cfe6b276a1c9d7c5",
  "visitReadId": "V6570486",
  "patient": {
      "_id": "654c734d86d66f4a9280f08c",
      "firstName": "Bees",
      "lastName": "Salam",
      "emailAddress": "salam@enyata.com",
      "countryCode": "+234",
      "phoneNumber": "7063008123",
      "gender": "Female",
      "dateOfBirth": "1990-01-02T00:00:00.000Z",
      "gNumber": "G-00508846",
      "profilePic": "https://photow-profile-images.s3.us-west-2.amazonaws.com/20231206-205928IMG_7834.jpg"
  },
  "primaryDiagnosis": "",
  "secondaryDiagnosis": "",
  "provider": {
      "_id": "655f422129472c09342ed241",
      "firstName": "trust",
      "lastName": "Hospital",
      "emailAddress": "fistemesta@gufum.com"
  },
  "institutionId": "655f422129472c09342ed23f",
  "status": "completed",
  "chiefComplaint": [],
  "physicalExamination": [
    {
        "title": "Appearance",
        "name": "appearance",
        "observation": "Patient appears malnourished.",
        "_id": "6570509fa49ba56ccad9180b"
    }
],
  "createdAt": "2023-11-29T12:27:21.377Z",
  "updatedAt": "2023-12-07T09:16:21.969Z",
  "visitSummary": {
    "summary": "visitttttt",
    "instructions": [
        {
            "note": "use accordingly ",
            "provider": {
                "_id": "655f422129472c09342ed241",
                "firstName": "trust",
                "lastName": "Hospital",
                "emailAddress": "fistemesta@gufum.com",
                "profilePic": ""
            },
            "_id": "657051fba49ba56ccad91916",
            "createdAt": "2023-12-06T10:50:35.560Z",
            "updatedAt": "2023-12-06T10:50:35.560Z"
        }
    ]
},
  "vitals": {
      "_id": "65672e29cfe6b276a1c9d7c7",
      "sysBloodPressure": "32",
      "diaBloodPressure": "32",
      "heartRate": "11",
      "respiratoryRate": "11",
      "temperature": "11",
      "oxygenSaturation": "11",
      "height": "45.2",
      "weight": "22",
      "bmi": "0.010768266896389692",
      "patient": "654c734d86d66f4a9280f08c",
      "visitId": "65672e29cfe6b276a1c9d7c5",
      "additionalNote": "",
      "recordedBy": "655f422129472c09342ed241",
      "createdAt": "2023-11-29T12:27:21.393Z",
      "updatedAt": "2023-11-29T12:27:21.393Z",
      "__v": 0
  },
  "eSignature": {
      "signature": "https://photow-profile-images.s3.us-west-2.amazonaws.com/20231207-101617IMG_7834.jpg",
      "signatureDate": "2023-12-07T09:16:06.974Z",
      "provider": {
          "_id": "655f422129472c09342ed241",
          "firstName": "trust",
          "lastName": "Hospital",
          "emailAddress": "fistemesta@gufum.com",
          "profilePic": ""
      },
      "_id": "65718d65e67c7b7bba7e5896",
      "createdAt": "2023-12-07T09:16:21.969Z",
      "updatedAt": "2023-12-07T09:16:21.969Z"
  }
}
export const emptyVisitDetails = {
  physicalExamination: [],
  visitSummary: {},
  chiefComplaint: []
}
export const emptyTreatmentPlan = {
  "therapies": [],
  "medications": [],
  "referral": [],
  "procedures": []
}
export const labTests2 =  {
  "page": 1,
  "limit": 10,
  "total": 1,
  "labTest": [
      {
          "_id": "656dd31d21653bc62233000c",
          "testType": "Complete Blood Count (CBC)",
          "testProperties": {},
          "isCritical": false,
          "labReadId": "L13348806",
          "requestedBy": {
              "providerId": "6565a94f640b0765aee962a4",
              "firstName": "salam",
              "lastName": "salam",
              "emailAddress": "salam@yopmail.com",
              "phoneNumber": "7063008126",
              "profilePic": "",
              "GPINumber": "GPI-00937713"
          },
          "note": "note",
          "isAbnormal": false,
          "abnormalFindings": "",
          "testResult": [],
          "dateRequested": "2023-12-04T13:24:45.185Z",
          "requestStatus": "requested",
          "visitId": "656dd25421653bc62232febb",
          "createdAt": "2023-12-04T13:24:45.187Z",
          "updatedAt": "2023-12-04T13:24:45.187Z",
          "patientInfo": {
              "id": "654c734d86d66f4a9280f08c",
              "firstName": "Bees",
              "lastName": "Salam",
              "emailAddress": "salam@enyata.com",
              "gNumber": "G-00508846",
              "profilePic": "https://photow-profile-images.s3.us-west-2.amazonaws.com/20231206-205928IMG_7834.jpg"
          }
      }
  ],
  "criticalLabTest": 0,
  "allLabTest": 0
}

