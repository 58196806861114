import FormWrapper from 'components/FormWrapper'
import React from 'react'
import PropTypes from "prop-types"
import VerifyEmail from './VerifyEmail'

const VerifyAccountForm = ({ email }) => {
  return (
    <FormWrapper
      title="Reset Password"
      description={<>Almost done!<br /><span className='text-primary_shade_400 font-campton_sb'>Just one last step</span></>}
      caption={<>Enter the 6-digit otp sent to <span className='text-brand_secondary font-campton_m'>{email}</span></>}
      className="sm:h-full md:mt-12 mt-8 w-full sm:w-[387px] mx-auto"
    >
      <VerifyEmail email={email} variant='flex justify-center items-center flex-col w-full max-w-md mx-auto mt-12' />
    </FormWrapper>
  )
}

export default VerifyAccountForm
VerifyAccountForm.propTypes = {
  email: PropTypes.string
}