import React, { useEffect, useMemo, useState } from 'react';
import useAppointment from 'hooks/fetch/useAppointment';
import useHome from 'hooks/fetch/useHome';
import useTable from 'hooks/useTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppointmentState } from 'store/modules/appointments';
import { useAuthState } from 'store/modules/auth';
import ModalContent from './Components/ModalContent';
import { useRecordState } from 'store/modules/myRecords';
import { medicalRecordsFields, requiredFields } from 'mocks/myRecords';

const useHomeCompLogic = () => {
  const { loggedUser } = useAuthState();
  const {patientInfo, lifestyleHabits } = useRecordState()
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [completeProfile, setCompleteProfile] = useState(false);
  const { get_medications, loading } = useHome();
  const { params } = useTable();
  const { appointments } = useAppointmentState();
  const { get_appointments, loading: is_appointment_loading } = useAppointment();

  const NewLifestyleData = lifestyleHabits ? Object.fromEntries(
    Object?.entries(lifestyleHabits).filter(([key]) => key !== 'lifestyleLastModified') 
  ) : {}

  const steps = useMemo(() => {
    return !patientInfo?.gender || !patientInfo?.maritalStatus || !patientInfo?.race || !patientInfo?.officeAddress || !patientInfo?.ethnicity
  }, [patientInfo])

  useEffect(() => {
    get_appointments({
      page: params.page,
      limit: params.limit,
      status: 'upcoming',
      requestType: 'patient',
      patientId: loggedUser._id,
    });
  }, [params.page, params.limit]);
  useEffect(() => {
    get_medications(loggedUser._id)
  }, []);

  const modalContent = [
    <ModalContent
      key="basic-info"
      title="Basic information"
      description="Gender, marital status, race, ethnicity, address"
      btnText="Add basic information"
      isDisabled={false}
      index={0}
      onBtnClick={() => {
        navigate('/profile');
        setCompleteProfile(false) 
      }}
    />,
    <ModalContent
      key="previous-history"
      title="Previous medical history"
      description="Blood type, emergency contact, insurance information, family hospital"
      btnText="Add medical records"
      isDisabled={steps}
      index={1}
      onBtnClick={() => {
      setCompleteProfile(false)
      navigate('/more')}
      }
    />,
  ];
  
  const totalLifestyleHabitAnswers = useMemo(() => {
    // Convert the values of the object into an array
    const valuesArray = Object.values(NewLifestyleData);

    // Flatten the array (handle nested objects)
    const flattenedArray = valuesArray.flatMap(value => {
        if (typeof value === 'object' && value !== null) {
          return Object.values(value);
        } else {
          return value;
        }
    });
    // Filter out empty and undefined values
    const nonEmptyValues = flattenedArray.filter(value => value !== "" && value !== undefined);

    // Return the total count of non-empty values
    return nonEmptyValues.length;
  }, [lifestyleHabits])

  const calculateProfileCompleteness = useMemo(() => {
    const filledFields = requiredFields.filter((field) => {
      const keys = field.split(".");
      let value = patientInfo;
        for (const key of keys) {
        value = value?.[key];
        if (!value) {
          return 0;
        }
      }
      return 1;
    });
    const filledMedicalRecordFields = medicalRecordsFields.filter((field) => {
      const keys = field.split(".");
      let value = patientInfo;
      for (const key of keys) {
        value = value?.[key];
        if (value?.length) {
          return 1;
        }
      }
      return 0;
    });
    const totalFilledFields = 
    filledFields?.length + 
    filledMedicalRecordFields?.length + (totalLifestyleHabitAnswers === 32 ? 1 : 0) + 
    (patientInfo?.medicalRecord?.bloodType?.bloodType && patientInfo?.medicalRecord?.bloodType?.genotype ? 1 : 0)
    const totalRequiredFields = requiredFields.length + medicalRecordsFields.length + 2;
    const percentageComplete = (totalFilledFields / totalRequiredFields) * 100;
    
    return percentageComplete;
  }, [patientInfo])

  const profilePercentage = useMemo(() => {
    return calculateProfileCompleteness.toFixed()
  }, [patientInfo]);


  return {
    loading,
    pathname,
    loggedUser,
    appointments,
    modalContent,
    completeProfile,
    setCompleteProfile,
    is_appointment_loading,
    NewLifestyleData,
    profilePercentage,
    totalLifestyleHabitAnswers
  };
};

export default useHomeCompLogic;
