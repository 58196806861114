import React, { useState } from 'react';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Illustration } from 'assets/svg/blood-type-illustration.svg';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../Header';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddInsuranceInfo';
import { ReactComponent as InsuranceSolid } from 'assets/icons/medicalRecords/insurance-solid.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import dayjs from 'dayjs';
import { useRecordState } from 'store/modules/myRecords';

const Insurance = ({ selected, onClick }) => {
  //Hooks
  const { delete_medical_record } = useMedicalRecord();
  //Redux State
  const { insuranceInformation, loading } = useRecordState();
  //Local State
  const [showModal, setShowModal] = useState();
  const [type, setType] = useState();
  const [selectedDetails, setSelectedDetails] = useState();
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={() => handleShowModal('Add')} condition={insuranceInformation?.length > 0} />
        {loading && (
          <div className="mt-10">
            <Spinner />
          </div>
        )}
        {!loading &&
          (insuranceInformation?.length > 0 ? (
            <>
              <div className="mt-6">
                {insuranceInformation.map((item) => (
                  <div
                    className="flex justify-between items-center border-b last-of-type:border-b-0 border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0"
                    key={item?._id}
                  >
                    <div className="flex space-x-4">
                      <InsuranceSolid />
                      <div>
                        <p className="capitalize font-campton_m text-neutral_black text-14 mb-1">{item.providerName} <span className='bg-[#E6F9EE] py-1 px-2 rounded-full text-14 text-[#1BA154] font-campton_r'>{item.plan}</span></p>
                        <p className="text-12 text-neutral_body mb-1">
                          Duration:{' '}
                          <span className="font-campton_r text-neutral_black ">
                            {dayjs(item.startDate).format('DD-MM-YYYY') +
                              ' to ' +
                              dayjs(item.endDate).format('DD-MM-YYYY')}
                          </span>
                        </p>
                        <p className="text-12 text-neutral_body font-campton_r">
                        Policy Number:{' '}
                          <span className=" text-neutral_black ">
                            {item.policyNumber}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <EditIcon
                        data-testid="test-edit"
                        className="cursor-pointer"
                        onClick={() => handleShowModal('Edit', () => setSelectedDetails(item))}
                      />
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => delete_medical_record('insuranceInformation', item._id)}
                        data-testid="test-delete"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyState
              illustration={<Illustration />}
              title="No Insurance Information"
              description="Add your insurance details and ensure comprehensive coverage in just a few taps."
              btnText="Add Insurance"
              onClick={() => handleShowModal('Add')}
            />
          ))}
      {showModal && (
        <DefaultModal
          closeModal={() => setShowModal(false)}
          className=""
          styles="min-w-[90%] md:min-w-[730px]"
          title={`${type} Insurance Information`}
          subTitle="Fill the form to input your insurance information."
          containerVariant="bg-neutral_white"
        >
          <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
        </DefaultModal>
      )}
</motion.div>
</AnimatePresence>
  );
};

export default Insurance;
Insurance.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
