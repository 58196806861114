import Dropdown from 'components/Dropdown/Index';
import FormWrapper from 'components/FormWrapper';
import { allergiesTypes } from 'mocks/medicalRecords';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { useAuthState } from 'store/modules/auth';
import { ReactComponent as AllergiesSolid } from 'assets/icons/medicalRecords/allergies-solid.svg';

const AddAllergies = ({ type, callback, selectedRecord }) => {
  //Local State
  const [formData, setFormData] = useState({
    allergy: '',
  });
  const [selectedAllergies, setSelectedAllergies] = useState();
  const [allergies, setAllergies] = useState([]);

  //Hooks
  const { update_medical_record, edit_medical_record } = useMedicalRecord();

  //Redux Store
  const {loggedUser} = useAuthState()
  //Effect
  useEffect(() => {
    if (selectedRecord && type === 'Edit') {
      setFormData({
        allergy: selectedRecord,
      });
      setSelectedAllergies({
        id: selectedRecord,
        name: selectedRecord,
        label: selectedRecord,
      });
    }
  }, [selectedRecord, type]);
  const pushIntoArray = () => {
    setAllergies(prevAllergies => [...prevAllergies, formData])
    setSelectedAllergies();
  };
  //Handler function
  const updateMedicalRecord = () => {
    const payload = {
      updatedAllergy: formData.allergy,
      allergy: selectedRecord
    }
    type === 'Edit'
      ? edit_medical_record('allergies', selectedRecord, payload, callback)
      : update_medical_record('allergies', loggedUser._id, formData , pushIntoArray);
  };
  const disabledBtn = () => {
    return !selectedAllergies;
  };
  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full">
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-6 grid-cols-12 mt-6">
            <Dropdown
              label="Select Allergies"
              variant="lg:col-span-6 col-span-12"
              name="allergies"
              id="allergies"
              value={selectedAllergies}
              //       disabled={loading}
              optionContainerVariant="w-full md:min-h-[100px] min-h-[150px]"
              options={allergiesTypes}
              onChange={(data) => {
                setSelectedAllergies(data);
                setFormData({ ...formData, allergy: data.value });
              }}
            />
            {type === 'Add' && (
              <p
                className={`text-brand_secondary font-campton_m text-14 ${!selectedAllergies ? 'cursor-not-allowed' : 'cursor-pointer'} col-span-12`}
                data-testid='test-add'
                onClick={disabledBtn() ? null : updateMedicalRecord}
                disabled={!selectedAllergies}
              >
                Add
              </p>
            )}
            {allergies.length > 0 && (
              <div className="col-span-12 border-t border-dashed border-neutral_stroke pt-6">
                {allergies?.map((item) => (
                  <div key={item} className="flex space-x-4 mb-6 last-of-type:mb-0">
                    <AllergiesSolid />
                    <p className="capitalize font-campton_m text-neutral_black text-14">{item?.allergy}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              className="w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white"
              onClick={type === 'Edit' ? updateMedicalRecord : callback}
            >
              {type === 'Edit' ? 'Save' : 'Close'}
            </button>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddAllergies;
AddAllergies.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};
