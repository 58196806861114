import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';

const MentalAndEmotional = ({ formData, setFormData, type }) => {
  const [mentalIllness, setMentalIllness] = useState(formData[type].historyOfMentalConditions);
  const [mentalActivities, setMentalActivities] = useState(formData[type].mentalActivities);
  const [emotionalHealthRating, setEmotionalHealthRating] = useState(formData[type].emotionalHealthRating);

  const handleMentalIllness = (name) => {
    setMentalIllness(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], historyOfMentalConditions: name}}));

  };
  const handleMentalActivities = (name) => {
    setMentalActivities(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], mentalActivities: name}}));

  };
  const handleEmotionalRate = (name) => {
    setEmotionalHealthRating(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], emotionalHealthRating: name}}));

  };
  return (
    <div className="my-4">
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          Do you have any history of mental health conditions?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6 h-[45px]"
            isChecked={mentalIllness === 'yes'}
            onChange={() => handleMentalIllness('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={mentalIllness === 'no'}
            onChange={() => handleMentalIllness('no')}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
        How would you rate your overall mood and emotional well-being?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Poor"
            variant="col-span-3"
            isChecked={emotionalHealthRating === 'poor'}
            onChange={() => handleEmotionalRate('poor')}
          />
          <RadioButtonSelect
            label="Fair"
            variant="col-span-3"
            isChecked={emotionalHealthRating === 'fair'}
            onChange={() => handleEmotionalRate('fair')}
          />
            <RadioButtonSelect
            label="Good"
            variant="col-span-3"
            isChecked={emotionalHealthRating === 'good'}
            onChange={() => handleEmotionalRate('good')}
          />
            <RadioButtonSelect
            label="Excellence"
            variant="col-span-3"
            isChecked={emotionalHealthRating === 'excellence'}
            onChange={() => handleEmotionalRate('excellence')}
          />

        </div>
      </div>
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">
          Do you engage in activities that promote mental and emotional well-being?
        </p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={mentalActivities === 'yes'}
            onChange={() => handleMentalActivities('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={mentalActivities === 'no'}
            onChange={() => handleMentalActivities('no')}
          />
        </div>
      </div>
    </div>
  );
};

export default MentalAndEmotional;
MentalAndEmotional.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
