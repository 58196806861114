import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showDrawer: true,
  notification: {
    display: false,
    status: '',
    message: '',
  },
  loader: {
    display: false,
    title: '',
    caption: '',
  }
}

export const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    notify: (state, action) => {
      if (action?.payload?.display === false) {
        state.notification.display = false;
        return
      }
  
      state.notification.display = true;
      state.notification.status = action?.payload?.status;
      state.notification.message = action?.payload?.message;
    },
    showLoader: (state, action) => {
      if (action?.payload?.display === false) {
        state.loader.display = false;
        return
      }
  
      state.loader.display = true;
      state.loader.title = action?.payload?.title;
      state.loader.caption = action?.payload?.caption;
    },
    toggleDrawer: (state, action) => {
      if (action.payload !== undefined) {
        state.showDrawer = action.payload
        return
      }
      state.showDrawer = !state.showDrawer;
    }
  }
})

// Action creators are generated for each case reducer function
export const { notify, toggleDrawer, showLoader } = global.actions
export default global.reducer