import React from "react";
import PropTypes from 'prop-types';
import FormWrapper from "components/FormWrapper";
import Button from "components/Buttons/Button";
import useAuth from "hooks/fetch/useAuth";

export default function ChooseStep({ callback, means, setMeans, emailAddress, phoneNumber }) {
    const { forgot_password, loading } = useAuth()

    const options = [
        {
            id: 1,
            name: "Email address"
        },
        {
            id: 2,
            name: "Phone number"
        }
    ]

    const sendOTP = () => {
        forgot_password({ payload: { emailAddress } })
            .then(res => {
                if (res?.code === 200) {
                    callback()
                }
            })
    }

    return (
        <FormWrapper className="relative" onSubmit={sendOTP}>
            <div className="modal-right-body">
                <p className="text-14 text-neutral_body font-campton_r leading-[21px] mb-[24px]">An OTP for verification will sent to your preferred option to change your password.</p>
                <p className="text-14 text-neutral_black font-campton_r leading-[21px] mb-[24px]">How do you want to receive the OTP?</p>
                <div className="mb-[8px] grid grid-cols-2 gap-[16px] font-campton_r">
                    {options.map((option) =>
                        <div
                            key={option.id}
                            data-testid={option.name}
                            className={`
                            flex items-center text-14 rounded-[8px] px-[16px] py-[12px] cursor-pointer hide_tap border
                            ${means !== option.name ? 'border-neutral_stroke_2 text-neutral_body ' : 'border-brand_secondary text-neutral_black bg-[#FFF3EB]'}
                            `}
                            onClick={() => setMeans(option.name)}
                        >
                            {means === option.name ?
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#FF7A21" />
                                    <circle cx="10" cy="10" r="4" fill="white" />
                                    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="white" />
                                </svg> :
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1" y="0.5" width="19" height="19" rx="9.5" fill="white" />
                                    <rect x="1" y="0.5" width="19" height="19" rx="9.5" stroke="#DFE2E2" />
                                </svg>
                            }
                            <p className="ml-[8px]">{option.name}</p>
                        </div>
                    )}
                </div>
                <p className="text-12 text-neutral_body font-campton_r leading-[21px]">
                    OTP code will be sent to {means === 'Email address' ? emailAddress : phoneNumber}
                </p>
            </div>
            <div className="modal-right-button-container">
                <Button
                    name="Send OTP"
                    theme="blue"
                    className="h-[40px] text-16 w-[100px]"
                    disabled={loading}
                    loading={loading}
                />
            </div>
        </FormWrapper>
    )
}

ChooseStep.propTypes = {
    callback: PropTypes.func,
    means: PropTypes.string,
    setMeans: PropTypes.func,
    emailAddress: PropTypes.string,
    phoneNumber: PropTypes.string
};