import React, { useState } from 'react';
import { ReactComponent as HeartRate } from 'assets/icons/vitals/heartbeat.svg';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import DefaultModal from 'components/Modals/DefaultModal';
import ViewSingleVitals from './ViewSingleVitals';
import { extractPatientVitals } from 'utils/extractPatientVitals';
import Spinner from 'components/Spinner';
import EmptyState from 'components/EmptyState';

const VitalsHistory = ({ history, loading }) => {
  const [vitalSign, setVitalSign] = useState({});
  const [showModal, setShowModal] = useState();

  const handleShowModal = (item) => {
    setVitalSign(item);
    setShowModal(true);
  };

  return (
    <div className="mt-6">
      <p className="font-campton_sb text-18 text-black_100 mb-2">History</p>
      <div className="mt-4 ">
        {history?.length ? history?.map((item) => (
          <div
            data-testid={`test-${item?.createdAt}`}
            key={item?.createdAt}
            className="flex justify-between py-4 first-of-type:pt-0 border-b last-of-type:border-b-0 border-neutral_stroke_2 cursor-pointer"
            onClick={() => handleShowModal(item)}
          >
            <div className="flex font-campton_r lg:text-14 text-12 space-x-4">
              <HeartRate className='shrink-0' />
              <div className="">
                <p className="font-campton_m">{dayjs(item?.updatedAt).format('MMM DD, YYYY')}</p>
                <p className="text-neutral_body">
                  {extractPatientVitals(item)?.join(", ")}
                </p>
                <p className="border border-neutral_stroke_2 px-2 h-7 rounded-full text-brand_blue flex justify-center items-center mt-2 w-fit capitalize">
                  {item?.recordedBy?.businessName || `${item?.recordedBy?.firstName + ' ' + item?.recordedBy?.lastName}`}
                </p>
              </div>
            </div>
            <p className="text-12 text-neutral_body font-campton_r min-w-max">{dayjs(item?.updatedAt).format('hh:mm A')}</p>
          </div>
        )) : ''}
        {(!history?.length && !loading) ? <EmptyState title="No records found." /> : '' }
        {loading && !history?.length ? <Spinner/> : ''}

      </div>
      {showModal && (
        <DefaultModal
          closeModal={() => setShowModal(false)}
          subTitle="View your current vital signs here"
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
          title="Vital Signs"
        >
          <ViewSingleVitals vitalSign={vitalSign} />
        </DefaultModal>
      )}
    </div>
  );
};

export default VitalsHistory;
VitalsHistory.propTypes = {
  history: PropTypes.array,
  loading: PropTypes.bool,
};
