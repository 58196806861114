import React from 'react';
import PropTypes from 'prop-types';

const StatusCard = ({ icon, title, description, status, containerVariant, loading, onClick }) => {
  return (
    <div
      className={`${containerVariant} cursor-pointer rounded-2xl p-4`}
      onClick={onClick}
      data-testid={`test-${title}`}
    >
      <div className="">
        {icon}
        <div className="flex justify-between flex-col ">
          <div className="">
            <p className="text-14 font-campton_m text-[#0D0F11] mt-4">{title}</p>
            <p className="text-neutral_body stat-card font-campton_r text-12 mt-1">{description}</p>
          </div>
          <p className="text-[#0D0F11] mt-4 bg-[#10354114] w-full rounded-full py-1 text-center h-7 flex items-center justify-center font-campton_r text-12">
            {loading ? '...' : status} added
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
StatusCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  containerVariant: PropTypes.string,
  icon: PropTypes.any,
  status: PropTypes.any,
  styles: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};
