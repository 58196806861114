import { useState } from "react"

export default function useTable(limit) {
    const [params, setParams] = useState({
        page: 1,
        limit: limit ?? 20,
        search: '',
    })

    const changePage = (e) => setParams({ ...params, page: e })
    const selectLimit = (e) => setParams({ ...params, limit: e })
    const handleSearch = (e) => setParams({ ...params, search: e.target.value.trim(), page: 1 })

    return { params, setParams, changePage, selectLimit, handleSearch }
}