import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import EmptyState from '../Forms/EmptyState';
import { ReactComponent as Illustration } from 'assets/svg/surgeries-illustration.svg';
import DefaultModal from 'components/Modals/DefaultModal';
import Form from '../Forms/AddAllergies';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-with-border.svg';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { ReactComponent as AllergiesSolid } from 'assets/icons/medicalRecords/allergies-solid.svg';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../Header';
import { useRecordState } from 'store/modules/myRecords';

const Allergies = ({ selected, onClick }) => {
  //Local State
  const [showModal, setShowModal] = useState();
  const [type, setType] = useState();
  const [selectedDetails, setSelectedDetails] = useState();
  //Redux State
  const { loading } = useAuthState();
  const { allergies } = useRecordState();

  //Hooks
  const { delete_medical_record } = useMedicalRecord();
  //Handler function
  const handleShowModal = (type, callback) => {
    if (callback) callback();
    setType(type);
    setShowModal(!showModal);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <Header selected={selected} onClick={onClick} callback={() => handleShowModal('Add')} condition={allergies?.length > 0} />
        {loading && (
          <div className="mt-10">
            <Spinner />
          </div>
        )}

        {!loading &&
          (allergies?.length > 0 ? (
            <div className="mt-6">
              {allergies?.map((item) => (
                <div
                  className="flex justify-between items-center border-b last-of-type:border-b-0 border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0"
                  key={item}
                >
                  <div key={item} className="flex space-x-4 last-of-type:mb-0">
                    <AllergiesSolid />
                    <p className="capitalize font-campton_m text-neutral_black text-14">{item}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <EditIcon
                      data-testid="test-edit"
                      onClick={() => handleShowModal('Edit', () => setSelectedDetails(item))}
                      className="cursor-pointer"
                    />
                    <DeleteIcon
                      data-testid="test-delete"
                      onClick={() => delete_medical_record('allergies', item)}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <EmptyState
              illustration={<Illustration />}
              title="No Previous Allergies"
              description="All added allergies will be displayed here."
              btnText="Add Allergies"
              onClick={() => handleShowModal('Add')}
            />
          ))}
        {showModal && (
          <DefaultModal
            closeModal={() => setShowModal(false)}
            styles="min-w-[90%] md:min-w-[730px]"
            title={`${type} Allergies`}
            subTitle="Input your allergies for personalized health insights and recommendations."
            containerVariant="bg-neutral_white"
          >
            <Form callback={() => setShowModal(false)} type={type} selectedRecord={selectedDetails} />
          </DefaultModal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default Allergies;
Allergies.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
