const base = '/patient-profile'

export const medicalRecord = {
  get_patient_profile: `${base}`,
  update_medical_record: `${base}/medical-records`,
  request_upload_url: `${base}/pre-signed-url`,
  update_liftstyle: `${base}/medical-records/lifestyle`,
  get_vitals_history: `${base}/vitals-history`,
  create_vitals: `${base}/vitals`,
  treatment_plan: `${base}/visits`,
  lab_test: `${base}/visit`
};
