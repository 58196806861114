import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types"
import Button from "components/Buttons/Button";
import FormWrapper from "components/FormWrapper";
import DefaultModal from "components/Modals/DefaultModal";
import { Input } from "components/Inputs";
import Dropdown from "components/Dropdown/Index";
import TextArea from "components/Inputs/TextArea";
import useAuth from "hooks/fetch/useAuth";
import { useDispatch } from "react-redux";
import { logout } from "store/modules/auth/actions";
import { reasonsForTermination, reasonsForDeactivation } from "mocks/myRecords";
export default function Termination({ reason, status, setTermination }) {
    const [step, setStep] = useState(1)
    const dispatch = useDispatch()
    const { disable_account, delete_account, loading } = useAuth()
    const [selectedReason, setSelectedReason] = useState({})
    const [formData, setFormData] = useState({
        password: '',
        reason: ''
    })

    useEffect(() => {
        setFormData({ ...formData, password: '', reason: '' })
        setSelectedReason({})
    }, [])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const selectReason = useCallback((e) => {
        setSelectedReason(e)
        setFormData({ ...formData, reason: e.value })
    }, [formData])

    const submit = () => {
        if (reason === 'delete') {
            delete_account({ payload: { password: formData.password, reason: formData.reason } })
                .then(res => {
                    if (res?.code === 200) dispatch(logout())
                })
            return
        }
        disable_account({ payload: { password: formData.password, disableReason: formData.reason } })
            .then(res => {
                if (res?.code === 200) dispatch(logout())
            })
    }

    return (
        <>
            {status ?
                <>
                    <DefaultModal
                        closeModal={() => {
                            setSelectedReason({})
                            setFormData({ reason: '', password: '' })
                            setTermination({ reason: '', status: false })
                        }}
                        styles="w-full md:w-[457px]"
                        position="modal-right"
                        title={`${reason === 'delete' ? "Delete" : 'Disable'} Account`}
                        variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
                    >
                        <FormWrapper className="relative">
                            <>
                                <div className="modal-right-body">
                                    <p className="text-14 text-neutral_body font-campton_r leading-[21px] mb-[24px]">
                                        {reason === 'delete' ?
                                            'Deleting your account only restricts access to your account.' :
                                            'Disabling your account only restricts access to your account.'
                                        }
                                    </p>
                                    <div className="mb-[24px] w-full">
                                        <Dropdown
                                            label="Reason"
                                            type="select"
                                            id="reason"
                                            name="reason"
                                            position="static"
                                            value={selectedReason}
                                            options={reason === 'delete' ? reasonsForTermination : reasonsForDeactivation}
                                            selected={selectedReason}
                                            onChange={selectReason}
                                        />
                                    </div>
                                    {selectedReason.label === "Others" ?
                                        <div>
                                            <TextArea
                                                placeholder="Enter reason"
                                                id="others"
                                                name="others"
                                                //readOnly={loading}
                                                value={formData.reason}
                                                onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
                                                rows={3}
                                            />
                                        </div> : ''
                                    }
                                </div>
                                <div className="modal-right-button-container">
                                    <Button
                                        name="Continue"
                                        theme="blue"
                                        className="h-[40px] text-16 w-[100px]"
                                        onClick={() => setStep(2)}
                                        disabled={!formData.reason}
                                    />
                                </div>
                            </>
                        </FormWrapper>
                    </DefaultModal>
                    {step === 2 ?
                        <DefaultModal
                            closeModal={() => setStep(1)}
                            styles="w-full md:w-[457px]"
                            position="modal-right"
                            title="Confirmation"
                            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
                        >
                            <FormWrapper className="relative" onSubmit={() => submit()}>
                                <>
                                    <div className="modal-right-body">
                                        <p className="text-14 text-neutral_body font-campton_r leading-[21px] mb-[24px]">
                                            {reason === 'delete' ?
                                                'Deleting your account only restricts access to your account.' :
                                                'Disabling your account only restricts access to your account.'
                                            }
                                        </p>
                                        <div className="mb-[24px] w-full sm:mr-[24px]">
                                            <Input
                                                label="Password"
                                                type="password"
                                                id="password"
                                                name="password"
                                                readOnly={loading}
                                                value={formData?.password}
                                                onChange={handleChange}
                                                showError={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-right-button-container">
                                        <Button
                                            name={`${reason === "delete" ? "Delete" : "Disable"} Account`}
                                            theme="blue"
                                            className="h-[40px] text-16 w-[149px]"
                                            disabled={!formData.password}
                                            loading={loading}
                                        />
                                    </div>
                                </>
                            </FormWrapper>
                        </DefaultModal> : ''
                    }
                </> : ''
            }
        </>
    )
}

Termination.propTypes = {
    reason: PropTypes.string,
    status: PropTypes.bool,
    setTermination: PropTypes.func
}