import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as Microscope } from 'assets/svg/microscope.svg';
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import { SlideUp } from "components/Animations";
import { Search } from "components/Inputs";
import Status from "components/Status";
import CustomSkeleton from "components/Skeleton";
import EmptyState from "components/EmptyState";
import { Tracker } from "components/Cards";

export default function LabTestHistory({
    tests, setTab, setSelectedTest, isFetchingLabTest, isFetchingPendingLabTest, handleSearch, pendingLabTest
}) {
    return (
        <SlideUp>
            {!isFetchingPendingLabTest && !pendingLabTest.length ?
                <Tracker
                    title="You currently have no pending test(s)"
                    icon={<Microscope />}
                    onClick={() => {
                        setTab('upload')
                        setSelectedTest(test)
                    }}
                /> :
                pendingLabTest?.map((test) =>
                    <Tracker
                        key={test?._id}
                        title={test?.testType}
                        purpose="Pending Test"
                        date={test?.dateRequested}
                        id={test?.labReadId || 'N/A'}
                        icon={<Microscope />}
                        buttonName="Upload test result"
                        onClick={() => {
                            setTab('upload')
                            setSelectedTest(test)
                        }}
                    />
                )

            }
            {isFetchingPendingLabTest && !pendingLabTest?.length ?
                <div className='mb-[16px]'>
                    <CustomSkeleton className="mb-[8px] py-[40px]" />
                </div> : ''
            }
            <p className='text-14 text-neutral_black font-campton_m mb-[16px]'>Test History</p>
            <Search
                id="search"
                name="search"
                placeholder="Search by test name..."
                onChange={handleSearch}
                className="mb-[16px]"
            />
            {tests?.map((test) =>
                <section key={test?.date} className="mb-[24px]">
                    <p className="text-neutral_body font-campton_r text-14">{formatDate(test?.date)}</p>
                    {test?.items?.map((item) =>
                        <div
                            key={item?._id}
                            data-testid={item?._id}
                            onClick={() => setSelectedTest(item)}
                            className="flex justify-between py-[16px] border-b border-[#ECEEEE] transition_left cursor-pointer hide_tap"
                        >
                            <div className="flex">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
                                    <rect width="32" height="32" rx="16" fill="#FAE0E0" />
                                    <path d="M23.7105 13.0712L19.4918 8.85247C19.4395 8.80017 19.3775 8.75868 19.3092 8.73038C19.2409 8.70207 19.1677 8.6875 19.0938 8.6875C19.0199 8.6875 18.9467 8.70207 18.8784 8.73038C18.8101 8.75868 18.7481 8.80017 18.6958 8.85247L9.60933 17.939C9.01894 18.5294 8.68726 19.3301 8.68726 20.1651C8.68726 21 9.01894 21.8007 9.60933 22.3911C10.1997 22.9815 11.0005 23.3132 11.8354 23.3132C12.6704 23.3132 13.4711 22.9815 14.0615 22.3911L21.9288 14.5239L23.4904 14.0036C23.5815 13.9733 23.6632 13.9202 23.728 13.8495C23.7927 13.7787 23.8383 13.6925 23.8603 13.5991C23.8824 13.5058 23.8802 13.4083 23.854 13.316C23.8278 13.2238 23.7784 13.1397 23.7105 13.0719V13.0712ZM13.2656 21.5952C12.8839 21.963 12.3732 22.1664 11.8432 22.1615C11.3131 22.1566 10.8062 21.9439 10.4314 21.5691C10.0566 21.1943 9.8439 20.6873 9.83901 20.1573C9.83412 19.6273 10.0375 19.1165 10.4053 18.7349L12.4303 16.7099C13.0258 16.506 13.9596 16.4286 15.1774 17.0615C15.9227 17.451 16.5963 17.624 17.1855 17.6753L13.2656 21.5952ZM21.4471 13.498C21.3642 13.5256 21.2889 13.572 21.2271 13.6337L18.4448 16.416C17.8493 16.6199 16.9155 16.6972 15.6977 16.0644C14.9524 15.6749 14.2788 15.5019 13.6896 15.4506L19.0938 10.0457L22.2712 13.2231L21.4471 13.498Z" fill="#DF3939" />
                                </svg>
                                <div className="ml-[8px]">
                                    <p className="text-12 text-neutral_black font-campton_m mb-[4px] uppercase">{item?.testType}</p>
                                    <p className="text-12 text-neutral_body">
                                        {item?.institution?.businessAddress?.address ?
                                            `${item?.institution?.businessAddress?.address}, ${item?.institution?.businessAddress?.city}.` : 'N/A'}
                                    </p>
                                    <Status
                                        status={item?.institution?.businessName}
                                        className="border border-neutral_stroke_2 mt-[8px] text-[#143566] text-12"
                                    />
                                </div>
                            </div>
                            <p className='text-12 font-campton_r text-neutral_body'>
                                {formatTwelveHour(item?.dateRequested)}
                            </p>
                        </div>
                    )}
                </section>
            )}
            {!tests?.length && isFetchingLabTest ?
                <div className="py-[20px]">
                    <CustomSkeleton count={3} className="mb-[8px] py-[4px]" />
                </div> : ''
            }
            {!tests?.length && !isFetchingLabTest ? <EmptyState title="No History." className="mt-[100px]" description='All required lab tests will be displayed here.'/> : ''}
        </SlideUp>
    )
}

LabTestHistory.propTypes = {
    tests: PropTypes.array,
    setTab: PropTypes.func,
    setSelectedTest: PropTypes.func,
    isFetchingLabTest: PropTypes.bool,
    isFetchingPendingLabTest: PropTypes.bool,
    handleSearch: PropTypes.func,
    pendingLabTest: PropTypes.array
}