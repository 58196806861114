


export const mockCountry = [
  {
    id: 1,
    value: 'Nigeria',
    label: 'Nigeria',
  },
  {
    id: 2,
    value: 'Ghana',
    label: 'Ghana',
  },
  {
    id: 3,
    value: 'Kenya',
    label: 'Kenya',
  },
  {
    id: 4,
    value: 'Togo',
    label: 'Togo',
  },
];
export const genderOptions = [
  {
    id: 1,
    value: 'male',
    label: 'Male',
  },
  {
    id: 2,
    value: 'female',
    label: 'Female',
  },
];
export const maritalStatusOptions = [
  {
    id: 1,
    value: 'single',
    label: 'Single',
  },
  {
    id: 2,
    value: 'married',
    label: 'Married',
  },
];
export const raceOptions = [
  {
    id: 1,
    value: 'american indian or alaska native',
    label: 'American Indian or Alaska Native',
  },
  {
    id: 2,
    value: 'hispanic or tatino',
    label: 'Hispanic or Latino',
  },
  {
    id: 3,
    value: 'asian',
    label: 'Asian',
  },
  {
    id: 4,
    value: 'black or african american',
    label: 'Black or African American',
  },
  {
    id: 5,
    value: 'native hawaiian or other pacific islander',
    label: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    id: 6,
    value: 'White',
    label: 'White',
  },
];
export const ethnicityOptions = [
  {
    id: 1,
    value: 'not hispanic or latino',
    label: 'Not Hispanic or Latino',
  },
  {
    id: 2,
    value: 'hispanic or latino',
    label: 'Hispanic or Latino',
  },
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const bloodTypes = [
  {
    id: 1,
    value: 'A+',
    label: 'A+',
  },
  {
    id: 2,
    value: 'A-',
    label: 'A-',
  },
  {
    id: 3,
    value: 'B+',
    label: 'B+',
  },
  {
    id: 4,
    value: 'B-',
    label: 'B-',
  },
  {
    id: 5,
    value: 'AB+',
    label: 'AB+',
  },
  {
    id: 6,
    value: 'AB-',
    label: 'AB-',
  },
  {
    id: 7,
    value: 'O+',
    label: 'O+',
  },
  {
    id: 7,
    value: 'O-',
    label: 'O-',
  },
];
export const genotypes = [
  {
    id: 1,
    value: 'AA',
    label: 'AA',
  },
  {
    id: 2,
    value: 'AS',
    label: 'AS',
  },
  {
    id: 3,
    value: 'AC',
    label: 'AC',
  },
  {
    id: 4,
    value: 'SS',
    label: 'SS',
  },
  {
    id: 5,
    value: 'SC',
    label: 'SC',
  },
];
export const hereditaryDiseases = [
  {
    id: 1,
    value: 'Brugada Syndrome',
    label: 'Brugada Syndrome',
  },
  {
    id: 2,
    value: 'Charcot-Marie-Tooth Disease',
    label: 'Charcot-Marie-Tooth Disease',
  },
  {
    id: 3,
    value: 'Cleft Lip and Palate',
    label: 'Cleft Lip and Palate',
  },
  {
    id: 4,
    value: 'Arrhythmogenic Right Ventricular Dysplasia/Cardiomyopathy (ARVD/C)',
    label: 'Arrhythmogenic Right Ventricular Dysplasia/Cardiomyopathy (ARVD/C)',
  },
  {
    id: 5,
    value: 'Cleidocranial Dysplasia (CCD)',
    label: 'Cleidocranial Dysplasia (CCD)',
  },
  {
    id: 6,
    value: 'Cystic Fibrosis',
    label: 'Cystic Fibrosis',
  },
  {
    id: 7,
    value: 'Familial Adenomatous Polyposis',
    label: 'Familial Adenomatous Polyposis',
  },
  {
    id: 8,
    value: 'Hirschsprungs Disease',
    label: 'Hirschsprungs Disease',
  },
  {
    id: 9,
    value: 'Huntington`s Disease',
    label: 'Huntington`s Disease',
  },
  {
    id: 10,
    value: 'Klinefelter Syndrome',
    label: 'Klinefelter Syndrome',
  },
  {
    id: 11,
    value: 'Kneist Syndrome',
    label: 'Kneist Syndrome',
  },
  {
    id: 12,
    value: 'Marfan syndrome',
    label: 'Marfan syndrome',
  },
  {
    id: 13,
    value: 'Mucopolysaccharidoses (MPS)',
    label: 'Mucopolysaccharidoses (MPS)',
  },
  {
    id: 14,
    value: 'Muscular Dystrophy',
    label: 'Muscular Dystrophy',
  },
  {
    id: 15,
    value: 'Sickle Cell Disease',
    label: 'Sickle Cell Disease',
  },
  {
    id: 16,
    value: 'Von Hippel-Lindau (VHL)',
    label: 'Von Hippel-Lindau (VHL)',
  },
];
export const patientInfo = {
  _id: '6464e0d2c168568e644a048f',
  firstName: 'Ogechi',
  lastName: 'Ahmed',
  emailAddress: 'alf@gmail.com',
  countryCode: '+234',
  phoneNumber: '09058702563',
  gender: 'Female',
  homeAddress: '12a,Anthony Street',
  country: 'Ghana',
  dateOfBirth: '1960-01-01T00:00:00.000Z',
  gNumber: 'G-00810507',
  patientStatus: 'active',
  verificationToken: '',
  verificationTokenExpiry: '',
  refreshToken: '15163fadaf2eabdb283b7d30ab6f0d054d841c9683c89db5b8e4191b8fd59d56301aade99bf41e1c82d0a180abb61799d230',
  maritalStatus: 'Single',
  race: 'African',
  ethnicity: 'Igbo',
  officeAddress: '371 agege motor road',
  createdAt: '2023-05-17T14:12:34.655Z',
  updatedAt: '2023-05-17T14:32:28.365Z',
  medicalRecord: {
    _id: '6464e0d2c168568e644a0491',
    patientId: '6464e0d2c168568e644a048f',
    bloodType: {
      bloodType: 'QA',
      genotype: 'AA',
    },
    allergies: [],
    emergencyContact: [
      {
        firstName: 'John',
        lastName: 'Doe',
        phoneNumber: '+2348161132806',
        emailAddress: 'johnDoe@gmail.com',
        relationship: 'brother',
      },
    ],
    insuranceInformation: [],
    medicalDiagnosis: [],
    familyHospital: [],
    surgeries: [],
    immunizationHistory: [],
    lifestyleHabits: {},
    familyHistory: [],
  },
};
export const patientInfo1 = {
  _id: '6464e0d2c168568e644a048f',
  firstName: 'Ogechi',
  lastName: 'Ahmed',
  emailAddress: 'alf@gmail.com',
  countryCode: '+234',
  phoneNumber: '09058702563',
  gender: 'Female',
  homeAddress: '12a,Anthony Street',
  country: 'Ghana',
  dateOfBirth: '1960-01-01T00:00:00.000Z',
  gNumber: 'G-00810507',
  patientStatus: 'active',
  verificationToken: '',
  verificationTokenExpiry: '',
  refreshToken: '15163fadaf2eabdb283b7d30ab6f0d054d841c9683c89db5b8e4191b8fd59d56301aade99bf41e1c82d0a180abb61799d230',
  maritalStatus: 'Single',
  race: 'African',
  ethnicity: 'Igbo',
  officeAddress: '371 agege motor road',
  createdAt: '2023-05-17T14:12:34.655Z',
  updatedAt: '2023-05-17T14:32:28.365Z',
  medicalRecord: {
    _id: '6464e0d2c168568e644a0491',
    patientId: '6464e0d2c168568e644a048f',
    bloodType: {
      bloodType: '',
      genotype: '',
    },
    allergies: [],
    emergencyContact: [],
    insuranceInformation: [],
    medicalDiagnosis: [],
    familyHospital: [],
    surgeries: [
      {
        procedure: 'chinedu',
        date: '2023-01-01T00:00:00.000Z',
        _id: '6464e9b6263434e75f80136f',
      },
    ],
    immunizationHistory: [],
    lifestyleHabits: [],
    familyHistory: [],
  },
};
export const loggedUser = {
  firstName: 'Gabriel',
  lastName: 'Bade',
  gNumber: 'G-00440114',
};
export const immunizationTypes = [
  {
    id: 1,
    label: 'Hepatitis A',
    value: 'Hepatitis A',
  },
  {
    id: 2,
    label: 'Hepatitis B',
    value: 'Hepatitis B',
  },
  {
    id: 3,
    label: 'Rotavirus',
    value: 'Rotavirus',
  },
  {
    id: 4,
    label: 'Diphtheria, Tetanus, and Pertussis (DTaP)',
    value: 'Diphtheria, Tetanus, and Pertussis (DTaP)',
  },
  {
    id: 5,
    label: 'Tetanus, Diphtheria, and Pertussis (TdaP)',
    value: 'Tetanus, Diphtheria, and Pertussis (TdaP)',
  },
  {
    id: 6,
    label: 'Haemophilus influenzae type b',
    value: 'Haemophilus influenzae type b',
  },
  {
    id: 7,
    label: 'Pneumococcal conjugate (PCV13, PCV15, PCV20)',
    value: 'Pneumococcal conjugate (PCV13, PCV15, PCV20)',
  },
  {
    id: 8,
    label: 'Pneumococcal Polysaccharide (PPSV 23)',
    value: 'Pneumococcal Polysaccharide (PPSV 23)',
  },
  {
    id: 9,
    label: 'Polio virus',
    value: 'Polio virus',
  },
  {
    id: 10,
    label: 'COVID-19',
    value: 'COVID-19',
  },
  {
    id: 11,
    label: 'Influenza',
    value: 'Influenza',
  },
  {
    id: 12,
    label: 'Measles, Mumps, Rubella (MMR)',
    value: 'Measles, Mumps, Rubella (MMR)',
  },
  {
    id: 13,
    label: 'Varicella (VAR)',
    value: 'Varicella (VAR)',
  },
  {
    id: 14,
    label: 'Zoster Recombinant (RZV)',
    value: 'Zoster Recombinant (RZV)',
  },
  {
    id: 15,
    label: 'Human papillomavirus (HPV)',
    value: 'Human papillomavirus (HPV)',
  },
  {
    id: 16,
    label: 'Meningococcal',
    value: 'Meningococcal',
  },
  {
    id: 17,
    label: 'Dengue',
    value: 'Dengue',
  },
  {
    id: 18,
    label: 'Rabies',
    value: 'Rabies',
  },
  {
    id: 19,
    label: 'Cholera',
    value: 'Cholera',
  },
  {
    id: 21,
    label: 'Adenovirus',
    value: 'Adenovirus',
  },
  {
    id: 22,
    label: 'Influenza',
    value: 'Influenza',
  },
  {
    id: 23,
    label: 'Shingles',
    value: 'Shingles',
  },
  {
    id: 24,
    label: 'Tuberculosis (BCG)',
    value: 'Tuberculosis (BCG)',
  },
  {
    id: 25,
    label: 'Typhoid Fever',
    value: 'Typhoid Fever',
  },
  {
    id: 26,
    label: 'Yellow Fever',
    value: 'Yellow Fever',
  },
];
export const allergyTypes = [
  {
    id: 1,
    label: 'Pollen',
    value: 'Pollen'
  },
  {
    id: 2,
    label: 'Dust mites',
    value: 'Dust mites'
  },
  {
    id: 3,
    label: 'Mould',
    value: 'Mould'
  },
  {
    id: 4,
    label: 'Animal dander',
    value: 'Animal dander'
  },
  {
    id: 5,
    label: 'Insect stings',
    value: 'Insect stings'
  },
  {
    id: 6,
    label: 'Insect stings.',
    value: 'Insect stings.'
  },
  {
    id: 7,
    label: 'Latex',
    value: 'Latex'
  },
  {
    id: 8,
    label: 'Food allergens',
    value: 'Food allergens'
  },
  {
    id: 8,
    label: 'Medication',
    value: 'Medication'
  }

]

export const surgicalProcedures = ['laparoscopy', 'appendectomy', 'cholecystectomy', 'abdominoplasty', 'hysterectomy', 'heart transplant', 'colectomy', 'oophorectomy', 'hip replacement', 'laminectomy', 'carotid endarterectomy', 'coronary artery bypass surgery', 'rhinoplasty', 'cataract surgery', 'tonsillectomy', 'mastectomy', 'cesarean section', 'breast augmentation', 'salpingectomy', 'colostomy', 'prostatectomy', 'thyroidectomy', 'gastric bypass surgery',
'hysteroscopy']
export const allergiesTypes = [
  {
    id: 1,
    label: 'Penicillin',
    value: 'Penicillin'
  },
  {
    id: 2,
    label: 'Ampicillin',
    value: 'Ampicillin'
  },
  {
    id: 3,
    label: 'Amoxicillin',
    value: 'Amoxicillin'
  },
  {
    id: 4,
    label: 'Tetracycline',
    value: 'Tetracycline'
  },
  {
    id: 5,
    label: 'Sulfa drugs',
    value: 'Sulfa drugs'
  },
  {
    id: 6,
    label: 'Carbamezepine',
    value: 'Carbamezepine'
  },
  {
    id: 7,
    label: 'Lamotrigine',
    value: 'Lamotrigine'
  },
  {
    id: 8,
    label: 'Phenytoin',
    value: 'Phenytoin'
  },
  {
    id: 9,
    label: 'Aspirin',
    value: 'Aspirin'
  },
  {
    id: 10,
    label: 'Ibuprofen',
    value: 'Ibuprofen'
  },
  {
    id: 11,
    label: 'Chemotherapy drugs',
    value: 'Chemotherapy drugs'
  },
  {
    id: 12,
    label: 'Animal fur or hair',
    value: 'Animal fur or hair'
  },
  {
    id: 13,
    label: 'Dust Mites',
    value: 'Dust Mites'
  },
  {
    id: 14,
    label: 'Eggs',
    value: 'Eggs'
  },
  {
    id: 15,
    label: 'Seafood',
    value: 'Seafood',
  },
  {
    id: 16,
    label: 'Soy',
    value: 'Soy'
  },
  {
    id: 17,
    label: 'Nuts',
    value: 'Nuts'
  },
  {
    id: 18,
    label: 'Gluten',
    value: 'Gluten'
  },
]
export const medicalDiagnosisOptions = [
 'coronary heart disease',
'atherosclerosis',
'cardiomyopathy',
'heart attack',
'pericarditis',
'peripheral vascular disease',
'rheumatic heart disease',
'qortic valve stenosis',
'congestive heart failure',
'atrial fibrillation',
'Essential hypertension',
'Diabetes mellitus',
'Osteoarthritis',
'Depressive disorders',
'Acute respiratory infections',
'Retinal disorders',
'Joint disorders',
'Cataracts',
'Hyperlipidemias',
'Low Back pain',
'Attention-deficit hyperactivity disorder',
'Otitis media',
'Acute pharyngitis',
'Obstructive sleep apnea',
'Glaucoma',
'Coronary atherosclerosis',
'Acute and chronic sinusiti',
'Allergic rhinitis',
'Ardiac dysrhythmias',
'Soft tissue disorders',
'Hypertension',
'Chronic ischemic heart disease',
'Heart failure',
'Cardiac dysrhythmias',
'Acute and chronic bronchitis',
'Behavioral syndromes',
'Opioid-related disorders',
'Dyslipidemia',
'Hypothyroidism',
'Obesity',
'Retinal disorders',
'Diabetic retinopathy',
'Conjunctivitis',
'Inflammation of the eye',
'Actinic keratosis',
'Acne',
'Psoriasis',
'Seborrheic keratosis',
'Contact dermatitis',
'Eczema',
'Urinary tract infection',
'Benign prostatic hyperplasia',
'Calculus of kidney and urethra',
'Gastroesophageal reflux disease',
'Hernias of abdominal cavity',
'Irritable bowel syndrome',
'Anal and rectal diseases',
'Nonmelanoma',
'Malignant neoplasm of breast',
'Benign neoplasm of skin',
'Malignant neoplasm of prostate'
]
export const sleepingSchedules = [
  {
    id: 1,
    label: '2',
    value: '2'
  }, 
  {
    id: 2,
    label: '4',
    value: '4'
  }, 
  {
    id: 3,
    label: '6',
    value: '6'
  }, 
  {
    id: 4,
    label: '8',
    value: '8'
  },
]
export const formData = {
  physicalActivity: {typeOfExercise: 'running'},
  sleepPattern: {},
  dietAndNutrition: {},
  stressManagement: {},
  substanceUse: {},
  mentalHealth: {},
  environmentalFactors: {},
  socialConnections: {},
  safeSexPractises: {},
  birthControl: {},
  sexualDysfunction: {},
}

