import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';

const SexualDysfunction = ({ formData, setFormData, type }) => {
  const [difficulty, setDifficulty] = useState(formData[type].difficulty)
const [specificIssues, setSpecificIssues] = useState(formData[type].specificIssues)
const [discuss, setDiscuss] = useState(formData[type].discussWithHealthProvider)

  const handleDifficulty = (name) => {
    setDifficulty(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], difficulty: name}}));

  };
  const handleIssues  = (name) => {
    setSpecificIssues(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], specificIssues: name}}));
  };
  const handleDiscuss  = (name) => {
    setDiscuss(name);
    setFormData((formData) => ({ ...formData, [type]: {...formData[type], discussWithHealthProvider: name}}));
  };
  return (
    <div className="my-4">
 <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">Do you experience any difficulties related to sexual function?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={difficulty === 'yes'}
            onChange={() => handleDifficulty('yes')}
          />
          
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={difficulty === 'no'}
            onChange={() => handleDifficulty('no')}
          />
        </div>
      </div> 
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">Are there any specific issues you would like to address?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={specificIssues === 'yes'}
            onChange={() => handleIssues('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={specificIssues === 'no'}
            onChange={() => handleIssues('no')}
          />
        </div>
      </div> 
      <div>
        <p className="font-campton_r text-14 text-neutral_black mb-2">Have you discussed these concerns with a healthcare provider?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={discuss === 'yes'}
            onChange={() => handleDiscuss('yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={discuss === 'no'}
            onChange={() => handleDiscuss('no')}
          />
        </div>
      </div>
    </div>
  )
}

export default SexualDysfunction
SexualDysfunction.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};