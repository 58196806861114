import React, { useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';


const BirthControl = ({formData, setFormData, type }) => {
  const [contraception, setContraception] = useState(formData[type].contraception);
const [satisfaction, setSatisfaction] = useState(formData[type].satisfaction);

  const handleSelect = (key, name) => {
    setFormData({ ...formData, [type]: {...formData[type], [key]: name}});
    key === 'contraception' ? setContraception(name) : setSatisfaction(name);
  };

  return (
    <div className="my-4">
      <div className="mb-4">
        <p className="font-campton_r text-14 text-neutral_black mb-2">Are you currently using any form of contraception?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6 one"
            isChecked={contraception === 'yes'}
            onChange={() => handleSelect('contraception', 'yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={contraception === 'no'}
            onChange={() => handleSelect('contraception', 'no')}
          />
        </div>
      </div>
      <div>
        <p className="font-campton_r text-14 text-neutral_black mb-2">Are you satisfied with your current method of contraception?</p>
        <div className="grid grid-cols-12 gap-4">
          <RadioButtonSelect
            label="Yes"
            variant="col-span-6"
            isChecked={satisfaction === 'yes'}
            onChange={() => handleSelect('satisfaction', 'yes')}
          />
          <RadioButtonSelect
            label="No"
            variant="col-span-6"
            isChecked={satisfaction === 'no'}
            onChange={() => handleSelect('satisfaction', 'no')}
          />
        </div>
      </div>
    </div>
  );
};

export default BirthControl;
BirthControl.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  type: PropTypes.string,
};
