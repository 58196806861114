import React from 'react'
import { ReactComponent as DoubleArrowRight } from 'assets/icons/double-arrow-right.svg';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const SubNavbar = ({back, current, backClick, nextClick}) => {
        const navigate = useNavigate()
  return (
      <div className="border-b border-b-neutral_stroke_2">
        <div className="px-6 py-2 text-12 md:text-14 font-campton_r flex space-x-2.5 items-center">
        <p className="text-neutral_body cursor-pointer" onClick={() => navigate(backClick)}>{back}</p>
        <DoubleArrowRight className="text-neutral_body" />
        <p className="text-brand_secondary cursor-pointer" onClick={() => navigate(nextClick)}>{current}</p>
      </div>
      </div>
  )
}

export default SubNavbar
SubNavbar.propTypes = {
        back: PropTypes.string,
        current: PropTypes.string,
        backClick: PropTypes.string,
        nextClick: PropTypes.string
      };