import Button from 'components/Buttons/Button';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import { SlideUp } from 'components/Animations';
import Table from 'components/Table';
import EmptyState from 'components/EmptyState';
import { ReactComponent as MedicationIllustration } from 'assets/svg/medications-empty.svg';
import useTable from 'hooks/useTable';
import { useAuthState } from 'store/modules/auth';
import { useRecordState } from 'store/modules/myRecords';
import useMyRecords from 'hooks/fetch/useMyRecords';
import useDebounce from 'hooks/useDebounce';
import { Search } from 'components/Inputs';
import { therapies_list_header } from 'mocks/myRecords';
import dayjs from 'dayjs';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import DefaultModal from 'components/Modals/DefaultModal';
import ViewTherapy from './ViewTherapy';

const Therapies = ({ callback }) => {
  const { loggedUser } = useAuthState();
  const newParams = {
    type: 'patient',
    patientId: loggedUser._id,
  };
  const { selectLimit, changePage, handleSearch, params } = useTable();
  const { therapies } = useRecordState();
  const { get_therapies, loading } = useMyRecords();
  const [showModal, setShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const debounceValue = useDebounce(params.search);
  const handleSingleView = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  useEffect(() => {
    get_therapies(
      debounceValue ?
        {
          search: debounceValue,
          ...newParams,
        } : newParams,
    );
  }, [debounceValue]);

  return (
    <>
      <SlideUp>
        <div className="pb-4 pt-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex space-x-4 items-center">
              <Button
                theme="transparent"
                className="w-fit rounded-full h-8 font-campton_m text-14"
                onClick={callback}
              >
                <p className="flex space-x-4 items-center">
                  <ArrowBack width="7" height="14" fill="#748181" className="text-14" />
                  <span>Back</span>
                </p>
              </Button>
              <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Therapies</h2>
            </div>
          </div>
          {therapies?.therapies?.length > 0 && (
            <div className="w-[400px]">
              <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            </div>
          )}
          <div className="">
            <Table
              headers={therapies_list_header}
              perPage={therapies?.limit}
              data={therapies?.therapies}
              hasHeader={therapies?.therapies?.length > 0}
              totalCount={therapies?.total}
              currentPage={therapies?.page}
              selectLimit={selectLimit}
              changePage={changePage}
              loading={loading}
              emptyState={
                <EmptyState
                  illustration={<MedicationIllustration />}
                  title="No Therapies Available"
                  description="You currently have no therapy.
                                All your therapies will be displayed here."
                />
              }
            >
              <>
                {therapies?.therapies?.map((item) => (
                  <tr
                    key={item?._id}
                    onClick={() => handleSingleView(item)}
                    data-testid={item?._id}
                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                  >
                    <td className="py-[8px]">
                      <p className="min-w-max mx-4">{item.therapyName}</p>
                    </td>
                    <td className="capitalize py-[8px] text-14">
                      <p className="min-w-max mx-4">
                        {dayjs(item.therapyDate).format('MMM DD, YYYY')}/{item.startTime}
                      </p>
                    </td>
                    <td className="capitalize py-[8px] text-14">
                      <p className="min-w-max mx-4">
                        {dayjs(item.createdAt).format('MMM DD, YYYY')}
                      </p>
                    </td>
                    <td className="px-[10px] py-[8px] capitalize">
                      <ArrowRight fill="#A9B2B2" />
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </div>
      </SlideUp>
      {showModal && (
        <DefaultModal
          closeModal={() => setShowModal(false)}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={selectedItem?.therapyName}
        >
          <ViewTherapy data={selectedItem} />
        </DefaultModal>
      )}
    </>
  );
};

export default Therapies;
Therapies.propTypes = {
  callback: PropTypes.any,
};
