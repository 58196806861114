export const appointments = [
  {
      _id: '64750e05ff9d5fb211fc2038',
      type: 'inApp',
      priority: 'routine',
      appointmentDate: '2023-09-23T00:00:00.000Z',
      startTime: '9:00 AM',
      endTime: '9:00 AM',
      note: 'Nothing',
      status: 'upcoming',
      createdAt: '2023-09-07T05:54:30.619Z',
      updatedAt: '2023-09-07T05:54:30.619Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      id: '6474ba0eeaceab6054c67b1d',
      firstName: 'Katie',
      lastName: 'Combs',
      emailAddress: 'Combs@gmail.com',
      GPINumber: 'GPI-00152839',
      qualification: 'MBChC',
      specialty: 'Dermatology',
    },
    institutionInfo: {
      id: '64a43f20c212288206699b95',
      businessName: 'Creek Hospital',
    },
  },
  {
      _id: '64750df9ff9d5fb211fc2032',
      type: 'onSite',
      priority: 'routine',
      appointmentDate: '2023-12-12T00:00:00.000Z',
      startTime: '4:00 PM',
      endTime: '5:00 PM',
      note: 'Cute doctors only',
      patientId: '647454c426f7af9369701edf',
      specialistId: '6474f2e7e3dd339f13cba809',
      providerId: '6474f2e6e3dd339f13cba7fd',
      status: 'ongoing',
      createdAt: '2023-05-29T20:41:29.809Z',
      updatedAt: '2023-05-29T20:41:29.809Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      firstName: 'John',
      lastName: 'Bravo',
      gender: 'female',
      GPINumber: 'GPI-609298',
      qualification: 'MBBS',
      specialty: 'Cardiology',
      locationName: 'California',
    },
  },
];
export const specialty = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Cardiology' },
  { id: 3, name: 'Neurology' },
  { id: 3, name: 'Dermatology' },
  { id: 3, name: 'Gynaecology' },
];
export const availableStaffs = [
  {
    _id: 1,
    firstName: 'Ojukwu',
    title: 'Doctor',
    lastName: 'Peter',
    location: 'Reddington lekki',
    specialty: 'Cardiologist',
    GPINumber: 'GPI-609298',
    qualification: 'MBBS',
    gender: 'male',
  },
  {
    _id: 2,
    title: 'Doctor',
    firstName: 'Ofem',
    location: 'Reddington lekki',
    specialty: 'Gynaecologist',
    qualification: 'MBBS',
    GPINumber: 'GPI-609298',
    lastName: 'Eunice',
    gender: 'female',
  },
  {
    _id: 3,
    firstName: 'Adeoye',
    location: 'Reddington lekki',
    specialty: 'Endocrinologist',
    qualification: 'MBBS',
    GPINumber: 'GPI-609298',
    title: 'Doctor',
    lastName: 'Lukman',
    gender: 'male',
  },
  {
    _id: 4,
    firstName: 'Ibrahim',
    title: 'Doctor',
    location: 'Reddington lekki',
    specialty: 'Gastroenterologist',
    qualification: 'MBBS',
    GPINumber: 'GPI-609298',
    lastName: 'Moyinoluwa',
    gender: 'female',
  },
  {
    _id: 5,
    title: 'Doctor',
    firstName: 'Awoete',
    location: 'Reddington lekki',
    specialty: 'Neurosurgeon',
    GPINumber: 'GPI-609298',
    qualification: 'MBBS',
    lastName: 'Emmanuel',
    gender: 'male',
  },
];
export const timeSlot = [
  {
    id: 1,
    label: '9:00 AM',
    value: '9:00 AM',
  },
  {
    id: 2,
    label: '10:00 AM',
    value: '10:00 AM',
  },
  {
    id: 3,
    label: '1:00 PM',
    value: '1:00 PM',
  },
  {
    id: 4,
    label: '4:00 PM',
    value: '4:00 PM',
  },
  {
    id: 5,
    label: '6:00 PM',
    value: '6:00 PM',
  },
  {
    id: 6,
    label: '8:00 PM',
    value: '8:00 PM',
  },
  {
    id: 7,
    label: '9:00 PM',
    value: '9:00 PM',
  },
];

export const onGoingAppointment = {
  "_id": "64f74b02c58378172d8af9a5",
  "type": "Telehealth",
  "priority": "urgent",
  "appointmentDate": "2023-09-07T00:00:00.000Z",
  "startTime": "08:00 AM",
  "endTime": "09:00 AM",
  "note": "Treat as urgent because it's an emergency.",
  "status": "ongoing",
  "createdAt": "2023-09-05T15:36:34.211Z",
  "updatedAt": "2023-09-06T11:32:12.256Z",
  "liveCallId": "f7262760-c5e3-49ef-b548-235c93361450",
  "patientInfo": {
    "id": "64bf8e04eefe1fb75883a7a9",
    "firstName": "Dolapo",
    "lastName": "Ireti",
    "emailAddress": "dolapo@mailinator.com",
    "gNumber": "G-00110148",
    "profilePic": ""
  },
  "providerInfo": {
    "id": "64f71160c58378172d8ae153",
    "firstName": "Ade",
    "lastName": "Bolu",
    "emailAddress": "ade@mailinator.com",
    "GPINumber": "GPI-00907322",
    "specialty": ""
  },
  "institutionInfo": {
    "id": "64c118ef8516c9d717a3b9fa",
    "businessName": "Isolo general clinic"
  }
}
export const cancellation_reasons = [
  {
    id: 1,
    label: 'Schedule conflict',
    value: 'Schedule conflict',
  },
  {
    id: 2,
    label: 'Work commitments',
    value: 'Work commitments',
  },
  {
    id: 3,
    label: 'Family emergency',
    value: 'Family emergency',
  },
  {
    id: 4,
    label: 'Travel plans',
    value: 'Travel plans',
  },
  {
    id: 5,
    label: 'Miscommunication with the provider',
    value: 'Miscommunication with the provider',
  },
  {
    id: 6,
    label: 'Others',
    value: 'others',
  },
];