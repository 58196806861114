import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useReduxHook';
import { useAuthState, setUserInfo } from 'store/modules/auth';
import { PHONE_NUMBER_LENGTH } from 'constants';

const Phone = ({ onChange, value, variant, disabled = false }) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAuthState();
  const [labelStyles, setLabelStyles] = useState(false);
  const [error, setError] = useState('')

  const validatePhoneNumber = (phoneNumber) => {
    setError('')

    let phone
    if (phoneNumber.charAt(0) === "0") {
      if (phoneNumber.length > PHONE_NUMBER_LENGTH) return setError('Phone number should not be more than 11 digits.')
      if (/^0\d{10}$/.test(phoneNumber)) {
        phone = phoneNumber.slice(1)
      }
    } else {
      if (phoneNumber.length > (PHONE_NUMBER_LENGTH - 1)) return setError('Phone number should not be more than 10 digits.')
      if (/^[1-9]\d{9}$/.test(phoneNumber)) {
        phone = phoneNumber
      }
      if(phoneNumber.length < (PHONE_NUMBER_LENGTH - 1)) return setError('Phone number should not be less than 10 digits')
    }
    return phone
  }

  const handleChange = (val, country) => {
    setLabelStyles(true);
    const enhancedPhoneNumber = val.slice(country.dialCode.length)
    onChange(val);
    dispatch(setUserInfo({
      ...userInfo,
      phoneNumber: validatePhoneNumber(enhancedPhoneNumber),
      countryCode: '+' + country.dialCode
    }));
  };

  return (
    <div className={`flex flex-col ${variant || 'mb-6'}`}>
      <div className="relative">
        <motion.label
          htmlFor="phone_number"
          animate={{
            scale: 0.8,
            top: !labelStyles && !value ? '8px left-80' : '-16px',
            fontSize: !labelStyles && !value ? '16px' : '14px',
            display: !labelStyles && !value ? 'none' : 'block',
          }}
          className="
                    absolute text-neutral_body mb-2 block text-14
                    font-campton_r bg-neutral_white px-1 pt-2 cursor-text z-10"
        >
          Phone Number
        </motion.label>
        <PhoneInput
          placeholder="Phone number"
          containerStyle={{
            width: '100%',
            borderRadius: '8px',
          }}
          inputProps={{
            disabled: disabled,
            id: 'test-phone_number',
          }}
          buttonStyle={{
            borderBottomLeftRadius: '8px',
            borderTopLeftRadius: '8px',
            borderRight: '0px',
            backgroundColor: disabled ? '#F2F3F3' : '#ffffff'
          }}
          inputStyle={{
            borderRadius: '8px',
            width: '100%',
            color: '#131515',
            border: '1px solid #DFE2E2',
            height: '56px',
            fontFamily: ['campton_r', 'sans-serif'],
            fontSize: '14px',
            boxShadow: '',
            backgroundColor: disabled ? '#F2F3F3' : '#ffffff'
          }}
          // disableCountryCode= {true}
          id="phone_number"
          name="phone_number"
          onChange={(value, country) => handleChange(value, country)}
          value={value}
          country={'ng'}
          countryCodeEditable={false}
          enableSearch
          required
          defaultErrorMessage="sfsfsfs"
        />
      </div>
      {error && <p className={`font-campton_r text-error text-12 mt-[4px]`}>{error}</p>}
    </div>
  );
};

export default Phone;
Phone.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
};
