import DefaultDatepicker from 'components/Datepicker/Index';
import FormWrapper from 'components/FormWrapper';
import { surgicalProcedures } from 'mocks/medicalRecords';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import useMedicalRecord from 'hooks/fetch/useMedicalRecord';
import { ReactComponent as SurgeriesSolid } from 'assets/icons/medicalRecords/surgeries-solid.svg';
import { useRecordState } from 'store/modules/myRecords';
import { Input } from 'components/Inputs';

const AddSurgeries = ({ type, callback, selectedRecord }) => {
  const [formData, setFormData] = useState({
    procedure: '',
    date: '',
  });
  const [selectedSurgeries, setSelectedSurgeries] = useState([]);
  const { update_medical_record, edit_medical_record } = useMedicalRecord();
  const { patientId } = useRecordState()

  useEffect(() => {
    if (selectedRecord && type === 'Edit') {
      setFormData({
        procedure: selectedRecord?.procedure,
        date: new Date(selectedRecord.date)
      });
    }
  }, [selectedRecord, type]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const pushIntoArray = () => {
    setSelectedSurgeries([...selectedSurgeries, formData])
    if (formData.date && formData.procedure) {
      setFormData({
        procedure: '',
        date: ''
      })
    }
  };

  const suggestions = useMemo(() => {
    if(formData.procedure) {
    return surgicalProcedures.filter((item) =>
      item.includes(formData.procedure?.toLowerCase())
    )}  
  }, [formData.procedure])

  const updateMedicalRecord = () => {
    const payload = {
      procedure: formData.procedure,
      date: dayjs(formData.date).format('YYYY-MM-DD'),
    };
    type === 'Edit'
      ? edit_medical_record('surgeries', selectedRecord._id, payload, callback)
      : update_medical_record('surgeries', patientId, payload, pushIntoArray);
  };
  const disabledBtn = !formData?.procedure || !formData?.date

  return (
    <FormWrapper className="h-[400px]">
      <div className="flex justify-between flex-col h-full">
        <div className="grid gap-6 grid-cols-12 mt-6">
          <div className="lg:col-span-6 col-span-12">
              <Input
                label="Surgical Procedure"
                type="text"
                id="surgicalProcedure"
                name="procedure"
                // readOnly={loading}
                value={formData?.procedure}
                onChange={handleChange}
              />
            </div>
          <DefaultDatepicker
            label="Date"
            containerVariant="lg:col-span-6 col-span-12"
            startDate={formData.date}
            maxDate={new Date()}
            onSelect={(date) => setFormData({ ...formData, date })}
            name="date"
            value={formData?.date}
          />
          <div className='col-span-12'>
          <ul className="flex flex-wrap">
              {suggestions?.map(suggestion =>
                  <li
                      key={suggestion}
                      className="text-12 text-neutral_black font-campton_r
                  leading-[18px] bg-[#F2F3F3] py-[4px] px-[8px] mr-[8px] w-fit mb-[8px] cursor-pointer"
                      onClick={() => setFormData({...formData, procedure: suggestion})}
                  >
                      {suggestion}
                  </li>
              )}
            </ul>
            </div>
          {type === 'Add' && (
            <p
              data-testid="test-add"
              className={`text-brand_secondary font-campton_m text-14  ${disabledBtn ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              onClick={disabledBtn ? null : updateMedicalRecord}
              disabled={disabledBtn}
            >
              Add
            </p>
          )}
          
          {selectedSurgeries.length > 0 && (
            <div className="col-span-12 border-t border-dashed border-neutral_stroke pt-6">
              {selectedSurgeries?.map((item) => (
                <div key={item.procedure} className="flex space-x-4 mb-6 last-of-type:mb-0">
                  <SurgeriesSolid />
                  <div className="">
                    <p className="capitalize font-campton_m text-neutral_black text-14">{item?.procedure}</p>
                    <p className="capitalize text-12 text-neutral_body">
                      Date: <span className="font-campton_r text-neutral_black "> {dayjs(item.date).format('YYYY-MM-DD')}</span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-end">
          <button
            className="w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white"
            onClick={type === 'Edit' ? updateMedicalRecord : callback}
          >
            {type === 'Edit' ? 'Save' : 'Close'}
          </button>
        </div>
      </div>
    </FormWrapper>
  );
};

export default AddSurgeries;
AddSurgeries.propTypes = {
  callback: PropTypes.any,
  type: PropTypes.string,
  selectedRecord: PropTypes.any,
};
